.performance-box {
  position: relative;
  width: calc((100% - 32px) / 3);
  height: 140px;
  border: 1px dashed #dadade;
  border-radius: 8px;
  padding: 8px 24px 16px 24px;
  transition: all 0.2s ease;

  &--chart-mode {
    width: 100%;
    padding: 24px;
    height: 315px;
  }

  &__mode-button {
    position: absolute;
    display: grid;
    place-items: center;
    background: #b4b6be;
    width: 24px;
    height: 24px;
    right: -12px;
    bottom: -12px;
    border-radius: 4px;
    cursor: pointer;
  }
}
