.pane-section-header {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #b4b6be;
  margin-bottom: 20px;

  .material-tooltip {
    max-width: 400px;

    &__toggle {
      margin-left: 8px;
    }

    &__content {
      text-align: left;
    }

    &__text {
      max-height: unset;
    }
  }
}

.section-header-details {
  margin-top: 20px;
}
