.publication-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  &__info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-creator {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #74788d;

      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
  }

  &__image {
    margin-top: 8px;
    width: 219px;
    height: 219px;
    object-fit: cover;
    object-position: center;
    background-color: #eff1ff;
    cursor: pointer;

    img {
      margin: 0;
    }
  }

  &__statistics {
    margin-top: 14px;
    width: 100%;
    display: grid;
    grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
    gap: 5px;

    &-item {
      width: 100px;
      display: flex;
      align-items: center;
      height: 24px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #74788d;

      &-wrapper {
        display: flex;
        flex-direction: column;

        &-name,
        &-value {
          width: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-name {
          height: 10px;
          font-size: 10px;
          line-height: 10px;
          color: #a7abc3;
        }

        &-value {
          height: 14px;
          font-size: 10px;
          line-height: 14px;
        }
      }

      span,
      svg {
        width: 20px;
        height: 20px;
      }

      span {
        margin-right: 4px;
      }
    }
  }

  &__description {
    width: 100%;
    margin-top: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #74788d;
    max-height: 58px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    &--one-line {
      display: block;
      height: 18px;
      white-space: nowrap;
      -webkit-line-clamp: 1;
    }
  }

  &__sentiments {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    width: 100%;
    margin-top: auto;

    &-item {
      display: flex;
      align-items: center;
      height: 24px;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #74788d;

      span,
      svg {
        width: 20px;
        height: 20px;
      }

      span {
        margin-right: 4px;
      }
    }
  }

  //Board version
  &--board {
    .publication-box {
      &__image {
        width: 266px;
        height: 266px;
      }

      &__description {
        margin-top: 10px;
      }
    }
  }
}
