.velocity-modal {
  .loader-wrapper {
    margin: auto;
    width: 85px;
    height: 65px;
  }
}

.velocity {
  width: 536px;
  display: flex;
  flex-direction: column;

  &__top {
    display: flex;

    &--tt {
      justify-content: space-between;
    }
  }

  &__info {
    border-right: 1px dashed #d3d5e1;
    flex: 3 0;
  }

  &__stats {
    flex: 2 0;
  }

  &__user {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  &__photo {
    width: 80px;
    height: 80px;
    margin-right: 12px;
    border-radius: 50%;
    background: transparent no-repeat center;
    background-size: cover;
  }

  &__interests {
    &-header {
      font-weight: 500;
      font-size: 10px;
      line-height: 22px;
      color: #a7abc3;
      margin-bottom: 8px;
    }

    .interest-content {
      align-items: flex-start;
    }
  }

  &__header {
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    color: #8e93ac;
    margin-bottom: 8px;
    margin-left: 20px;
    text-transform: uppercase;
    display: flex;
    gap: 4px;
    align-items: center;

    .react-tooltip {
      text-transform: none;
    }

    .tooltip {
      margin-left: 8px;
    }
  }

  &__box {
    background: rgba(#a7abc3, 0.1);
    border-radius: 8px;
    width: 110px;
    height: 72px;
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a7abc3;
    margin-bottom: 4px;
    margin-left: 20px;

    &--high {
      background: rgba(#5ec7eb, 0.1);
      color: #5ec7eb;
    }

    &--low {
      background: rgba(#fd397a, 0.1);
      color: #fd397a;
    }

    .velocity-icon {
      width: 25px;
      height: 25px;
      margin-right: 5px;
    }
  }

  &__item {
    border-bottom: 1px dashed #cacddb;

    &--ig {
      padding: 16px 0 14px 20px;

      &:last-of-type {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    &--tt {
      padding: 16px 0 14px 0px;

      &:last-of-type {
        border-bottom: 0;
      }
    }

    &-title {
      font-weight: 500;
      font-size: 10px;
      line-height: 22px;
      color: #a7abc3;
      margin-bottom: 8px;
    }

    &-content {
      display: flex;
      align-items: center;
    }

    &-value {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #74788d;
      width: 40px;
    }

    .progress-bar {
      margin-left: 24px;
    }
  }
}
