.cdt-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 24px;
  table-layout: fixed;
  position: relative;

  &--layout-auto {
    table-layout: auto;
  }

  tr {
    border-bottom: 1px dashed #d3d5e1;
  }

  th {
    padding: 13px 12px;
    font-weight: 600;
    font-size: 10px;
    line-height: 1.5;
    letter-spacing: 0.03em;
    color: #8e93ac;
  }

  td {
    padding: 12px;
  }

  td,
  th {
    text-align: center;

    &.to-left {
      text-align: left;
    }

    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }

    .dropdown__menu {
      text-transform: none;
    }
  }

  &__checkbox {
    width: 20px;

    .checkbox-wrapper {
      display: flex;
      align-items: center;

      .ws-checkbox {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }

  &__image {
    width: 112px;

    &--small {
      width: 92px;
    }

    &-content {
      width: 100px;
      height: 100px;
      border-radius: 4px;
      background: #fff no-repeat center;
      background-size: cover;
      border: 1px solid #d3d5e1;
      position: relative;

      &--multiple {
        width: 94px;
        height: 94px;

        &::after {
          content: "";
          position: absolute;
          background: #e9eaf0;
          border-radius: 4px;
          bottom: -6px;
          right: -6px;
          width: 94px;
          height: 94px;
          display: block;
          z-index: -1;
        }
      }

      &--rounded {
        border-radius: 50%;
        border: 0;
      }

      &--small {
        width: 80px;
        height: 80px;
      }
    }
  }

  &__column-long {
    width: 400px;
  }

  // TODO: remove column size classes
  &__column-medium-plus {
    width: 250px;
  }

  &__column-medium {
    width: 200px;
  }

  &__column-short-plus {
    width: 150px;
  }

  &__column-short {
    width: 100px;
  }

  &__column-very-short {
    width: 50px;
  }

  &__header-column {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    position: relative;

    &--left {
      justify-content: flex-start;
    }

    > span:first-of-type {
      white-space: nowrap;
    }

    .tooltip {
      margin-left: 8px;

      &__text {
        white-space: normal;
      }
    }

    > .ws-checkbox {
      position: absolute;
      left: 0;
      text-transform: none;
      white-space: nowrap;
    }

    &__text {
      text-transform: uppercase;
    }
  }

  &__number {
    font-weight: 500;
    font-size: 20px;
    color: #45485b;

    &--blue {
      color: #1267fb;
    }

    &--medium {
      font-size: 14px;
    }

    &--small {
      font-weight: 500;
      font-size: 12px;
      line-height: 1.5;
      color: #a7abc3;
    }

    &--light {
      font-weight: 500;
      color: #a7abc3;
    }

    small {
      font-size: 14px;
      margin-left: 16px;
      color: #d3d5e1;

      span {
        margin: 0 8px;
      }
    }
  }

  .influencer-photo {
    position: relative;
    cursor: pointer;

    &__img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background: transparent no-repeat center;
      background-size: cover;
    }

    .flag {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateX(-50%);
      height: 16px;
      width: 16px;
      border-radius: 100%;
      object-fit: cover;
      outline: solid white 3px;
      cursor: default;
    }
  }
}

.table-no-result {
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: #a7abc3;
  text-align: left;
  border-bottom: 0 !important;
  margin-bottom: 48px;

  > div {
    text-align: left;
  }

  .no-results {
    margin-bottom: 0;
  }
}
