@import "src/styles/variables.scss";

html body .material-tooltip {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  background: #45485b;
  color: #dadade;
  border-radius: 8px;
  padding: 8px 12px;
  opacity: 1;
  z-index: $z-index-max;
  max-width: 250px;

  &__toggle {
    display: flex;

    img {
      width: 16px;
      height: 16px;
    }
  }

  &--center {
    text-align: center;
  }
}
