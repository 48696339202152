@import "src/styles/mixins.scss";

.plan-limit-reached-modal {
  max-width: 600px;
  min-width: 600px;
  padding: 16px 24px;

  &--warning {
  }

  &--info {
    .stigg-subscription-usage-layout > div:first-of-type {
      display: none;
    }
  }

  &__highlighted-text {
    background-color: #fdf5eb;
    border-radius: 4px;
    margin: 12px 0;
    padding: 8px 10px;
    list-style-type: disc;
    list-style-position: inside;

    li {
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: #45485b;
    }
  }

  .modal {
    &__scroller {
      .stigg-subscription-usage-layout {
        border: none;
        padding: 0;
      }

      .stigg-subscription-usage-layout > div:first-of-type {
        margin-bottom: 20px;
      }

      .stigg-customer-portal-usage-section-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #7d808d;
      }

      .MuiGrid {
        &-container {
          flex-basis: auto;
          flex-direction: column;
          flex-wrap: nowrap;
          width: 100%;
          margin: 0;
          gap: 20px;
        }

        &-item {
          width: 100%;
          padding: 0;
          flex-basis: auto;
          max-width: unset;
        }
      }
    }

    &__buttons {
      margin-top: 16px;
    }
  }
}
