.table-settings-dropdown {
  .dropdown {
    width: 100%;

    &__menu {
      min-width: 210px;

      &-item {
        grid-gap: unset;
        gap: unset;
      }
    }
  }

  &__layout-wrapper,
  &__tab-wrapper {
    display: flex;
    align-items: center;
  }

  &__layout {
    display: flex;
    justify-content: space-between;

    &--active {
      background-color: #f6f7f9;
    }
  }

  &__tab-wrapper {
    color: #8f919d;

    svg {
      margin-left: 4px;
      transition: all 0.2s ease;

      path {
        fill: #8f919d;
      }
    }

    &--rotated {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__layout-options {
    height: 0;
    transition: all 0.2s ease;
    opacity: 0;
    overflow: hidden;

    .image-select-option {
      grid-gap: unset;
      gap: unset;
      padding-left: 28px;

      svg path {
        fill: #45485b;
      }
    }

    &--visible {
      height: 76px;
      opacity: 1;
    }
  }

  &__settings {
    border-top: solid 1px #ededef;
  }
}
