@import "../../../../../styles/media-queries.scss";
@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include custom-scrollbar;

  @include max-screen($desktopWidth) {
    overflow-y: auto;
    padding-right: 16px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &--to-top {
      align-items: flex-start;
    }

    &--with-separator {
      border-top: 1px dashed #cacddb;
      margin-top: 0;
      padding-top: 16px;

      .ws-react-select {
        width: 160px !important;
      }
    }

    &--with-target {
      .ws-react-select {
        width: 218px !important;
      }

      .target-value-select {
        .ws-react-select-container {
          height: 40px;
          margin-bottom: 0;
        }
      }

      .ws-input {
        width: 218px;
      }
    }

    &:not(:first-of-type) {
      margin-top: 16px;
    }

    &-switch-wrapper {
      display: flex;
      align-items: center;
      color: #8e93ac;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;

      .switch-button {
        margin: {
          left: 0;
          right: 8px;
        }
      }
    }

    &-value {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #74788d;

      svg {
        margin-left: 16px;
        cursor: pointer;
      }
    }

    .ws-react-select {
      width: 260px;
    }

    .modal__column:nth-child(2n) {
      .ws-react-select {
        width: 160px;
      }
    }

    &--equal-inputs {
      .modal__column {
        width: 210px;
      }

      .ws-react-select {
        width: 100% !important;
      }

      svg {
        margin-top: 18px;
        cursor: pointer;
      }
    }

    &--with-cross {
      justify-content: flex-start;

      .modal__column {
        width: 195px;
        margin-right: 20px;
      }
    }

    &-button {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: #5d78ff;
      cursor: pointer;

      .tooltip {
        margin-left: 8px;
      }
    }
  }
}
