.projects-data-table__row {
  position: relative;

  width: fit-content;
  min-width: 100%;

  &--header {
    position: sticky;
    top: 112px;
    z-index: 6;
    background: white;
  }
}
