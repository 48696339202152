.code-settings-dropdown {
  &__button {
    width: 44px;
    height: 44px;
    padding: 0;

    svg {
      margin-right: 0;
      transform: rotate(90deg);
    }
  }
}
