.edit-option-pane {
  width: 320px;
  padding: 8px 0;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  opacity: 0;
  visibility: hidden;

  &--open {
    opacity: 1;
    visibility: visible;
    display: block;
  }

  .input-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    input {
      text-align: left;
      width: calc(100% - 30px);
      margin: {
        bottom: 8px;
      }
    }
  }

  .dropdown__menu-item {
    height: 32px;
    justify-content: space-between;
    display: flex;

    .tick-icon {
      margin: 0;
    }

    .option-wrapper {
      &__left {
        display: flex;
        align-items: center;
      }
    }

    .color-box {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      border-radius: 5.71429px;
      border: 0.714286px solid rgba(0, 0, 0, 0.1);
    }
  }

  .ws-input {
    border: solid transparent 1px;

    &:focus {
      border: 1px solid rgba(93, 120, 255, 0.6);
    }
  }
}
