.post-preview {
  border: 0;

  &:hover {
    .post-preview__hover-layer {
      display: block;
    }
    .post-preview__bonus-content {
      display: none;
    }
  }

  &--universal {
    background: #f2f2f6
      url("../../../../../../../images/placeholder-universal.svg") no-repeat
      center !important;
    background-size: 48px 48px;
    border: 0;

    .post-preview__hover-layer {
      display: none !important;
    }
  }

  &--video {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url("../../../../../../../images/movie-roll.svg") no-repeat
        center;
      background-size: cover;
      transform: rotate(90deg);
      border-radius: 4px;
    }
  }

  &--error {
    background: #f2f2f6 url("../../../../../../../images/placeholder-image.svg")
      no-repeat center !important;
    background-size: 24px 24px !important;
    border: 0;

    .post-preview__hover-layer {
      display: none !important;
    }
  }

  &--error-video {
    background-image: url("../../../../../../../images/placeholder-video.svg") !important;

    &::before {
      display: none;
    }
  }

  &--hide-error-img {
    display: none;
  }

  &__bonus-content {
    z-index: 4;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(#000, 0.6)
        url("../../../../../../../images/present-full.svg") no-repeat 60% 50%;
      background-size: 32px 32px;
      border-radius: 4px;
      opacity: 0.5;
    }

    &::before {
      content: attr(data-count);
      position: absolute;
      top: 0;
      left: 18px;
      bottom: 0;
      width: 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: rgba(#fff, 0.7);
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__hover-layer {
    cursor: pointer;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    border-radius: 4px;
    overflow: hidden;

    &::after,
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    &::before {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    }

    &::after {
      background: url("../../../../../../../images/post-preview-image.svg")
        no-repeat center;
      background-size: 32px 32px;
    }

    &--video {
      &::after {
        background-image: url("../../../../../../../images/post-preview-video.svg");
      }
    }

    &--link {
      &::after {
        background-image: url("../../../../../../../images/link.svg");
      }
    }
  }

  &__label {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 4px;
    z-index: 2;
    padding: 5px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    &--amended {
      background: linear-gradient(
        180deg,
        rgba(253, 57, 122, 0) 50%,
        #fd397a 100%
      );
    }

    &-text {
      font-weight: 500;
      font-size: 8px;
      line-height: 16px;
      color: #fff;
      text-align: center;
    }
  }

  &__status {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 4px;
    overflow: hidden;
    z-index: 1;
    padding: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transform: rotate(-180deg);
    }

    &-text {
      font-weight: 500;
      font-size: 8px;
      line-height: 16px;
      color: #fff;
      text-align: center;
      z-index: 2;
    }

    &--accepted {
      &::after {
        background: linear-gradient(
          180deg,
          rgba(71, 196, 171, 0) 50%,
          #47c4ab 100%
        );
      }
    }

    &--revised {
      &::after {
        background: linear-gradient(
          180deg,
          rgba(120, 72, 199, 0) 50%,
          #7848c7 100%
        );
      }
    }

    &--rejected {
      &::after {
        background: linear-gradient(
          180deg,
          rgba(253, 57, 122, 0) 50%,
          #fd397a 100%
        );
      }
    }
  }
}
