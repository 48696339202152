.rename-coupon-modal {
  max-width: 600px;
  min-width: 600px;

  &__input-wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    input {
      width: 100%;
    }
  }
}
