.tag-cloud {
  align-content: space-evenly;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 50px;
  padding: 5px;
  width: inherit;

  &__item {
    margin: 0 3px;
  }

  &--blue {
    color: #5d78ff;
  }

  &--green {
    color: #38a48e;
  }
}
