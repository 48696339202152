.chart-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-info {
      display: flex;
      align-items: center;
    }

    svg {
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }
  }

  &__text {
    display: flex;
    align-items: flex-end;
  }

  &__value {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    color: #45485b;
  }

  &__title {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    margin-left: 20px;
    color: #6a6d7c;
  }

  &__views {
  }

  &__view {
    border: 1px solid #dadade;
    color: #45485b;
    padding: 8px 12px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;

    &:first-of-type {
      border-radius: 8px 0px 0px 8px;
    }

    &:last-of-type {
      border-radius: 0px 8px 8px 0px;
    }

    &--active {
      background: #dadade;
    }

    &--symmetrical {
      border-radius: 8px !important;
    }
  }
}
