.brand-safety-email-notification-info {
  display: flex;
  gap: 8px;
  align-items: center;

  &__col {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &--big {
    .brand-safety-email-notification-info__name {
      font-size: 20px;
    }
  }

  a.brand-safety-email-notification-info__name {
    &:hover {
      opacity: 0.7;
    }
  }

  &__name-wrapper {
    display: flex;
    align-items: center;
  }
}
