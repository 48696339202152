.empty-content-proposal-list {
  &__header {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &__description {
    font-size: 14px;
    font-weight: 400;
  }
}