.contact-data {
  display: flex;
  gap: 20px;
  flex-direction: column;

  &-div {
    min-height: 60px;
    &__height {
      min-height: 180px;
    }

    &__row {
      display: flex;
      gap: 20px;
      align-items: center;
      padding: 10px 0px;

      &__icon {
        padding: 5px;
        background: #ddd;
        border-radius: 50%;

        img {
          display: block;
          margin: auto;
          width: 15px;
          height: auto;
        }
      }

      &-text {
        font-size: 13px;
        word-break: break-all;

        &__blur {
          filter: blur(10px);
        }
      }
    }

    &__access-denied-div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-left: 20px;
      width: 70%;

      &__content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        font-size: 13px;
        text-align: center;

        img {
          width: 30px;
        }
      }
    }

    &__loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 50px;
      }
    }
  }

  &__show-contact-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
  }
}


