.profile-data-pane__export-dropdown {
  &__toggle {
    cursor: pointer;

    &:hover {
      path,
      rect {
        fill: #536ceb;
      }
    }
  }

  &__menu {
    svg path {
      fill: #6a6d7c;
    }
  }
}
