.ws-page-default {
  padding: 26px 48px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  overflow: auto;

  &__topbar {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
}
