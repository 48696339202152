.title-box {
  display: flex;

  img {
    object-fit: cover;
    width: 80px;
    height: 80px;
    margin-right: 20px;
    border-radius: 3px;
  }

  &__info {
    display: flex;
    flex-direction: column;

    &-header {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #a7abc3;
    }

    &-value {
      margin-top: 8px;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #74788d;
    }
  }
}
