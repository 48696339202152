.project-summary-dashed-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__value {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #74788d;

    &--text {
      display: block;
      white-space: pre-wrap;
      text-align: center;
      font-size: 12px;
      word-break: break-all;
      max-height: 120px;
      overflow: auto;
      width: 210px;
      padding: 0 6px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px transparent;
        border-radius: 2px;
        background-color: #e0e2ea;
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #a7abc3;
        border-radius: 30px;
      }
    }

    .ws-table__field-value-icon {
      margin-right: 16px;
      svg,
      span {
        width: 40px;
        height: 40px;
      }
    }
  }

  &__label {
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    color: #47c4ab;
    padding: 4px 8px;
    border-radius: 8px;
  }

  &__member {
    display: flex;
    align-items: center;
  }

  &__member-avatar {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 50%;
  }

  &__member-name {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #74788d;
  }
}
