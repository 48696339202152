.single-provider {
  display: flex;
  gap: 6px;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  color: #a7abc3;

  &--highlight {
    order: -1;
    color: #45485b;
  }

  &--tik_tok {
    gap: 3px;
  }

  &:hover {
    opacity: 0.7;
  }
}
