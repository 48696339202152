.formula-switch {
  margin-bottom: 16px;

  &__input-wrapper {
    display: flex;

    .ws-radio:first-of-type {
      margin-right: 48px;
    }
  }
}
