.metadata-options-field-switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 8px;
  padding-right: 16px;
  background-color: white;
  // transition: background 0.2s ease;
  border-radius: 4px;
  gap: 8px;
  left: auto !important;

  &:hover {
    background: #f2f2f6;
  }

  &__name {
    display: flex;
    align-items: center;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #45485b;
    word-break: break-word;

    svg {
      margin-right: 8px;
      height: 20px;
      width: 20px;
    }
  }

  &__drag {
    display: grid;
    place-items: center;
  }
}
