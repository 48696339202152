.audience-result-loading {
  &__username {
    color: #8e93ac;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-align: left;
  }

  &__adding-result {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__warning {
    color: #45485b;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
  }

  &__warning-icon {
    margin-right: 4px;
  }

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #45485b;
    margin-top: 4px;
  }

  &__show-reason {
    color: #1267fb;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    margin-top: 4px;

    .tooltip__content {
      max-width: 600px;
      width: unset;
      white-space: nowrap;
    }

    .tooltip__text {
      text-align: left;
      ul {
        list-style-type: disc;
        padding-left: 16px;
      }
    }
  }

  &__remove-icon {
    width: 24px;
    height: 24px;

    &:hover {
      cursor: pointer;
    }
  }

  .loader-wrapper {
    width: 64px;
    height: 122px !important;
    display: flex;
    margin: 0 auto;
  }

  .cdt-table__checkbox {
    opacity: 0;
  }
}
