.publication-synchronization {
  width: 100%;

  &__link {
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    color: #b4b6be;
    cursor: pointer;
  }

  &__row {
    margin-top: 16px;
    min-height: 70px;

    .ws-react-select {
      &-wrapper {
        height: 48px;
      }

      &__control {
        min-height: 48px;
      }

      &__menu::before {
        top: -47px;
      }

      &__placeholder {
        top: 15px;
      }

      &__indicator {
        margin-top: 14px;
      }
    }
  }

  .modal__title {
    display: flex;
    gap: 16px;
  }

  .modal__buttons {
    display: none;
  }

  &__label {
    color: #8e93ac;
    font-size: 11px;
    padding: 4px 8px;
    background-color: #f2f2f6;
    border-radius: 8px;

    &--active {
      color: #1d9880;
      background-color: #daf3ee;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;

    .loader-wrapper {
      display: flex;
      align-items: center;
      height: 46px;
      width: 48px;
      margin: 0 24px;
    }

    &-done {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: #7d808d;
      padding: 12px;
      margin-left: 8px;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 4px;

        path {
          fill: #47c4ab;
        }
      }
    }

    &-back {
      padding: 12px;
      width: 100px;

      svg {
        margin-right: 4px;

        path {
          fill: #7d808d;
        }
      }
    }

    &-wrapper {
      display: flex;
      align-items: center;

      .ws-button {
        padding: 12px;
      }

      &--multiple {
        .ws-button {
          margin-left: 8px;
        }
      }
    }
  }
}
