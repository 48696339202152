.board-column {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 86px;
  min-width: 300px;
  width: 300px;
  transition: all 0.2s ease;

  &__placeholder {
    position: absolute;
    background: #f5f7ff;
    border-radius: 12px;
    margin-top: 80px;
    transition: all 0.2s ease;
    z-index: 1;
  }

  &--dragging-over {
    // background-color: red;
  }

  &:not(:last-of-type) {
    margin-right: 16px;
  }

  &__topbar {
    display: flex;
    width: 100%;
    min-height: 44px;
    height: 44px;
    margin: {
      top: 32px;
      bottom: 4px;
    }

    &-content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 24px;
    }

    h1 {
      display: block;
      overflow: hidden;
      max-height: 24px;
      color: #74788d;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      max-width: calc(100% - 36px);
    }

    &-counter {
      display: grid;
      place-items: center;
      color: white;
      width: 24px;
      height: 24px;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      background: #d3d5e1;
      border-radius: 6px;
      margin-left: 12px;
    }

    &-buttons {
      display: flex;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;
        cursor: pointer;

        path {
          fill: #d3d5e1;
          transition: all 0.3s ease;
        }

        &:not(:last-of-type) {
          margin-right: 4px;
        }

        &:hover {
          path {
            fill: #5d78ff;
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__label {
    display: flex;
    align-items: center;
    position: relative;

    &-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .custom-image-wrapper {
      width: 24px;
      height: 24px;
    }

    &-image {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 4px;

      &--content {
        border-radius: 4px;
      }
    }
  }

  &__task-placeholder {
    position: absolute;
    width: 300px;
    height: 112px;
    background: linear-gradient(
      180deg,
      #f5f6f9 0%,
      rgba(250, 250, 251, 0) 100%
    );
    border-radius: 12px;
    color: #cacddb;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: -1;

    svg {
      margin-bottom: 8px;
    }
  }
}
