.simple-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;

  &__title {
    display: flex;
    align-items: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    color: #8f919d;
    text-transform: uppercase;
    height: 32px;
  }

  &__value {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: #45485b;

    svg {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }
  }
}
