.country-selector {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  &__checkbox-wrapper {
    display: flex;
  }

  &__select-all-countries {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-left: auto;
    align-items: center;
    font-size: 13px;
    font-weight: 300;

    span {
      display: inline-block;
    }
  }

  &__provider {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #45485b;
    margin: 0;
    margin-left: 8px;
  }

  &__input {
    width: 100%;
    margin: {
      top: 8px;
      bottom: 16px;
    }
  }
}
