.field-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 4px;
  transition: all 0.2s ease;
  border-radius: 4px;
  font-size: 12px;

  &:hover {
    background: #f2f2f6;
    .field-switch__data-edit-icon {
      opacity: 1;
    }

    .field-switch__data-trash-can-icon {
      opacity: 1;
    }
  }

  &__data {
    display: flex;
    align-items: center;
    overflow: hidden;

    &-grid-icon {
      margin-right: 4px;
      flex-shrink: 0;
    }

    &-name {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #8e93ac;
      margin-right: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-edit-icon {
      opacity: 0;
      flex-shrink: 0;
      transition: all 0.2s ease;
      cursor: pointer;
    }

    &-trash-can-icon {
      opacity: 0;
      flex-shrink: 0;
      transition: all 0.2s ease;
      cursor: pointer;
      transform: translatey(-2px) scale(0.9);
      margin-left: 2px;
    }
  }

  .switch-button {
    flex-shrink: 0;
  }
}
