.preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(#000, 0.4);
  z-index: 1030;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview {
  max-width: 600px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  position: relative;

  &--video {
    background-color: transparent;
  }

  &__img-wrapper {
    position: relative;
  }

  &__img {
    max-width: 100%;
    display: block;
    max-height: 95vh;
  }

  &__img-loader {
    position: absolute;
    top: -8px;
    left: -8px;
    height: calc(100% + 16px);
    width: calc(100% + 16px);
    filter: blur(8px);
  }

  &__video {
    max-width: 100%;
    display: block;
    max-height: 95vh;
    position: relative;
  }

  &__close {
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.35);
    z-index: 10;
    border-radius: 8px;

    svg path {
      fill: white;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &__slide {
    display: none;

    &--active {
      display: block;
    }
  }

  &__button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    background: rgba(0, 0, 0, 0.35) no-repeat center;
    background-size: 24px 24px;
    cursor: pointer;
    z-index: 10;
    border-radius: 8px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }

    &--prev {
      left: 20px;
      background-image: url("../../../../../../../images/arrow-left-white.svg");
    }

    &--next {
      right: 20px;
      background-image: url("../../../../../../../images/arrow-right-white.svg");
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }

  &__counter {
    position: absolute;
    right: 55px;
    top: 16px;
    font-weight: 600;
    font-size: 13px;
    line-height: 21px;
    color: #fff;
    z-index: 1;
    padding: 4px 9px;
    height: 32px;
    background-color: rgba(0, 0, 0, 0.35);
    display: flex;
    align-items: center;
    border-radius: 8px;
  }
}
