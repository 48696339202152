.wsProjectReportShowcasePdfReadyModal {
  min-width: 200px;
  width: 600px;

  .modal__body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .modal__buttons {
    margin-top: -60px;
    justify-content: flex-start;
  }

  &__text {
    display: block;
  }

  &__icon {
    svg {
      width: 200px;
      height: 200px;
      margin-left: auto;
      margin-right: 0;
    }
  }
}