@import "src/styles/media-queries.scss";
@import "src/styles/variables.scss";
@import "src/styles/mixins.scss";

.team-details {
  &__link {
    display: inline;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    color: #b4b6be;
    cursor: pointer;
  }

  &__title,
  &__title-input {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    color: #45485b;
  }

  &__title {
    display: flex;
    align-items: center;

    &-input {
      border: none;
      outline: none;
      padding: 0;
      color: #a1a3ad;
    }

    svg {
      margin-left: 12px;
      cursor: pointer;

      path {
        fill: #6a6d7c;
      }
    }
  }

  &__list {
    &-wrapper {
      max-height: 220px;
      overflow-y: auto;
      @include custom-scrollbar;

      @include min-screen($desktopWidth) {
        max-height: 270px;
      }

      @include min-screen($desktopLargeWidth) {
        max-height: 410px;
      }
    }

    &-topbar {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: #45485b;
      height: 36px;
      border-bottom: solid 1px #ededef;
    }

    &-member {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;
      padding-right: 12px;

      &:not(:last-of-type) {
        border-bottom: solid 1px #ededef;
      }

      &-info {
        display: flex;
        align-items: center;
      }

      &-cover {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 12px;
      }

      &-data {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &-name {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        color: #45485b;
      }

      &-email {
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
        color: #8f919d;
      }

      &-delete-icon {
        cursor: pointer;
      }
    }
  }
}
