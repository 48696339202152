.table-view-selector {
  &__menu {
    width: 240px;
    margin-top: 48px;

    .view-option {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__content {
        display: flex;
        align-items: center;
      }

      .trashcan {
        opacity: 0;

        &:hover {
          path {
            fill: #45485b;
          }
        }
      }

      &:hover {
        .trashcan {
          opacity: 1;
        }
      }
    }

    .dropdown__menu-separator {
      padding: 0;
      border-color: #ededef;
    }
  }

  &__save-view-button {
    color: #5d78ff;
  }
}
