.book-demo-modal {
  .modal__title {
    font-size: 24px;
    line-height: 32px;
  }

  .ws-react-select-container {
    margin-bottom: -8px;
  }

  &__calendar-img,
  &__other-markets-img {
    width: 319px;
    display: block;
    margin: 0 auto;
  }

  &__avatar {
    display: block;
    margin: 0 auto;
    width: 80px;
    height: 80px;
  }

  &__contact-person {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #45485b;
    margin-top: 20px;

    span {
      font-weight: 600;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #45485b;
    text-align: center;
    margin-top: 16px;
  }

  &__btn {
    width: 160px;
  }

  &__other-markets {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__other-markets-contact {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #45485b;
  }

  &__other-markets-mail {
    a {
      color: #5d78ff;
      font-weight: 500;
    }

    font-size: 14px;
    line-height: 24px;
  }
}
