.interest-wrapper {
  &__button {
    background: #ededef;
    padding: 0;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    border: 0;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;

    svg path {
      fill: #6a6d7c;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__tooltip-btn {
    width: 26px;
    margin: 0 auto;
  }

  .tooltip {
    width: 25px;
    margin: 0 auto;
  }
}

.interest-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.interest {
  padding: 4px 0;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #45485b;

  &--selected {
    background: #f2f2f6;
    color: #a7abc3;
    padding: 4px 8px;

    &::before {
      content: "";
      background: url("../../../../../../../images/tick.svg") no-repeat center;
      width: 16px;
      height: 16px;
      display: block;
      margin-right: 4px;
    }
  }
}

.interest-wrapper__tooltip-content {
  width: 280px !important;
  max-width: unset;
  border-radius: 8px;
  padding: 8px 12px;
  box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
  opacity: 1;

  &::before {
    border-color: #45485b transparent transparent transparent !important;
  }

  .tooltip__text {
    padding: 0 10px;
    max-height: 300px;
    background: #45485b;

    .interest {
      background: #45485b;
      color: #dadade;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 2px;
    width: 5px;
    background: #ededef;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    width: 5px;
    background: #8f919d;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #6e6f77;
  }
}
