.cropped-image-uploader {
  &__label {
    display: flex;
    justify-content: center;
    gap: 16px;
    align-items: center;
    text-align: center;
    width: 300px;
    margin: 50px auto;
    height: 296px;

    position: relative;

    &__avatar-without-photo {
      transition: all 0.2s ease;
      width: 68px;
      height: 68px;
      background-image: url("../../../images/avatar-without-photo.svg");
    }

    &__choose-image {
      transition: all 0.2s ease;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #a7abc3;
    }

    &:hover {
      .cropped-image-uploader__label__avatar-without-photo {
        background-image: url("../../../images/avatar-without-photo-hover.svg");
      }
      .cropped-image-uploader__label__choose-image {
        color: #5d78ff;
      }
    }
  }
  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  &--circle {
    .cropped-image-uploader__label {
      border-radius: 50%;
    }
  }

  .croppie-container .cr-slider-wrap {
    width: 82%;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 11px;

    &::before,
    &::after {
      padding-top: 1px;
      color: #7d808d;
      font-size: 24px;
    }

    &::before {
      content: "-";
    }
    &::after {
      content: "+";
    }
  }

  input[type="range"] {
    max-width: 82%;
    &::-webkit-slider-thumb {
      background: #8f919d;
    }
    &::-webkit-slider-runnable-track {
      background: #e0e2ea;
      height: 4px;
      border-radius: 8px;
    }
  }
}
