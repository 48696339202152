@import "../../../../styles/media-queries.scss";
@import "../../../../styles/variables.scss";

.manage-fields {
  width: calc(100% + 24px);
  max-height: 655px;
  overflow: auto;
  padding: {
    right: 24px;
    bottom: 12px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f2f2f6;
    border-radius: 30px;
    width: 6px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #a7abc3;
    border-radius: 30px;
    width: 6px;
    height: 64px;
  }

  .global-fields__list {
    height: 110px;

    @include min-screen($desktopWidth) {
      height: 170px;
    }

    @include min-screen($desktopLargeWidth) {
      height: 310px !important;
      max-height: 310px !important;
    }
  }
}
