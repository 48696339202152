.settings-separator {
  width: 100%;
  height: 0;
  border-bottom: 1px dashed #dadade;
  margin: {
    top: 20px;
    bottom: 20px;
  }
}

.settings-row-element {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left {
    display: flex;
    flex-direction: column;

    &-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #45485b;
    }

    &-description {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #8f919d;
      display: flex;
      gap: 10px
    }
  }

  .ws-switch {
    padding-left: 16px;
  }
}
