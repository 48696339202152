.location-list {
  width: 100%;

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    justify-content: space-between;
  }

  &__label {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #a7abc3;
    display: flex;
    align-items: center;

    img {
      margin-right: 8px;
    }
  }

  &__value {
    width: 46px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #74788d;
    white-space: nowrap;
  }
}
