.settings-tab-content {
  padding: 20px 24px;
  width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__loader {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
  }

  &__buttons {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    background-color: #fff;

    &-left-align {
      justify-content: flex-start;
      gap: 20px;
    }

    &::after {
      content: "";
      position: absolute;
      width: calc(100% + 48px);
      height: 1px;
      background-color: #ededef;
      left: -24px;
      top: 0;
    }
  }
}
