@import "../../../../../styles/mixins.scss";

.creator-messenger {
  overflow: auto;
  @include custom-scrollbar;
  padding-right: 4px;

  .extensions__item {
    padding: 0;
    border-bottom: none;
  }

  &__content {
    gap: 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .tabs {
      margin-bottom: 0;
    }
  }

  &__input-row {
    display: flex;
    gap: 24px;
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &__general-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 10px;
  }

  &__table-wrapper {
    .ws-custom-table th:first-child,
    .ws-custom-table tbody .ws-custom-table-row td:first-child {
      padding-left: 0;
    }
  }

  &__settings-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .modal__row {
      align-items: center;
    }
    .modal__row .ws-react-select {
      width: 302px;
    }
  }

  &__settings-label {
    font-weight: 400;
    font-size: 13px;
    color: #45485b;
  }

  &__amount-select-option {
    display: flex;
    align-items: center;
  }

  &__connection-test {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #8f919d;
    font-size: 13px;
  }

  .ws-react-select-container {
    width: 273px;
  }

  &__text-area {
    padding: 12px 16px;
    resize: none;
    width: 100%;
    height: 104px;
    border: 0;
    outline: 1px solid #b4b6be;
    border-radius: 8px;
    margin-bottom: 0 !important;
    @include custom-scrollbar;
    &:focus {
      border: 0;
      outline: 1px solid rgba(93, 120, 255, 0.6);
    }
  }

  &__legal-text {
    padding-bottom: 10px;
    padding-left: 1px;
  }
}
