@import "src/styles/variables.scss";

.selected-tasks-bar {
  display: flex;
  align-items: center;
  padding: 0 46px;
  position: fixed;
  width: fit-content;
  box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  left: calc(50% + (72px - 17px) / 2);
  transform: translateX(-50%);
  height: 64px;
  bottom: 36px;
  border-radius: 130px;
  z-index: $z-index-popover;
  transition: all 0.2s ease;

  &--with-tooltip {
    left: calc(50% + (72px - 17px + 476px) / 2);
  }

  &--hidden {
    transform: translateX(-50%) translateY(100%);
    opacity: 0;
    pointer-events: none;
  }

  .loader-wrapper {
    height: 40px;
    width: 52px;
  }

  &__counter {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #5d78ff;

    &-number {
      display: grid;
      place-items: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      min-width: 24px;
      padding: 0 5px;
      height: 24px;
      margin-left: 8px;
      background: #5d78ff;
      border-radius: 6px;
      color: white;
    }
  }

  &__separator {
    margin: 0 46px;
    height: 32px;
    width: 1px;
    background-color: #d3d5e1;
  }

  button {
    color: #74788d;
    white-space: nowrap;
  }
}

.dd-active .selected-tasks-bar {
  transform: translateX(-50%) translateY(100%);
  opacity: 0;
  pointer-events: none;
}

#selected-tasks-bar__tooltip {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  background: #45485b;
  color: #dadade;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
  width: 476px;
  height: 98px;
  opacity: 1;

  @media screen and (min-width: 1500px) {
    left: -486px !important;
    top: -18px !important;
    .react-tooltip-arrow {
      top: 46px !important;
      right: -4px !important;
      left: unset !important;
    }
  }

  .react-tooltip-arrow {
    background: #45485b;
  }

  button {
    color: #ffffff;
    position: absolute;
    background: #45485b;
    right: 16px;
    bottom: 16px;
    border: 1px solid #5d78ff;
  }
}
