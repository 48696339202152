.crediblity-tool-exploring {
  color: #585b6c;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  line-height: 24px;
  width: 100%;
  height: 44px;
  align-items: center;
  padding: 30px;
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  border-bottom: 1px solid #EDEDEF;

  &__content {
    display: flex;
    gap: 12px;
  }

  &__button {
    color: #45485B;
    cursor: pointer;
    transition: color 0.2s ease;
    font-weight: 500;

    &:hover {
      color: #45485B;
    }
  }

  &__close {
    width: 24px;
    height: 24px;
    cursor: pointer;

    path {
      transition: all 0.2s ease;
    }

    &:hover {
      path {
        fill: #585b6c;
      }
    }
  }

  &__face-icon {
    width: 24px;
    height: 24px;
  }
}
