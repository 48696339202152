.label-selector {
  position: relative;

  &__dropdown {
    .drag-n-drop-wrapper {
      overflow-y: auto;
    }
  }

  &__wrapper {
    display: inline-block;
    max-height: 80px;
  }

  &__selected-labels {
    display: flex;
    flex-wrap: wrap;
    padding: 6px 16px;
    gap: 8px;
    width: 346px;
    max-height: 160px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      border-radius: 2px;
      background-color: #e0e2ea;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #a7abc3;
      border-radius: 30px;
    }
  }

  &__value-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    cursor: pointer;
    max-width: 200px;

    &--single-line {
      flex-wrap: nowrap;
    }

    &-counter {
      color: #464b61;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      padding: 4px 8px;
      border: 1px solid var(--grey-05, #ededef);
      border-radius: 8px;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 8px;
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: fit-content;
    text-align: center;
    color: #45485b;
    border: solid 1px #dadade;

    &--selected {
      background-color: #ededef;
      border: solid 1px #ededef;
    }

    &--to-create {
      display: block;
      max-width: 264px;
    }

    &--readOnly,
    &--readOnly .ws-table__empty-field {
      cursor: not-allowed;
    }

    &-text-wrapper {
      display: flex;
      align-items: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;

      &--multi {
        width: calc(100% - 14px);
      }
    }

    .pictogram {
      width: 16px;
      height: 16px;
      margin-right: 2px;

      span,
      svg {
        width: 16px;
        height: 16px;
      }
    }

    &-text {
      font-weight: normal;
      font-size: 11px;
      line-height: 20px;
    }

    &--placeholder {
      padding: 0;
      border-radius: 0;
      border: none;
      background-color: #fff;
    }
  }
}

.label-selector__menu {
  width: 346px;

  &--global-fields {
    width: 100%;
    border: {
      top: 1px solid #e0e2ea;
      bottom: 1px solid #e0e2ea;
    }
    padding: 20px 0;

    .search-input {
      padding-top: 0;
    }

    .option {
      left: auto !important;
    }

    .drag-n-drop-wrapper {
      overflow-y: auto;
    }
  }

  &--left {
    left: 190px;
  }

  &-header {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: #b4b6be;
    padding: 6px 12px 6px 16px;
  }

  .dropdown__menu-item {
    padding: 6px 16px;
  }

  .search-input {
    outline: none;
    width: 100%;
    padding: 10px 16px;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    color: #8e93ac;
    border: none;
    text-align: left;

    &::placeholder {
      color: #cacddb;
    }
  }

  .search-text {
    color: #cacddb;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    margin-left: 16px;
    text-align: left;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    height: 40px;
    width: 100%;

    &:hover {
      background-color: #f5f7ff;
    }

    svg {
      margin-left: 0 !important;
    }

    &-left {
      display: flex;
      align-items: center;

      &-drag {
        height: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &--disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }

      &-create {
        margin-right: 8px;
      }
    }

    &-right {
      svg {
        opacity: 0;
        transition: all 0.2s ease;
        margin-right: 0;
      }
    }

    &:hover {
      .dropdown__menu-item-right svg {
        opacity: 1;
      }
    }
  }

  .options-list {
    display: flex;
    flex-wrap: wrap;
    max-height: 160px;
    margin-top: 6px;
    overflow-y: auto;
    padding: 0px 16px 0px 16px;
    gap: 6px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      border-radius: 2px;
      background-color: #e0e2ea;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #a7abc3;
      border-radius: 30px;
    }
  }

  .option {
    &__placeholder {
      height: 40px !important;
      position: absolute;
      z-index: 0;
    }
  }

  .dropdown__menu-item-right svg {
    opacity: 0;
    transition: all 0.2s ease;
  }

  .dropdown__menu-item {
    justify-content: space-between;
    background: #fff;
    transition: all 0.3s;

    &:hover {
      background: #f5f7ff;
      .dropdown__menu-item-right svg {
        opacity: 1;
      }
    }
  }

  .dropdown__menu-item-left,
  .dropdown__menu-item-right {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .dropdown__menu-item-left-drag {
    display: flex;
    align-items: center;
    &--hidden {
      display: none;
    }
  }

  .dropdown__menu-item-no-results {
    pointer-events: none;
  }
}
