.showcase-topbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 76px;
  padding: 16px 20px 16px 40px;
  box-shadow: inset 0px -1px 0px #e5e6ed;
  background-color: #fff;
  z-index: 1;

  &__left {
    display: flex;
    align-items: center;

    &-tabs {
      bottom: 0;
      position: absolute;
      display: flex;
      align-items: center;

      .tab--active {
        background: white;
      }
    }

    &-cover {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 12px;
    }

    &-title {
      color: #74788d;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__right {
    display: flex;

    button {
      margin-left: 20px;
    }

    .ws-button {
      &--white {
        color: #74788d;

        svg path {
          fill: #74788d;
        }
      }

      &--disabled {
        svg path {
          fill: #d3d5e1;
        }
      }
    }
  }
}
