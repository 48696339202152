.roles-info-modal {
  .modal-overlay {
    background-color: transparent;
  }

  &__permissions {
    display: grid;
    grid-template-columns: repeat(3, 280px);
    grid-column-gap: 32px;
  }

  &__role {
    font-weight: 500;
    color: #5d78ff;
    font-size: 14px;
    line-height: 20px;
    padding-left: 28px;
    padding-bottom: 16px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 20px;
      height: 20px;
      background-size: 20px 20px;
    }

    &--admin {
      &::before {
        background-image: url("../../../../images/gearwheel.svg");
      }
    }

    &--member {
      &::before {
        background-image: url("../../../../images/members-blue.svg");
      }
    }

    &--client {
      &::before {
        background-image: url("../../../../images/clients-blue.svg");
      }
    }
  }

  &__permission {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #74788d;
    position: relative;
    padding: 16px 0 16px 28px;
    border-top: 1px dashed #d3d5e1;
    min-height: 73px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 20px;
      height: 20px;
      background-image: url("../../../../images/tick-blue.svg");
      background-size: 20px 20px;
    }
  }
}
