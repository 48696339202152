@import "../../../styles/mixins.scss";

.add-social-profiles-modal {
  width: 600px;

  .form-inputs-wrapper {
    max-height: 380px;
    margin-bottom: 20px;
  }

  .input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }

    input {
      width: 485px;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    .trash-can {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 16px;
      cursor: pointer;

      path {
        transition: all 0.2s ease;
      }

      &:hover {
        path {
          fill: #5d78ff;
        }
      }
    }
  }

  button {
    width: 160px;
  }

  .add-social-link-button {
    padding: 0;
    width: fit-content;

    &:hover {
      svg path {
        fill: #5d78ff;
      }
    }
  }

  .ws-modal__footer {
    margin-top: 20px;
  }

  &__buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }
}
