.pane-wrapper {
  display: flex;
  justify-content: flex-end;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
}

.pane {
  display: flex;
  flex-direction: column;
  background: white;
  width: 802px;
  min-height: 100vh;
  border-radius: 20px 0px 0px 20px;

  &--default-position {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &__container {
    border-top: 1px solid #f2f2f6;
    border-bottom: 1px solid #f2f2f6;
    padding: 20px;
    flex-grow: 1;
  }
}

.pane-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
}
