.empty-promo-codes {
  width: 100%;
  height: calc(100vh - 196px);
  display: flex;
  align-items: center;
  justify-content: center;

  &__subtitle-wrapper-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    margin-top: 24px;
  }
}
