.social-provider-icon {
  width: 16px;
  height: 16px;
  background: transparent no-repeat center;
  background-size: contain;
  margin-right: 4px;
  display: inline-block;
  flex-shrink: 0;

  &--big {
    width: 18px;
    height: 18px;
  }

  &--instagram {
    background-image: url("../../../../../../../images/social/instagram.svg");
  }

  &--instagram-stories {
    background-image: url("../../../../../../../images/social/instagram-story.svg");
  }

  &--tiktok {
    background-image: url("../../../../../../../images/social/tiktok.svg");
  }

  &--douyin {
    background-image: url("../../../../../../../images/social/tiktok.svg");
  }

  &--universal {
    background-image: url("../../../../../../../images/social/universal.svg");
  }

  &--snapchat {
    background-image: url("../../../../../../../images/social/snapchat.svg");
    width: 14px;
    height: 14px;
  }

  &--facebook {
    background-image: url("../../../../../../../images/social/facebook-grey.svg");
    width: 14px;
    height: 14px;
  }

  &--youtube {
    background-image: url("../../../../../../../images/social/youtube.svg");
  }

  &:not(.social-provider-icon--user) {
    &.social-provider-icon--cpcon {
      background-image: url("../../../../../../../images/social/cpcon.svg");
    }
  }

  &--user {
    width: 20px;
    height: 20px;

    &.social-provider-icon--universal {
      background-image: url("../../../../../../../images/social/universal-user.svg");
    }
  }

  &--white {
    &.social-provider-icon {
      &--instagram {
        background-image: url("../../../../../../../images/social/instagram-color.svg");
      }

      &--instagram-stories {
        background-image: url("../../../../../../../images/social/instagram-story.svg");
      }

      &--tiktok {
        background-image: url("../../../../../../../images/social/tiktok.svg");
      }

      &--universal {
        background-image: url("../../../../../../../images/social/universal.svg");
      }

      &:not(.social-provider-icon--user) {
        &.social-provider-icon--cpcon {
          background-image: url("../../../../../../../images/social/cpcon.svg");
        }
      }

      &--user {
        width: 20px;
        height: 20px;

        &.social-provider-icon--universal {
          background-image: url("../../../../../../../images/social/universal-user.svg");
        }
      }
    }
  }
}
