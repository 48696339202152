.global-meta-field-table-item {
  display: flex;
  align-items: center;
  height: 80px;
  border-bottom: 1px dashed #d3d5e1;
  gap: 48px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #45485b;

  &__meta-field,
  &__members,
  &__actions {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .table-item__value-name {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__meta-field{
    flex-basis: 450px;

    a {
      color: #45485b;
    }
  }

  &__members {
    flex-basis: 300px;

    a {
      color: #45485b;
    }
  }

  &__action{
    flex-basis: 80px;

    svg {
      path {
        fill: #45485b;
      }
    }

    a {
      color: #45485b;
    }
  }

  &__members {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;

    .stacked-avatars {
      margin-left: 0px;
    }

    &-all {
      padding-left: 8px;
    }

    svg {
      width: 16px;
      height: 16px;
      opacity: 0;
      transition: all 0.2s ease;
      margin-left: 8px;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }

  &__meta-field {
    &-value {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #45485b;

      img {
        width: 24px;
        height: 24px;
        border-radius: 2px;
        margin-right: 8px;
      }
    }
  }

  &__action {
    padding-right: 16px;
    display: flex;
    align-items: center;

    &-row {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    svg {
      margin-right: 5px;
    }
  }
}
