@import "../../../styles/mixins.scss";

.sort-dictionary-dropdown__menu {
  left: unset !important;
  right: 0 !important;

  .ws-input {
    border: 0;
  }

  .dropdown__menu-list {
    max-height: 50vh;
    overflow-y: auto;
    @include custom-scrollbar;
  }

  .dropdown__menu-item {
    width: 250px;
    min-height: 34px;
    word-break: break-word;
    white-space: pre-wrap;
    line-height: 16px;
  }
}
