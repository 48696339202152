.contact-support-modal {
  &__email {
    color: #5d78ff;
  }

  a {
    color: #5d78ff;
    text-decoration: none;
  }
}
