.save-as-modal {
  .modal__title {
    margin-bottom: 16px;
  }

  input {
    width: 100%;
  }

  button {
    width: 160px;
  }
}
