.profile-data-pane__user-info {
  display: flex;
  gap: 20px;

  &__img {
    height: 80px;
    width: 80px;
    border-radius: 100%;
    background-size: cover;
    position: relative;

    &--error {
      background: #f2f2f6 url("../../../../../../../../../images/person.svg")
        no-repeat center !important;
      background-color: #f2f2f6 !important;
      background-size: 48px 48px !important;
      border: 0 !important;
    }

    .flag {
      position: absolute;
      bottom: 2px;
      right: 2px;
      transform: translateX(0px);
      height: 22px;
      width: 22px;
      border-radius: 100%;
      object-fit: cover;
      outline: solid white 4px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    color: #45485b;
    font-size: 20px;
    font-weight: 500;

    svg {
      margin-left: 8px;
      cursor: pointer;
    }
  }

  &__subheader {
    color: #6a6d7c;
    font-size: 13px;

    display: flex;
    gap: 12px;
  }

  &__data {
    display: flex;
    flex-direction: column;
    gap: 9px;
  }
}
