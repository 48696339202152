@import "../../../../styles/mixins.scss";

.manage-pages-modal {
  padding: 0;
  display: block;
  height: 800px;
  width: 1188px;
  overflow-x: auto;
  @include custom-scrollbar;

  .modal__body {
    display: flex;
    height: 100%;
    border-radius: 8px;
  }

  .modal__buttons {
    display: none;
  }

  &__main {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: auto;
    padding: 32px;
    padding-top: 34px;
    padding-right: 0;
    overflow: hidden;

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: {
        right: 32px;
        bottom: 32px;
      }

      .modal__title {
        margin: 0;
      }

      &-close {
        width: 32px;
        height: 32px;
        background: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0;

        svg {
          width: 100%;
          height: 100%;

          path {
            fill: #74788d;
          }
        }
      }
    }

    &-content {
      max-width: 1156px;
      height: calc(100% - 64px);
      display: flex;
      justify-content: center;
      overflow: auto;
      padding: {
        right: 32px;
        bottom: 32px;
      }

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px transparent;
        border-radius: 2px;
        background-color: #e0e2ea;
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: #a7abc3;
        border-radius: 30px;
      }

      &-loader {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-pages {
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      row-gap: 36px;
      column-gap: 24px;
      margin-top: 24px;

      &-plus {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background: #5d78ff;
        border-radius: 3px;
        top: 50%;
        right: -24px;
        transform: translateY(-50%);
        opacity: 0.1;

        svg {
          transition: all 0.2s ease;
          cursor: pointer;
          width: 18px;
          height: 18px;

          path {
            fill: white;
          }
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &__page {
    position: relative;
    // width: 164px;
    // height: 214px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed transparent;
    border-radius: 8px;
    padding: 20px;
    padding-bottom: 8px;

    &--disabled {
      pointer-events: none;
      opacity: 0.6;
    }

    &-wrapper {
      display: flex;
      position: relative;
    }

    &-button {
      position: absolute;
      width: 32px;
      height: 32px;
      background: #5d78ff;
      border-radius: 4px;
      display: grid;
      place-items: center;
      opacity: 0;

      svg {
        width: 24px;
        height: 24px;

        path {
          fill: white;
        }
      }
    }

    &-delete {
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
    }

    &:hover {
      .manage-pages-modal__page-button {
        opacity: 1;
      }
    }

    &:hover,
    &:active {
      border: 1px dashed #5d78ff;
      cursor: pointer;
    }

    img {
      width: 124px;
      filter: drop-shadow(0px 0px 3px rgba(16, 24, 40, 0.1))
        drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06));
    }

    &-number {
      margin-top: 8px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #74788d;
    }
  }

  // &__toolbar {
  //   padding: 12px;
  //   width: 200px;
  //   border-radius: 0 8px 8px 0;
  // }
}
