.tasks-table {
  padding-bottom: 64px;
  padding-top: 12px;
  margin-top: 1px;
  z-index: 1;

  table {
    thead tr {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: #e0e2ea;
        z-index: -1;
      }
    }

    tbody tr {
      border-bottom: 1px dashed #d3d5e1;
    }
  }

  &__checkbox-wrapper {
    color: #b2b3c3;
    font-size: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    span,
    input[type="checkbox"] {
      cursor: pointer;
    }
  }

  &--dragging {
    user-select: none;

    tbody {
      opacity: 0.5;
    }
  }

  &--sticky {
    .tasks-table__header {
      position: sticky;
      z-index: 1;
      width: fit-content;
      top: 73px;
      background-color: #fff;
    }

    .tasks-table__footer {
      position: sticky;
      z-index: 1;
      width: fit-content;
      bottom: 0;
    }
  }

  &__header {
    min-width: 100%;

    &-droppable {
      display: flex;
      align-items: center;
      background: #f5f7ff;
      width: 100%;
    }
  }
  &__body {
    position: relative;
    z-index: 0;
  }

  &__th {
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0;
    height: 44px;
    color: #a7abc3;
    text-transform: uppercase;
    background-color: #fff;
    text-align: left;

    &--action {
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      padding-right: 30px;
      margin-left: auto;
    }

    &--total-row {
      color: #74788d;
      .currency-code,
      .ws-table__field-value-wrapper {
        font-size: 13px;
        font-weight: 500;
      }
    }

    &:not(:first-of-type) {
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 0;
        bottom: 0;
        left: 0;
        border-bottom: 1px dashed #e0e2ea;
        z-index: -1;
      }
    }

    &:first-of-type,
    &--draggable {
      border-bottom: none;
    }

    &:first-of-type {
      width: 48px;
      min-width: 48px;
    }

    &:nth-of-type(2) {
      width: 48px;
      min-width: 48px;
    }

    &:nth-of-type(3) {
      min-width: 240px;
      max-width: 240px;
    }

    &-drag-and-drop-wrapper {
      width: 16px;
      height: 16px;
    }

    &-drag-and-drop {
      width: 16px;
      height: 16px;
      opacity: 0;
      cursor: pointer;

      &-trigger {
        position: absolute;
        visibility: hidden;
      }
    }

    &:hover {
      .tasks-table__th-drag-and-drop {
        opacity: 1;
      }
    }
  }

  &__td {
    height: 100px;
    font-size: 13px;
    line-height: 20px;
    color: #74788d;
    font-weight: 500;

    &--action {
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      padding-right: 30px;
      margin-left: auto;
    }

    &-checkbox {
      position: relative;

      &-index {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #b2b3c3;
        font-size: 10px;
        pointer-events: none;
      }

      input {
        visibility: hidden;
      }

      &--checked {
        .tasks-table__td-checkbox-index {
          visibility: hidden;
        }

        input {
          visibility: visible;
        }
      }
    }

    &:hover {
      .tasks-table__td-checkbox-index {
        visibility: hidden;
      }

      input {
        visibility: visible;
      }
    }

    &-info-loader {
      display: flex;
      align-items: center;
    }

    &:first-of-type {
      padding-left: 15px;
      width: 48px;
      min-width: 48px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &:nth-of-type(2) {
      width: 48px;
      min-width: 48px;
    }

    &:nth-of-type(3) {
      min-width: 240px;
      max-width: 240px;
    }
  }

  &__td:first-of-type {
    position: relative;
    background-color: #fff;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 1px;
      background: white;
    }
  }

  &__td:nth-of-type(2) {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 2px;
      width: calc(100%);
      height: calc(100% - 4px);
      background: white;
      z-index: -1;
    }
  }

  &__td:nth-of-type(3) {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 2px;
      width: calc(100% - 2px);
      height: calc(100% - 4px);
      background: white;
      z-index: -1;
    }
  }

  &__tr {
    min-width: 100%;
  }

  &__tr-drag-and-drop-wrapper {
    width: 48px !important;
  }

  &__tr-drag-and-drop {
    width: 48px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    &--no-drop {
      &:hover {
        cursor: no-drop;
      }
    }

    &--hidden {
      visibility: hidden;
      pointer-events: none;
      opacity: 0;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  &__header-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 0;
    height: 42px;

    &:hover {
      color: #8e93ac;

      .tasks-table-header-options-dropdown__dots {
        visibility: initial;
      }
    }

    &--active {
      color: #8e93ac;

      .tasks-table-header-options-dropdown__dots {
        visibility: initial;
      }
    }
  }

  &__column-shared {
    vertical-align: middle;
    cursor: pointer;

    &--disabled {
      cursor: default;
    }
  }

  &__column-not-shared {
    background-image: url("../../../../images/client-grey.svg");
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    cursor: pointer;

    &--disabled {
      cursor: default;
    }

    &:hover {
      background-image: url("../../../../images/client-light-grey.svg");
    }
  }

  &__column-header-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @supports (-webkit-line-clamp: 2) {
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.tasks-table__td:nth-child(3),
.tasks-table__th:nth-child(3) {
  justify-content: left;
}

.tasks-table__th:nth-child(2) {
  .tasks-table__th-drag-and-drop {
    display: none;
  }

  .tasks-table__th-menu-dots {
    display: none;
  }
}

.tasks-table__th:nth-child(1),
.tasks-table__td:nth-child(1),
.tasks-table__th:nth-child(2),
.tasks-table__td:nth-child(2) {
  border-right: none;
}

.tasks-table__th:last-child,
.tasks-table__td:last-child {
  border: none;
}
