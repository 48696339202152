.cover-footer {
  height: 490px;
  position: relative;
  padding: 48px 24px;
  padding-bottom: 180px;
  overflow: hidden;

  &__info {
    border: dashed 1px #d3d5e1;
    border-radius: 6px;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    .idh-aa-logo img {
      max-width: 80px;
      height: 80px;
    }

    &-text {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-showcase {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        color: #8f919d;
      }

      &-thank-you {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        color: #45485b;
      }
    }
  }

  &__background-color {
    height: 120px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
