.table-of-contents {
  padding: 64px;

  &__heading {
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    color: #74788d;
    margin-bottom: 25px;
  }

  &__element {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #74788d;
    height: 50px;
    width: 100%;
    margin-top: 12px;

    &-content {
      display: flex;
      cursor: pointer;
    }

    &-number {
      width: 32px;
      color: #cacddb;
    }

    &--subheader {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding-left: 36px;
      margin: 0;
    }
  }

  .elements-page-number {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}

.table-of-contents-wrapper {
  padding: 0 !important;
  border-radius: 0 !important;
  border: none !important;
  background-color: transparent !important;
}
