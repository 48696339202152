.content-box {
  width: 100%;
  white-space: pre-wrap;

  &__header {
    color: #a7abc3;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;

    &--with-margin {
      margin-bottom: 12px;
    }
  }

  &__value {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #74788d;
    width: 100%;

    &--prefix {
      margin-right: 4px;
      color: #d3d5e1;
    }
  }
}
