.profile-data-pane__user-not-found {
  height: 100%;

  .pane__container-wrap {
    height: 100%;
  }

  .pane__container {
    height: 100%;
    gap: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__text {
    font-weight: 400;
    font-size: 13px;
    color: #45485b;
  }

  &__btn {
    margin-top: 6px;
  }
}
