.ws-page-default.ws-page-default--creator-discovery-tool {
  gap: 0;
  padding: 26px 48px 48px 48px;

  .page-header {
    z-index: 22;
    max-width: 500px;
    pointer-events: none;
  }

  .tooltip__text {
    white-space: break-spaces;
    span {
      word-break: break-word;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 2px;
    width: 5px;
    background: #ededef;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    width: 5px;
    background: #8f919d;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #6e6f77;
  }
}

.idh-form {
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: 320px;
    margin: 0 10px 20px;

    &--small {
      width: 150px;
    }

    &--avatar {
      width: 100px;
      margin-right: 16px;
    }
  }

  &__full-width {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &__requirements {
    &-heading {
      color: #d3d5e1;
      font-size: 10px;
      line-height: 15px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    &-text {
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      margin-bottom: 4px;
      color: #8e93ac;

      span:last-child {
        color: #74788d;
      }
    }
  }

  &__placeholder {
    border-radius: 8px;
    width: 100px;
    height: 100px;
    border: 1px dashed #b8d1fe;
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #4185fc;
    transition: 0.3s all;
    position: relative;

    span {
      margin-top: 30px;
      font-weight: 500;
    }

    &:hover {
      border: 1px solid #e7f0ff;
      background-color: #e7f0ff;
      color: #1267fb;
    }
  }

  &__avatar {
    width: 98px;
    margin: 1px 1px;
    border-radius: 8px;
    height: 98px;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s;
    background-color: #fff;

    &:hover {
      cursor: pointer;
      opacity: 0.05;
    }
  }

  &__label {
    display: block;

    &-text {
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      color: #a7abc3;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
    }
  }

  &__input {
    display: block;
    background: #ffffff;
    border: 1px solid #d3d5e1;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    padding: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #8e93ac;

    &:disabled {
      background: #f2f2f6;
      color: #d3d5e1;
    }

    &::placeholder {
      color: #d3d5e1;
    }

    &:focus {
      outline: 0;
      border: 1px solid #b8d1fe !important;
    }
  }

  &__original-select {
    position: absolute;
    left: -9999px;
  }
}
