.projects-data-table__header-cell {
  border-top: 1px dashed #d3d5e1;
  border-bottom: dashed #d3d5e1 1px;

  &__column-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    @supports (-webkit-line-clamp: 2) {
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &__content {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 0 24px;

    min-width: 72px;
    min-height: 36px;

    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0;
    height: 44px;
    color: #a7abc3;
    text-transform: uppercase;
  }

  &[data-sticky-last-left-td="true"] {
    position: sticky;
    left: 2px !important;
    padding-left: 50px;
    width: 483px;

    border-right: 1px dashed #d3d5e1;
    background: white;
    z-index: 5;

    &::before {
      content: "";
      position: absolute;
      left: -2px;
      top: -1px;
      height: calc(100% + 5px);
      width: 52px;
      background: white;
    }
  }

  &[data-sticky-first-right-td="true"] {
    position: sticky;
    right: 52px !important;
    width: 86px;

    border-left: 1px dashed #d3d5e1;
    background: white;

    &::after {
      content: "";
      position: absolute;
      right: -52px;
      top: -1px;
      height: calc(100% + 5px);
      width: 52px;
      background: white;
    }
  }
}
