.member-or-client-modal {
  .modal__text {
    display: flex;
    justify-content: space-between;
  }

  &__roles-button {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #8e93ac;
    margin-left: auto;
    cursor: pointer;
  }

  &__info-icon {
    margin-right: 4px;
  }

  &__options-wrapper {
    display: flex;
  }

  &__option {
    width: 256px;
    height: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #d3d5e1;
    border-radius: 8px;
    color: #8e93ac;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      background: #eff1ff;
      border: 1px dashed #5d78ff;
      color: #5d78ff;

      .member-or-client-modal__option-icon--member {
        background-image: url("../../../images/members-blue.svg");
      }

      .member-or-client-modal__option-icon--client {
        background-image: url("../../../images/clients-blue.svg");
      }
    }

    &:first-of-type {
      margin-right: 24px;
    }
  }

  &__option-icon {
    margin-right: 6px;
    width: 20px;
    height: 20px;

    &--member {
      background-image: url("../../../images/members-grey.svg");
    }

    &--client {
      background-image: url("../../../images/clients-grey.svg");
    }
  }
}
