.projects-data-table {
  width: fit-content;
  min-width: calc(100% - 50px);
  table-layout: auto;

  .ws-table__content {
    height: unset;
    width: 346px;

    .ws-table__name {
      word-break: break-word;
    }
  }

  &__cell {
    &--projectAction {
      justify-content: center;

      .project-menu-dropdown-popper__icon {
        display: flex;
        cursor: pointer;
        border-radius: 8px;
        background-color: #ededef;
        width: 44px;
        height: 44px;
        justify-content: space-around;
        align-items: center;

        &:hover {
          background-color: #e0e2ea;
        }

        transition: all 0.15s ease;
      }
    }
  }
}
.no-results-screen-wrapper {
  display: flex;
  justify-content: center;
  min-width: calc(100vw - 87px);
  margin-top: 50px;
}
