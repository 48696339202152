.sort-fields-dropdown {
  .dropdown__menu {
    max-height: 338px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #a7abc3;
      border-radius: 30px;
      width: 6px;
      height: 64px;
    }

    &::-webkit-scrollbar-track {
      background: #f2f2f6;
      border-radius: 30px;
      width: 6px;
    }
  }
  &__active-filter-ellipsis {
    text-transform: capitalize;
  }
}
