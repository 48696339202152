.simple-select {
  display: flex;
  align-items: center;
  gap: 21px;
  padding: 14px 12px 14px 16px;
  border: solid #b4b6be 1px;
  color: #6a6d7c;
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 500;
  cursor: pointer;
  border-radius: 8px;
  transition: all 100ms;

  &__chevron-down {
    width: 20px;
    height: 20px;
    transition: transform 0.2s ease-out;
  }

  &.rc-dropdown-open {
    border: solid #8095ff 1px;
    .simple-select__chevron-down {
      transform: rotate(180deg);
    }
  }

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f2f2f6;
    border-radius: 8px;
    width: 83px;
    height: 28px;
    padding: 4px 8px;
    color: #8e93ac;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    cursor: pointer;

    &--disabled {
      cursor: not-allowed;
    }

    &:hover {
      background: #e0e2ea;
    }
  }

  &__tick-blue {
    width: 20px;
    height: 20px;
  }

  .dropdown__menu {
    min-width: 96px;
  }

  .dropdown__menu-item {
    padding: 6px 16px;
  }

  .dropdown__menu-item svg {
    margin-right: 0;
  }
}
