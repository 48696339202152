.demo-mode-info-bar {
  padding-left: 120px;
  color: #fff;
  background-color: #e89437;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  gap: 10px;
}
