.embed-form-modal {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .settings-tab-content__buttons {
    &::after {
      display: none;
    }
  }

  &__input {
    width: 100%;
    height: 86px;
    padding: 12px 16px;
    resize: none;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #45485b;
  }

  .settings-tab-content__buttons {
    padding: 0;
  }
}
