@import "../../../styles/base/media-queries.scss";
@import "../../../styles/brand-variables.scss";

.post-preview-not-available {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 80px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__title {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.01em;
    text-align: left;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }

  &__description {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #8F919D;
  }

  &__view-post {
    display: flex;
    gap: 5px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    vertical-align: center;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: #45485B;
  }
}

.tiktok-embed-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(#000, 0.4);
  z-index: 1030;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tiktok-embed {
  margin: 0 auto !important;
  border-radius: 8px;
}

.tiktok-embed-container {
  position: relative;
  max-width: 605px;
  min-width: 325px;
  min-height: 739px;
  background-color: #fff;
  border-radius: 8px;
  // margin to hide tiny thumbnail's border visible behind modal
  margin-left: 5px;
  transform: scale(0.75);

  @include min-screen($desktopHugeWidth) {
    transform: unset;
  }

  .loader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 80px;
  }

  &__close {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.35);
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 10;
    border-radius: 8px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }

    svg path {
      fill: white;
    }
  }
}
