.empty-dictionary-tab {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 64px;
  color: #45485b;

  &__text {
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    max-width: 480px;
    margin: 20px 0;
  }
}
