.select-tags__selected-labels {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  &:empty {
    display: none;
  }

  &-wrap {
    display: flex;
    justify-content: space-between;
  }

  &__clear {
    color: #6a6d7c;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    margin-left: 4px;
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: #45485b;
    }
  }
}
