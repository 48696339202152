.detected-publications-section {
  &:not(:last-of-type) {
    margin-bottom: 40px;
  }

  &__project {
    display: flex;
    align-items: center;
    padding: 16px 0;
    border: {
      top: 1px dashed #dadade;
      bottom: 1px dashed #dadade;
    }
    margin-bottom: 16px;

    &-cover {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      object-fit: cover;
    }

    &-data {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &-text {
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        color: #b4b6be;
      }

      &-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #585b6c;
        width: 470px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  &__publications {
    &-header {
      font-weight: 600;
      font-size: 10px;
      line-height: 20px;
      color: #a7abc3;
      margin-bottom: 16px;
      text-transform: uppercase;
    }

    &-item {
      display: flex;
      align-items: center;
      margin-top: 16px;

      &-cover {
        width: 48px;
        height: 48px;
        border-radius: 4px;
        margin-right: 12px;
      }

      &-data {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #585b6c;
      }

      &-provider {
        margin-top: 4px;

        span,
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
