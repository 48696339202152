@import "src/styles/variables.scss";

.tooltip-portal {
  position: relative;
  display: block;

  &--no-break {
    white-space: nowrap;
  }

  &--small {
    .tooltip-portal__content {
      width: auto;
      min-width: 96px;
      font-weight: 600;
    }
  }

  &--big {
    .tooltip-portal__content {
      width: 400px;
    }
  }

  &--no-height-limit {
    ~ .tooltip-portal__text {
      max-height: unset !important;
    }
  }

  &--auto-size {
    .tooltip-portal__content {
      min-width: auto;
      width: fit-content;
    }
  }

  &--align-right {
    .tooltip-portal__content {
      left: auto;
      right: -8px;
      transform: translateX(0);

      &::before {
        left: auto;
        right: 14px;
        transform: translateX(0);
      }
    }
  }

  &:not(.tooltip-portal--clickable):hover {
    .tooltip-portal__content {
      display: block;
    }
  }

  &__content {
    position: absolute;
    touch-action: none;
    transform: translate(calc(-50% - 1px), 10px);
    max-width: 190px;
    background: #45485b;
    color: #dadade;
    box-shadow: 0px 1px 15px rgba(69, 65, 78, 0.2);
    border-radius: 4px;
    text-transform: initial;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    cursor: auto;
    opacity: 0;
    transition: opacity 0.2s ease;
    z-index: $z-index-max;

    &--open {
      opacity: 1;
    }

    &--center {
      text-align: center;
    }

    &::before {
      content: url("../../../images/rounded-triangle.svg");
      position: absolute;
      top: -12px;
      left: 50%;
      transform: translateX(-50%);
      width: 12px;
      height: 13px;
      border-color: transparent transparent #fff transparent;
    }

    &::after {
      content: "";
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 10px;
    }

    &--up {
      &::before {
        top: auto;
        bottom: -13px;
        border-width: 8px 6px 0 6px;
        border-color: #fff transparent transparent transparent;
        transform: translateX(-50%) rotate(180deg);
      }

      &::after {
        top: auto;
      }
    }

    .suggestion-tooltip-content {
      padding: 8px 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      z-index: 0;
      width: 220px;

      &__header {
        color: white;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
      }

      ul {
        list-style-type: disc;
        margin: 8px 0;
        margin-left: 20px;
      }

      a {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: #5d78ff;
      }
    }
  }

  &__text {
    padding: 8px;
    // max-height: 250px;
    display: block;
    word-break: break-all;
    transition: all 0.3s ease;
  }

  .tooltip-portal-sign {
    width: 16px;
    height: 16px;
    display: block;
  }

  .tooltip-portal-blue-text {
    color: #1267fb;
  }
}
