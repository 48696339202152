@import "../../../styles/mixins.scss";

.ws-custom-table {
  width: 100%;
  thead {
    height: 24px;
  }

  th:first-child {
    padding-left: 44px;
  }

  th {
    color: #a1a3ad;
    text-align: left;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0;
    text-transform: uppercase;

    padding-bottom: 8px;
    border-bottom: 1px dashed #d3d5e1;
  }

  tbody {
    .ws-custom-table-row {
      td:first-child {
        padding-left: 44px;
      }

      td {
        color: #45485b;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        border-bottom: 1px dashed #d3d5e1;
        padding: 16px 0;
      }

      &--clickable {
        cursor: pointer;
      }
    }
  }
}
