@import "src/styles/mixins.scss";

.metadata-options-dropdown {
  left: unset !important;
  right: 0 !important;
  width: 340px;
  padding: 20px;

  &__tab-name {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 4px;
    color: #b4b6be;
  }

  &__subheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #45485b;
  }

  &__field-switch-list {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow-y: auto;
    background-color: #f5f7ff;
    position: relative;

    @include custom-scrollbar;
  }

  .dropdown__menu-title {
    color: #45485b;
  }
}
