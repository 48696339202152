.workspace-tasks-table {
  overflow: scroll;
  height: 100%;
  padding: 0;
  transition: none;
  position: relative;

  &--no-columns {
    .ws-skeleton {
      &::after {
        animation: none;
      }
    }
  }

  .tasks-table {
    margin-top: 0;
    padding-top: 0;
  }

  &__topbar {
    position: sticky;
    background: white;
    z-index: 2;
    top: 0;
    left: 0;
  }

  &__header {
    padding: 45px 31px 20px 48px;
  }

  &__tabs {
    padding-left: 40px;
    padding-right: 31px;
    .tabs {
      border-bottom: 1px dashed #e0e2ea;
      margin-bottom: 0;
      .tab__button {
        height: 44px;
      }
    }
  }

  &__toolbar {
    display: flex;
    margin-left: auto;
    align-items: center;
    gap: 16px;
  }
}

.workspace-tasks-table-filters {
  &__fields-wrap {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  .ws-checkbox label span {
    word-break: break-all;
  }

  .pane__container.filters {
    height: calc(100vh - 102px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      border-radius: 4px;
      width: 5px;
      background: #ededef;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      width: 5px;
      background: #8f919d;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #6e6f77;
    }
  }
}
.workspace-tasks-table-filters-button {
  border-radius: 0 0 4px 4px;
}
