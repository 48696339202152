.popup__section {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;

  &::after {
    content: "";
    position: absolute;
    width: calc(100% + 66px);
    height: 1px;
    background: #ededef;
    bottom: 0;
    left: -33px;
  }
}
