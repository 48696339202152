@import "../../../styles/mixins.scss";

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(#000, 0.6);
  transition: all 0.2s ease;

  &--hidden {
    visibility: hidden;
    opacity: 0;
  }
}

.modal {
  font-family: "Poppins", sans-serif;
  min-width: 511px;
  max-width: 90vw;
  max-height: 95vh;
  border-radius: 8px;
  padding: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background-color: #fff;
  flex-direction: column;
  display: flex;
  transition: all 0.3s ease;

  &--small {
    min-width: 348px;
    width: 348px;
    padding: 32px 24px;

    .modal__scroller {
      padding: 20px 24px;
      margin-left: -24px;
      width: calc(100% + 48px);
    }
  }

  &--medium {
    width: 600px;
    padding: 32px;

    .modal__scroller {
      padding: 20px 32px;
      margin-left: -32px;
      width: calc(100% + 64px);
    }
  }

  &--with-img {
    flex-direction: row;
    padding: 0;
    border-radius: 16px;
    width: 680px;

    .modal__title {
      font-weight: 500;
      font-size: 28px;
      line-height: 36px;
    }

    .modal__close-icon {
      top: 24px;
      right: 24px;
    }

    .modal__text {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__content-with-img {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &--large {
    min-width: 875px;
    width: 875px;
    padding: 32px;

    .modal__scroller {
      padding: 20px 32px;
      margin-left: -32px;
      width: calc(100% + 64px);
    }
  }

  &__close-icon {
    position: absolute;
    top: 36px;
    right: 32px;
    height: 24px;
    width: 24px;

    &:hover {
      cursor: pointer;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: #45485b;
    margin: 0 0 16px;
    display: flex;
    gap: 8px;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
    }

    &--with-back-btn {
      margin: 0 0 24px;
    }
  }

  &__scroller {
    width: 100%;
    overflow-y: auto;
    border: {
      top: solid 1px #d9d9d9;
      bottom: solid 1px #d9d9d9;
    }
    @include custom-scrollbar;

    padding: 20px 24px;
    margin-left: -24px;
    width: calc(100% + 48px);
  }

  &__title-back-btn {
    background: #f2f2f6;
    border-radius: 6px;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__title-back-btn-icon {
    width: 20px;
    height: 20px;
  }

  &__text {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 24px;
    color: #45485b;

    &--light {
      opacity: 0.5;
      margin-bottom: 32px;
    }

    &--big {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__image {
    width: 56px;
    height: 56px;
    margin-right: 16px;
  }

  &__img {
    width: 300px;
    height: 434px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  &__body {
    flex: 1;
  }

  &__row {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .ws-react-select {
      width: 150px;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__wrapper {
    display: flex;
    height: fit-content;
    align-items: center;
    margin-bottom: 32px;

    &--no-margin {
      margin: 0;
    }

    &--space-between {
      justify-content: space-between;
    }
  }

  &__input-file {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 277px;
    min-height: 100px;
    border: 1px dashed #7f7e7c;
    border-radius: 8px;
    padding: 9px;

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      border: 1px solid transparent;
      cursor: pointer;
      opacity: 0;
    }
  }

  iframe {
    height: 640px;
  }

  &__form-item {
    margin-bottom: 32px;
    position: relative;

    .show-text-icon {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);

      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }

  &__mb-20 {
    margin-bottom: 20px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;

    &--centered {
      justify-content: center;
    }

    .loader-wrapper {
      height: 40px;
      width: 52px;
      margin: 0;
    }
  }

  &--buttons-left {
    .modal__buttons {
      justify-content: flex-start;
      gap: 15px;
    }
  }

  &--buttons-right {
    .modal__buttons {
      justify-content: flex-end;
      gap: 15px;
    }
  }

  &--buttons-space-between {
    .modal__buttons {
      justify-content: space-between;
      gap: 15px;
    }
  }

  &__input-wrapper {
    display: flex;
    flex-direction: column;

    label {
      font-size: 12px;
      line-height: 18px;
      color: #7f7e7c;
      margin-bottom: 4px;
    }

    &--big label {
      color: #2a2825;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 16px;
    }
  }

  &__input-side-wrapper {
    display: flex;
    align-items: center;

    span {
      color: #2a2825;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      margin: {
        left: 12px;
        right: 9px;
      }
    }
  }

  &__input,
  &__textarea {
    position: relative;
    border: 1px solid #d4d4d3;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #2a2825;
    height: 48px;
    font-weight: 500;
    width: 100%;
    padding: 0 16px;
    outline: none;

    &:focus {
      &::placeholder {
        color: #72706e;
      }

      border: solid 1px transparent;
      border-radius: 4px;
      background-image: linear-gradient(#fdf2fb, #fdf1f1),
        linear-gradient(to bottom, #f682d1, #fb817b);
      background-origin: border-box;
      background-clip: padding-box, border-box;
    }

    &--sm {
      width: 300px;
    }

    &--centered {
      text-align: center;
      color: #2a2825 !important;
    }

    &:disabled {
      background-color: #eaeae9;
      color: #aaa9a8;
    }

    &::placeholder {
      color: #d4d4d3;
    }
  }

  &__textarea {
    resize: vertical;
    padding-top: 14px;
    height: auto;
    height: 48px;
  }

  .nft__button {
    width: 100%;
  }

  &__buttons-wrapper {
    display: flex;
    justify-content: space-between;

    &--centered {
      justify-content: center;
    }

    .idh-button {
      width: 230px;
      display: flex;
      justify-content: center;
    }
  }

  &__password-recovery {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
    margin: 32px 0 0;

    &:hover {
      opacity: 0.7;
    }
  }

  &__or-label {
    position: relative;
    height: 18px;
    margin-bottom: 20px;
    margin-top: 32px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      height: 1px;
      background-color: rgba(#aaa9a8, 0.3);
    }

    &::after {
      content: "OR";
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #aaa9a8;
      background-color: #fff;
      padding: 0 32px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      z-index: 2;
    }
  }

  &__info {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #2a2825;
    margin-bottom: 32px;

    &--no-margin {
      margin: 0;
    }

    a {
      color: #e994b7;
      font-weight: bold;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__bottom-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #2a2825;
    margin-top: 32px;

    &-pink {
      color: #e994b7;
      cursor: pointer;
      font-weight: bold;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .social-login {
    display: flex;
    justify-content: center;
    margin: 0 auto;

    &__item {
      margin: 0 16px;
      height: 48px;
      width: 48px;
      border: 1px solid #d4d4d3;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .verify {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;

    .modal__form-item {
      &:not(:last-of-type) {
        margin-right: 15px;
      }

      .modal__input {
        text-align: center;
      }
    }
  }
}

.add-task-modal {
  .modal__column {
    width: 100%;

    input {
      width: 100%;
    }
  }
}
