.stacked-labels {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  &__item {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 4px 8px;
    border-radius: 8px;
    border: 1px solid #dadade;
    color: #45485b;
  }
}
