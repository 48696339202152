.influencers-list__actions-dropdown {
  &__menu {
    left: auto;
    right: 70px !important;
    top: -4px;

    svg path,
    svg rect {
      fill: #45485b;
    }

    .influencers-list__action {
      color: #45485b;
      align-items: center;
      &--danger {
        color: #d93025;

        svg path {
          fill: #d93025;
        }
      }

      &-icon {
        display: flex;
      }

      &-ext {
        margin-left: 4px;
      }

      &-tooltip {
        flex-direction: column;
      }
    }
  }
}
