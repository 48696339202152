.scaling-input {
  position: absolute;
  width: 100%;
  left: 0 !important;
  text-align: left;
  background: none;
  transition: none !important;

  &__wrapper {
    position: relative;
    display: inline;
  }

  &__content {
    pointer-events: none;
    width: fit-content !important;
    white-space: nowrap;
    opacity: 0;
  }
}
