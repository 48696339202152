.interests-list {
  display: flex;
  flex-wrap: wrap;

  &__item {
    color: #a7abc3;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    width: 50%;
    flex-shrink: 0;

    &--full-width {
      width: 100%;
    }

    svg {
      margin-right: 8px;
    }
  }
}
