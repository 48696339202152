.page-preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 150;

  .loader-wrapper {
    width: 170px;
    height: 130px;
  }
}
