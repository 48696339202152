html body .projects-table-filters__member-select {
  .ws-react-select__indicators {
    padding-right: 8px;
    display: flex;
    gap: 8px;
  }

  .ws-react-select__control .ws-react-select__indicators > * {
    height: 100%;
    margin: 0 !important;
    align-items: center;
  }

  .ws-react-select__clear-indicator {
    display: none;
  }
  .ws-react-select__multi-value {
    background: #ededef;
  }
}
