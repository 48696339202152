.task-menu-dropdown__icon {
  cursor: pointer;
  &:hover {
    path {
      fill: #536ceb;
    }
  }

  &--subtask {
    padding: 4px;
  }

  &-wrapper {
    display: flex;
    align-items: center;
  }
}
