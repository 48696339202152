.creator-discovery-link {
  min-height: 40px;

  .sidebar__item {
    height: 40px;

    .chevron-icon {
      transition: all 0.2s ease;
    }

    &--open {
      .chevron-icon {
        transform: rotate(-180deg);
      }
    }
  }

  &__button-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 150px;
  }

  &__elements {
    overflow: hidden;
    height: 0;
    transition: all 0.2s ease;
    opacity: 0;

    &--visible {
      height: 146px;
      opacity: 1;
    }

    .sidebar__item {
      margin-top: 8px;
    }
  }
}
