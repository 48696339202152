.content-data-tab {
  .brief-text-box {
    min-height: 157px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    outline: 1px solid #d3d5e1;
    padding: 10px 12px;
    border-radius: 4px;
    margin-bottom: 20px;

    .public-DraftEditorPlaceholder-inner {
      // color: red !important;
      color: #a7abc3;
    }

    &:hover,
    &:focus {
      outline: 1px solid #9eaeff;
    }
  }
}
