.post-info {
  display: flex;
  flex-direction: column;

  &__username {
    font-weight: 500;
    font-size: 13px;
    line-height: 1.5;
    color: #8e93ac;
    display: flex;
    align-items: center;

    &:hover {
      opacity: 0.7;
    }
  }

  &__followers {
    font-weight: 500;
    font-size: 11px;
    line-height: 21px;
    color: #a7abc3;
    display: flex;
    align-items: center;
    margin-right: 16px;
    white-space: nowrap;

    &::before {
      content: "";
      width: 20px;
      height: 20px;
      background: url("../../../../../../../images/clients-full-grey.svg")
        no-repeat center;
      background-size: contain;
      margin-right: 8px;
      display: inline-block;
    }
  }

  &__campaign {
    margin-top: 8px;
    font-weight: 500;
    font-size: 9px;
    line-height: 12px;
    text-transform: uppercase;
    color: #d3d5e1;
  }

  &__middle {
    align-items: center;
    margin-top: 3px;
  }
}
