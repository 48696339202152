.emojiSelect {
  display: inline-block;
  cursor: auto !important;
  width: 20px;
  height: 20px;
}
.emojiSelectButton,
.emojiSelectButtonPressed {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  line-height: 1.2em;
  font-size: 1.5em;
  color: #888;
  background: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
  padding: 0;
  opacity: 0;
}
.emojiSelectButton:focus,
.emojiSelectButtonPressed:focus {
  outline: 0;
  /* reset for :focus */
}
.emojiSelectButton:hover,
.emojiSelectButtonPressed:hover {
  background: #f3f3f3;
}
.emojiSelectButton:active,
.emojiSelectButtonPressed:active {
  background: #e6e6e6;
}
.emojiSelectButtonPressed {
  background: #ededed;
}
.emojiSelectPopover {
  margin-top: 10px;
  padding: 0 0.3em;
  position: absolute;
  bottom: 0;
  z-index: 1000;
  box-sizing: content-box;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
}
.emojiSelectPopoverClosed {
  display: none;
}
.emojiSelectPopoverTitle {
  margin: 0 0 0.3em;
  padding-left: 1em;
  height: 2.5em;
  line-height: 2.5em;
  font-weight: normal;
  font-size: 1em;
  color: #9e9e9e;
}
.emojiSelectPopoverGroups {
  margin: 0 0 0.3em;
  position: relative;
  z-index: 0;
  width: 21em;
  height: 20em;
}
.emojiSelectPopoverGroup {
  padding: 0 0.5em;
}
.emojiSelectPopoverGroupTitle {
  display: none;
}
.emojiSelectPopoverGroupTitle {
  margin: 1em 0;
  padding-left: 0.5em;
  font-weight: normal;
  font-size: 1em;
  color: #9e9e9e;
}
.emojiSelectPopoverGroupList {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}
.emojiSelectPopoverGroupItem {
  width: 2.5em;
  height: 2.5em;
}
.emojiSelectPopoverToneSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.emojiSelectPopoverToneSelectList {
  margin: 0.3em;
  padding: 0.3em;
  position: absolute;
  display: -webkit-box;
  display: flex;
  list-style: none;
  border: 1px solid #e0e0e0;
  border-radius: 0.5em;
  background: #fff;
  box-shadow: 0 0 0.3em rgba(0, 0, 0, 0.1);
}
.emojiSelectPopoverToneSelectItem {
  width: 2.5em;
  height: 2.5em;
}
.emojiSelectPopoverToneSelectItem:first-child {
  border-right: 1px solid #e0e0e0;
}
.emojiSelectPopoverEntry,
.emojiSelectPopoverEntryFocused {
  padding: 0;
  background: none;
  border: none;
  outline: none;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}
.emojiSelectPopoverEntryFocused {
  background-color: #efefef;
}
.emojiSelectPopoverEntryIcon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
}
.emojiSelectPopoverNav {
  margin: 0;
  padding: 0 0.5em;
  display: -webkit-box;
  display: flex;
  width: 20em;
  list-style: none;
}
.emojiSelectPopoverNavItem {
  width: 2.5em;
  height: 2.5em;
}
.emojiSelectPopoverNavEntry,
.emojiSelectPopoverNavEntryActive {
  padding: 0;
  font-size: 1.2em;
  color: #bdbdbd;
  background: none;
  border: none;
  outline: none;
}
.emojiSelectPopoverNavEntryActive {
  color: #42a5f5;
}
.emojiSelectPopoverScrollbar {
  position: absolute;
  right: 0;
  top: 0.3em;
  bottom: 0.3em;
  width: 0.25em;
  background-color: #e0e0e0;
  border-radius: 0.125em;
  opacity: 0.1;
  transition: opacity 0.4s;
}
.emojiSelectPopoverScrollbarThumb {
  background-color: #000;
  border-radius: 0.125em;
  cursor: pointer;
}
.emojiSelectPopoverGroups:hover,
.emojiSelectPopoverScrollbar {
}
.emojiSelectPopoverGroups,
.emojiSelectPopoverScrollbar:hover,
.emojiSelectPopoverGroups,
.emojiSelectPopoverScrollbar:active {
  // opacity: 0.6;
}

.emoji {
  background-position: center;
  /* make sure the background the image is only shown once */
  background-repeat: no-repeat;
  background-size: contain;
  /* move it a bit further down to align it nicer with the text */
  vertical-align: middle;
  /*
  We need to limit the emoji width because it can be multiple characters
  long if it is a 32bit unicode. Since the proper width depends on the font and
  it's relationship between 0 and other characters it's not ideal. 1.95ch is not
  the best value, but hopefully a good enough approximation for most fonts.
  */
  display: inline-block;
  overflow: hidden;
  max-width: 1.95ch;
  /*
  Needed for iOS rendering to avoid some icons using a lot of height without
  actually needing it.
  */
  max-height: 1em;
  line-height: inherit;
  margin: -0.2ex 0em 0.2ex;
  /*
  In the past we used opacity: 0 to hide the original Emoji icon no matter what
  system it is. Recently we switched to color: transparent since it started to
  work in recent iOS version.
  */
  color: transparent;
  /*
  Some SVG files (say 2764 for :heart:) don't have default width/height, thus
  may not be rendered properly on some platforms/browsers (e.g., Windows 10 +
  Chrome 61).
  */
  min-width: 1em;
}
.emojiSuggestionsEntry {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}
.emojiSuggestionsEntry:active {
  background-color: #f6f7f9;
}
.emojiSuggestionsEntryFocused {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  background-color: #f6f7f9;
}
.emojiSuggestionsEntryText {
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 368px;
  color: #74788d;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
}
.emojiSuggestionsEntryIcon {
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
  margin-right: 12px;
}
.emojiSuggestions {
  margin-top: 24px;
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  background: #fff;
  border-radius: 2px;
  cursor: pointer;
  padding: 8px 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: all 0.2s ease;
}

.text-box--emojis-to-bottom {
  .emojiSuggestions {
    transform: translateY(-100%) !important;
    margin-top: -24px;
  }
}
