.dashed-box {
  border: 1px dashed #d3d5e1;
  padding: 24px;
  border-radius: 8px;

  &--focused {
    background-color: #eff1ff;
    border: 1px dashed #5d78ff;
  }
}
