.pending-user-loading {
  width: 100%;
  padding-bottom: 24px;
  border-bottom: 1px dashed #d3d5e1;
  tr {
    display: grid;
    grid-template-columns: 0fr 1fr;
  }
  td {
    display: flex;
    align-items: center;
  }
  .loader-wrapper {
    width: 64px;
    height: unset;
    padding: 10px;
    margin-top: 5px;
  }

  &__user {
    gap: 12px;

    svg rect {
      fill: #f2f2f6;
    }
  }

  &__text {
    justify-self: center;
  }
}
