.ws-fullscreen-modal.create-new-project-modal {
  padding-bottom: 48px;
}

.create-new-project-modal {
  overflow-y: auto;

  .loader-wrapper {
    height: 60px;
    width: 72px;
    margin-top: 24px;
    margin-bottom: 74px;
  }

  &__wrapper {
    max-width: 400px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    .ws-input-label {
      margin-bottom: 8px;
    }
  }

  .select-project-type {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &__description {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #585b6c;
    }
    &__options {
      display: flex;
      gap: 120px;
    }
  }

  .modal__column {
    width: 100%;
  }
  .ws-react-select {
    width: 100%;
  }

  .page-header {
    margin-bottom: 24px;
  }

  .ws-button {
    margin-top: 4px;
    width: 160px;
  }

  .select-project-structure {
    .ws-react-select .ws-react-select__multi-value {
      width: auto;

      &::after {
        content: ",";
        padding-right: 3px;
        color: #74788d;
      }
    }
  }

  &__subtitle-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    h2 {
      all: unset;
      color: #45485b;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
    }
  }

  &__required-info {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #8f919d;

    span:first-child {
      margin-right: 5px;
      font-size: 16px;
    }
  }

  .ws-fullscreen-modal__content {
    display: flex;
    justify-content: center;
  }

  .value-field {
    white-space: nowrap;
  }

  &__wizard-fields .task-meta-fields__row {
    height: auto;
    min-height: 32px;
  }
}
