.associate-task-modal {
  .ws-react-select {
    width: 100%;
  }
  .member-option {
    gap: 4px;

    &__photo-wrapper {
      background-size: cover;
      overflow: hidden;
      border-radius: 2px;
      width: 24px;
      height: 24px;

      &--rect {
        background-color: #f2f2f6;
      }
    }

    &__photo {
      background-size: cover;
      border-radius: unset;
      &-wrapper {
        flex-shrink: 0;
      }
    }
  }
}
