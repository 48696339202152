@import "src/styles/variables.scss";

.dropdown {
  position: relative;
  display: inline-block;

  &--open {
    .idh-button:focus {
      outline: 0;
    }
  }

  &--to-right {
    .dropdown__menu {
      left: auto;
      right: 0;
    }
  }

  &--adjacent-to-button {
    .dropdown__menu {
      left: auto;
      right: 70px !important;
      top: -4px;
    }
  }

  &--align-bottom {
    .dropdown__menu {
      top: auto;
      bottom: 0;
    }
  }

  &--to-bottom {
    .dropdown__menu {
      top: auto;
      bottom: calc(100% + 4px);
    }
  }

  &--align-right {
    .dropdown__menu-item {
      text-align: right;
    }
  }

  &--from-right {
    .dropdown__menu {
      left: calc(100% + 16px);
      top: 0;
      right: auto;
    }
  }

  &--block {
    width: 100%;
    display: block;
  }

  &--big {
    .dropdown__menu {
      padding: 32px;
    }
  }

  &__menu {
    position: absolute;
    top: 100%;
    margin-top: 4px;
    background: #fff;
    box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 0;
    min-width: 140px;
    z-index: $z-index-dropdown;
    transition: all 0.2s ease;
    opacity: 0;
    visibility: hidden;

    &--open {
      opacity: 1;
      visibility: visible;
      display: block;
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      font-weight: 500;
      font-family: "Poppins";
      font-size: 12px;
      line-height: 16px;
      color: #74788d;
      text-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
      padding: 8px 16px;
      display: flex;
      width: 100%;
      white-space: nowrap;
      transition: all 300ms;

      &:hover {
        background-color: #f6f7f9;
      }

      &--danger {
        background-color: transparent;
        color: #fd397a;

        svg path {
          fill: #fd397a;
        }

        &:hover {
          background-color: #ffecf2 !important;
        }
      }

      a {
        color: inherit;
      }

      &--disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      &--dark {
        color: #74788d;
      }

      svg {
        height: 20px;
        width: 20px;
      }
    }

    &-item-tick {
      margin-left: 12px;
    }

    &-separator {
      color: #74788d;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      text-align: left;
      border-bottom: 1px solid #cacddb;
      margin: 6px 0;

      &--dashed {
        border-bottom: 1px dashed #cacddb;
      }
    }

    &-title {
      color: #74788d;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin-top: 0;
    }

    &-search {
      width: 100%;
      height: 41px;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #8e93ac;
      border: none;
      outline: none;
      padding: 10px 16px;
    }
  }
}

//other styles

.image-select-option {
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: #a7abc3;
    }
  }

  &.create-project {
    color: #1267fb;

    svg {
      path {
        fill: #1267fb !important;
      }
    }
  }
}
