.generator-loader {
  display: flex;
  justify-content: center;
  padding: 250px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
  z-index: 100;
  opacity: 1;

  &__wrapper {
    position: sticky;
    top: 50vh;
    transform: translateY(-50%);
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__counter {
    margin-top: 16px;
    color: #74788d;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }
}
