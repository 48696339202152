.access-link-page__header-content {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__caption {
    display: flex;
    gap: 20px;
    align-items: center;
    &-cover {
      width: 48px;
      height: 48px;
      border-radius: 4px;
      img {
        border: 1px solid #dadade;
      }
    }

    &-title {
      all: unset;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #45485b;
    }
  }

  &__description {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #45485b;
    user-select: text;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  &__legend {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #d93025;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  @media screen and (max-width: 600px) {
    &__caption {
      &-title {
        font-size: 17px;
      }
    }
    &__description {
      font-size: 11px;
    }
  }
}
