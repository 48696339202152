.remove-template-modal {
  min-width: 464px;

  .modal {
    &__title {
      margin-bottom: 16px;
    }

    &__text {
      margin: 0;
    }

    &__buttons {
      margin-top: 24px;
    }
  }
}
