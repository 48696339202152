.report-footer {
  width: 100%;

  &__campaign {
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    &-data {
      margin-bottom: 92px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-logo {
        width: 80px;
        height: 80px;
        object-fit: cover;
        margin-bottom: 24px;
        border-radius: 3px;
      }

      &-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #a7abc3;
        margin-bottom: 32px;
      }

      &-thank-you {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #74788d;
      }
    }
  }

  &__indahash {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 100px;
    width: 100%;
    height: 450px;
    background-color: #5d78ff;

    &-logo {
      display: flex;
      align-items: center;
      color: #aebbff;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;

      svg {
        margin-left: 16px;
        path {
          fill: white;
        }
      }
    }
  }
}
