@import "../../../../styles/media-queries.scss";
@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.template-manager {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__headers {
    color: #a1a3ad;
    margin-bottom: 16px;
    font-size: 10px;
    display: grid;
    text-transform: uppercase;
    font-weight: 600;

    div:first-child {
      padding-left: 36px;
    }
  }

  &__headers.grid--templates-list {
    margin-top: 20px;
  }

  .grid {
    &--templates-list {
      grid-template-columns: 328px 90px 1fr;
    }

    &--attributes {
      grid-template-columns: 318px repeat(3, 90px);
    }

    &--fields {
      grid-template-columns: 1fr 104px;
    }
  }

  &__list {
    &-col {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &-item {
      display: grid;
      padding: 16px 0;
      transition: background-color 0.2s;
      left: auto !important;
      border-top: 1px solid #ededef;
      background-color: #fff;

      &:hover {
        background-color: #ededef;
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      gap: 12px;
      button {
        width: 24px;
        height: 24px;
      }
    }
  }

  .template-manager__list--attributes,
  .template-manager__list--fields {
    .template-manager__list-actions {
      justify-content: space-between;
    }
  }

  &__text {
    display: flex;
    align-self: center;
    gap: 16px;
    .idh-date-picker__input {
      background-color: transparent;
    }
  }

  &__name {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #45485b;
    display: flex;
    align-items: center;
    gap: 6px;

    svg {
      width: 20px;
      height: 20px;
    }
    path {
      fill: #45485b;
    }

    &-text {
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .text-box {
    padding: 12px 12px 12px 16px;
    border-radius: 8px;
    overflow: auto;
    color: #45485b;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
  }

  &__description {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #8f919d;
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__content-wrapper {
    gap: 16px;
    display: flex;
    flex-direction: column;
  }

  &__text-area {
    height: 104px;
    max-width: 592px;
    &:focus,
    &:hover {
      outline: none;
    }

    &:focus-within {
      border: 1px solid rgba(93, 120, 255, 0.6);
    }
  }

  &__section-label {
    font-size: 14px;
    color: #8f919d;
    font-weight: 500;
  }

  &__selector {
    border-top: 1px solid #d9d9d9;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 36px;
    .ws-react-select .ws-react-select__multi-value {
      width: auto;

      &::after {
        content: ",";
        padding-right: 3px;
        color: #74788d;
      }
    }

    .ws-react-select .ws-react-select__control {
      height: 40px;
      overflow: hidden;
    }

    .ws-react-select-container {
      width: 282px;
    }
    .idh-select-option {
      width: 100%;
    }
  }

  &__option {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-name {
      width: calc(100% - 36px);
      display: flex;
      align-items: center;
      color: #45485b;
      font-size: 13px;
      gap: 8px;
      svg {
        width: 20px;
        height: 20px;
      }
      path {
        fill: #45485b;
      }
    }
  }

  &__switch {
    width: 100px;
    gap: 8px;
  }

  &__template-name {
    display: flex;
    align-items: center;
    gap: 20px;
    input {
      all: unset;
      width: 160px;
      &::placeholder {
        color: #dadade;
      }
    }
    &-controls {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  &__extensions {
    gap: 16px;
    display: flex;
    flex-direction: column;
  }

  &__extensions-count {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #6a6d7c;
    font-size: 13px;
    font-weight: 500;
  }

  &__col {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__metaField-value {
    .text-box {
      max-width: 200px;
      max-height: 100px;
    }
  }

  .animated-div {
    min-height: 100%;
    max-height: calc(100% - 156px);
    overflow-x: auto;
    @include custom-scrollbar;
    padding-right: 4px;
  }

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 10px;
  }

  &__input-row {
    display: flex;
    gap: 24px;
  }
}
