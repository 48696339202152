.projects {
  height: 100%;
  overflow: scroll;

  &__topbar {
    &-right {
      .dropdown-popper--open {
        .ws-button {
          background-color: #f2f2f6;
        }
      }
    }
  }

  &--single-project {
    overflow: scroll;
    height: 100%;
    padding: 0;
    transition: none;
    position: relative;

    .ws-table {
      margin-top: 16px;
    }
  }

  // padding: 0 46px;

  &__part-above-table {
    padding: 16px 31px 0 48px;
    position: sticky;
    left: 0;
    z-index: 4;
  }

  &__topbar {
    position: sticky;
    background: white;
    z-index: 6;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    width: 100%;

    &--list {
      padding: 45px 31px 20px 48px;
      height: 110px;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background: white;
        bottom: -2px;
        left: 0;
      }
    }

    button.tab__button {
      height: 44px;
    }

    &--tasks-table {
      height: 61px;
      position: sticky;
      top: 0;
      left: 0;
      background-color: #fff;
      padding: 24px 31px 0 48px;
      width: 100%;
      z-index: 3;

      .tabs {
        margin-bottom: 0;
        background-color: #fff;
      }
    }

    .ws-react-select {
      border: none !important;
      width: 160px !important;

      &__control {
        border: none !important;
        min-height: 44px !important;
        cursor: pointer;
      }

      &:hover {
        .ws-react-select__control {
          background-color: #f2f2f6;
        }
      }
      &__indicators {
        display: none;
      }
    }

    &-button {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-right: 32px;
      color: #a7abc3;
      transition: all 0.3s ease;
      cursor: pointer;

      &--purple {
        color: #5d78ff;
      }

      svg {
        margin-right: 8px;

        path,
        rect {
          transition: all 0.3s ease;
        }
      }

      &:hover,
      &--active {
        color: #5d78ff;

        svg {
          path,
          rect {
            fill: #5d78ff;
          }
        }
      }
    }

    &-left {
      display: flex;

      .tabs {
        margin-bottom: 0;
      }
    }

    &-right {
      display: flex;
      gap: 16px;
      align-items: center;

      .see-as-client-mode-switch {
        margin-right: 20px;
      }

      &-add-button {
        text-transform: capitalize;

        svg,
        img {
          width: 20px;
          height: 20px;
        }
      }

      &-members-avatars {
        display: inline-flex;
        flex-direction: row-reverse;
        height: 24px;
        cursor: pointer;

        .avatar {
          position: relative;
          border: 2px solid #fff;
          border-radius: 50%;
          overflow: hidden;
          width: 24px;

          &:not(:last-child) {
            margin-left: -10px;
          }

          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            object-position: center;
          }
        }
      }

      &-share {
        margin-left: 16px !important;
      }

      .ws-react-select {
        width: 220px;
      }
    }

    &__invite-btn {
      height: 36px;
      width: 36px;
      svg {
        margin: 0;
      }
    }
  }

  &__project-main-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  &__project-name-container {
    display: flex;
    align-items: center;
  }

  &__project-name {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #74788d;
    max-width: 480px;
    height: 28px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 20px;
    cursor: pointer;
  }

  &__overview-container {
    display: flex;
    gap: 8px;
  }

  &__topbar-toolbar {
    display: flex;
    margin-left: auto;
    align-items: center;
    gap: 16px;

    &-add-button {
      text-transform: capitalize;

      svg,
      img {
        width: 20px;
        height: 20px;
      }
    }

    &-members-avatars {
      display: inline-flex;
      flex-direction: row-reverse;
      height: 24px;
      cursor: pointer;

      .avatar {
        position: relative;
        border: 2px solid #fff;
        border-radius: 50%;
        overflow: hidden;
        width: 24px;

        &:not(:last-child) {
          margin-left: -10px;
        }

        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          object-position: center;
        }
      }
    }

    &-share {
      margin-left: 16px !important;
    }

    .ws-react-select {
      width: 220px;
    }
  }

  &__button {
    margin: 280px 0 0 90px;
  }

  &__scroller {
    margin-bottom: 22px;
    display: flex;
    flex-direction: column;
    width: fit-content;
  }
}
