.settings-sidebar {
  width: 250px;
  height: 100%;
  padding: 16px;
  border-right: 1px solid #ededef;

  &__context {
    display: flex;
    align-items: center;
    padding: 6px 8px;
    margin-bottom: 20px;
    height: 36px;

    &-image {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      border-radius: 2px;

      &--round {
        border-radius: 50%;
      }
    }

    &-title {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #45485b;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 169px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #45485b;
    padding: 7px;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover,
    &--active {
      background: #ededef;
    }

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: #45485b;
      }
    }

    svg,
    .custom-image-wrapper {
      margin-right: 8px;
    }

    .custom-image,
    .custom-image-wrapper {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }
}
