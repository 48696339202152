@import "../../../../../../../../styles/media-queries.scss";
@import "../../../../../../../../styles/variables.scss";


.meta-field-members {
  width: calc(100% + 24px);
  display: flex;
  flex-direction: column;
  max-height: 405px;
  padding: {
    bottom: 10px;
    right: 24px;
  }

  .ws-react-select {
    min-height: 46px;
  }

  .ws-react-select__dropdown-indicator {
    margin: 13px 10px 0 4px !important
  }

  @include min-screen($desktopLargeWidth) {
    max-height: 594px;
  }

  &__add-container {
    display: flex;
    gap: 20px;

    &-selects {

    }

    &-member-list {
      width: 100%;
      flex-basis: auto;

      .ws-react-select .ws-react-select__control {
        min-height: 50px;
        margin-top: 4px;
      }

      .ws-react-select .ws-react-select__control .ws-react-select__dropdown-indicator {
        margin-top: 15px !important;
      }
    }

    &-roles {
      flex-basis: 130px;
    }

    &-btn {
      flex-basis: 106px;

      button {
        width: 100%;
      }
    }
  }

  &--no-client-mode-switch {
    .meta-field-members__user-list__footer {
      display: none;
    }
  }

  &__roles-button {
    display: flex;
    gap: 4px;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #8e93ac;
    margin-left: auto;
    cursor: pointer;
  }

  .modal {
    &__row {
      margin-bottom: 16px;
    }
  }

  &__info-icon {
    width: unset !important;
    height: unset !important;
  }

  &__user-list {
    overflow-y: auto;
    height: max-content;
    display: flex;
    flex-direction: column;
    height: 100%;

    &__header {
      color: #6a6d7c;
      font-size: 16px;
      font-weight: 500;
      border-bottom: solid #ededef 1px;
      padding-bottom: 14px;
      margin-top: 4px;
      display: flex;
      justify-content: space-between;
    }

    &-wrap {
      display: flex;
      flex-direction: column;
      gap: 12px;
      flex-grow: 1;
      max-height: 455px;
      overflow: auto;

      ::-webkit-scrollbar {
        width: 6px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f2f2f6;
        border-radius: 30px;
        width: 6px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #a7abc3;
        border-radius: 30px;
        width: 6px;
        height: 64px;
      }
    }

    &__footer {
      width: 100%;
      height: 0;
      border-bottom: solid #ededef 1px;
    }
  }

  &__user-list-empty {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #6a6d7c;
    display: flex;
    align-items: center;
  }

  &__eyes-icon {
    margin-right: 8px;
  }

  &__user-list-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__user-list-item-left {
    display: flex;

    .stacked-avatars {
      margin-left: 0px;
    }
  }

  &__user-list-item-avatar-wrapper {
    width: auto;
    margin-right: 12px;
  }

  &__user-list-item-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__user-list-item-info {
    display: flex;
    flex-basis: 300px;
    flex-direction: column;
    justify-content: space-around;
  }

  &__user-list-item-name {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #6a6d7c;
  }

  &__user-list-item-email {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: #b4b6be;
  }

  &__user-list-item-right {
    display: flex;
    gap: 18px;
    align-items: center;
    margin-right: 8px;
  }

  &__user-list-item-trashcan {
    width: 20px;
    height: 20px;
    cursor: pointer;

    &--disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:not(.meta-field-members__user-list-item-trashcan--disabled):hover path {
      fill: #63646a;
    }
  }

  &__user-list-item__copy {
    padding: 6px;
    background: #eff1ff;
    display: flex;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.15s;

    &:hover:not(.meta-field-members__user-list-item__copy--copied) {
      background: #dfe4ff;
    }

    &--copied {
      background: #daf3ee;
    }
  }

  &__user-list-item-client {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #f7911a;
    font-size: 11px;
    font-weight: 500;
    padding: 8px;
    background: #fff5e9;
    border-radius: 8px;
  }

  &__user-list-item-role {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    &--grey {
      color: #74788d;
    }

    &--light-grey {
      color: #8e93ac;
    }
  }

  &__user-select {
    width: 100%;

    .ws-react-select__placeholder {
      font-size: 13px;
      top: 21px;
    }

    .ws-react-select__dropdown-indicator {

    }

    .css-nlhphf,
    .css-10e44ox {
      max-width: 326px;
    }
  }

  &__label-row {
    display: flex;
    gap: 10px;
    width: 100%;

    .stacked-avatars {
      margin-left: auto;
    }

    &-name {
      line-height: 32px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .switch-button {
    margin-left: 0;
  }

  &__see-as-client-mode {
    display: flex;
    align-items: center;
    margin-top: 12px;
  }

  &__see-as-client-mode-info-icon {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    cursor: pointer;
  }

  &__separator {
    border-bottom: dashed 1px #d3d5e1;
  }

  &__notification-check {
    margin-top: 16px;
  }

  .modal__buttons {
    .ws-button {
      min-width: 120px;
    }
  }
}
