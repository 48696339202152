.coupon-group-settings-dropdown {
  &__button {
    width: 44px;
    height: 44px;
    padding: 0;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 0;
      transform: rotate(90deg);
    }

    &--small {
      width: 32px;
      height: 32px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
