.person-profile-picture {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #f2f2f6;

  &:hover {
    .person-profile-picture__button {
      opacity: 1;
    }
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
  }

  &--square {
    border-radius: 4px;

    input {
      border-radius: 4px;
    }

    .person-profile-picture {
      &__photo,
      &__button {
        border-radius: 4px;
      }
    }
  }

  input::-webkit-file-upload-button {
    cursor: pointer;
  }

  &__button {
    position: relative;
    left: 0;
    top: 0;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: transparentize($color: white, $amount: 0.3);
    border: dashed 1px #5d78ff;
    opacity: 0;
    transition: all 0.2s ease;
    cursor: pointer;

    svg {
      pointer-events: none;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .image-input {
    position: relative;
    cursor: pointer;
  }

  &__photo {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    object-fit: cover;
    object-position: center;
  }

  &__flag {
    position: absolute;
    left: 50%;
    bottom: -2px;
    transform: translateX(-50%);
    pointer-events: none;
    z-index: 5;
  }
}
