@import "src/styles/variables.scss";

.sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  top: 0;
  background: #fff;
  width: 72px;
  height: 100%;
  padding: 20px;
  padding-left: 15px;
  border-right: 1px solid #d3d5e1;
  overflow: hidden;
  z-index: calc($z-index-navigation + 1);
  transition: all 200ms;

  .link-button__item-text {
    opacity: 0;
  }

  &__brand-logo {
    position: relative;
    display: flex;
    align-items: center;
    transition: transform 0.3s ease-in-out;

    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
      object-position: center;
      margin-top: 0;
    }

    &--hover {
      transform: scale(1);
    }

    &-empty {
      width: 40px;
      height: 60px;
    }

    &-full {
      width: 140px !important;
      height: 40px;
      transition: width 0.3s ease-out;
    }

    &-small {
      width: 40px;
      height: 40px;
      transition: width 0.3s ease-in-out;
    }
  }

  &__logo {
    position: relative;
    display: flex;
    align-items: center;

    transition: opacity 1.3s ease-in-out;

    svg path {
      shape-rendering: optimizeQuality;
    }

    &-shape {
      width: 40px;
      height: 40px;
      margin-bottom: 30px;
    }

    &-araby-ads {
      transition: all 0.3s ease;
      position: absolute;
      bottom: 15px;

      path {
        fill: #45485b !important;
      }
    }

    &::before {
      transition: all 0.3s ease;
    }

    &--local {
      $color: #f54e60;
      &::before {
        content: "local";
        position: absolute;
        font-size: 11px;
        font-weight: bold;
        color: $color;
        text-transform: uppercase;
        bottom: 2px;
        width: 40px;
        text-align: center;
      }

      svg {
        path:nth-child(-n + 4) {
          fill: $color;
        }
      }
    }

    &--dev {
      $color: #834df4;
      &::before {
        content: "dev";
        position: absolute;
        font-size: 11px;
        font-weight: bold;
        color: $color;
        text-transform: uppercase;
        bottom: 2px;
        width: 40px;
        text-align: center;
      }

      svg {
        path:nth-child(-n + 4) {
          fill: $color;
        }
      }
    }

    &--beta {
      $color: #3fc5bd;
      &::before {
        content: "beta";
        position: absolute;
        font-size: 11px;
        font-weight: bold;
        color: $color;
        text-transform: uppercase;
        bottom: 2px;
        width: 40px;
        text-align: center;
      }

      svg {
        path:nth-child(-n + 4) {
          fill: $color;
        }
      }
    }

    &--stage1 {
      $color: #f8a801;
      &::before {
        content: "stage1";
        position: absolute;
        font-size: 11px;
        font-weight: bold;
        color: $color;
        text-transform: uppercase;
        bottom: 2px;
        width: 40px;
        text-align: center;
      }

      svg {
        path:nth-child(-n + 4) {
          fill: $color;
        }
      }
    }

    img {
      width: 32px;
      height: 32px;
      object-fit: cover;
      object-position: center;
      margin-top: 20px;
      border-radius: 4px;
    }

    &-word {
      position: absolute;
      left: 52px;
      top: 3px;
      opacity: 0;
      transition: all 0.3s ease;
    }
  }

  .creator-discovery-link__elements {
    max-height: 0px;
  }

  &:hover {
    width: 221px;

    .sidebar__logo {
      &::before {
        bottom: 15px;
      }

      &-araby-ads {
        opacity: 0;
      }

      &-word {
        opacity: 1;
      }
    }

    .sidebar__item {
      &-text {
        opacity: 1;
      }
    }

    .creator-discovery-link__elements {
      max-height: 1000px;
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    height: calc(100%);
    align-items: flex-start;
  }

  &__list {
    width: 100%;
    padding: 0;
    padding-top: 8px;
    list-style-type: none;
    margin-top: 15px;
    display: grid;
    gap: 8px;

    &--bottom {
      margin-top: auto;
    }
  }

  &__intercom-icon {
    display: inline-block;

    &-counter {
      width: 15px;
      height: 15px;
      position: absolute;
      left: 2px;
      bottom: 12px;
      line-height: 10px;
      font-size: 10px;
      text-align: center;
      background: #fff;
      padding: 2px;
      border: 1px solid #ddd;
      border-radius: 50%;
    }

    &-text {
      top: 8px;
    }
  }
}
