.project-selector-field {
  display: flex;
  align-items: center;
  padding-right: 20px;

  &__cover {
    width: 24px;
    height: 24px;
    margin-right: 10px;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 4px;
    }
  }

  &__name {
    padding-right: 10px;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__link {
    height: 26px;
  }
}