.profile-data-text {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #6a6d7c;
  white-space: pre-wrap;
  margin-top: 20px;
  word-break: break-word;
  overflow-wrap: anywhere;
}
