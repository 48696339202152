

.chart {
  width: 100%;
  flex-shrink: 0;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background: transparent no-repeat center;
    background-size: contain;

    // &--reach {
    //   background-image: url("../../../images/stats-reach.svg");
    // }
    // &--likes {
    //   background-image: url("../../../images/stats-likes.svg");
    // }
    // &--posts {
    //   background-image: url("../../../images/stats-posts.svg");
    // }
    // &--comments {
    //   background-image: url("../../../images/stats-comments.svg");
    // }
    // &--impressions {
    //   background-image: url("../../../images/stats-impressions.svg");
    // }
    // &--views {
    //   background-image: url("../../../images/stats-views.svg");
    // }
    // &--followers {
    //   background-image: url("../../../images/stats-followers.svg");
    // }
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.5;
    color: #74788d;
  }

  &__value {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.5;
    color: #74788d;
  }

  .recharts-wrapper {
    .recharts-cartesian-axis-tick-line,
    .recharts-cartesian-axis-line {
      display: none;
    }

    .yAxis {
      .recharts-text.recharts-cartesian-axis-tick-value {
        fill: #8e93ac;
      }
    }

    .xAxis {
      .recharts-text.recharts-cartesian-axis-tick-value {
        fill: #d3d5e1;
      }
    }

    .recharts-surface {
      overflow: visible;
    }
  }
}

.chart-tooltip {
  background: #fff;
  box-shadow: 0px 1px 15px rgba(69, 65, 78, 0.2);
  border-radius: 3px;
  color: #8e93ac;
  font-weight: 500;
  font-size: 9px;
  padding: 10px 12px;

  &__label {
    margin-bottom: 4px;
  }
}
