.sortingTasksArrow {
  transform: rotate(180deg);
  transition: transform 0.3s;
  cursor: pointer;
  &--sortingAscending {
    transform: rotate(0deg);
  }
  &__wrapper {
    width: 17px;
  }
}
