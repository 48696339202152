.stop-sharing-group-with-client-modal {
  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .share-tooltip{
    display: flex;

    &__title {
      margin-right: 10px;
      line-height: 20px;
    }
  }

  .stacked-avatars {
    margin-left: auto;
  }

  .modal__buttons {
    margin-top: 0;
  }

  &__task-details {
    display: flex;
    margin-right: 10px;

    &-container {
      display: flex;
      margin-bottom: 20px;
    }
  }

  &__task-name-wrapper {
    height: 48px;
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    justify-content: center;

    &--with-socials {
      justify-content: space-between;
    }
  }

  &__task-name {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #74788d;
  }
}
