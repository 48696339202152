.influencer-info {
  display: flex;
  gap: 6px;
  flex-direction: column;

  &__username-wrap {
    display: flex;
  }

  &__username {
    display: flex;
    gap: 8px;
    font-weight: 500;
    font-size: 16px;
    color: #45485b;
    cursor: pointer;
    align-items: center;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }

    &-icon {
      path {
        fill: #6a6d7c;
      }
    }
  }

  &__followers {
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: #a7abc3;
    display: flex;
    align-items: center;
    margin-right: 16px;
    white-space: nowrap;

    &::before {
      content: "";
      width: 20px;
      height: 20px;
      background: url("../../../../../../../images/clients-full-grey.svg")
        no-repeat center;
      background-size: contain;
      margin-right: 8px;
      display: inline-block;
    }
  }

  &__activity {
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: #a7abc3;
    display: flex;
    align-items: center;
    margin-right: 16px;
    white-space: nowrap;
    cursor: pointer;

    &::before {
      content: "";
      width: 20px;
      height: 20px;
      background: url("../../../../../../../images/activity.svg") no-repeat
        center;
      background-size: contain;
      margin-right: 8px;
      display: inline-block;
    }
  }

  &__bottom {
    display: flex;
    gap: 12px;
    justify-content: flex-start;
    align-items: center;
  }

  &__link-to-profile {
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: #1267fb;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 6px 0;
    align-items: center;
    white-space: nowrap;
    border-radius: 4px;
    margin-top: 7px;
    margin-bottom: 0px;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }

    &__icon {
      width: 20px;
      height: 20px;

      path {
        opacity: 1;
        fill: #1267fb;

        &:nth-child(3) {
          fill: #b8d1fe;
        }
      }
    }
  }

  &__more {
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: #6a6d7c;
    display: flex;
    align-items: center;
    word-break: keep-all;
    margin-top: 8px;
    width: min-content;
    cursor: pointer;
    user-select: none;
    display: flex;
    gap: 6px;

    &-icn {
      background: #f2f2f6;
      display: flex;
      border-radius: 4px;
      padding: 2px;

      path {
        fill: #6a6d7c;
      }
    }

    &:hover {
      opacity: 0.7;
    }

    &--opened {
      color: #1267fb;

      .influencer-info__more-icn {
        background: #dceafb;

        path {
          fill: #1267fb;
        }
      }
    }
  }

  &__social-platforms-wrapper {
    display: flex;
    padding-top: 10px;
  }

  .dropdown__menu {
    min-width: 165px;
    padding: 0;

    &-item {
      & > div {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }

    &-separator {
      padding: 4px 16px;
    }
  }
}
