@import "../../../../../../../styles/mixins.scss";

.add-creators-modal {
  &__textarea {
    width: 600px;
    resize: none;
    border-radius: 8px;
    height: 48px;
    color: #45485b;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    padding: 14px 12px 14px 16px;
    border: 1px solid #b4b6be;
    outline: none;
    @include custom-scrollbar;
    &:focus {
      border: 1px solid rgba(93, 120, 255, 0.6);
    }

    &::placeholder {
      font-weight: 500;
      font-size: 12px;
      color: #d3d5e1;
    }

    &--not-empty {
      height: 120px;
    }

    &--error {
      border: 1px solid #fec4d7;
    }

    &:disabled {
      background: #f2f2f6;
      border: 1px solid #d3d5e1;
    }
  }

  &__error {
    color: #fd397a;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
  }

  &__error-icon {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
}
