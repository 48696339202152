.recently-assigned {
  position: relative;
  width: 100%;
  height: 120px;
  border: dotted 1px #d3d5e1;
  border-radius: 12px;
  padding: 0 24px;
  padding-bottom: 0;
  height: auto;

  &__no-results {
    height: calc(100% - 100px);
  }

  &__empty {
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #74788d;
    margin-top: 24px;
  }

  &__header {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    color: #74788d;

    svg {
      margin-right: 16px;
      transition: all 0.3s ease;
      path {
        fill: #74788d;
      }
    }
  }

  &__list {
    &-cover {
      width: 60px;

      .ws-table__image-cover {
        position: static;
      }

      img {
        width: 48px;
        height: 48px;
        object-fit: cover;
        object-position: center;
        background-color: #f2f2f6;
      }

      &--square img {
        border-radius: 8px;
      }
    }

    &-due-date {
      position: relative;
      width: 110px;
      height: 30px;
      text-align: center;

      .date-picker-wrapper {
        .idh-date-picker input {
          width: 100% !important;
          height: 100%;
          padding: 0;
          text-align: center;
          border: none;
          background-color: transparent;
        }

        &--empty {
          width: 110px !important;
          height: 30px !important;
          border: 1px dashed #d3d5e1;

          &:hover {
            border: 1px dashed #5d78ff;
          }
        }
      }

      &--today span {
        color: #47c4ab;
      }
    }

    &-name {
      cursor: pointer;

      &-header {
        text-align: left;
      }
    }

    &-project-name {
      width: 300px;
      text-align: center;

      span {
        color: #74788d;
        background-color: #f2f2f6;
        border-radius: 104px;
        padding: 6px 12px 6px 8px;
        cursor: pointer;
      }
    }
  }

  table {
    margin-top: 20px;
    width: 100%;
    border-collapse: collapse;

    tbody tr:not(:last-child) {
      border-bottom: dotted 1px #d3d5e1;
    }

    thead tr {
      border-bottom: dotted 1px #d3d5e1;
    }

    th {
      color: #a7abc3;
      font-weight: 600;
      font-size: 10px;
      line-height: 16px;
      text-transform: uppercase;
      padding-bottom: 14px;
    }

    td {
      height: 88px;
      color: #74788d;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
    }
  }
}
