.creator-box {
  display: flex;

  &__data {
    width: 348px;
    height: 102px;
    display: flex;
    align-items: center;

    &-top {
      display: flex;

      &-avatar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 12px;
      }

      &-info {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #74788d;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &-bottom {
      display: flex;
      margin-top: 12px;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #8e93ac;

      &-location {
        display: flex;
        align-items: center;
        margin-right: 20px;

        .flag {
          margin-right: 8px;
        }
      }
    }
  }

  &__metrics {
    display: grid;
    grid-template-columns: 145px 145px 145px;
    column-gap: 8px;

    &-box {
      display: flex;
      flex-direction: column;
      width: 145px;
      height: 52px;
      padding: 8px 10px;

      &-name {
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        min-height: 10px;
        color: #a7abc3;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &-value {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        min-height: 10px;
        color: #74788d;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
