.audience-search-wrapper {
  position: relative;
}

.audience-search-actions {
  position: absolute;
  right: 0;
  top: -47px;

  .ws-button {
    margin-left: 48px;
  }
}

.audience-search {
  border-bottom: 1px dashed #d3d5e1;
  width: 100%;
  margin-bottom: 82px;
  position: relative;
  width: 100%;
  display: block;

  &-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__tooltip {
    &--light-grey {
      color: #a7abc3;
    }

    &--white-bold {
      font-weight: 600;
      color: #e1e1e1;
    }

    &--blue {
      color: #1267fb;
    }

    &--bold {
      font-weight: 700;
    }
  }

  .tooltip-content-wrapper .tooltip-tick {
    vertical-align: middle;
    height: 14px;
    width: 14px;
    margin-right: 4px;
    transform: translateY(-1px);

    path {
      fill: white;
    }
  }

  &__content {
    display: flex;
  }

  &__column {
    position: relative;
    flex: 4 0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &:nth-of-type(1) {
      padding: 32px 32px 32px 0;
    }

    &:nth-of-type(2) {
      border-left: 1px dashed #d3d5e1;
      border-right: 1px dashed #d3d5e1;
      padding: 32px;
    }

    &:nth-of-type(3) {
      padding: 32px 0 32px 32px;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__text {
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      color: #45485b;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__switch {
    display: flex;
    align-items: flex-start;
    color: #45485b;

    .ws-switch {
      margin-right: 8px;
    }

    &-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    &-subtext {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }

  &__field {
    &-columns {
      display: flex;
      margin: 0 -8px;
    }

    &-col {
      &-1 {
        flex: 1 0;
        margin: 0 8px;

        .ws-react-select {
          .ws-react-select__control {
            .ws-react-select__single-value {
              margin-left: 2px;
            }
          }

          .ws-react-select__option {
            padding: 4px 10px;
          }
        }

        .ws-react-select__placeholder {
          left: 10px;
        }
      }
      &-3 {
        flex: 3 0;
        margin: 0 8px;
      }
    }
    &-box {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &:empty {
      display: none;
    }
  }

  &__input.audience-search__input--error {
    display: flex;
    flex-direction: column;
    gap: 8px;

    input,
    input:focus {
      border: 1px solid #e15951 !important;
    }
  }

  &__input {
    width: 100%;
    position: relative;

    &__error-message {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 12px;
      font-weight: 400;
      color: #d93025;
      svg {
        width: 16px;
        height: 16px;
      }
    }

    input {
      width: 100%;
      height: 40px;
      background: #fff;
      border: 1px solid #d3d5e1;
      border-radius: 8px;
      color: #74788d;
      font-weight: 500;
      padding-left: 16px;
      font-size: 12px;
      line-height: 18px;

      &::placeholder {
        color: #d3d5e1;
      }

      &:focus {
        outline: 0;
        border: 1px solid #9eaeff !important;
      }

      &:disabled {
        background-color: rgba(211, 213, 225, 0.3);
      }
    }

    .bio-input-with-emoji-picker {
      border-color: #9eaeff;
    }

    &:empty {
      display: none;
    }

    ul {
      transform: translateX(18px);
      padding-right: 18px;

      li {
        list-style-type: disc;
      }
    }

    &-wrapper {
      display: flex;
      gap: 16px;

      &--with-emoji {
        .emoji-button {
          right: 56px;
        }
      }
    }
  }

  &__labels {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    z-index: 3;

    .tooltip {
      display: inline-block;
    }

    &:empty {
      display: none;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    gap: 4px;
    background: #eff1ff;
    color: #5d78ff;
    font-size: 12px;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 4px;

    &--grey {
      background-color: #ededef;
      color: #6a6d7c;

      .audience-search__label__close {
        path {
          fill: #6a6d7c;
        }

        &:hover {
          path {
            fill: #6a6d7c;
            opacity: 0.7;
          }
        }
      }
    }

    &--disabled {
      background-color: #ededef;
      color: #c8c9ce;
      pointer-events: none;

      .audience-search__label__close {
        path {
          fill: #c8c9ce;
        }
      }
    }

    &__close {
      cursor: pointer;

      path {
        transition: all 0.2s ease-out;
        fill: #71a4fd;
      }

      &:hover {
        path {
          fill: #5d78ff;
        }
      }
    }
  }

  &__clear {
    margin-bottom: 32px;
  }

  &__arrow-button {
    padding: 4px;
    margin: 0;
    margin-left: 8px;

    img {
      margin: 0;
    }
  }

  .topics-list {
    overflow: auto;
    max-height: 496px;

    &--hashtags {
      margin-bottom: 17px;
    }

    .topic {
      margin-bottom: 16px;
    }
  }

  .cdt-search-input {
    outline: none;
    width: 100%;
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      bottom: 10px;
      left: 16px;
      background: url("../../../../../../../images/magnifier-grey.svg")
        no-repeat center;
      width: 20px;
      height: 20px;
    }

    input {
      width: 100%;
      height: 40px;
      left: 0px;
      top: 19px;
      background: #fff;
      border: 1px solid #d3d5e1;
      border-radius: 8px;
      padding: 0 40px;
      color: #74788d;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }

    &::placeholder {
      color: #d3d5e1;
    }

    &:focus {
      outline: 0;
      border: 1px solid #b8d1fe !important;
    }

    .topics-list {
      overflow: auto;
      height: 300px;

      &--hashtags {
        margin-bottom: 15px;
      }

      &:disabled {
        background-color: rgba(211, 213, 225, 0.3);
      }
    }
  }

  .ws-react-select .ws-react-select__clear-indicator svg,
  .ws-react-select .ws-react-select__dropdown-indicator svg {
    display: block;
  }
}

.idh-label {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #7d808d;
  margin-bottom: 4px;

  &--with-tooltip {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.input-wrapper {
  width: 100%;
  display: flex;
  align-items: center;

  &__range {
    flex-shrink: 0;
    background: #f2f2f6;
    border: 1px solid #d3d5e1;
    border-right: 0;
    box-sizing: border-box;
    border-radius: 8px 0px 0px 8px;
    width: 53px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 11px;
    color: #a7abc3;

    & + .input-wrapper__item {
      width: calc(100% - 53px);
      height: 40px;

      .ws-react-select {
        .ws-react-select__control {
          height: 40px;
          border-radius: 0px 8px 8px 0px;

          .ws-react-select__value-container {
            height: 40px;
          }
          .ws-react-select__single-value {
            margin-left: 2px;
          }
        }

        .ws-react-select__dropdown-indicator {
          margin: 10px 2px 0 4px;
        }

        .ws-react-select__option {
          padding: 4px 10px;

          &--is-selected::after {
            display: none;
          }
        }
      }

      .ws-react-select__placeholder {
        left: 10px;
      }
    }
  }

  &__item {
    flex: 1;
    width: 100%;

    input {
      width: 100%;
      height: 40px;
      border-radius: 8px;
      border: 1px solid #d3d5e1;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      padding-left: 16px;

      &::placeholder {
        color: #d3d5e1;
      }

      &:focus {
        outline: 0;
      }
    }
  }
}

.emoji-picker-wrapper {
  position: absolute;
  right: 0;
  top: 70px;
  z-index: 100;
  box-shadow:
    0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px -2px 8px -2px rgba(16, 24, 40, 0.05);
}

.emoji-button {
  background: #ededef;
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #b4b6be;
  border-radius: 0px 8px 8px 0px;
  position: absolute;
  padding: 10px;
  top: 22px;
  right: 0;
  height: 40px;

  &--active {
    border-color: #9eaeff;
    background: #dfe4ff;
  }

  .epr-emoji-img {
    cursor: pointer;
  }
}
