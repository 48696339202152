.task {
  &__top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 20px;
  }

  &__toolbar {
    display: flex;
    align-items: center;
    gap: 16px;

    &-icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
      &:hover {
        path {
          fill: #536ceb;
        }
      }
    }
  }

  &__person {
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    &--publication {
      align-items: flex-start;
    }
  }

  .person-profile-picture {
    margin-right: 12px;
  }

  &__container {
    border-top: 1px solid #f2f2f6;
    border-bottom: 1px solid #f2f2f6;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;

    scroll-behavior: smooth;

    &__inner-wrap {
      padding: 20px;
    }
  }

  &__details {
    display: flex;
    justify-content: space-between;
    margin: 16px 0;

    &-left {
      display: flex;
      color: #a7abc3;
      font-weight: 600;
      font-size: 10px;
      line-height: 16px;
      text-transform: uppercase;
      flex-direction: column;

      &-row {
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 32px;

        &:not(:first-of-type) {
          margin-top: 8px;
        }
      }

      &-button-wrapper {
        height: 100%;
        display: flex;
        align-items: center;

        .dropdown-value-wrapper {
          display: flex;
          align-items: center;
          transition: all 0.1s ease;
          margin-left: 12px;
          height: 100%;
          border-radius: 8px;
          padding: 6px 12px;

          &:hover {
            background-color: lighten($color: #eff1ff, $amount: 1);

            svg {
              opacity: 1;
            }
          }

          &__buttons {
            margin-left: 8px;
            display: flex;
            align-items: center;

            .share-icon,
            .close-icon {
              width: 16px;
              height: 16px;
              cursor: pointer;
              opacity: 0;
              transition: all 0.1s ease;
            }

            .close-icon {
              margin-left: 2px;
            }
          }
        }

        .date-picker-wrapper {
          position: relative;
          margin: 0;

          &--no-hover {
            .dropdown-value-wrapper:hover {
              background: transparent;
            }
          }

          &:hover {
            .idh-date-picker__input {
              border: 1px dashed #5d78ff;
            }

            .date-picker-wrapper__plus {
              path {
                fill: #5d78ff;
              }
            }
          }

          &__plus {
            position: absolute;
            left: calc(50% + 6px);
            top: 50%;
            transform: translate(-50%, -50%);
            width: 12px;
            height: 12px;
            z-index: 5;
            pointer-events: none;

            path {
              fill: #d3d5e1;
              transition: all 0.2s ease;
            }
          }
        }

        .idh-date-picker {
          &__input {
            width: 90px;
            height: 24px;
            padding: 10px 8px !important;
            border: 1px dashed #d3d5e1;
            background: transparent;
            cursor: pointer;
          }
        }

        .add-button {
          margin-left: 24px;
        }

        &:not(:first-of-type) {
          margin-left: 24px;
        }
      }
    }

    &-right {
      display: flex;
      align-items: center;
      height: fit-content;

      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 16px;
      }
    }
  }

  &__description {
    min-height: 70px;
    border-radius: 8px;
    outline: 1px solid #d3d5e1;

    &:hover,
    &:focus {
      outline: 1px solid #9eaeff;
    }
  }

  &__heading {
    color: #d3d5e1;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-top: 14px;

    &--big {
      color: #8e93ac;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      margin: {
        top: 0;
        bottom: 20px;
      }
    }
  }

  &__title {
    color: #74788d;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    margin-top: 8px;
  }

  &__goals {
    display: flex;
    align-items: center;
    color: #a7abc3;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    margin: 16px 30px 10px;

    &-counter {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #5d78ff;
      border-radius: 4px;
      color: white;
      margin-left: 12px;
    }
  }

  &__btn {
    &-share {
      margin-left: 16px;

      svg {
        margin-right: 0;
      }
    }
  }

  &__add-goal {
    margin-top: 16px;
    padding: 0;

    &:hover {
      background: none;

      & svg path:nth-child(1) {
        fill: #6f84e9;
      }

      & svg path:nth-child(2) {
        fill: white;
      }
    }
  }

  &__footer {
    position: relative;
    display: flex;
    gap: 12px;

    .button--add-content-proposal {
      margin: 20px;
    }

    .add-comment-wrapper {
      display: flex;
      margin: 20px;
      gap: 12px;
    }
  }

  &__author-avatar {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    object-fit: cover;
  }
}

.task-story-feed {
  margin: 20px 0;

  &__activity {
    display: flex;
    align-items: center;
    color: #b4b6be;
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
    gap: 6px;
  }

  &__avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }

  &__date,
  &__time,
  &__name {
    font-weight: 500;
    font-size: 12px;
    color: #8f919d;
  }

  &__name {
    text-transform: capitalize;
  }

  &__time {
    color: #b4b6be;
  }
}

.subtask-list {
  display: flex;
  flex-direction: column;
  position: relative;

  &__header-row {
    display: flex;
    justify-content: space-between;
    padding: 12px 30px;
    padding-right: 16px;
    border-bottom: 1px dashed #d3d5e1;

    &-right {
      display: flex;
    }
  }

  &__column-header {
    color: #a7abc3;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;

    &--date {
      width: 82px;
      text-align: center;
    }

    &--assignee {
      width: 82px;
      text-align: center;
      margin-left: 12px;
    }
  }
}
