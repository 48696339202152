@import "../../../../styles//mixins.scss";

.filters-header {
  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #74788d;
  }
  &__icon {
    margin-right: 8px;
  }
}
.filter {
  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 6px 0;
    margin: 8px 0;
    color: #74788d;
  }
  &__option {
    padding: 6px 0;

    .ws-checkbox > label > span {
      width: 100%;
      word-wrap: break-word;
    }
  }
}

.projects__topbar-right .ws-button.filters-button {
  margin-left: 0;
  border-radius: 0;
  width: 100%;
}

.filter-box-empty {
  color: #74788d;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  text-align: center;
  pointer-events: none;
  user-select: none;
}

.campaign-items-filters {
  overflow-y: auto;
  @include custom-scrollbar;
}
