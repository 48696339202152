.ws-input {
  width: 260px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #b4b6be;
  outline: none;
  padding: 0 16px;
  color: #45485b;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  transition: all 0.2s ease;

  &--fullWidth {
    width: 100%;
  }

  &--wide {
    width: 400px;
  }

  &:focus {
    border: 1px solid rgba(93, 120, 255, 0.6);
  }

  &--error {
    border: 1px solid #fd397a;

    &:focus {
      border: 1px solid #fd397a;
    }
  }

  &--disabled {
    background: #ededef;
    border: 1px solid #dadade;
    color: #c8c9ce;
  }

  &::placeholder {
    color: #d3d5e1;
  }
}

.ws-input-label {
  line-height: 20px;
  margin-bottom: 4px;

  &--light-grey {
    color: #7d808d;
  }

  &--dark-grey {
    color: #45485b;
  }

  &--font-small {
    font-size: 12px;
  }

  &--font-medium {
    font-size: 13px;
  }

  &--font-big {
    font-size: 14px;
  }

  &--thin {
    font-weight: 400;
  }

  &--medium {
    font-weight: 500;
  }

  &--bold {
    font-weight: 600;
  }

  &--above-input {
    display: flex;
    align-items: center;
  }

  &--required {
    margin-left: 5px;
    font-size: 16px;
  }
}
