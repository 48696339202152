.content-proposal-moderation-comment-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  max-width: 100%;
  &__caption {
    display: flex;
    gap: 8px;
    max-width: 100%;
    word-wrap: break-word;
  }
}

.comment-header-element {
  &__action-info {
    display: flex;
    align-items: center;
    gap: 4px;

    &--scroll {
      span {
        color: #5d78ff;
        transition: all 300ms;
        cursor: pointer;

        &:hover {
          color: #4d67ea;
          text-decoration: underline;
        }
      }
    }
  }

  &__caption,
  &__media {
    margin: 0 4px;
  }

  &__caption {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #8f919d;
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    flex-grow: 1;
  }
  &__media {
    opacity: 0.7;
  }
}
