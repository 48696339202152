@import "src/styles//mixins.scss";

.profile-box {
  padding: 24px;
  border: 1px dashed #d3d5e1;
  border-radius: 12px;
  margin-bottom: 20px;
  position: relative;

  .tooltip__content {
    padding: 10px;
  }

  .tooltip__text {
    white-space: break-spaces;
    span {
      word-break: break-word;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 2px;
    width: 5px;
    background: #ededef;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    width: 5px;
    background: #8f919d;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #6e6f77;
  }

  &--small {
    padding: 16px;
  }

  &--on-project-details {
    width: auto;
    margin-bottom: 0;
    min-height: 190px;

    .profile-box__heading {
      font-size: 11px !important;
      line-height: 13px;
      margin-bottom: 16px;
    }
  }

  &--adaptive-height {
    flex: 1;
  }

  &__heading {
    color: #a7abc3;
    font-weight: 500;
    line-height: 40px;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .tooltip,
    .material-tooltip__toggle {
      margin-left: 8px;
      cursor: pointer;
    }
  }

  .progress-bar-data__label {
    width: 56px;
  }

  &--block {
    width: 100%;

    .progress-bar-data__label {
      width: 300px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .material-tooltip__toggle {
      cursor: pointer;
    }

    svg {
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }

    &--on-showcase {
      margin-bottom: 20px;

      svg {
        height: 24px;
        width: 24px;
        margin-right: 8px;
      }

      .profile-box__header {
        &-count {
          font-size: 13px !important;
          line-height: 24px !important;
        }

        &-title,
        &-title--with-scroll {
          margin-left: 8px;
          font-size: 13px !important;
          line-height: 24px !important;
        }
      }
    }

    &--on-project-details {
      margin-bottom: 16px;

      svg {
        height: 24px;
        width: 24px;
        margin-right: 16px;
      }

      .profile-box__header {
        &-count {
          font-size: 13px !important;
          line-height: 24px !important;
        }

        &-title {
          font-size: 13px !important;
          line-height: 24px !important;
        }
      }
    }

    &--showcase-bio {
      margin-bottom: 12px;

      svg {
        margin-right: 0;
      }
    }
  }

  &__header-title {
    color: #a7abc3;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-left: 16px;

    &--big {
      font-weight: 500;
      font-size: 18px;
      line-height: 40px;
      color: #74788d;
      margin-right: 8px;
      margin-left: 0;
    }

    &--with-scroll {
      font-weight: 500;
      font-size: 20px;
      line-height: 40px;
      color: #74788d;
      margin-right: 8px;
    }
  }

  &__header-progress-bar {
    flex: 1 1;
    padding: 0 40px;
  }

  &__header-count {
    color: #74788d;
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
  }

  &__parameter {
    color: #a7abc3;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    .tooltip {
      margin: 0 8px;
    }
  }

  &__content {
    display: flex;

    &--column {
      flex-direction: column;
    }
  }

  &__parameter {
    display: flex;
    align-items: center;
    margin-top: 16px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__parameter-name {
    color: #a7abc3;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    margin: 0;
  }

  &__parameter-value {
    color: #74788d;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    &--grey {
      color: #a7abc3;
      margin-left: 10px;
    }
  }

  &__parameter-values {
    display: flex;
    align-items: center;
  }

  &__value-type {
    color: #d3d5e1;
    margin-right: 8px;
    font-weight: 500;
    font-size: 8px;
    line-height: 20px;

    &--second {
      margin-left: 16px;
    }
  }

  &__bio-info {
    color: #6a6d7c;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  &__credibility-info {
    &-header {
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 20px;
      color: #a7abc3;
    }
    &__show-details {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: #F6F6F7;
      border-radius: 8px;

      &-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 12px 16px 12px 16px;
        gap: 32px;
        align-items: center;
        font-size: 14px;

        &-btn {
          align-self: flex-end;
          margin-left: auto;
          background-color: transparent;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
          color: #45485B;
          padding: 8px 12px !important;
          border: 1px solid #EDEDEF !important;

          .chevron {
            background: transparent !important;
          }
        }
      }
      &-description {
        padding: 12px 16px 12px 16px;
      }
    }
    &-stats {
      display: flex;
      flex-direction: row;
      gap: 30px;
      width: 100%;
      margin-top: 30px;

      .tooltip__content {
        min-width: 300px;
      }

      .profile-box__heading {
        line-height: 24px;
        color: #45485B;
      }

      .progress-bar-data__label {
        width: 100px;
      }

      &-col {
        width: 100%;
      }

      &--on-showcase {
        display: flex;
        flex-direction: column;
        margin-top: 0;

        .profile-box__heading {
          line-height: 18px;
          font-size: 12px;
          color: #74788d;
          margin-bottom: 5px;
        }

        .progress-bar-data__label {
          width: 100px;
        }

        &-col {
          width: 100%;
        }
      }
    }

    &-table {
      display: block;
      margin-bottom: 15px;

      &-row {
        display: flex;

        &-range {
          gap: 20px;
          flex-basis: 80px;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
          color: #74788d;

          span {
            line-height: 28px;
          }
        }

        &-description {
          flex-basis: 600px;
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
          margin-bottom: 15px;
          color: #a7abc3;
        }

        &-type {
          display: inline-flex;
          background: #f3efff;
          border-radius: 5px;
          color: #8358ff;
          padding: 5px 10px;
          margin-right: 15px;
        }
      }
    }

    &--on-showcase {
      .profile-box__credibility-info {
        &-header {
          font-size: 13px;
          line-height: 24px;
          margin-bottom: 8px;
        }

        &-table {
          margin-bottom: 8px;

          &-row {
            &-range {
              font-size: 13px;
              line-height: 24px;
              flex-basis: 64px;
            }

            &-type {
              padding: 4px 8px;
            }

            &-description {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  &__credibility-description {
    color: #a7abc3;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;

    &--on-showcase {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;

      br {
        display: none;
      }
    }
  }

  &__credibility-list-item {
    margin-top: 7px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #a7abc3;
    display: flex;
    align-items: center;

    &:first-of-type {
      margin-top: 16px;
    }
  }

  &__credibility-tick {
    margin-right: 4px;
  }

  &__bio {
    color: #8e93ac;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  &__location-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__location-list-item {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #74788d;
    display: flex;
    align-items: center;
    width: 50%;
    margin-top: 8px;

    .flag {
      margin-right: 8px;
    }
  }

  &__followers-range {
  }

  &__followers-range-count {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #74788d;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -14px;

    &--on-generator {
      margin-top: 48px;
    }

    span {
      color: #d3d5e1;
      margin: 0 16px;
    }
  }

  &--tags {
    padding: 0 !important;

    .profile-box__header {
      padding: 24px;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  &__similar-profiles-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 404px;
    overflow-y: auto;
    @include custom-scrollbar;
  }

  &__profile {
    display: flex;
    align-items: center;

    a {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: #45485b;
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 12px;
    }
  }
}

.recent-posts {
  position: relative;

  &__social-provider-icon {
    margin-left: 8px;
  }

  &__watch-more {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  &__list {
    display: flex;
  }

  &__item {
    margin-right: 34px;
    width: 210px;

    a {
      display: block;
    }

    &--on-showcase {
      margin-right: 18px;

      .recent-posts__image,
      .recent-posts__text {
        width: 158px !important;
      }

      .recent-posts__image {
        height: 158px !important;
      }

      .recent-posts__text {
        height: 156px !important;
        -webkit-line-clamp: 7;
      }
    }
  }

  &__text {
    background-color: #f6f6f7;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    padding: 12px;
    height: 96px;
    margin-bottom: 10px;
    color: #45485b;
    font-style: italic;

    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    .emoji {
      font-style: normal;
    }
  }

  &__image {
    width: 210px;
    height: 200px;
    background: #d3d5e1;
    border-radius: 6px;
    margin-bottom: 10px;
    object-fit: cover;
    object-position: center;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  &__stats-wrapper {
    display: flex;
    align-items: center;
  }

  &__stats {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: #a7abc3;
    margin: 0 20px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: -20px;
      width: 16px;
      height: 16px;
      margin-right: 3px;
      background-size: contain;
    }

    &--er {
      &::before {
        background: url("../../../../../../images/profile-data/er.svg")
          no-repeat center;
        background-size: contain;
      }
    }

    &--images {
      &::before {
        background: url("../../../../../../images/profile-data/image.svg")
          no-repeat center;
        background-size: contain;
      }
    }
  }

  // .watch-instagram {
  //   position: absolute;
  //   top: 24px;
  //   right: 24px;
  //   display: flex;
  //   align-items: center;

  //   svg {
  //     margin: -5px 0 -5px 6px;
  //     width: 20px;
  //     height: 20px;

  //     path {
  //       fill: #257be6;
  //     }
  //   }
  // }
}
