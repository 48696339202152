@import "../../../styles/base/media-queries.scss";
@import "../../../styles/brand-variables.scss";

.youtube-embed-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(#000, 0.4);
  z-index: 1030;
  display: flex;
  justify-content: center;
  align-items: center;
}

.youtube-embed {
  margin: 0 auto !important;
  border-radius: 8px;
}

.youtube-embed-container {
  background-color: #fff;
  border-radius: 8px;
  position: relative;

  @include min-screen($desktopHugeWidth) {
    transform: unset;
  }

  .loader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 80px;
  }

  &__close {
    position: absolute;
    top: -40px;
    right: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.35) url("../../../../../../../images/close.svg") no-repeat
      center;
    background-size: 24px 24px;
    z-index: 10;
    border-radius: 8px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  iframe {
    position: relative;
    z-index: 0;
  }
}
