.xtrmPayments {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__content {
    gap: 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .tabs {
      margin-bottom: 0;
    }
  }

  &__input-row {
    display: flex;
    gap: 24px;
  }

  &__general-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__table-wrapper {
    .ws-custom-table th:first-child,
    .ws-custom-table tbody .ws-custom-table-row td:first-child {
      padding-left: 0;
    }
  }

  &__settings-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .modal__row {
      align-items: center;
    }
    .modal__row .ws-react-select {
      width: 302px;
    }
  }

  &__settings-label {
    font-weight: 400;
    font-size: 13px;
    color: #45485b;
  }

  &__amount-select-option {
    display: flex;
    align-items: center;
  }
}
