.language-selector {
  &__item {
    display: flex;
    align-items: center;

    svg {
      margin: 0 !important;
    }

    &-original {
      margin-left: 8px;
      color: #45485b;
    }

    &-translation {
      margin-left: 8px;
    }
  }
}
