.access-link-page__form-buttons {
  display: flex;
  justify-content: space-between;

  .ws-button {
    width: 160px;
  }

  @media screen and (max-width: 600px) {
    .ws-button {
      width: 100%;
      padding: 9px 20px;
    }

    & {
      flex-direction: column;
      gap: 20px;
    }
  }
}
