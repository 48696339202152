.criteria-option-selector {
  &__menu {
    width: 300px;

    &-item {
      display: flex;
      gap: 8px;
      padding: 8px 16px 8px 16px;

      &:hover {
        background: #ededef;
        cursor: pointer;
      }

      &-data {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      &-header {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        color: #45485b;
        gap: 4px;
      }

      &-text {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #8f919d;
      }
    }
  }

  &__value {
    width: 40px;
    height: 40px;
    border-radius: 8.89px;
    border: 1px solid #dadade;
    display: grid;
    place-items: center;
    cursor: pointer;
  }
}
