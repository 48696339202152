@import "../../../../../styles/media-queries.scss";
@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.formula-overview {
  @include custom-scrollbar;

  @include max-screen($desktopWidth) {
    overflow-y: scroll;
    height: 300px;
    padding-right: 16px;
  }

  .modal__text {
    margin-bottom: 20px;
  }

  &__select-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .ws-react-select-container {
      width: calc(100% - 40px);
    }

    &-number {
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #45485b;
    }

    &-creatable-label {
      color: #5d78ff;

      &-value {
        background-color: #eff1ff;
        padding: 4px 8px;
        border-radius: 8px;
      }
    }
  }

  &__operation-selector {
    margin-bottom: 16px;
    padding-left: 40px;

    .ws-react-select-container {
      width: 100px;
    }
  }
}
