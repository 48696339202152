.task-statistics-synchronization {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &__date {
    color: #74788d;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    margin: 0 20px 0 8px;
  }

  &__sync-date-text {
    color: #a7abc3;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
  }

  &__refresh-wrapper {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 11px;
    line-height: 24px;
    color: #a7abc3;

    &--disabled {
      pointer-events: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__refresh-icon {
    margin-right: 4px;
    animation: spin 2s linear infinite;
    animation-play-state: paused;

    &--rotating {
      animation-play-state: running;
    }

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
