.single-filter {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__label {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #45485b;
    margin-bottom: 13px;
  }

  &:not(:first-of-type) .single-filter__label {
    margin-top: 20px;
  }

  .custom-date-range__input {
    height: 40px;
  }

  .ws-input-label {
    word-break: break-all;
  }
}
