.custom-date-range {
  position: relative;
  height: 48px;

  &__clear-btn {
    position: absolute;
    right: 0px;
  }

  &__input {
    display: flex;
    align-items: center;
    height: 48px;
    width: 100%;
    border: 1px solid #b4b6be;
    border-radius: 8px;
    cursor: pointer;
    padding: 14px 12px 14px 16px;
    color: #6a6d7c;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    transition: all 0.3s ease;

    &--focused {
      border: 1px solid #9eaeff;
    }

    &--error {
      border: 1px solid #fd397a;
    }

    &-separator {
      width: 8px;
      height: 1px;
      background-color: #6a6d7c;
      margin: 0 6px;
    }

    &-error {
      color: #fd397a;
    }

    svg {
      margin-right: 6px;
    }
  }
}

// plugin styling

.rdrDayToday {
  background: #ededef !important;
  border-radius: 4px;
}

.rdrDateRangePickerWrapper {
  &--to-right {
    .rdrDateRangeWrapper {
      right: 0;
    }
  }
}

.rdrDateRangeWrapper {
  box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: "Poppins", sans-serif !important;
  width: 620px;
}

.rdrDay {
  position: relative;
  height: 40px !important;
  width: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  span {
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
  }
}

.rdrDefinedRangesWrapper {
  display: none;
}

.rdrDayActive {
}

.rdrDayStartOfWeek,
.rdrDayEndOfWeek,
.rdrDayWeekend,
.rdrInRange {
  border-radius: 0 !important;
}

.rdrDayNumber {
  z-index: 1;

  span {
    color: #6a6d7c !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    &::after {
      display: none;
    }
  }
}

.rdrStartEdge,
.rdrEndEdge {
  border-radius: 4px !important;
  color: #5d78ff !important;

  ~ .rdrDayNumber span {
    color: white !important;
  }
}

.rdrDayStartPreview,
.rdrDayEndPreview {
  border: none !important;
  z-index: 0;
  border-radius: 4px !important;
  color: #5d78ff !important;
}

.rdrDayInPreview {
  border: none !important;
  z-index: 0;
  border-radius: 0 !important;
  color: #eff1ff !important;
  background: #eff1ff !important;
}

// .rdrDay, .rdrDayNumber {
//   width: 40px !important;
//   height: 40px !important;
// }

.rdrDayPassive {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.rdrDayDisabled {
  background: white !important;
}

.rdrInRange {
  color: #eff1ff !important;
}

.rdrWeekDay {
  flex-basis: 40px !important;
  color: #5d78ff !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.rdrDayHovered {
  border: none !important;
  background: #5d78ff !important;
  color: white;
  z-index: 0;
  border-radius: 4px !important;

  .rdrDayStartPreview,
  .rdrDayEndPreview {
    display: none;
  }

  .rdrDayNumber span {
    color: white !important;
  }

  .rdrInRange {
    color: transparent !important;
  }
}
