.cpd-modal {
  &__list {
    max-height: calc(65vh - 58px);
    overflow: auto;
    padding-right: 6px;
    margin-top: 6px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      border-radius: 2px;
      background-color: #e0e2ea;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #a7abc3;
      border-radius: 30px;
    }
  }

  &__username {
    color: #a7abc3;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    padding-bottom: 16px;
    border-bottom: 1px dashed #d3d5e1;
  }

  &__keywords {
    margin-top: 16px;
    display: flex;
  }

  &__keyword {
    background: #f2f2f6;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #a7abc3;
    border-radius: 4px;
    padding: 4px 8px;
    margin-right: 8px;
  }

  &__counter {
    color: #ffffff;
    background: #a7abc3;
    font-weight: 600;
    font-size: 14px;
    border-radius: 8px;
    padding: 4px 7px;
  }

  &__links {
    margin-top: 4px;
  }

  &__link-row {
    display: flex;
    justify-content: space-between;

    &:first-of-type {
      margin-top: 16px;
    }

    margin-top: 12px;
  }

  &__link {
    color: #1267fb;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }

  &__date {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #a7abc3;
  }
}
