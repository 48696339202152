@import "../../../../../styles/media-queries.scss";
@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";


.dictionary-sub-field {
  &-wrapper {
    &__row {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    &__column {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &__switch {
      display: flex;
      flex-direction: column;
      margin-top: 2px;

      &-info {
        &-header {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          color: #45485B;
        }

        &-description {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          color: #585B6C;
        }
      }
    }

    &__sub-fields {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-right: 10px;

      .ws-input-label{
        margin-bottom: 4px;

      }
    }
  }
}
