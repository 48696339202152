.recharts-custom-tooltip {
  margin: 0px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.863);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;

  &__amount {
      font-weight: 500;
  }
}
