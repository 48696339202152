.access-link-page__input-field {
  &__placeholder {
    padding: 0 16px;
    color: #8e93ac;
  }

  &__preview {
    display: flex;
    gap: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #6a6d7c;
    white-space: pre-line;

    &-prefix {
      color: #b4b6be;
    }

    &--text {
      max-width: 100%;
      overflow-wrap: break-word;
    }
  }

  &__multi-select {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  &-name-and-description {
    display: flex;
    flex-direction: column;
  }

  &-label {
    font-size: 14px;
    color: #6A6D7C;
    line-height: 22px;
    .ws-input-label--required {
      color: #D93025;
    }
  }

  &-description {
    font-size: 12px;
    color: #8F919D;
    line-height: 18px;
    margin-bottom: 8px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  .text-box,
  .external-percent-input,
  .external-currency-input {
    flex-direction: row;
    align-items: center;
    border: 1px solid #a7abc3;
    border-radius: 8px;
    min-height: 40px;
    line-height: 25px;
    padding: 6px 16px;
    transition: all 0.2s ease;

    &:focus,
    &:hover {
      outline: none;
    }

    &:focus-within {
      border: 1px solid rgba(93, 120, 255, 0.6);
    }
  }

  .external-percent-input {
    width: 100%;
    color: #74788d;
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
  }

  .external-currency-input {
    cursor: text;

    .currency-code {
      margin-left: 0;
      color: #b4b6be;
      font-size: 13px;
    }
    .currency-input {
      padding: 0;
      border: none;
      outline: none;
      color: #74788d;
      background: transparent;
    }
  }

  .ws-checkbox {
    display: flex;
    transition: all 0.2s ease;
  }

  .ws-checkbox input[type="checkbox"]:disabled + label::before {
    background: #ededef;
  }

  .error {
    border-color: #d93025 !important;
    background-color: #ffecf2 !important;
  }

  &__error-message {
    margin-top: 8px;
    display: flex;
    gap: 4px;

    color: #d93025;
    font-weight: 500;
    font-size: 12px;
    align-items: center;
  }
}