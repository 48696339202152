.single-percent-filter {
  display: flex;
  gap: 8px;

  &__input {
    width: 50%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #b4b6be;
    outline: none;
    padding: 0 16px;
    color: #45485b;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    transition: all 0.2s ease;

    &:focus {
      border: 1px solid rgba(93, 120, 255, 0.6);
    }

    &::placeholder {
      color: #d3d5e1;
    }
  }
}
