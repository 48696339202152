@import "src/styles/mixins.scss";

.campaign-list {
  height: 200px;
  overflow-y: auto;
  padding-right: 12px;
  @include custom-scrollbar;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-of-type) {
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: dashed 1px #b4b6be;
    }

    &-cover {
      width: 24px;
      height: 24px;
      margin-right: 8px;

      img {
        border: 1px solid #c8c9ce;
        border-radius: 2px;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      color: #45485b;
    }

    &-data {
      display: flex;
      align-items: center;
    }

    &-value {
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      width: 120px;
      color: #45485b;
    }

    a {
      width: 24px;
      height: 24px;
      display: grid;
      place-items: center;
    }

    &-link {
      cursor: pointer;

      &-placeholder {
        width: 24px;
      }
    }
  }
}
