.access-link-page__block--success {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  &__title {
    color: #6a6d7c;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
  }

  &__subtitle {
    color: #6a6d7c;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }

  &__icon {
    path {
      fill: #47c4ab;
    }
  }
}
