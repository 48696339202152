.velocity-icon {
  width: 20px;
  height: 20px;
  background: url("../../../../../../../images/velocity-normal.svg") no-repeat
    center;

  &--high {
    background-image: url("../../../../../../../images/velocity-high.svg");
  }

  &--low {
    background-image: url("../../../../../../../images/velocity-low.svg");
  }
}
