.file-field {
  position: relative;
  display: grid;
  place-items: center;
  color: #8e93ac;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-right: 22px;

  &__add-file-btn {
    position: relative;
    color: #5d78ff;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    padding: 14px 16px;
    border-top: solid 1px #ededef;
    border-radius: 0 0 8px 8px;
    transition: all ease-in 0.2s;
    display: flex;
    gap: 10px;
    align-items: center;
    &:hover {
      background-color: #eff1ff;
    }
    input[type="file"] {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;
    }
  }

  &:hover {
    .file-field__buttons {
      &-upload,
      &-delete {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  svg.attachment-video__play-icon {
    margin-bottom: 10px;
    margin-right: 0;
  }

  &__value-wrapper {
    display: grid;
    place-items: center;
    cursor: pointer;

    .extension-icon {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  &__value {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
      max-width: 150px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;
      color: #8e93ac;
      font-weight: 500;
    }

    &-counter {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }

    .dropdown__menu {
      width: 300px;
      padding: 0;
    }

    &-element {
      position: relative;
      cursor: default;

      svg {
        width: 20px;
        height: 20px;
      }

      &-delete {
        margin-right: 0px !important;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover path {
          fill: #6a6d7c;
        }
      }

      .extension-icon {
        width: 40px;
        height: 30px;
        cursor: pointer;
      }

      &-wrap {
        padding: 10px 0px;

        &:hover .file-field__value-element-name {
          color: #5d78ff;
        }
      }

      &-name {
        width: 182px;
        color: #45485b;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &--full-width {
          width: 240px;
        }
      }

      &-delete {
        right: 16px;
        width: 20px;
        height: 20px;

        path {
          opacity: 1;
        }
      }
    }
  }

  &__loader {
    position: relative;
    margin-top: 5px;
    width: 168px;
    height: 6px;
    border-radius: 8px;
    background-color: #d3d5e1;

    &-progress {
      position: absolute;
      left: 0;
      width: 84px;
      height: 6px;
      border-radius: 8px;
      background-color: #47c4ab;
    }
  }

  &__buttons {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 1px;
    right: -22px;

    &-upload,
    &-delete {
      display: flex;
      position: relative;
      width: 16px;
      height: 16px;
      margin-left: 6px;
      transition: all 0.2s ease;
      visibility: hidden;
      opacity: 0;
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;

        path {
          fill: #8e93ac;
        }
      }

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
      }

      ::-webkit-file-upload-button {
        cursor: pointer;
      }
    }
  }

  &--one-element {
    margin-right: 44px;

    .file-field__buttons {
      right: -44px;
    }
  }
}

.dropdown-portal__menu.file-field__dropdown-menu {
  padding: 0;
}
