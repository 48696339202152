.coupon-group-view {
  &__go-back-button {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color: #b4b6be;
  }

  &__topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    margin: {
      top: 4px;
      bottom: 24px;
    }
  }

  &__group-name {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: #45485b;
    margin-right: 20px;
  }
}
