.select-tags__selected-labels__single {
  display: flex;
  align-items: center;
  gap: 4px;

  background: #eff1ff;
  color: #5d78ff;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 4px;

  &--disabled {
    background-color: #ededef;
    color: #c8c9ce;
    pointer-events: none;

    svg {
      path {
        fill: #c8c9ce;
      }
    }
  }

  &__close {
    cursor: pointer;

    path {
      transition: all 0.2s ease-out;
    }

    &:hover {
      path {
        fill: #5d78ff;
      }
    }
  }
}
