.target-value-modal {
  min-width: 404px;
  width: 404px;
  padding: 32px;

  &__inputs {
    margin-top: 16px;
    height: 50px;

    input {
      width: 100%;
    }
  }

  &__radio-wrapper {
    display: flex;

    .ws-radio:not(:first-of-type) {
      margin-left: 32px;
    }
  }

  button {
    width: 160px;
  }
}
