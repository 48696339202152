@import "../../../styles//mixins.scss";

.ws-modal {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-width: 511px;
  max-width: 90vw;
  max-height: 95vh;
  padding: 32px;
  transition: all 0.3s ease;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  &__header {
    margin-bottom: 24px;
  }

  &__title {
    font-size: 20px;
    line-height: 28px;
    margin: 0;
    color: #45485b;
    font-weight: 400;
  }

  &__description {
    font-size: 13px;
    line-height: 20px;
    margin-top: 20px;
    color: #45485b;
    font-weight: 400;
  }

  &__body {
    overflow-y: scroll;
    padding-right: 5px;
    @include custom-scrollbar;
  }

  &__footer {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__close-icon {
    position: absolute;
    top: 36px;
    right: 32px;
    height: 24px;
    width: 24px;

    &:hover {
      cursor: pointer;
    }
  }
}
