@import "../../styles/media-queries.scss";
@import "../../styles/variables.scss";

.settings-modal {
  width: 900px;
  height: 500px;
  padding: 0;

  @include min-screen($desktopWidth) {
    height: 560px;
  }

  @include min-screen($desktopLargeWidth) {
    min-height: 700px;
  }

  .modal {
    &__body {
      display: flex;
      height: 100%;
      min-height: 500px;

      @include min-screen($desktopWidth) {
        min-height: 500px;
      }

      @include min-screen($desktopLargeWidth) {
        min-height: 500px;
      }

      &-wrapper {
        height: 100%;
      }
    }

    &__buttons {
      display: none;
    }

    &__title {
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      color: #45485b;
    }

    &__text {
      color: #8f919d;
    }
  }

  &__view {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    &-heading {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
}
