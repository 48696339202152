@import "../../styles/media-queries.scss";
@import "../../styles/variables.scss";

.onboarding-page {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ededef;

  &__container {
    flex-basis: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #dadade;
    border-radius: 16px;
    padding: 24px;
  }

  &__logo {
    display: none;

    @include min-screen($desktopHugeWidth) {
      margin-bottom: 24px;
      display: block;
    }
  }

  &__logo-centered {
    margin-bottom: 32px;

    @include min-screen($desktopHugeWidth) {
      display: none;
      margin-bottom: unset;
    }
  }

  &__header {
    font-weight: 400;
    font-size: 26px;
    line-height: 32px;
    color: #45485b;
    margin-bottom: 20px;
  }

  &__waving-hand {
    width: 32px;
    height: 32px;
    margin: 0 20px 0 12px;
  }

  &__text {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #a7abc3;
  }

  &__upload-avatar-wrapper {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #a7abc3;
    margin: 32px 0;
  }

  &__avatar-without-photo {
    width: 68px;
    height: 68px;
    background-image: url("../../images/avatar-without-photo.svg");
    cursor: pointer;
  }

  &__choose-image-wrapper {
    display: flex;
    align-items: center;

    input {
      display: none;
    }

    &:hover {
      cursor: pointer;

      .onboarding-page__avatar-without-photo {
        background-image: url("../../images/avatar-without-photo-hover.svg");
      }

      .onboarding-page__choose-image {
        color: #5d78ff;
      }
    }
  }

  &__choose-image {
    margin-left: 16px;
  }

  &__terms-label {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #585b6c;

    a {
      color: #5d78ff;
    }
  }

  &__dot {
    margin: 2px 8px 0;
  }

  &__delete-image {
    &:hover {
      color: #5d78ff;
      cursor: pointer;
    }
  }

  &__avatar-img {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .ws-input {
    margin-bottom: 32px;
  }

  &__required {
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    color: #a7abc3;
    margin-top: 8px;
    margin-bottom: 32px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }

  &__btn {
    width: 140px;
    margin-top: 32px;

    &--block {
      width: 100%;
    }
  }
}
