.content-proposal-download-modal {
  width: 512px;

  &__row {
    display: flex;
    gap: 20px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #45485B;
  }

  &__description {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #45485B;
  }

  &__image {
    width: 200px;
  }

  button {
    width: 100px;
  }
}
