.extensions-dropdown {
  .ws-button {
    gap: 0;

    svg {
      margin-right: 4px;
    }
  }

  svg path {
    fill: #45485b;
  }

  &__subheader {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    padding: 0 16px;
    height: 32px;
    text-transform: uppercase;
    color: #a1a3ad;
  }
}
