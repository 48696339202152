.ws-fullscreen-modal {
  height: 100%;
  transition: all 0.2s ease;
  padding: 48px;
  padding-bottom: 0;

  &__topbar {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    svg {
      transition: all 0.3s ease;
      cursor: pointer;
      &:hover {
        path {
          fill: #5d78ff;
        }
      }
    }
  }
}
