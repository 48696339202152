.projects-list-data {
  width: fit-content;
  min-width: calc(100vw - 88px);
  position: relative;

  .ws-table__row-flex {
    padding: 0 19px;
    width: calc(100vw - 191px);
    position: sticky;
    left: 52px;
  }
}
