.upgrade-account-modal {
  width: 512px;

  .modal__buttons {
    margin-top: 0px;
  }

  &__row {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__header {
    color: #45485B;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 20px;
  }

  &__description {
    color: #45485B;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    flex: 1;
  }

  &__buttons {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    gap: 15px;

    button {
      padding: 12px;
      border-radius: 8px;
      border: 1px solid var(--grey-05, #EDEDEF);
      background: #FFF;

      /* Drop shadow 1 */
      box-shadow: 0px 1px 50px 0px rgba(0, 0, 0, 0.10);
    }
  }

  &__upgrade-account-btn {
    color: #fff;
    background: #E5A317 !important;

    &:hover {
      background: #D29005 !important;
    }
  }
}
