.dashboard {
  display: block;
  transition: all 0.2s ease;
  height: calc(100vh - 128px);
  padding: 32px 46px;

  &--hidden {
    opacity: 0;
  }

  &__content {
    display: flex;
    padding-bottom: 64px;
    width: 100%;
    height: 100%;

    &-right,
    &-left {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-left {
      text-align: center;

      &-text {
        color: #74788d;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;
      }

      &-text-item {
        margin-bottom: 4px;
        &:last-of-type {
          margin-bottom: 0;
        }
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-text-item-icon {
        width: 20px;
        margin-left: 4px;
      }

      button {
        width: 160px;
      }
    }
  }
}
