.audience-overlap-pane {
  font-family: Poppins;
  color: #74788d;

  overflow-y: auto;

  &__title-bar {
    border-bottom: 1px dashed #d3d5e1;
    padding-bottom: 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;

    & button {
      padding: 0;

      &:hover {
        background: transparent;
        opacity: 0.7;
      }
    }

    .audience-results-page__overlap-btn {
      svg path {
        fill: #8e93ac;
      }
    }
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  }

  &__paragraph {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 24px;
  }

  &__row {
    display: flex;
  }

  &__column {
    width: 50%;

    &--boxes {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-right: 15px;
    }

    &--chart {
      display: flex;
      justify-content: space-around;
    }
  }

  &__box {
    padding: 20px;
    &-content {
      margin-top: 5px;
    }
    &-title {
      font-weight: 600;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 1px;
      color: #a7abc3;
    }
    &-amount {
      margin-top: 14px;
      display: flex;
      align-items: flex-end;
      &__number {
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
        color: #74788d;
      }
      &__percent {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;

        &--overlapping {
          color: #1267fb;
        }

        &--unique {
          color: #5ec7eb;
        }
      }
    }
    &-icon {
      margin-right: 16px;

      &__overlapping {
        path {
          fill: #1267fb;

          &:nth-child(1) {
            opacity: 0.3;
          }

          &:nth-child(2) {
            opacity: 1;
          }
        }
      }

      &__unique {
        path {
          fill: #5ec7eb;
        }
      }
    }
  }
  .loader-wrapper {
    width: 92px;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 2px;
    width: 5px;
    background: #ededef;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    width: 5px;
    background: #8f919d;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #6e6f77;
  }
}

.audience-overlap-results {
  margin-top: 32px;
}

.audience-overlap-table {
  &__amount {
    font-family: Poppins;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #74788d;

    &--overlapping {
      color: #1267fb;
    }

    &--unique {
      color: #5ec7eb;
    }
  }
  &__username {
    display: flex;

    .influencer-photo {
      &__img {
        width: 48px;
        height: 48px;
      }
      & .flag {
        height: 14px;
        width: 14px;
        transform: translateX(-25%);
        outline: solid white 2px;
      }
    }
    .influencer-info {
      justify-content: space-around;
      margin-left: 12px;
    }
  }
}
