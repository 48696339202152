.details-popup {
  display: flex;
  flex-direction: column;
  z-index: 1;
  gap: 18px;

  .popup__title {
    font-size: 24px;
    line-height: 32px;
    color: #45485b;
  }

  .popup__paragraph {
    font-size: 13px;
    line-height: 20px;
    color: #6a6d7c;
  }

  &__description {
    border: 1px solid #a7abc3;

    &.text-box {
      padding: 9px 16px;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      transition: all 0.2s ease;
      height: 64px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        outline: solid #d3d5e1 1px;
        &-track {
          background: #d3d5e1;
        }
      }
    }

    &:focus-within {
      border: 1px solid rgba(93, 120, 255, 0.6);
    }
  }

  .ws-input-label,
  .ws-textarea-label {
    font-size: 12px;
    line-height: 18px;
    color: #8f919d;
    margin-bottom: 4px;
  }

  .ws-react-select-container {
    margin-bottom: 0;
    height: 40px;
  }

  .ws-react-select__height {
    display: none;
  }
}
