.pane__container {
  padding: 20px;
  flex-grow: 1;

  &-wrap {
    overflow-y: auto;
    border-top: 1px solid #f2f2f6;
    border-bottom: 1px solid #f2f2f6;

    &--no-border {
      border-top: none;
    }
  }
}
