.after-export-modal {
  width: 512px;
  padding: 24px;

  .modal {
    &__row {
      gap: 24px;
    }
    &__title {
      color: #45485b;
      font-weight: 400;
      font-size: 20px;
      margin: 0;
    }
    &__column {
      gap: 20px;
    }
    &__body-wrapper {
      position: relative;
    }
    &__buttons {
      position: absolute;
      bottom: 0;
      margin-top: 0;

      .ws-button {
        padding: 12px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        border-color: #5d78ff;
      }
    }
  }

  &__text {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #45485b;
  }
}
