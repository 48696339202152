.tabs {
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e2ea;
  width: 100%;

  &--disabled {
    .tab {
      &__name {
        color: #d3d5e1 !important;
      }

      &__count {
        background-color: #d3d5e1 !important;
      }
    }
  }

  &--underlined {
    border-bottom: 1px dashed #d3d5e1;
    padding-bottom: 20px;
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
    color: #d3d5e1;
    margin-bottom: 12px;

    &--big {
      font-size: 18px;
      color: #74788d;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 4px;
  }
}

.tab {
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: transparent;
  padding: 8px 12px;
  border-radius: 12px 12px 0px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #f2f2f6;

    .tab__count {
      background: #e0e2ea;
      color: #8e93ac;
    }
  }

  &:not(.tab--active):hover {
    opacity: 0.7;
  }

  &--big {
    height: 48px;
  }

  // &--medium {
  //   .tab__name {
  //     font-size: 16px;
  //   }

  //   .tab__count {
  //     font-size: 12px;
  //     padding: 4px 7px;
  //   }
  // }

  &--active {
    border-color: #e0e2ea;
    position: relative;

    &::after {
      content: "";
      width: 100%;
      height: 2px;
      background-color: white;
      position: absolute;
      left: 0;
      bottom: -2px;
    }

    &:hover {
      background: #fff;
    }
  }

  &--no-clickable {
    pointer-events: none;
  }

  &__button {
    padding: 0;
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
  }

  &__name {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #45485b;
  }

  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f2f2f6;
    border-radius: 8px;
    font-weight: 600;
    font-size: 13px;
    line-height: 1;
    text-align: center;
    color: #a7abc3;
    padding: 0 5px 1px;
    margin-left: 8px;
    min-width: 24px;
    height: 24px;
  }
}
