@import "../../styles/media-queries.scss";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.trial-page {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
}

.trial-page-left {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 510px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  padding: 64px 64px 42px 64px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../images/into.trends.jpg"), #5d78ff;
  background-repeat: no-repeat;
  background-size: cover;

  @include max-screen($desktopWidth) {
    display: none;
    min-height: unset;
  }

  &__container {
    margin-top: 24px;
  }

  &__header {
    margin-bottom: 24px;
  }

  &__box {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    border-radius: 16px;
    padding: 16px;
    display: flex;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &--blue {
      background: rgba(93, 120, 255, 0.2);
      border: 1px solid rgba(93, 120, 255, 0.3);
      backdrop-filter: blur(5px);
    }
  }

  &__box-icon {
    flex-shrink: 0;
    margin-right: 16px;
    width: 24px;
    height: 24px;
  }

  &__social-platform {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #8f919d;
    display: flex;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 4px;
  }

  &__social-platform-icon {
    margin-right: 4px;
    width: 16px;
    height: 16px;
  }

  &__copyrights {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #8f919d;
  }
}

.trial-page-right {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: calc(100% - 510px);
  margin-left: auto;

  .ws-react-select__placeholder {
    font-size: 13px;
    line-height: 22px;
  }

  &__wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__navigation {
    padding: 40px 40px 0 40px;
    @include max-screen($desktopWidth) {
      padding: 0;
    }
  }

  &__text-area-wrapper {
    position: relative;
  }

  &__text-area {
    padding: 12px 16px;
    resize: none;
    width: 100%;
    height: 104px;
    border: 0;
    outline: 1px solid #b4b6be;
    border-radius: 8px;
    margin-bottom: 0 !important;
    @include custom-scrollbar;
    &:focus {
      border: 0;
      outline: 1px solid rgba(93, 120, 255, 0.6);
    }
  }

  @include max-screen($desktopWidth) {
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    display: block;
    min-height: unset;
    width: 100%;
    margin-bottom: 0;

    .ws-button {
      font-size: 16px;
      line-height: 28px;
    }

    .ws-input-label {
      font-size: 14px;
      line-height: 22px;
    }

    .ws-react-select__placeholder {
      font-size: 14px;
      line-height: 22px;
    }

    .ws-react-select__input {
      height: 48px;
    }

    .ws-react-select__single-value {
      font-size: 14px;
      line-height: 22px;
    }

    .ws-react-select__value-container {
      min-height: 34px;
    }

    .ws-react-select__dropdown-indicator {
      margin-top: 14px;
    }

    .ws-react-select__height {
      height: 48px !important;
    }

    .ws-react-select__control {
      min-height: 48px;
    }

    .ws-react-select__option {
      font-size: 14px;
      line-height: 22px;
      min-height: 38px;
    }

    .ws-input {
      font-size: 16px;
      line-height: 28px;
      height: 48px;
      width: 100%;
    }

    .ws-checkbox {
      label {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  .ws-input {
    margin-bottom: 11px;
    &--validation-error {
      margin-bottom: 0;
    }
  }

  .ws-react-select .ws-react-select__multi-value {
    width: auto;

    &::after {
      content: ",";
      padding-right: 3px;
      color: #74788d;
    }
  }

  &__row {
    display: flex;
    gap: 19px;
  }

  &__column {
    width: 50%;
  }

  &__book-a-demo,
  &__back-to-homepage {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #74788d;
    white-space: nowrap;
    @include max-screen($desktopWidth) {
      font-size: 16px;
      line-height: 28px;
    }
  }

  &__book-a-demo {
    &-btn {
      all: unset;
      color: #5d78ff;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
    }
  }

  .ws-react-select__single-value {
    .trial-page-right__country-option {
      &-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 120px;
      }
    }
  }

  &__country-option {
    display: flex;
    gap: 8px;
    .flag {
      border-radius: 50%;
      width: 18px;
      height: 18px;
      object-fit: cover;
    }
  }

  .ws-react-select-wrapper {
    margin-bottom: 11px;
  }

  &__back-to-homepage {
    display: flex;
    align-items: center;

    a {
      color: #74788d;
    }
  }

  &__back-to-homepage-icon {
    margin-right: 4px;
  }

  &__header {
    font-weight: 500;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    color: #45485b;
    margin-bottom: 16px;

    &--confirm {
      margin-bottom: 48px;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #45485b;
    width: 440px;
    margin-bottom: 32px;

    @include max-screen($desktopWidth) {
      width: 100%;
    }
  }

  &__disclaimer {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #585b6c;
    width: 440px;
    margin-top: 21px;
    width: 100%;

    @include max-screen($desktopWidth) {
      font-size: 14px;
      line-height: 22px;
      width: 100%;
    }

    a {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #5d78ff;
    }
  }

  &__user-message {
    color: #45485b;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-top: 8px;
    margin-bottom: 11px;
  }

  &__characters-left {
    &--under-limit {
      color: #57b471;
    }
    &--over-limit {
      color: #d93025;
    }
  }

  &__invalid-email-error {
    display: flex;
    align-items: center;
    color: #d93025;
    margin-bottom: 11px;
    font-size: 12px;
  }

  &__invalid-email-error-icon {
    margin-right: 4px;
  }

  &__sign-in {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #585b6c;
    margin-top: 8px;

    a {
      color: #5d78ff;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .ws-checkbox {
    margin-top: 32px;
  }

  &__text {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #6a6d7c;
    margin-bottom: 16px;
  }

  &__bold {
    font-weight: 500;
    color: #45485b;
  }

  &__email-address {
    color: #45485b;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  &__try-again {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #45485b;
    margin-bottom: 32px;
  }

  &__try-again-link {
    color: #5d78ff;
    font-weight: 500;

    &:hover {
      cursor: pointer;
    }
  }

  &__container {
    max-width: 432px;
    display: flex;
    flex-direction: column;

    @include max-screen($desktopWidth) {
      width: 100%;
      margin-top: 40px;

      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &--confirm {
      align-items: center;
    }
  }

  &__confirm-wrapper {
    width: 400px;

    @include max-screen($desktopWidth) {
      width: 100%;
    }
  }

  &__vertical-line {
    border: 1px solid #ededef;
    width: 1px;
    margin: 0 auto;
    height: 176px;
  }

  &__box {
    display: flex;
    margin-bottom: 8px;
  }

  &__box-left {
    margin-right: 16px;
  }

  &__box-header-icon {
    margin-bottom: 8px;
  }

  &__box-header-text {
    color: #45485b;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  &__submit-wrapper {
    height: 80px;
    display: flex;
    align-items: flex-start;
    margin-top: 32px;
    .loader-wrapper {
      margin: 0;
      svg {
        width: 100px !important;
      }
    }
  }

  &__btn {
    padding: 12px 60px;
  }
}
