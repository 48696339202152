.duplicate-project-modal {
  &__options {
    margin-bottom: 25px;
  }

  &__header {
    font-size: 10px;
    color: #A7ABC3;
    font-weight: 500;
    padding-bottom: 16px;
  }

  &__row {
    margin-bottom: 16px;
  }
}
