@import "../../../styles/media-queries.scss";
@import "../../../styles/variables.scss";

.share-multiple-columns {
  &__checkbox-wrapper {
    max-height: 252px;
    overflow-y: auto;
    margin-top: 16px;

    @include min-screen($desktopLargeWidth) {
      max-height: 344px;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f2f2f6;
      border-radius: 30px;
      width: 6px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #a7abc3;
      border-radius: 30px;
      width: 6px;
      height: 64px;
    }
  }

  &__checkbox {
    margin-bottom: 16px;
  }

  &__task-details {
    display: flex;
  }

  &__task-name-wrapper {
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12px;

    &--with-socials {
      justify-content: space-between;
    }
  }

  &__task-name {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #74788d;
  }

  .modal__text {
    margin-bottom: 0;
    padding-bottom: 16px;
    border-bottom: 1px dashed #d3d5e1;
  }
}
