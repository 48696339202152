.remove-modal {
  min-width: 400px;
  max-width: 400px;
  width: 400px;
  padding: 24px;

  &__trashcan {
    path {
      fill: #d93025;
    }
  }

  &__object-name-list {
    li {
      color: #45485b;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.02em;
      text-align: left;
    }

    &-over-limit {
      font-weight: 300 !important;
    }
  }

  .modal__text {
    margin-bottom: 12px;
  }

  .modal__buttons {
    gap: 12px;

    .ws-button {
      width: 170px;
    }
  }
}
