.action-dropdown {
  &__button {
    width: 44px;
    height: 44px;
    padding: 0;

    svg {
      margin-right: 0;
    }
  }

  &__gray-icon {
    path {
      fill: #8e93ac;
    }
  }
}
