.cover-placeholder {
  position: relative;
  height: 490px;
  padding: 0 24px;

  &__image {
    margin-top: 18px;

    svg {
      width: 100%;
    }
  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 24px;

    &-bold {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #45485b;
      margin-top: 12px;
    }

    &-text {
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      color: #45485b;

      &-underline {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .showcase-template__separator {
    // margin: 20px 0;
  }

  &__bottom-separator {
    position: absolute;
    bottom: 0;
    width: 823px !important;
  }
}
