.person-info {
  width: 100%;
  &__input-wrapper {
    display: flex;
    align-items: center;

    .check {
      margin: {
        right: 8px;
        bottom: 8px;
      }
    }

    .creator-in-database-link {
      margin-left: 9px;
    }
  }

  &__name {
    color: #74788d;
    font-weight: 500;
    font-size: 17px;
    line-height: 32px;
    margin-bottom: 4px;
    border: none;
    outline: none;
    padding: 0;
    width: 100%;
    transition: color 0.3s ease, opacity  0.3s ease;

    &::placeholder {
      color: #d3d5e1;
    }

    &--complete {
      color: #a7abc3;
    }
  }

  &__description {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #74788d;
    padding-right: 12px;
  }

  &__publication-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80px;
  }

  &__publication-url-wrapper {
    display: flex;
    align-items: center;
  }

  &__publication-url {
    color: #5d78ff;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-overflow: ellipsis;
    width: 305px;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 16px;
  }

  &__edit-publication-url {
    &:hover {
      cursor: pointer;
    }
  }

  &__social-icon {
    margin-right: 6px;
    width: 20px;
    height: 20px;
  }

  &__icons-wrapper {
    display: flex;
  }

  &__edit-icon {
    margin-left: 6px;
    width: 20px;
    height: 20px;
    cursor: pointer;

    path {
      fill: #d3d5e1;
    }
  }
}
