.empty-table {
  position: sticky;
  left: 0;
  top: 0;
  border-bottom: 1px dashed #d3d5e1;
  padding: 36px 31px 32px 48px;

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__left {
    margin-right: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    font-weight: 500;
    width: 480px;
    font-size: 12px;
    line-height: 20px;
    color: #74788d;
    white-space: pre-wrap;
    margin-bottom: 20px;
    text-align: center;
    &-client {
      margin-bottom: 12px;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__img {
    width: 310px;
  }

  &__cdt-link {
    margin-top: 12px;
    color: #6a6d7c;
    font-size: 12px;
    font-weight: 500;

    button {
      all: unset;
      cursor: pointer;
      color: #5d78ff;
      text-decoration: none;
    }
  }

  &__empty-client-text {
    color: #45485b;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }

  &__empty-client-bottom-text {
    color: #45485b;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
  }
}
