.hashtag-live-search {
  padding: 26px 48px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  &__title {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.03em;
    color: #45485b;
    font-weight: 500;
    margin: 0 0 6px 0;
  }

  &__description {
    font-size: 14px;
    line-height: 22px;
    color: #7D808D;
    font-weight: 400;
    margin: 0 0 20px 0;
  }

  &__input {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 16px;
    color: #45485b;

    &-social {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    input {
      outline: none;
      border: none;
      color: #45485b;
      padding-left: 0;

      &::placeholder {
        color: #dadade;
      }
    }

    &-clear {
      width: 24px;
      height: 24px;
      cursor: pointer;

      &--hidden {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  &__button-wrapper {
    position: relative;
    height: 44px;
    transition: all 0.2s ease;

    .ws-button {
      position: absolute;
      top: 0;
    }

    &--hidden {
      height: 0;
      opacity: 0;

      .ws-button {
        pointer-events: none;
      }
    }
  }

  .tabs {
    margin-top: 16px;
    margin-bottom: 32px;
  }

  &__results {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(394px, 1fr));
    gap: 8px;
    transition: all 0.2s ease;

    &--hidden {
      opacity: 0;
      transform: translateY(150px);
    }
  }

  &__loader {
    display: grid;
    place-items: center;
    width: 100%;
    grid-column: 1 / -1;

    &--empty-page {
      height: 30vh;
    }
  }

  .no-results-message__wrapper {
    margin-top: 10px;
  }
}
