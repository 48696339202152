.trial-limitations-modal {
  &--thin {
    min-width: 360px;
    padding: 24px;
  }

  &__idh-logo {
    margin-bottom: 24px;
  }

  &__email {
    color: #5d78ff;
  }

  &__buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    button {
      width: 160px;
    }

    &--full-width {
      button {
        width: 100%;
      }
    }
  }

  .modal__buttons {
    display: none;
  }
}

.trial-limitations-list {
  &__item {
    padding: 9px 0;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__item-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #45485b;
  }

  &__item-icon {
    margin-right: 8px;
  }

  &__item-description {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #8f919d;
    margin-left: 32px;
  }

  &__title {
    flex-grow: 1;
  }

  &__actions-left {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #6a6d7c;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    width: 56px;
    height: 24px;
    background-color: #feeabd;
    color: #d58a28;

    span {
      color: #f2cf86;
    }

    &--full {
      background: #ededef;
      color: #8a8a95;

      span {
        color: #d3d5e1;
      }
    }
  }
}
