.share-with-client-info-modal {
  .modal__title {
    margin: 32px 0 16px 0;
  }

  .modal__text {
    margin-bottom: 24px;
  }

  .modal__buttons {
    margin-top: 0;
    justify-content: flex-end;
  }

  &__dots {
    display: flex;
    position: absolute;
    left: 32px;
    bottom:48px;
  }

  &__dot {
    width: 8px;
    height: 8px;
    border-radius: 2px;
    cursor: pointer;

    &:first-of-type {
      margin-right: 12px;
    }

    &--blue {
      background: #5d78ff;
    }

    &--grey {
      background: #d3d5e1;
    }
  }
}
