html body .members__user-select {
  margin-bottom: 8px;

  .css-i9ytwc-control,
  .css-1m7wa3h-control {
    max-height: 180px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f2f2f6;
      border-radius: 30px;
      width: 6px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #a7abc3;
      border-radius: 30px;
      width: 6px;
      height: 64px;
    }
  }

  &-input {
    &,
    &-container {
      width: 100%;
      margin-bottom: unset;
    }

    &--invalid > div {
      border: solid red 1px !important;
    }
  }

  &-row {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &-option {
    display: grid;
    grid-template-columns: 1fr calc(100% - 30px);

    gap: 6px;
    align-items: center;
    text-transform: initial;
    width: 100%;

    &__photo {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-left: 0 !important;
    }

    &__details {
      display: flex;
      gap: 8px;

      &-name {
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &-client {
        color: #f7911a;
        align-items: center;
        display: flex;
        flex-shrink: 0;
        gap: 6px;

        &::before {
          content: "•";
          color: #b4b6be;
        }
      }
    }
  }

  &-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #74788d;
  }

  &-invite {
    display: flex;
    position: relative;
    align-items: center;
    color: #5d78ff;
  }

  &-invite-env {
    margin-right: 8px;
  }

  &-invite-email {
    color: #5d78ff;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    background: #eff1ff;
    border-radius: 8px;
    padding: 4px 8px;
    margin-left: 8px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-invite-btn {
    flex-shrink: 0;
    height: 48px;
    width: 100px;
    position: sticky;
    bottom: 0;
    margin-right: 4px;
  }

  .simple-select {
    position: sticky;
    bottom: 0;
  }

  &-spinner {
    display: flex;
    justify-content: center;
    pointer-events: none;
    user-select: none;
    align-items: center;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f2f2f6;
    border-radius: 30px;
    width: 6px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #a7abc3;
    border-radius: 30px;
    width: 6px;
    height: 64px;
  }
}

html body .members__user-select__validation-message {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 500;
  color: #d93025;
}
