@import "../../../../styles/media-queries.scss";
@import "../../../../styles/variables.scss";

.workspace-setup-tab {
  max-height: 454px;

  &__instructions {
    width: 100%;
    border-top: dashed 1px #ededef;
    padding: {
      top: 12px;
      right: 12px;
    }

    &-client-id {
      display: flex;
      align-items: center;
      margin: {
        bottom: 24px;
      }

      input {
        height: 40px;
        border-radius: 8px;
        padding: 14px 12px 14px 16px;
        width: 100%;
        border: 1px solid #b4b6be;
        outline: none;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: #45485b;

        &:focus {
          border: 1px solid #9eaeff;
        }
      }
    }
  }

  .allow-domains-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    width: 100%;
    outline: none;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: #45485b;
  }

  .allow-domains-item {
    display: flex;
    gap: 10px;
    color: rgb(142, 147, 172);
    background-color: rgb(242, 242, 246);
    transition:
      color 0.2s ease 0s,
      background-color 0.2s ease 0s;
    align-items: center;
    padding: 4px 8px;
    border-radius: 8px;
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: fit-content;
    text-align: center;

    button {
      margin: 0px !important;
      padding: 0px !important;
      border: none;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}
