.creator-discovery {
  overflow-y: auto;
  padding: {
    right: 24px;
    bottom: 24px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f2f2f6;
    border-radius: 30px;
    width: 6px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #a7abc3;
    border-radius: 30px;
    width: 6px;
    height: 64px;
  }

  &__element {
    display: flex;
    // flex-wrap: wrap;

    &-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #45485b;
    }

    &-description {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #8f919d;
      max-width: 520px;
    }
  }

  .creator-discovery-by-market-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    width: 100%;
    outline: none;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: #45485b;
  }

  .creator-discovery-by-market-item {
    display: flex;
    gap: 10px;
    border: 0px;
    color: rgb(142, 147, 172);
    background-color: rgb(242, 242, 246);
    transition: color 0.2s ease 0s, background-color 0.2s ease 0s;
    align-items: center;
    padding: 4px 8px;
    border-radius: 8px;
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: fit-content;
    text-align: center;

    &__default {
      display: flex;
      gap: 10px;
      color: rgb(142, 147, 172);
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(142, 147, 172);
      transition: color 0.2s ease 0s, background-color 0.2s ease 0s;
      align-items: center;
      padding: 4px 8px;
      border-radius: 8px;
      font-weight: normal;
      font-size: 11px;
      line-height: 20px;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: fit-content;
      text-align: center;
    }

    button {
      margin: 0px !important;
      padding: 0px !important;
      border: none;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}
