.icon-selector {
  position: relative;
  height: 40px;
  width: 187px;

  &__button {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px 10px 16px;
    width: 187px;
    height: 40px;
    border: 1px solid #a7abc3;
    border-radius: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #74788d;
    cursor: pointer;
    z-index: 1;

    &--menu-open {
      border: 1px solid white;
    }

    &-input {
      width: 136px;
      height: 20px;
      border: none;
      outline: none;
      color: #74788d;

      &::placeholder {
        color: #d3d5e1;
      }
    }

    &-selected-icon {
      display: flex;
      align-items: center;

      span {
        height: 20px;
      }

      svg {
        height: 20px;
        width: 20px;
        margin-right: 6px;
      }

      &-name {
        width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-chevron {
      display: grid;
      place-items: center;
      transition: all 0.2s ease;

      &--rotated {
        transform: rotate(180deg);
      }
    }
  }

  &__menu {
    position: absolute;
    top: 0;
    width: 187px;
    background-color: #fff;
    box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding-top: 40px;

    &-list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 40px);
      gap: 2px;
      justify-content: center;
      max-height: 220px;
      padding: 8px;
      overflow-x: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px transparent;
        border-radius: 2px;
        background-color: #e0e2ea;
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: #a7abc3;
        border-radius: 30px;
      }
    }

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border: 1px solid transparent;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        border: 1px solid #d3d5e1;
      }

      &--selected {
        border: 1px solid #5d78ff !important;
      }
    }
  }
}
