.data-hub-no-columns {
  position: absolute;
  top: 258px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: #fff;
  width: calc(100% - 336px);
  left: 336px;
  height: 599px;

  &__image {
    margin-bottom: 24px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 440px;
    text-align: center;
    color: #45485b;

    &-upper {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    &-lower {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
    }
  }

  .ws-button {
    margin-top: 24px;
  }
}
