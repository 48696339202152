.report-glossary {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;

  &__left,
  &__right {
    width: 100%;
  }

  &__item {
    margin-top: 20px;

    &-heading {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      color: #74788d;

      span,
      svg {
        width: 24px;
        height: 24px;
      }

      &-title {
        margin-left: 8px;
      }
    }

    &-text {
      margin-top: 4px;
      font-weight: 500;
      font-size: 11px;
      line-height: 18px;
      color: #8e93ac;
      padding-left: 32px;
    }
  }
}
