.single-filter {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .custom-date-range__input {
    height: 40px;
  }

  .ws-input-label {
    word-break: break-all;
  }

  .project-selector {
    &__option {
      display: flex;
      align-items: center;

      &-image {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        border-radius: 50%;

        &--square {
          border-radius: 2px;
        }
      }
    }
  }


}
