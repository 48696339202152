.sentiment-analysis-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__name {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: #a7abc3;
    text-align: center;
    text-transform: uppercase;
  }

  &__value {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #74788d;
    margin-top: 21px;

    &-icon {
      margin-right: 16px;

      span,
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
}
