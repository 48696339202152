.idh-aa-logo {
  display: grid;
  place-items: center;
  border: solid 1px #dadade;
  border-radius: 4px;

  svg,
  img {
    width: 75%;
    height: 75%;
  }

  img {
    object-fit: contain;
  }
}
