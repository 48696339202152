.tasks-table__field {
  padding: 0 24px;
  height: 86px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 0;

  * {
    transition: none !important;
  }

  &:hover .select__value-wrapper {
    max-height: 86px;
  }

  &--absolute {
    position: absolute;
  }

  &--read-only {
    pointer-events: none;
  }

  &-content {
    display: flex;
    align-items: center;

    &-edit-icon {
      position: absolute;
      left: 20px;
      transform: translateY(calc(100% + 4px));
      display: flex;
      align-items: center;
      height: 20px;
      color: #a1a3ad;
      border-radius: 94px;
      font-weight: 500;
      font-size: 11px;
      line-height: 20px;
      font-style: italic;

      svg {
        width: 12px;
        height: 12px;
        margin-right: 4px;

        path {
          fill: #a1a3ad;
        }
      }
    }

    &--disabled {
      pointer-events: none;
      cursor: pointer !important;
    }
  }

  .idh-date-picker input {
    width: 108px !important;
    padding: 0;
    text-align: center;
  }

  .share-icon {
    opacity: 0;
    transition: all 0.2s ease;

    pointer-events: all;
  }

  &:hover {
    .tasks-table_empty-field {
      border: 1px dashed #5d78ff;
      background-color: #dfe4ff;

      svg {
        path {
          fill: #5d78ff;
        }
      }
    }

    .share-icon {
      opacity: 1;
    }

    .tasks-table__field-close {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &-value {
    width: 100%;
    overflow: hidden;

    &-text {
      display: block;
      color: #74788d;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      width: 160px;
      min-height: 30px;
      max-height: 80px;
      white-space: pre-wrap;
    }
  }

  &-close {
    position: absolute;
    right: 5px;
    height: 16px;
    width: 16px;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;

    svg {
      height: 16px;
      width: 16px;
    }

    &--hidden {
      opacity: 0 !important;
      pointer-events: none;
    }
  }

  input {
    text-align: center;
    width: 100%;
    border: 0;
    background-color: transparent;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #74788d;
    outline: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .text-box {
    overflow-y: auto;
    overflow-x: hidden;
    width: 152px;
    padding: 0;
    max-height: 80px;
  }

  .public-DraftStyleDefault-block {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    width: 148px;
    min-height: 16px;
    max-height: 80px;
    outline: none;

    &:focus,
    &:hover {
      outline: none;
    }
  }

  .assignee-field {
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
  }
}

.tasks-table__empty-field {
  background-color: #fff;
  padding: 6px 26px;
  border: 1px dashed #d3d5e1;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
  cursor: pointer;

  &--circle {
    padding: 6px;
    border-radius: 50%;
  }

  &--file {
    position: relative;

    input {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: pointer;
    }
    ::-webkit-file-upload-button {
      cursor: pointer;
    }
  }

  svg {
    height: 12px;
    width: 12px;

    path {
      fill: #d3d5e1;
      transition: all 0.2s ease;
    }
  }
}
