@import "../../styles/mixins.scss";

.profile-details {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 1000;
  width: 802px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 20px 0px 0px 20px;
  @include custom-scrollbar;

  .profile-box__header-title--big {
    margin-left: 0px;
  }

  .profile-box__header--on-showcase .profile-box__header-count {
    font-size: initial !important;
    line-height: initial !important;
  }
  .profile-box__header {
    svg {
      height: 40px;
      width: 40px;
    }
  }

  &__profile-box-container {
    display: flex;
    flex-direction: column;
  }

  &__upgrade-account {
    display: flex;
    padding: 9px 20px;
    background-color: #fff8e9;
    align-items: center;
    width: 100%;

    &-info {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    button {
      border-radius: 8px;
      padding: 12px;
      color: #fff;
      background: #e5a317;
      margin-left: auto;

      &:hover {
        background: #d29005 !important;
      }
    }
  }

  &__profile-box-column {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__shadow {
      width: 100%;

      .progress-bar-data__value,
      .progress-bar-data,
      .profile-box__header-count,
      .tag-cloud__item,
      .interests-list__item,
      .chart,
      .profile-box__parameter-value {
        z-index: 1000;
        cursor: initial;
        background-color: #fff;
        padding: 0px;
        filter: blur(6px);
        user-select: none;
        pointer-events: none;
      }
    }
  }

  &__profile-box-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;

    .profile-details__profile-box-column {
      max-width: 50%;
    }

    &__without-margin {
      margin-left: -20px;
      margin-right: -20px;
    }

    &__header {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  &__top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 20px;
  }

  &__toolbar {
    display: flex;
    height: 24px;

    &-icon {
      margin-left: 12px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      &:hover {
        path {
          fill: #536ceb;
        }
      }
    }
  }

  &__person {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
  }

  .person-info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-close-button {
      margin-bottom: auto;
      cursor: pointer;

      svg {
        path {
          color: #45485b;
        }
      }
    }

    &-username {
      color: #45485b;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }

    &-description {
      color: #6a6d7c;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    &-social-icon {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #45485b;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .person-profile-picture {
    margin-right: 12px;

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }

  &__container {
    border: 1px solid #f2f2f6;
    flex-grow: 1;
    scroll-behavior: smooth;

    &__inner-wrap {
      padding: 20px;
    }

    &__fixed-header {
      position: sticky;
      top: 0;
      padding: 20px;
      padding-bottom: 0px;
      background: #ffffff;
      z-index: 1001;
    }
  }

  &__details {
    display: flex;
    justify-content: space-between;
    margin: 16px 0;

    &-left {
      display: flex;
      color: #a7abc3;
      font-weight: 600;
      font-size: 10px;
      line-height: 16px;
      text-transform: uppercase;
      flex-direction: column;

      &-row {
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 32px;

        &:not(:first-of-type) {
          margin-top: 8px;
        }
      }

      &-button-wrapper {
        height: 100%;
        display: flex;
        align-items: center;

        .dropdown-value-wrapper {
          display: flex;
          align-items: center;
          transition: all 0.1s ease;
          margin-left: 12px;
          height: 100%;
          border-radius: 8px;
          padding: 6px 12px;

          &:hover {
            background-color: lighten($color: #eff1ff, $amount: 1);

            svg {
              opacity: 1;
            }
          }

          &__buttons {
            margin-left: 8px;
            display: flex;
            align-items: center;

            .share-icon,
            .close-icon {
              width: 16px;
              height: 16px;
              cursor: pointer;
              opacity: 0;
              transition: all 0.1s ease;
            }

            .close-icon {
              margin-left: 2px;
            }
          }
        }

        .date-picker-wrapper {
          position: relative;
          margin: 0;

          &--no-hover {
            .dropdown-value-wrapper:hover {
              background: transparent;
            }
          }

          &:hover {
            .idh-date-picker__input {
              border: 1px dashed #5d78ff;
            }

            .date-picker-wrapper__plus {
              path {
                fill: #5d78ff;
              }
            }
          }

          &__plus {
            position: absolute;
            left: calc(50% + 6px);
            top: 50%;
            transform: translate(-50%, -50%);
            width: 12px;
            height: 12px;
            z-index: 5;
            pointer-events: none;

            path {
              fill: #d3d5e1;
              transition: all 0.2s ease;
            }
          }
        }

        .idh-date-picker {
          &__input {
            width: 90px;
            height: 24px;
            padding: 10px 8px !important;
            border: 1px dashed #d3d5e1;
            background: transparent;
            cursor: pointer;
          }
        }

        .add-button {
          margin-left: 24px;
        }

        &:not(:first-of-type) {
          margin-left: 24px;
        }
      }
    }

    &-right {
      display: flex;
      align-items: center;
      height: fit-content;

      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 16px;
      }
    }
  }

  &__description {
    min-height: 70px;
    border-radius: 8px;
    outline: 1px solid #d3d5e1;

    &:hover,
    &:focus {
      outline: 1px solid #9eaeff;
    }
  }

  &__heading {
    color: #d3d5e1;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-top: 14px;

    &--big {
      color: #8e93ac;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      margin: {
        top: 0;
        bottom: 20px;
      }
    }
  }

  &__title {
    color: #74788d;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    margin-top: 8px;
  }

  &__goals {
    display: flex;
    align-items: center;
    color: #a7abc3;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    margin: 16px 30px 10px;

    &-counter {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #5d78ff;
      border-radius: 4px;
      color: white;
      margin-left: 12px;
    }
  }

  &__btn {
    &-share {
      margin-left: 16px;
      height: 36px;
      width: 36px;

      svg {
        margin-right: 0;
      }
    }
  }

  &__add-goal {
    margin-top: 16px;
    padding: 0;

    &:hover {
      background: none;

      & svg path:nth-child(1) {
        fill: #6f84e9;
      }

      & svg path:nth-child(2) {
        fill: white;
      }
    }
  }

  &__footer {
    position: relative;
    display: flex;
    margin: 20px;
  }

  &__author-avatar {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin-top: 4px;
    object-fit: cover;
  }
}
