.edit-comment-field {
  display: flex;
  position: relative;
  width: 100%;
  border: 1px solid #d3d5e1;
  border-radius: 8px;
  margin-top: 8px;

  &__textarea {
    padding: 12px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    width: 100%;
    border: none;
    caret-color: #74788d;

    &--absolute {
      position: absolute;
      pointer-events: none;
      color: #74788d;
    }

    &:focus {
      outline: none;
    }

    .text-box__buttons {
      margin-top: 12px;
    }
  }

  &__toolbar {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    svg {
      margin-left: 5px;

      &:hover {
        cursor: pointer;
        path {
          fill: #536ceb;
        }
      }
    }
  }

  &__buttons {
    display: flex;

    button {
      height: 36px;
    }
  }

  &__cancel {
    margin-right: 8px;
  }
}
