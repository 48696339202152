.send-payment-modal {
  .ws-react-select,
  .ws-input {
    width: 292px;
  }

  &__icon {
    width: 16px;
    height: 16px;
  }

  &__info-icon {
    fill: #45485b;
  }

  &__square-separator {
    width: 1px;
    height: 1px;
    background-color: #8f919d;
  }

  &__error-message {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #d93025;
    font-weight: 400;
    font-size: 12px;
    display: none;
    &--visible {
      display: flex;
    }
  }

  &__select-wallet-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .ws-input-label--required {
    color: #d93025;
  }
  .modal__column .send-payment-modal__recipient-name {
    gap: 0;
    .action-field {
      margin-right: 8px;
    }
  }
  .modal__row {
    align-items: center;
  }
  &__content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__wallet-option {
    display: flex;
    align-items: center;
    gap: 15px;
    color: #45485b;
    font-size: 13px;
    font-weight: 400;
  }

  &__line-separator {
    width: 1px;
    height: 15px;
    background-color: #dadade;
  }

  &__recipient-name {
    display: flex;
    align-items: center;
    gap: 8px;
    .action-field {
      cursor: default;
    }
  }
  &--isLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 434px;
  }

  &__amount-input {
    width: 220px;
    height: 56px;
    padding: 12px 16px;
  }

  &__currency {
    color: #b4b6be;
  }

  &__text {
    color: #45485b;
    font-weight: 500;
    display: flex;
    gap: 8px;
    &--small {
      font-size: 14px;
      line-height: 22px;
    }
    &--medium {
      font-size: 18px;
      line-height: 26px;
    }
    &--big {
      font-size: 24px;
      line-height: 32px;
    }
    .tooltip-portal,
    .tooltip-portal span {
      display: flex;
      align-items: center;
    }
  }

  .modal__buttons {
    button {
      width: 120px;
    }
  }

  .send-payment-modal__select-wallet-error {
    border: 1px solid #d93025;
    border-radius: 8px;
  }
}
