@import "../../../styles/media-queries.scss";
@import "../../../styles/variables.scss";

.share-task-details {
  display: flex;

  &__container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    
    .stacked-avatars {
      margin-left: 0;
      .custom-image-wrapper.avatar img, .avatars-left {
        width: 48px;
        height: 48px;
      }
    }
  }
}
