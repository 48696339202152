.ws-textarea {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #a7abc3;
  color: #8e93ac;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  padding: 8px 10px;

  &::placeholder {
    color: #d3d5e1;
  }

  &:focus {
    border: 1px solid #9eaeff;
    outline: none;
  }

  &--auto-grow {
    resize: none;
    min-height: 40px;
    padding: 10px 16px;
    overflow: hidden;
    box-sizing: border-box;
    // white-space: pre-line;
  }
}

.ws-textarea-label {
  color: #a7abc3;
  font-size: 10px;
  font-weight: 500;
  line-height: 22px;
}
