.preview {
  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &-data {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-cover {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: white;

      &-wrapper {
        width: 80px;
        height: 80px;
        margin-right: 12px;
      }
    }

    &-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 36px;
      color: #45485b;
      padding: 0;
      border: none;
      outline: none;
    }

    &-social-icons {
      display: flex;
      align-items: center;
      margin-top: 4px;
    }
  }

  &__text {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: #45485b;
    margin-bottom: 24px;
  }
}
