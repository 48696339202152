.add-creators-info-modal {
  &__list {
    padding: 16px 0;
    border-top: 1px dashed #d3d5e1;
    border-bottom: 1px dashed #d3d5e1;
  }

  &__username {
    color: #45485b;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
  }

  &__warning {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffb822;
    display: flex;
    align-items: center;
    margin-top: 16px;
  }

  &__conditions-title {
    color: #45485b;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    margin-top: 8px;
  }

  &__conditions-list {
    list-style-type: disc;
    color: #8e93ac;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    margin-top: 8px;

    li {
      margin-left: 24px;
    }
  }
}
