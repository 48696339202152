@import "../../../../styles//mixins.scss";

.activity-tab {
  .avatar-and-name {
    display: flex;
    gap: 12px;
    align-items: center;
    &__avatar-wrapper {
      object-fit: cover;
    }
    &__avatar {
      border-radius: 2px;
      border: 1px solid #c8c9ce;
      &-placeholder {
        border: none;
      }
    }
  }

  &__table-header {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 10;
  }

  &__table-wrapper {
    max-height: calc(100vh - 431px);
    overflow: auto;
    @include custom-scrollbar;
  }

  &__skeleton-wrapper {
    gap: 16px;
    display: flex;
    flex-direction: column;
  }

  &__skeleton-avatar-and-text {
    display: flex;
    gap: 12px;
  }

  &__skeleton-item {
    display: flex;
    gap: 32px;
  }

  .avatar-and-list-name {
    display: flex;
    align-items: center;
    gap: 12px;
    .stacked-avatars {
      margin-left: 0;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%;
    height: 248px;

    color: #45485b;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    &-img {
      width: 80px;
    }
  }

  &__add-button {
    margin-top: 24px;
    padding: 8px 16px 8px 12px;
  }
}
