@import "src/styles/mixins.scss";

.live-search-box {
  position: relative;
  width: 394px;
  aspect-ratio: 1/1;
  border-radius: 8px;
  border: 1px solid #dadade;

  &:hover {
    .live-search-box {
      &__data {
        opacity: 1;
      }

      &__provider {
        color: #6a6d7c;
        &-logo {
          &--visible {
            opacity: 0.8;
          }
          &--hidden {
            opacity: 1;
          }
        }
      }
    }
  }

  &__photo {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: contain;
    background-color: #f2f2f6;
    border-radius: 8px;
    cursor: pointer;
  }

  &__provider {
    position: absolute;
    transition: all 0.2s ease;
    color: #45485b;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    top: 16px;
    right: 16px;
    gap: 12px;
    display: flex;
    align-items: center;
    z-index: 1;

    &-logo {
      width: 24px;
      height: 24px;
      &--hidden {
        opacity: 0;
      }
    }
  }

  &__data {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
    border-radius: 0 0 8px 8px;
    opacity: 0;
    transition: all 0.2s ease;
    padding: {
      left: 16px;
      right: 16px;
      top: 48px;
      bottom: 12px;
    }
    z-index: 1;

    &-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;

      &-creator {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: white;

        .custom-image-wrapper,
        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }

        svg {
          width: 20px;
          height: 20px;
        }

        &--clickable {
          cursor: pointer;
        }
      }

      &-social-data {
        display: flex;
        gap: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: white;

        span {
          display: flex;
          align-items: center;
          gap: 4px;
        }
      }
    }

    &-description {
      box-sizing: content-box;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      color: #fff;
      max-height: 150px;
      overflow-y: auto;
      overflow-x: hidden;

      padding-right: 8px;
      @include custom-scrollbar;
    }
  }
}
