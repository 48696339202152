@import "../../styles/base/media-queries.scss";
@import "../../styles/brand-variables.scss";

#audience-manager-page {
  padding-top: 0;
}

.audience-name {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  letter-spacing: -0.03em;
  color: #74788d;
  font-weight: 500;
  position: relative;
  font-size: 18px;
  white-space: nowrap;

  &--empty {
    color: #d3d5e1;

    &::after {
      display: none;
    }
  }

  &--left {
    display: flex;
    gap: 8px;
    cursor: pointer;

    &-icon {
      width: 20px;
      height: 20px;
      opacity: 0.5;
      transition: opacity 0.2s;

      path {
        fill: #6a6d7c;
      }
    }
  }

  &--right {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:hover {
    .audience-name--left-icon {
      opacity: 0.9;
    }
  }

  &--empty {
    .audience-name--left {
      font-size: 24px;
      color: #45485b;
      font-weight: 500;
    }
    .audience-name--left-heading {
      font-size: 28px;
      line-height: 36px;
      letter-spacing: -0.03em;
      color: #45485b;
      font-weight: 500;
      margin: 0 0 6px 0;
    }

    .audience-name--left-description {
      font-size: 14px;
      line-height: 22px;
      color: #7d808d;
      font-weight: 400;
      margin: 0;
    }
  }
}

.audience-results-page {
  position: relative;

  .breadcrumbs {
    margin-bottom: 2px;

    &__item {
      color: #b4b6be;
    }
  }

  &__header {
    &--sticky {
      position: sticky;
      top: -48px;
      left: 0;
      padding-top: 20px;
      margin-top: -20px;
      z-index: 20;
      background-color: #fff;

      .audience-results-page__actions-wrap {
        top: 28px;
      }
    }

    &__topbar {
      display: flex;
      justify-content: space-between;

      &--right {
        display: flex;
        flex-direction: column;
        gap: 12px;

        z-index: 6;

        &__top {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }

        .export-wrapper {
          margin-top: 0px;
        }
      }
    }
  }

  &__plus-icon {
    margin-left: 8px;
  }

  > .loader-wrapper {
    margin: 150px auto;
  }

  &__buttons {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    height: 45px;

    &__filters {
      height: 40px;
    }
  }

  &__actions-wrap {
    display: flex;
  }

  &__actions {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    &-button {
      display: flex;
      align-items: center;
    }

    &-button-wrapper {
      display: flex;
      gap: 8px;
      align-items: center;
      height: 40px;
      justify-content: flex-end;
    }

    &__overlap-btn {
      display: none;
    }
    &-clear,
    &--add-to-workspace-project {
      .icon-left {
        height: 20px;
        transform: translateY(-1px);

        path {
          fill: #1267fb;
        }
      }
    }

    .warning-wrapper {
      margin-right: 12px;
    }

    .influ-count-warning {
      width: 20px;
      height: 20px;
    }

    .ws-button--transparent:hover {
      background-color: #e7f0ff;
    }
  }

  .sort-and-labels-wrapper {
    position: relative;
    margin-bottom: 5px;
  }

  .sort-wrapper {
    // We have 2 sort-wrappers due to different position for opened and closed search filters
    align-items: center;
    display: none;

    &--in-tabs {
      display: flex;
    }

    &--filters-opened {
      display: flex;
      position: absolute;
      top: -52px;
      right: 0;
    }

    .idh-label {
      white-space: nowrap;
    }

    .ws-react-select-container {
      margin-left: 8px;
      width: 240px;
    }
  }

  &__icon {
    width: 14px;
    vertical-align: middle;
    margin-left: 6px;
  }

  &__tabs {
    display: flex;
    gap: 20px;
    align-items: center;
    padding-bottom: 16px;
    min-height: 60px; // to prevent screen jumping when tab changes

    &-wrapper {
      position: relative;

      .tabs {
        margin-bottom: 0;
      }

      .ws-button {
        display: flex;
        align-items: center;

        span {
          white-space: nowrap;
        }
      }
    }
  }

  &__similar-profile-tabs {
    height: 57px;
    margin-top: 20px;

    .tabs {
      position: absolute;
    }
  }

  .toggle-filters-icon {
    width: 20px;
    height: 20px;
    background: transparent no-repeat center;

    &--up {
      path {
        fill: #5d78ff;
      }
    }
  }

  &--selected {
    .audience-results-page__actions-wrap {
      top: -30px;
    }
  }

  .ws-button--thin {
    height: 40px;
  }

  .ws-button--medium {
    height: 40px;
  }
  .audience-results-page__action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
  }
}
