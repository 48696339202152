.add-field-modal {
  width: 600px;

  &--hidden {
    display: none;
  }

  .modal {
    &__column {
      width: 100%;
    }
    &__text {
      margin: {
        top: 0;
        bottom: 16px;
      }
    }
  }

  #fieldType .ws-react-select__menu-list {
    max-height: 40vh;
  }

  &__role {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #8e93ac;
    margin-left: auto;
  }

  &__role-icon {
    margin-right: 6px;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 2px;
    width: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #b8d1fe;
    border-radius: 2px;
    width: 5px;
  }

  .row-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    gap: 20px;

    &--no-border {
      border-bottom: none !important;
    }

    .description-wrapper {
      width: 70%;

      &--full-width {
        width: 100%;
      }
    }

    .task-type-wrapper {
      width: 30%;
    }
  }

  .row-wrapper:not(:first-child) {
    border-bottom: 1px dashed #cacddb;
  }

  .ws-button {
    width: 160px;
  }

  .ws-input {
    color: #6a6d7c;
    width: 100%;
  }

  .image-select-option {
    color: #6a6d7c;

    path {
      fill: #6a6d7c;
    }
  }
}

.metric-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  gap: 20px;

  .ws-react-select {
    width: 100%;

    &__option {
      height: 50px;
    }
  }

  &--no-margin {
    margin: 0;
  }
}

.currency-wrapper {
  padding-top: 16px;
  // justify-content: space-between;

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 14px;

    .field-icons {
      width: 536px;
    }
  }

  .ws-react-select {
    width: 100%;

    &__option {
      height: 50px;
    }

    &-container {
      margin-top: 4px;
    }

    & .currency-option {
      text-transform: capitalize;
    }

    & .currency-code {
      color: #d3d5e1;
      margin-right: 4px;
    }
  }
}
