.use-in-creator-database {
  display: flex;
  align-items: center;
  margin: 16px 0;

  &__title {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #45485b;
    margin-left: 10px;
  }
}
