.projects-list-element-loader {
  height: 87px;
  position: sticky;
  left: 2px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #d3d5e1;
  min-width: calc(100vw - 140px);

  .ws-skeleton--rect {
    position: sticky;
    left: 75px;
  }

  .ws-skeleton--text {
    position: sticky;
    left: 135px;
  }

  &__left {
    position: sticky;
    left: 0;
    display: flex;
    align-items: center;
  }

  &__right {
    position: sticky;
    right: 0;
    // margin-right: -50px;
  }

  &__before {
    width: 50px;
    height: 100px;
    background: white;
  }

  &__after {
    width: 50px;
    height: 100px;
    background: white;
  }
}
