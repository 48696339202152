.ws-table__field {
  padding: 0 24px;
  height: 86px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition:
    max-width 0.3s ease,
    overflow 0.3s ease 0.3s;
  z-index: 0;
  width: min-content;

  &:hover,
  &--hovered {
    overflow-y: auto;
    overflow-x: hidden;
    background: white !important;
    box-shadow:
      15px 0 15px -4px white,
      -15px 0 12px -4px white !important;
    z-index: 10;

    &::after {
      opacity: 0;
    }
  }

  &::-webkit-scrollbar {
    width: 4px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f6;
    box-shadow: inset 0 0 5px transparent;
    border-radius: 2px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #a7abc3;
    border-radius: 2px;
    width: 4px;
  }

  &:hover .select__value-wrapper {
    max-height: 86px;
  }

  &--read-only {
    pointer-events: none;
  }

  &-content {
    display: flex;
    align-items: center;
    width: 100%;

    &-edit-icon {
      position: absolute;
      left: 50%;
      transform: translate(-50%, calc(100% + 4px));
      display: flex;
      align-items: center;
      background-color: #f2f2f6;
      padding: 0px 8px 0px 6px;
      height: 20px;
      color: #a7abc3;
      border-radius: 94px;
      font-weight: 500;
      font-size: 11px;
      line-height: 20px;

      svg {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
    }

    &--disabled {
      pointer-events: none;
      cursor: pointer !important;
    }
  }

  .idh-date-picker input {
    width: 108px !important;
    padding: 0;
    text-align: center;
  }

  .share-icon {
    opacity: 0;
    transition: all 0.2s ease;
  }

  &:hover {
    .ws-table__empty-field {
      border: 1px dashed #5d78ff;
      background-color: #dfe4ff;

      svg {
        path {
          fill: #5d78ff;
        }
      }
    }

    .share-icon {
      opacity: 1;
    }

    .ws-table__field-close {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &-value {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;

    &-wrapper {
      display: flex;
      flex-direction: column;

      &--horizontal {
        flex-direction: row;
        align-items: center;

        .ws-table__field-value-icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;

          svg,
          span {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &-text {
      display: block;
      color: #74788d;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      width: 160px;
      min-height: 30px;
      max-height: 80px;
      white-space: pre-wrap;
    }

    &-icon {
      svg,
      span {
        width: 32px;
        height: 32px;
      }
    }
  }

  &-close {
    position: absolute;
    right: 2px !important;
    height: 16px;
    width: 16px;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;

    svg {
      height: 16px;
      width: 16px;
    }

    &--hidden {
      opacity: 0 !important;
      pointer-events: none;
    }
  }

  input {
    text-align: center;
    width: 100%;
    border: 0;
    background-color: transparent;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #74788d;
    outline: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .text-box {
    overflow-y: auto;
    overflow-x: hidden;
    width: 152px;
    max-height: 80px;
    height: fit-content;
    padding: 0;
  }

  .public-DraftStyleDefault-block {
    font-weight: 500 !important;
    font-size: 11px !important;
    line-height: 16px !important;
    width: 148px;
    min-height: 16px;
    max-height: 80px;
    text-align: center;
    outline: none;

    &:focus,
    &:hover {
      outline: none;
    }
  }

  .assignee-field {
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
  }
}

.ws-table__empty-field {
  padding: 6px 26px;
  border: 1px dashed #d3d5e1;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
  cursor: pointer;
  background-color: white;

  &--circle {
    padding: 6px;
    border-radius: 50%;
  }

  &--file {
    position: relative;

    input {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: pointer;
    }
    ::-webkit-file-upload-button {
      cursor: pointer;
    }
  }

  svg {
    height: 12px;
    width: 12px;

    path {
      fill: #d3d5e1;
      transition: all 0.2s ease;
    }
  }
}
