.attachments-container {
  display: flex;
  margin: 20px 0;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 16px;
}

.attachment-square {
  width: 64px;
  height: 64px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px dashed #ced7ff;
  border-radius: 8px;
  color: #5d78ff;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  transition: background 0.1s;

  &:hover {
    cursor: pointer;
    background-color: #eff1ff;
  }

  &__add-icon {
    width: 20px;
  }
}

.add-attachment-square {
  flex-direction: column;
  width: 112px;
}
