.field-manager-modal {
  width: 600px;

  .modal {
    &__column {
      width: 100%;
    }
    &__text {
      margin: {
        top: 0;
        bottom: 16px;
      }
    }
  }

  #field-type .ws-react-select__menu-list {
    max-height: 40vh;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 2px;
    width: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #b8d1fe;
    border-radius: 2px;
    width: 5px;
  }

  .row-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    gap: 20px;
  }

  .row-wrapper:not(:first-child) {
    border-bottom: 1px dashed #cacddb;
  }

  .ws-button {
    width: 160px;
  }

  .ws-input {
    color: #6a6d7c;
    width: 100%;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__footer-buttons {
    display: flex;
    justify-content: space-between;
  }

  .modal__buttons {
    display: none;
  }

  .image-select-option {
    color: #6a6d7c;

    path {
      fill: #6a6d7c;
    }
  }
}
