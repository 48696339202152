@import "../../../styles/media-queries.scss";
@import "../../../styles/variables.scss";

.react-autosuggest__container {
  .ws-textarea {
    width: 536px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    gap: 8px;

    &--empty .ws-textarea-placeholder {
      opacity: 1;
    }

    &--empty:focus-within .ws-textarea-placeholder {
      opacity: 0;
    }

    &-placeholder {
      position: absolute;
      transition: all 0.2s;
      opacity: 0;
      color: #d3d5e1;
      pointer-events: none;
      font-size: 12px;
      line-height: 36px;
      font-weight: 500;
      overflow: hidden;
    }
  }

  .react-autosuggest__input {
    min-width: 5px;
    color: #8e93ac;
    font-size: 12px;
    line-height: 36px;
    font-weight: 500;
    overflow: hidden;

    &:focus {
      outline: none;
    }
  }

  .react-autosuggest__suggestions-list {
    max-height: 270px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      border-radius: 2px;
      background-color: #e0e2ea;
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #a7abc3;
      border-radius: 30px;
    }
  }

  .react-autosuggest__suggestions-container {
    position: absolute;
    width: 100%;
    background: #ffffff;
    overflow-y: auto;
    border-radius: 8px;
    box-shadow: 0px 1px 50px 0px #0000001a;
    transition: all 0.2s;
    margin-top: 10px;
    transition: all 0.2s;

    &--open {
      padding: 10px 0;
    }

    .suggestion {
      padding: 10px 20px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      transition: background 0.1s;

      &:hover {
        background-color: #f2f2f677;
      }

      .suggestion-data {
        display: flex;
        column-gap: 12px;
      }

      .suggestion-placeholder {
        background-size: contain;
        background-repeat: no-repeat;
        width: 32px;
        height: 32px;
      }

      .suggestion-img {
        width: 32px;
        height: 32px;
        background-size: cover;
        border-radius: 80px;
        transition: background-image 2s;
      }

      .suggestion-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .suggestion-details__username {
          font-weight: 500;
          font-size: 12px;
          color: #74788d;
          display: flex;
          align-items: center;

          .suggestion-details__username-tick {
            visibility: hidden;
            margin-left: 4px;
          }
        }
      }

      .social-profile-icons__icon--awaiting {
        opacity: 1;
      }
      .social-profile-icons--small {
        margin-top: 2px;
      }
    }

    .fake-suggestion {
      padding: 10px 20px;
      display: none;
      column-gap: 12px;
      color: #74788d;
      cursor: pointer;
      transition: background 0.1s;
      font-weight: 500;

      &:hover {
        background-color: #f2f2f677;
      }
      &--open {
        display: flex;
      }

      .suggestion-details {
        display: flex;
        font-size: 12px;
        column-gap: 8px;
      }
      &__title {
        padding: 7px 0px;
      }
      &__value {
        color: #5d78ff;
        background-color: #eff1ff;
        padding: 7px 10px;
        border-radius: 8px;
      }
    }

    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 8px;
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: #888;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .selected-option {
    background: #f2f2f6;
    display: flex;
    padding: 8px;
    border-radius: 4px;
    line-height: 20px;
    column-gap: 4px;

    .social-profile-icons {
      margin-top: 0;
      height: 20px;

      &__icon.social-profile-icons__icon--awaiting {
        opacity: 1;
        cursor: default;
      }

      img {
        width: 16px;
        height: 16px;
        margin: 2px;
        margin-right: 4px;
      }
    }

    .close-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
      opacity: 0.8;
      transition: opacity 0.1s;

      &:hover {
        opacity: 1;
      }
    }
  }
}
