.single-filter {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .custom-date-range__input {
    height: 40px;
  }

  .ws-input-label {
    word-break: break-all;
  }
}
