@import "src/styles/media-queries.scss";
@import "src/styles/variables.scss";
@import "src/styles/mixins.scss";

.team-list {
  .modal__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__table {
    &-head {
      display: flex;
      border-bottom: solid 1px #ededef;
      padding: 12px 0;

      &-el {
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 12px;
        color: #a1a3ad;
        text-transform: uppercase;
      }
    }

    &-body {
      overflow-y: auto;
      max-height: 300px;
      overflow-y: auto;
      @include custom-scrollbar;

      @include min-screen($desktopWidth) {
        max-height: 350px;
      }

      @include min-screen($desktopLargeWidth) {
        max-height: 490px;
      }

      &-row {
        height: 64px;
        display: flex;
        align-items: center;

        &:not(:last-of-type) {
          border-bottom: solid 1px #ededef;
        }

        &-el {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          color: #45485b;
        }
      }
    }

    &-head-el,
    &-body-row-el {
      &:first-of-type {
        width: 300px;
      }

      &:nth-of-type(2) {
        flex: 1;
      }

      &:nth-of-type(3) {
        margin-left: auto;
      }
    }

    .stacked-avatars {
      margin: 0;

      .avatar img,
      .avatars-left {
        width: 32px;
        height: 32px;
      }

      .avatars-left {
        color: #6a6d7c;
        background-color: #ededef;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
      }
    }

    .team-name {
      cursor: pointer;

      input {
        border: none;
        outline: none;
        padding: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        color: #a1a3ad;
      }
    }

    .team-options {
      display: flex;
      align-items: center;
      gap: 12px;
      padding-right: 12px;

      svg {
        cursor: pointer;

        path {
          fill: #6a6d7c;
        }
      }
    }
  }

  th {
  }

  td {
  }
}
