.chart {
  width: 100%;
  flex-shrink: 0;

  .recharts-wrapper {
    .recharts-cartesian-axis-tick-line,
    .recharts-cartesian-axis-line {
      display: none;
    }

    .yAxis {
      .recharts-text.recharts-cartesian-axis-tick-value {
        fill: #8e93ac;
      }
    }

    .xAxis {
      .recharts-text.recharts-cartesian-axis-tick-value {
        fill: #d3d5e1;
      }
    }

    .recharts-surface {
      overflow: visible;
    }
  }
}

.chart-tooltip {
  background: #45485b;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  font-size: 9px;
  padding: 10px 12px;
  font-weight: 500;

  &__label {
    margin-top: 4px;
    font-weight: 400;
    color: #dadade;
  }

  &__row:not(:last-of-type) {
    margin-bottom: 8px;
  }
}
