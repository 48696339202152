.ws-checkbox {
  input[type="checkbox"] {
    position: relative;
    left: -9999px;
    width: 0;
    height: 0;
    margin: 0;
    display: none;

    &:checked + label {
      color: #74788d;

      &::before {
        background: #eff1ff;
        border-color: #5d78ff;
      }
      &::after {
        display: block;
      }
    }

    &:disabled {
      & + label {
        cursor: not-allowed;
        &:hover {
          opacity: 1;
        }
      }

      &:checked {
        & + label {
          &::before {
            background: #f2f2f6;
            border-color: #f2f2f6;
          }
          &::after {
            display: block;
            background-image: url("../../../images/tick-light-grey.svg");
          }
        }
      }
      &:not(:checked) {
        & + label {
          color: #d3d5e1;

          &::before {
            background: #fff;
          }
        }
      }
    }
  }

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #8e93ac;
    padding-left: 20px;
    position: relative;
    display: flex;
    cursor: pointer;
    min-width: 20px;
    min-height: 20px;
    user-select: none;

    > span {
      margin-left: 8px;

      a {
        color: #5d78ff;
      }
    }

    &:hover {
      opacity: 0.7;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      border: 1px solid #d3d5e1;
      border-radius: 4px;
      width: 20px;
      height: 20px;
      box-sizing: border-box;
    }

    &::after {
      content: "";
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      background: url("../../../images/tick.svg") no-repeat center;
      background-size: contain;
      width: 20px;
      height: 20px;
    }
  }

  &--blue {
    input[type="checkbox"]:checked + label {
      &::before {
        background-color: #5d78ff !important;
      }
      &::after {
        background: url("../../../images/tick-white.svg") no-repeat center;
      }
    }
  }
}
