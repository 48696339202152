@import "src/styles/variables.scss";

.App {
  * {
    box-sizing: border-box;
  }

  &__main-container {
    height: calc(100vh - 64px);
    box-sizing: border-box;
    margin: {
      top: 64px;
    }
    // padding: 32px 46px;
  }

  .ws-hyperlink {
    color: #5d78ff;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.Overlays {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: $z-index-max;
  left: 0;
  top: 0;
  pointer-events: none;
  overflow: hidden;

  & > * {
    pointer-events: all;
  }
}

.intercom-launcher {
  background-color: #5d78ff;
}

.animate-on-update {
  animation: fadeIn 0.5s;
}

.button-style-reset {
  all: unset;
  cursor: pointer;
}

.sticky-bars {
  width: 100%;
  z-index: 501;

  position: sticky;
  height: 0px;
  top: 0;
}

.element-center-and-full-screen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
