.dictionary-details {
  &__topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    &-buttons {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &-close-icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  &__content {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
    border-top: 1px solid #f2f2f6;

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .preview__info {
        margin-bottom: 0;
      }
    }
  }

  &__data {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &-cover {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: 12px;
    }

    &-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 36px;
      color: #45485b;
    }
  }

  &__feed {
    margin: 20px 0;

    &-activity {
      display: flex;
      align-items: center;
      color: #74788d;
      font-weight: 600;
      font-size: 10px;
      text-transform: uppercase;
      gap: 6px;
    }

    &-avatar {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }

    &-date,
    &-time,
    &-name {
      font-weight: 500;
      font-size: 12px;
      color: #8e93ac;
    }

    &-name {
      text-transform: capitalize;
    }
  }
}
