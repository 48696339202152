.see-as-client-mode-switch {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #74788d;
  display: flex;
  gap: 8px;
  align-items: center;

  .switch-button {
    margin-right: 8px;
  }
}
