.progress-field {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__value {
    color: #74788d;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 11px;
  }

  &__bar {
    width: 150px;
    height: 6px;
    background: #f2f2f6;
    border-radius: 20px;
    margin-bottom: 10px;

    &-progress {
      max-width: 100%;
      height: 6px;
      background: #5d78ff;
      border-radius: 20px;
    }
  }

  &__range {
    display: flex;
    color: #8e93ac;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    &-value {
      pointer-events: all;
      cursor: pointer;
    }

    &-slash {
      margin: 0 4px;
      color: #d3d5e1;
    }

    &-prefix {
      margin-right: 4px;
      color: #d3d5e1;
    }
  }
}
