.table-summary {
  &--with-summary {
    table tbody tr {
      &:last-of-type {
        position: relative;

        td {
          padding-bottom: 8px;
        }

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: calc(100% - 8px);
          background-color: #f6f6f7;
          left: 0;
          top: 0;
          z-index: -1;
          border-radius: 0px 0px 4px 4px;
        }
      }
    }
  }

  table {
    width: 100%;
    border-spacing: 8px 0;

    tr {
      position: relative;
      padding-bottom: 10px;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: #ededef;
      }

      .stat-header,
      .stat-cell {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: fit-content;

        .separator {
          height: 1px;
          background-color: #e0e2ea;
        }
      }

      .stat-header span {
        max-width: 80px;
      }
    }

    thead {
      tr {
        th {
          font-size: 10px;
          font-weight: 600;
          line-height: 12px;
          text-transform: uppercase;
          text-align: left;
          color: #b4b6be;

          .pictogram {
            width: 24px;
            height: 24px;

            span,
            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }

    tbody {
      td {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: #45485b;

        &:first-of-type {
          width: 32px;
        }

        &:nth-of-type(2) {
          width: 152px;
          font-size: 13px;
          font-weight: 500;
        }

        &:nth-last-of-type(2) {
          margin-left: auto;
          overflow: hidden;
          white-space: nowrap;
          width: 100px;
        }

        &:last-of-type {
          margin-left: auto;
          width: 20px;
        }

        .creator-cover {
          display: flex;
          align-items: center;

          img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
          }
        }

        .link--disabled {
          pointer-events: none;

          svg {
            path,
            rect {
              fill: #8e93ac;
            }
          }
        }

        .link,
        .link a,
        .link svg {
          display: block;
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
    }
  }
}
