.empty-tasks-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 124px;

  &__text-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 110px;

    p {
      margin-bottom: 24px;
      color: #74788d;
      font-size: 14px;
      line-height: 20px;
    }

    button svg path {
      fill: white;
    }
  }
}

.project-cover {
  position: relative;
  display: grid;
  place-items: center;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  background-color: #f2f2f6;
  cursor: pointer;
  margin-right: 20px;

  &__image {
    position: absolute;
    pointer-events: none;
    width: 46px;
    height: 46px;
    border-radius: 4px;
    z-index: 0;
    object-fit: cover;
    object-position: center;
  }

  &__file {
    cursor: pointer;
    z-index: 1;

    input {
      position: absolute;
      height: 67px;
      width: 48px;
      left: 0;
      top: 0;
      border-radius: 4px;
      opacity: 0;
      z-index: 100;
    }

    input,
    input::-webkit-file-upload-button {
      cursor: pointer;
    }
  }

  .image-input {
    position: relative;
    cursor: pointer;

    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    input,
    input::-webkit-file-upload-button {
      cursor: pointer;
    }
  }

  &__icon {
    width: 48px;
    height: 48px;
    background-color: transparentize($color: white, $amount: 0.3);
    border: dashed 1px #5d78ff;
    display: grid;
    place-items: center;
    opacity: 0;
    transition: all 0.3s ease;
    border-radius: 4px;
  }

  &:hover {
    .project-cover__icon {
      opacity: 1;
    }

    .project-cover__icon {
      opacity: 1;
    }
  }
}
.no-filtered-results-screen-wrapper {
  height: 200px;
}