.pane-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 20px;

  &__toolbar {
    display: flex;
    align-items: center;
    gap: 12px;

    &-icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
      &:hover {
        path {
          fill: #536ceb;
        }
      }
    }
  }
  &__left {
    display: flex;
    align-items: center;
  }

  &__col {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .ws-react-select-container {
      width: 200px;
    }
  }
}
