.add-file-button {
  position: relative;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }

  ::-webkit-file-upload-button {
    cursor: pointer;
  }
}

.external-file-field {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  &__add-button {
    position: relative;
    width: 64px;
    height: 64px;
    border: 1px dashed #ced7ff;
    border-radius: 8px;
    display: grid;
    place-items: center;
    transition: all 0.2s ease;
    cursor: pointer;

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;
    }

    ::-webkit-file-upload-button {
      cursor: pointer;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    &:hover {
      background: #eff1ff;
      border: 1px dashed transparent;
    }
  }

  &__box {
    position: relative;
    display: flex;
    align-items: center;
    width: 302px;
    height: 64px;
    background: #f6f6f9;
    border-radius: 8px;
    cursor: pointer;

    .attachment-wrapper .attachment-file {
      width: 242px;
      &:hover {
        background: #f6f6f9;
      }
    }

    &:hover {
      .multiple-file-field__box-delete {
        opacity: 1;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 10px;
      width: 100%;

      &-loader {
        position: relative;
        margin-top: 5px;
        width: 150px;
        height: 6px;
        border-radius: 8px;
        background-color: #d3d5e1;
        width: 100%;

        &-progress {
          position: absolute;
          left: 0;
          width: 84px;
          height: 6px;
          border-radius: 8px;
          background-color: #47c4ab;
        }
      }

      &-top {
        width: 150px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #74788d;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &-bottom {
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
        color: #a7abc3;

        span {
          margin-right: 4px;
        }

        &-download {
          transition: all 0.2s ease;

          &:hover {
            color: #5d78ff;
          }
        }
      }
    }

    &-delete {
      display: none;
      position: absolute;
      right: 12px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      opacity: 0;
      transition: all 0.2s ease;

      svg path {
        opacity: 1;
      }
    }
    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }

  .attachment-file__name {
    width: 208px;
    max-width: unset;
  }

  .attachment-file__details {
    margin-right: 18px;
  }

  a.attachment-file__download {
    transition: all 0.1s ease-out;
    &:hover {
      color: rgb(93, 120, 255);
    }
  }
  .attachment-image {
    transition: all 0.05s ease-out;
  }
  .attachment-wrapper__inline .extension-icon {
    pointer-events: none;
    margin-right: 0;
  }
  @media screen and (max-width: 700px) {
    .attachment-file__name {
      width: calc(100vw - 163px);
    }
  }

  .attachment-loader {
    svg {
      flex-shrink: 0;
    }
    .attachment-loader__icon {
      margin-right: 10px;
    }
  }
}
