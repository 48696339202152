.creator-showcase {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: auto;
  top: 0;
  background-color: #f2f2f6;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 2px;
    background-color: #e0e2ea;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #a7abc3;
    border-radius: 30px;
  }

  &__template-wrapper {
    display: grid;
    place-items: center;
    width: calc(100% - 314px);

    // height: 100%;
    margin-top: 76px;
    margin-left: 314px;
    padding: {
      top: 38px;
      bottom: 46px;
      left: 40px;
      right: 40px;
    }
  }
}
