.activity-filters-pane {
  overflow: unset;
}

.activity-filters {
  background-color: #fff;

  .ws-react-select {
    &-wrapper {
      min-height: 48px;
    }

    &__control {
      min-height: 48px;
      overflow-y: auto;
      max-height: calc(100vh / 2 - 194px);

      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 8px;
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: #888;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    &__menu::before {
      top: -47px;
    }

    &__placeholder {
      top: 15px;
    }

    &__indicator {
      margin-top: 14px;
    }

    &__multi-value {
      max-width: 186px;
    }
  }

  &__option {
    display: flex;
    align-items: center;

    &-image {
      width: 20px;
      height: 20px;
      margin-right: 8px !important;
      border-radius: 50%;

      &--square {
        border-radius: 2px;
      }
    }

    &-text {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 180px;
    }
  }
}
