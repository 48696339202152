.empty-performance {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 64px;
  color: #45485b;

  &__text {
    margin-top: 48px;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }

  &__subtext {
    margin-top: 12px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
  }
}
