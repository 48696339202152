.ws-overview-fields-table {
  border-top: 1px dashed #d3d5e1;
  border-bottom: 1px dashed #d3d5e1;
  display: flex;
  margin-top: 30px;
  position: relative;
  // TODO:: just for task lists, do it better in future
  margin-bottom: 32px;

  &::after {
    content: "";
    position: absolute;
    top: 44px;
    left: 0;
    right: 0;
    border-bottom: 1px dashed #d3d5e1;
    z-index: 6;
  }

  &__column,
  &__row {
    width: 240px !important;
  }

  &__column {
    // width: 180px;
    // width: 220px;
    background-color: #fff;

    &--dragging {
    }
  }

  &__row {
    // position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 12px 24px;
    white-space: nowrap;
    height: 110px;
    // value field
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #74788d;
  }

  &__info {
    width: 320px;
    flex-shrink: 0;
    border-right: 1px dashed #d3d5e1;
    background-color: #fff;
    z-index: 6;

    .ws-overview-fields-table__content:not(:last-of-type) {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100vw - 164px);
        border-bottom: 1px dashed #d3d5e1;
      }
    }
  }

  &__columns {
    display: flex;
    flex: 1;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      height: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      border-radius: 2px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #b8d1fe;
      border-radius: 2px;
    }
  }

  &__actions {
    padding: 0 24px;
    flex-shrink: 0;
    border-left: 1px dashed #d3d5e1;
    z-index: 6;

    .ws-overview-fields-table__content {
      position: relative;
      background-color: #fff;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: -1px;
        width: 100%;
        border-bottom: 1px dashed #d3d5e1;
      }

      &--first {
        &::after {
          display: none;
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.03em;
    height: 44px;
    color: #a7abc3;
    padding: 14px;
    white-space: nowrap;
    text-transform: uppercase;
    text-align: center;

    &:hover {
      .ws-overview-fields-table__header-drag {
        opacity: 1;
      }

      .ws-overview-fields-table__header-dropdown-button {
        opacity: 1;
      }
    }

    &--flex {
      // TODO:: better name
      display: flex;
      align-items: center;
      justify-content: center;
      // border-bottom: 1px dashed #d3d5e1;
    }

    &--left {
      display: flex;
      align-items: center;
      padding-left: 0;
    }

    &-drag {
      display: grid;
      place-items: center;
      margin-right: 8px;
      opacity: 0;
      cursor: pointer !important;
    }
  }

  &__header-data {
    display: flex;
    align-items: center;
  }

  &__header-dropdown {
    width: 20px !important;
    max-width: 20px;
    display: flex;
    justify-content: center;
    text-transform: initial;

    &-button {
      display: flex;
      align-items: center;
      cursor: pointer !important;
      margin-left: 8px;
      opacity: 0;
    }
  }

  &__header-title {
    text-transform: uppercase;
    height: 100%;
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__header-title-icon {
    margin-left: 8px;
  }

  &__content {
    height: 110px;
    display: flex;
    align-items: center;
    background-color: #fff;

    &--tasks-table {
      height: 90px;
    }

    &--center {
      justify-content: center;
    }
  }

  &__open-new-card {
    margin-right: 16px;

    &:hover {
      cursor: pointer;
    }
  }

  &__name {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #74788d;
    margin: 0 12px;

    &--with-tick {
      margin-left: 8px;
    }

    svg {
      margin-right: 0;
    }
  }

  &__task-name {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    max-width: 214px;
    cursor: pointer;
  }

  &__task-name-wrapper {
    display: flex;
    align-items: center;
  }

  &__task-shared {
    margin-left: 8px;
    cursor: pointer;

    &--disabled {
      cursor: default;
    }
  }

  &__task-not-shared {
    margin-left: 8px;
    background-image: url("../../../images/client-grey.svg");
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
    cursor: pointer;

    &--disabled {
      cursor: default;
    }

    &:hover {
      background-image: url("../../../images/client-light-grey.svg");
    }
  }

  &__row-info {
    display: flex;
    align-items: center;
  }

  &__field {
    height: 100%;
    width: 100%;
    min-width: 90px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    input {
      text-align: center;
      width: 100%;
      border: 0;
      background-color: transparent;

      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: #8e93ac;
      outline: 0;
    }
  }

  &__empty-field {
    padding: 6px 26px;
    border: 1px dashed #d3d5e1;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 12px;
      width: 12px;

      path {
        // fill: #d3d5e1;
      }
    }
  }

  &__add-button {
    display: flex;
    align-items: center;
    color: #d3d5e1;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    transition: all 0.2s ease;
    cursor: pointer;

    &--project {
      height: 110px;
      margin: {
        top: 44px;
        left: 0;
        right: 32px;
      }
    }

    &-empty {
      display: grid;
      place-items: center;
      width: 48px;
      height: 48px;
      border: dashed 1px #d3d5e1;
      margin-right: 12px;
      transition: all 0.2s ease;

      &--circle {
        border-radius: 50%;
      }

      &--square {
        border-radius: 4px;
      }

      svg {
        width: 20px;
        height: 20px;

        path {
          fill: #d3d5e1;
          transition: all 0.2s ease;
        }
      }

      &--small {
        width: 30px;
        height: 30px;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    &:hover {
      color: #5d78ff;

      .ws-overview-fields-table__add-button-empty {
        background-color: #eff1ff;
        border: dashed 1px #5d78ff;

        svg path {
          fill: #5d78ff;
        }
      }
    }
  }

  &--empty {
    display: none;
  }

  .attachment-video__play-icon {
    position: absolute;
    left: 0;
    top: 0;
  }

  .project-selector-field {
    &__name {
      width: 100px;
    }
  }
}
