@import "../../../styles/variables.scss";

.snack-bar {
  position: absolute;
  left: 72px;
  top: 64px;
  box-shadow: 0px 1px 50px rgba(white, 1);
  background: #eff1ff;
  width: calc(100% - 72px);
  display: flex;
  align-items: center;
  padding: 11px 29px 11px 48px;
  z-index: $z-index-navigation;

  &__text {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #6a6d7c;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  &__icon {
    width: 20px;
    height: 20px;
  }

  &__close-icon {
    width: 24px;
    height: 24px;
    margin-left: auto;
    cursor: pointer;
  }

  &__cta-btn {
    margin-left: 12px;
  }
}

.trial-snack-bar {
  &__action {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #5d78ff;
  }
}
