.showcase-cover-tab {
  &__creators {
    display: flex;
    align-items: center;
    gap: 8px;

    &-header {
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
    }

    .stacked-avatars {
      margin-left: 0;
    }

    &-cover {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }
}
