.external-file-button {
  border: #b4b6be dashed 1px;
  min-height: 162px;
  border-radius: 8px;
  position: relative;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 12px;
  transition: all 0.2s ease;

  &__icon {
    flex-shrink: 0;
  }

  &__title {
    color: #6a6d7c;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }

  &__text {
    color: #8f919d;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
  }

  &__input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }

  &:hover {
    background: #eff1ff;
    border: 1px dashed #5d78ff;

    & .external-file-button__text,
    & .external-file-button__title {
      color: #5d78ff;
    }

    & .external-file-button__icon path {
      fill: #5d78ff;
    }
  }
}
