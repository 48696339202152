.icon-color-selector {
  width: 40px;
  height: 40px;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid #a7abc3;
    border-radius: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #74788d;
    cursor: pointer;

    &-color {
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }
  }

  &__menu {
    position: absolute;
    width: 380px;
    background-color: #fff;
    box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px;

    &-section {
      display: grid;
      justify-content: space-between;
      grid-template-columns: repeat(8, 40px);
      gap: 2px;
    }

    &-name {
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      color: #a7abc3;
      margin-bottom: 8px;
    }

    &-item {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        border: 1px solid #d3d5e1;
      }

      &-color {
        width: 16px;
        height: 16px;
        border-radius: 50%;
      }

      &--selected {
        border: 1px solid #5d78ff !important;
      }
    }
  }
}
