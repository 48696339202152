.ws-switch {
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #45485b;

  &--big {
    input {
      &:checked + i {
        &::after {
          transform: translate3d(21px, 2px, 0);
        }
      }
    }
    i {
      width: 44px;
      height: 24px;
      &::after {
        width: 20px;
        height: 20px;
        transform: translate3d(3px, 2px, 0);
      }
    }
  }

  &--small {
    input {
      &:checked + i {
        &::after {
          transform: translate3d(17px, 2px, 0);
        }
      }
    }
    i {
      width: 36px;
      height: 20px;
      &::after {
        width: 16px;
        height: 16px;
        transform: translate3d(3px, 2px, 0);
      }
    }
  }

  i {
    position: relative;
    display: inline-block;
    background-color: #dadade;
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      background-color: #fff;
      border-radius: 11px;
      box-shadow:
        0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
      transition: all 0.2s ease-in-out;
    }
  }

  input {
    display: none;

    &:checked + i {
      background-color: #5d78ff;
      &::after {
        background-color: #fff;
      }
    }
  }

  &--disabled {
    cursor: not-allowed;
    i {
      background-color: #dadade;
      &::after {
        background-color: #ededef;
      }
    }
  }
}
