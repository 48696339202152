.showcase-toolbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  width: 314px;
  height: calc(100vh - 76px);
  top: 76px;
  left: 0;
  background-color: #fff;

  &--scrollbar-space {
    right: 6px;
  }

  &--lists-hidden {
    .showcase-toolbar__header,
    .toolbar-list {
      opacity: 0;
    }
  }

  &__content {
    padding: 20px;
    width: 100%;
    overflow: scroll;
    height: 100%;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      width: 0px;
    }

    .toolbar-list {
      border-bottom: none;
      border-top: solid 1px #ededef;
      padding-top: 16px;
      margin-top: 16px;
      padding-bottom: 0;
    }
  }

  &__buttons {
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;

    button {
      width: 100%;
      height: 46px;
      white-space: nowrap;
      overflow: hidden;
    }

    &-bottom {
      width: 100%;
      margin-top: 16px;
      display: flex;
      gap: 16px;
    }
  }

  &__header {
    color: #74788d;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    transition: all 0.2s ease;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #8e93ac;

    &-item {
      display: flex;
      align-items: center;
    }

    .ws-react-select {
      width: 166px;
    }

    .ws-checkbox {
      margin-right: 8px;
    }

    &-list {
      &-item {
        color: #a7abc3;
        display: flex;
        margin-top: 16px;
      }
    }

    &--vertical {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;

      .ws-react-select-container,
      .ws-react-select {
        width: 100%;
      }
    }

    &--no-padding {
      padding: 0;
    }
  }

  &__metrics:not(:first-of-type) {
    margin-top: 12px;
  }

  &__metric-wrapper {
    padding: 16px 0;
    border-top: 1px dashed #d3d5e1;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #8e93ac;
    min-height: 72px;
  }
}
