.codes-table {
  &__head {
    display: flex;
    align-items: center;
    height: 44px;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #a7abc3;
    text-transform: uppercase;
    border: {
      top: 1px dashed #d3d5e1;
      bottom: 1px dashed #d3d5e1;
    }
    gap: 48px;

    &-code,
    &-offer {
      flex-basis: 330px;
    }

    &-campaign,
    &-campaigns {
      flex-basis: 300px;
    }

    &-connected-with,
    &-coupons {
      flex: 1;
    }

    &-action {
      flex-basis: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px dashed #d3d5e1;
      height: 100%;
    }
  }
  &__campaign-option {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
    }
  }
}
