.publications-detected-modal {
  width: 600px;

  &__text {
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    color: #b4b6be;
  }

  &__row {
    margin-top: 16px;
    min-height: 70px;

    .ws-react-select {
      &-wrapper {
        height: 48px;
      }

      &__control {
        min-height: 48px;
      }

      &__menu::before {
        top: -47px;
      }

      &__placeholder {
        top: 15px;
      }

      &__indicator {
        margin-top: 14px;
      }
    }
  }

  &__list {
    max-height: 370px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      border-radius: 2px;
      background-color: #ededef;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #b4b6be;
      border-radius: 30px;
    }
  }

  .modal__buttons .ws-button {
    width: 160px;
  }
}
