.my-account {
  &__subheader {
    margin: {
      top: 20px;
      bottom: 8px;
    }
    color: #45485b;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
  }

  .ws-input {
    width: 100%;
    margin-bottom: 8px;
  }

  &__avatar {
    display: flex;
    align-items: center;

    .custom-image {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-right: 16px;
    }

    &-button {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: #7d808d;
      cursor: pointer;
    }
  }
}
