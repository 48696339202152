.task-box {
  width: 300px;
  height: 112px;
  background-color: #fff;
  transition: border 0.2s ease;
  margin-bottom: 16px;
  border: 1px solid #e5e6ed;
  box-sizing: border-box;
  box-shadow: 0px 2px 3px rgba(167, 171, 195, 0.2);
  border-radius: 12px;
  padding: 16px;

  &--content {
    height: 368px;
  }

  &--publication {
    height: 495px;
  }

  &--dragging {
    border: solid 1px #dde2f7;
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--creator {
      min-height: 80px;
    }

    &--content {
      min-height: 52px;
    }

    &--action {
      min-height: 80px;
    }

    &--publication {
      min-height: 52px;
    }

    // &--completed {
    //   .task-box__main-title {
    //     color: #d3d5e1 !important;
    //   }
    // }

    .check {
      margin-right: 4px;
    }

    &-info {
      display: flex;
      justify-content: space-between;

      &-content {
        display: flex;
        align-items: center;
      }

      &-cover {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 12px;
      }
    }

    &-menu-button {
      width: 20px;
      height: 20px;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        path {
          fill: #536ceb;
        }
      }
    }

    &-content-image {
      width: 268px;
      height: 268px;
      margin-bottom: 16px;
      object-fit: cover;
      object-position: center;
      cursor: pointer;
    }

    &-title {
      color: #74788d;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      margin: 0;
      transition: all 0.2s ease;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 235px;
    }

    &-task-count {
      font-weight: 500;
      font-size: 10px;
      line-height: 24px;
      margin-right: 4px;
    }

    &-chevron {
      margin-left: 4px;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    &-due-date {
      font-weight: 500;
      font-size: 9px;
      line-height: 14px;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #a7abc3;
    transition: all 0.3s ease;

    &-icons {
      svg {
        margin-right: 8px;
      }
    }

    &-wrapper {
      height: 24px;
      display: flex;
      align-items: center;
    }
  }
}
