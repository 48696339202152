.influencers-list-authors-dropdown {
  .filter-button {
    display: grid;
    place-items: center;
    padding: 0;

    .filter-button-icon {
      width: 12px;

      &--active path {
        fill: #1267fb;
      }
    }
  }

  label {
    width: 100%;
  }
}
