.coupons-learn-more-modal {
  width: 600px;

  &__topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    height: 22px;
  }

  &__title {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #8f919d;
  }

  &__steps {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #6a6d7c;
    padding: 4px 8px;
    background-color: #ededef;
    border-radius: 6px;

    &-divider {
      color: rgba($color: #6a6d7c, $alpha: 0.3);
      margin: 0 4px;
    }
  }

  .chevrons {
    display: flex;
    align-items: center;
  }

  .chevron-button {
    width: 44px;
    height: 44px;
    padding: 0;
    gap: 12px;

    svg {
      margin: 0;
    }

    &:last-of-type {
      margin-left: 12px;
      svg {
        transform: rotate(180deg);
      }
    }
  }

  video {
    width: 100%;
    height: 289.77px;
    border-radius: 8px;
    border: 1px solid var(--grey-05, #ededef);
    object-fit: cover;
  }
}
