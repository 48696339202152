.table-item {
  display: flex;
  align-items: center;
  height: 100px;
  border-bottom: 1px dashed #d3d5e1;
  gap: 48px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #45485b;

  &__code,
  &__offer,
  &__campaign,
  &__connected-with {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .table-item__value-name {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__code,
  &__offer {
    flex-basis: 330px;

    a {
      color: #45485b;
    }
  }

  &__code-name {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;

    svg {
      width: 16px;
      height: 16px;
      opacity: 0;
      transition: all 0.2s ease;
      margin-left: 8px;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }

  &__campaign,
  &__connected-with {
    &-value {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #45485b;

      img {
        width: 24px;
        height: 24px;
        border-radius: 2px;
        margin-right: 8px;
      }
    }
  }

  &__campaign,
  &__campaigns {
    flex-basis: 300px;
  }

  &__connected-with,
  &__coupons {
    flex: 1;

    img {
      border-radius: 50%;
    }
  }

  &__action {
    flex-basis: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px dashed #d3d5e1;
    height: 100%;
  }

  .coupon-tooltip {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
