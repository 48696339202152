.currency-code {
  margin-left: 5px;
  margin-right: 4px;
  color: #d3d5e1;
  cursor: default;
}
.currency-value,
.currency-input {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  padding: 1px 2px;
  margin: 0;
  white-space: nowrap;

  &--hidden {
    top: 10px;
    left: 0;
    position: absolute;
    visibility: hidden;
    pointer-events: none;
  }
}
