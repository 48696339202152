.subtask-container {
  transition: background-color 0.15s ease;
  background-color: white;

  input {
    width: 300px;
    transition: all 0.15s ease;
  }

  &:hover {
    background-color: lighten($color: #eff1ff, $amount: 2);

    input {
      background-color: lighten($color: #eff1ff, $amount: 2);
    }
  }
}

.subtask {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px dashed #d3d5e1;
  cursor: pointer;
  transition: all 0.2s ease;

  &__icon {
    &--wip {
      margin-right: 9px;
    }
  }

  &__placeholder {
    position: absolute;
    background: #f5f7ff;
    transition: all 0.2s ease;
    z-index: 0;
  }

  &__name {
    color: #74788d;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    border: none;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 30px;
    transition: all 0.2s ease;

    &:focus {
      outline: none;
    }
  }

  &__right {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;

    &-button {
      min-width: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 12px;
    }

    .date-picker-wrapper {
      position: relative;
      margin-left: 16px;
      width: 100px;

      &:hover {
        .idh-date-picker__input {
          border: 1px dashed #5d78ff;
        }

        svg {
          path {
            fill: #5d78ff;
          }
        }
      }

      &__plus {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        z-index: 5;
        pointer-events: none;

        path {
          fill: #d3d5e1;
          transition: all 0.2s ease;
        }
      }
    }

    .idh-date-picker {
      &__input {
        width: 100px;
        height: 24px;
        padding: 10px 8px !important;
        border: 1px dashed #d3d5e1;
        text-align: center;
        cursor: pointer;
      }
    }

    .assignee-field {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s ease;

      &__username {
        margin-left: 4px;
      }
    }
  }

  &--complete {
    ~ .subtask__name,
    .idh-date-picker__input,
    .assignee-field {
      opacity: 0.5;
    }
  }

  .dropdown {
  }
}

.subtask-container {
  position: relative;
}
