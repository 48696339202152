@import "../../styles/media-queries.scss";
@import "../../styles/variables.scss";

.login-page {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
}

.login-page-left {
  background-color: #5d78ff;
  width: 510px;
  height: 100vh;
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 48px;
  color: #ffffff;

  @include max-screen($tabletWidth) {
    display: none;
  }

  &__group {
    display: flex;
    flex-direction: column;
    height: calc(100% - 80px);
    justify-content: space-between;

    @include min-screen-height(660px) {
      height: calc(100% - 104px);
    }
  }

  &__logo {
    margin: 64px 0 0 64px;
    margin-bottom: 40px;

    @include min-screen-height(900px) {
      margin-bottom: 80px;
    }
  }

  &__header {
    margin-left: 64px;
  }

  &__header-bottom {
    display: flex;
    align-items: center;
  }

  &__victory-hand {
    margin-left: 8px;
  }

  &__group-image {
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 160px;

    @include min-screen-height(660px) {
      height: 248px;
    }

    @include min-screen-height(840px) {
      height: 424px;
    }
  }

  &__copyrights {
    margin: 20px 64px;
    font-weight: 400;
    font-size: 12px;
    line-height: 40px;
    color: #bec9ff;

    @include min-screen-height(660px) {
      margin: 32px 64px;
    }
  }
}

.login-page-right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  @include max-screen($tabletWidth) {
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    overflow: scroll;
    display: block;
  }

  @include max-screen($tabletWidth) {
    .ws-button {
      font-size: 16px;
      line-height: 28px;
    }

    .ws-input-label {
      font-size: 14px;
      line-height: 22px;
    }

    .ws-input {
      font-size: 16px;
      line-height: 28px;
      height: 48px;
      width: 100%;
    }

    .ws-checkbox {
      label {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  &__book-demo {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #74788d;
    position: absolute;
    top: 40px;
    right: 40px;

    @include max-screen($tabletWidth) {
      position: static;
      font-size: 16px;
      line-height: 28px;
    }

    span {
      &:last-of-type {
        color: #5d78ff;
        cursor: pointer;
      }
    }
  }

  &__header {
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    color: #45485b;
    margin-bottom: 48px;

    &--confirm {
      margin-bottom: 56px;
    }
  }

  &__invalid-email-error {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #d93025;
    margin-top: 8px;
  }

  &__invalid-email-error-icon {
    margin-right: 4px;
  }

  &__book-demo-2 {
    color: #5d78ff;
    text-decoration: none;
    cursor: pointer;
  }

  &__contact-us {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #74788d;
    margin-top: 8px;

    a {
      color: #5d78ff;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .ws-checkbox {
    margin-top: 32px;
  }

  &__btn {
    padding: 12px 60px;
    margin-top: 32px;
  }

  &__incoming-icon {
    margin-bottom: 32px;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #74788d;
    line-height: 32px;
  }

  &__email-address {
    color: #45485b;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
  }

  &__container {
    display: flex;
    flex-direction: column;

    @include max-screen($tabletWidth) {
      width: 100%;
      margin-top: 40px;

      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &--confirm {
      align-items: center;
    }
  }

  &__try-again {
    margin-top: 56px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #74788d;

    div {
      color: #5d78ff;
      font-weight: 500;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
