.report-toolbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  width: 314px;
  height: calc(100vh - 76px);
  top: 76px;
  right: 0;
  background-color: #fff;

  &--scrollbar-space {
    right: 6px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    width: 100%;
    overflow: scroll;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      width: 0px;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__buttons {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;

    button {
      width: 100%;
      height: 46px;
      white-space: nowrap;
      overflow: hidden;
    }

    &-bottom {
      width: 100%;
      margin-top: 16px;
      display: flex;
      gap: 16px;
    }
  }

  &__header {
    color: #74788d;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px dashed #d3d5e1;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #8e93ac;

    &-item {
      display: flex;
      align-items: center;
    }

    .ws-react-select {
      width: 166px;
    }

    .ws-checkbox {
      margin-right: 8px;
    }

    &-list {
      &-item {
        color: #a7abc3;
        display: flex;
        margin-top: 16px;
      }
    }

    &-button {
      display: flex;
      align-items: center;
      background: white;
      border: none;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: #6a6d7c;
      height: 44px;
      margin-top: 16px;
      cursor: pointer;
      transition: all 0.2s ease;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 4px;

        rect {
          transition: all 0.2s ease;
        }
      }

      &:hover {
        svg rect {
          fill: #b4b6be;
        }

        color: #b4b6be;
      }
    }

    &--vertical {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;

      .ws-react-select-container,
      .ws-react-select {
        width: 100%;
      }
    }
  }

  &__draft {
    &-button {
      width: 100%;
    }

    &-date {
      width: 100%;
      text-align: right;
      color: #cacddb;
    }
  }

  &__metrics {
    margin-top: 40px;
  }

  &__metric-wrapper {
    padding: 16px 0;
    border-bottom: 1px dashed #d3d5e1;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #8e93ac;
    min-height: 72px;
  }

  &__cover-option {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #74788d;

    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      border-radius: 1.66667px;
    }

    &-text {
      width: 190px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
