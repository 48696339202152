.create-payment-modal {
  width: 600px;
  .modal__row {
    justify-content: flex-start;
    gap: 24px;
  }

  &__payment {
    font-size: 14px;
    color: #45485b;
    padding-bottom: 12px;
    margin-bottom: 8px;
    border-bottom: 1px solid #ededef;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__tags {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
  }

  &__input {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__payment-config {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-basis: 50%;
  }

  &__selectable-tag {
    padding: 4px;
    padding-left: 8px;
    border: 1px solid #dadade;
    border-radius: 8px;
    color: #45485b;
    font-size: 13px;
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    transition: all 0.2s;
    &--active {
      background-color: #ededef;
      border: 1px solid #ededef;
      svg {
        transform: rotate(45deg);
      }
    }
    &:hover {
      background-color: #ededef;
      border: 1px solid #ededef;
    }
  }
}
