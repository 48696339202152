.add-team {
  &__link {
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    color: #b4b6be;
    cursor: pointer;
  }

  input {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    color: #45485b;
    outline: none;
    border: none;
    padding: 0;

    &::placeholder {
      color: #dadade;
    }
  }
}
