.single-access-field {
  background-color: #fff;

  &__draggable-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-drag {
      width: 36px;
    }

    .popup__row {
      width: calc(100% - 36px);
    }

    .popup__col {
      width: unset !important;
    }
  }

  .popup__col {
    justify-content: start;

    &:first-child {
      .ws-react-select-container {
        width: clamp(300px, 300px, 345px);
      }
    }
  }
  .access-fields-popup__select {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .ws-switch {
    i {
      width: 32px;
      height: 20px;

      &::after {
        width: 14px;
        height: 14px;
        box-shadow: none;
        transform: translate3d(3px, 3px, 0);
      }
    }

    input:checked + i::after {
      transform: translate3d(15px, 3px, 0);
    }
  }
  &__text-area {
    overflow-y: auto;
    margin-top: 12px;
    height: 64px;
    max-width: 300px;
    &:focus,
    &:hover {
      outline: none;
    }

    &:focus-within {
      border: 1px solid rgba(93, 120, 255, 0.6);
    }
  }
}
