.custom-image-wrapper {
  position: relative;
  background-color: transparent;
}

.custom-image {
  left: 0;
  top: 0;
  transition: all 0.2s ease;
  background: white;

  &--hidden {
    opacity: 0;
  }

  &--new {
    z-index: 1;
    position: absolute;
  }
}
