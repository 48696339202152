.no-results-screen {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #45485b;
  gap: 48px;
  &__content {
    max-width: 480px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
  }
  &__subtitle {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    margin: 0;
    margin-top: 12px;
  }
  &__img {
    width: 100%;
    height: 100%;
    &-wrapper {
      max-width: 360px;
      max-height: 360px;
      width: 100%;
      height: 100%;
    }
  }
  &__bottom-content {
    margin-top: 24px;
    gap: 16px;
    display: flex;
    justify-content: center;
  }
}
