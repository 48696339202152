.profile-data-pane {
  .pane__container {
    padding-top: 0;
    border-top: none;
    border-bottom: none;
    height: 100%;

    &-wrap {
      border-bottom: none;
      height: 100%;
    }
  }

  .pane-top-bar__col--right {
    align-items: flex-end;
  }

  .loader-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__update-profile-data-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    color: #45485b;
    font-size: 13px;
    font-weight: 500;
    gap: 6px;
    &--hidden {
      opacity: 0;
    }
  }

  &__update-profile-data-icon {
    &--spin {
      animation-name: spin;
      animation-duration: 2000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
