.project-info {
  &__input {
    margin-left: 12px;
    color: #74788d;
    font-weight: 500;
    font-size: 17px;
    line-height: 32px;
    border: none;
    outline: none;
    padding: 0;
    width: 100%;
    transition: all 0.3s ease;

    &::placeholder {
      color: #d3d5e1;
    }
  }
}
