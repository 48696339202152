@import "src/styles/variables.scss";

.select-tags__selectable-labels__single {
  display: flex;
  align-items: center;
  gap: 4px;

  cursor: pointer;
  color: #45485b;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 4px;
  border: solid 1px #b4b6be;
  transition: background ease 0.1s;

  &__add {
    path {
      fill: #45485b;
    }
  }

  .ws-react-select-container {
    position: absolute;
    width: 160px;
    bottom: 0;
    transform: translate(-9px, 3px);
    margin: 0 !important;
    z-index: $z-index-dropdown;
  }

  &--active,
  &:hover {
    background: #ededef;
  }

  &--disabled {
    background-color: #ededef;
    color: #c8c9ce;
    pointer-events: none;

    svg {
      path {
        fill: #c8c9ce;
      }
    }
  }
}
