.page-not-found {
  display: flex;
  justify-content: center;
  gap: 72px;
  height: calc(100vh - 160px);

  &__col {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    align-items: center;

    &--left {
      width: 350px;
    }

    &--right {
      width: 360px;
    }

    &__header {
      font-weight: 500;
      font-size: 20px;
      color: #45485b;
    }

    &__sub {
      font-weight: 400;
      font-size: 13px;
      color: #45485b;
    }

    &__btn {
      margin-top: 12px;
    }
  }
}
