.rating-selector {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  &-item {
    height: 24px;
    width: 24px;
    padding: 3px;
    filter: drop-shadow(1px 1px 1px #ffffff);

    &--medium{
      height: 20px;
      width: 20px;
    }
    
    &--small {
      height: 16px;
      width: 16px;
    }


    &--empty {
      fill: #ededef;
    }

    &:not(.rating-selector-item--disabled) {
      cursor: pointer;
    }

    &:hover:not(.rating-selector-item--disabled, .rating-selector-item--selected) {
      fill: #b4b6be;

      ~ .rating-selector-item {
        fill: #b4b6be;
      }
    }
  }
}
