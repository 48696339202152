.add-button {
  border: 1px dashed #d3d5e1;
  display: grid;
  place-items: center;
  transition: all 0.2s ease;
  cursor: pointer;

  svg {
    width: 12px;
    height: 12px;

    path {
      fill: #d3d5e1;
      transition: all 0.2s ease;
    }
  }

  &--circle {
    padding: 6px;
    border-radius: 50%;
  }

  &--rect {
    padding: 6px 26px;
    border-radius: 6px;
  }

  &--square {
    padding: 6px;
    border-radius: 4px;
  }

  &:hover {
    border: 1px dashed #5d78ff;
    background-color: #dfe4ff;

    svg {
      path {
        fill: #5d78ff;
      }
    }
  }
}
