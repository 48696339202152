@import "../../../styles/mixins.scss";

.glide-data-grid {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__header {
    display: flex;
    flex-direction: column;
    padding-right: 48px;
    height: 100px;
    .page-description {
      margin: 0;
    }
  }

  &__tabs .tabs {
    margin-bottom: 0;
    border-bottom: 0;
  }

  &__toolbar {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
  }

  &__line {
    border-bottom: 1px solid #d3d5e1;
  }

  &__wrapper {
    height: 100%;
    overflow: hidden;
    padding-top: 46px;
    padding-left: 48px;
    padding-bottom: 68px;
    .no-results-screen {
      height: calc(100% - 146px);
    }
  }

  &__editor {
    width: 100%;
    height: calc(100% - 81px);
  }

  &__hovered-header {
    display: flex;
    position: absolute;
    align-items: center;
    pointer-events: none;
    padding-top: 1px;
    padding-left: 20px;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    color: #a7abc3;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__hovered-item {
    display: flex;
    position: absolute;
    align-items: center;
    pointer-events: none;
    padding-top: 1px;
    padding-left: 20px;
    color: #74788d;
    font-size: 13px;
    font-weight: 500;

    &--remove-with-metafield {
      display: flex;
      align-items: center;
      gap: 10px;
      pointer-events: auto;
      width: 100%;
      height: 100%;

      .value-input {
        all: unset;
        cursor: text;
        width: 100%;
      }

      .text-box {
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0;
        max-height: 80px;
        border-radius: 0;

        .public-DraftStyleDefault-block {
          line-height: 16px;
          min-height: 16px;
          max-height: 80px;
          outline: none;
        }
      }
    }

    &--read-only-text {
      pointer-events: auto;
    }

    &--clickable {
      pointer-events: auto;
      cursor: pointer;
      object-fit: cover;
    }

    &--non-clickable {
      pointer-events: none !important;
    }

    &--with-data {
      display: flex;
      align-items: center;

      .glide-data-grid__hovered-item-cover {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 12px;
      }
    }

    &--only-remove {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: transparent;
      width: 100%;
      height: 100%;
    }

    &-link-icon {
      pointer-events: auto;
      background-color: #fff;
      cursor: pointer;
      z-index: 1;
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }

    &-remove-icon {
      pointer-events: auto;
      background-color: #fff;
      box-shadow: 0 0 10px 10px #fff;
      cursor: pointer;
      &--transparent {
        background-color: transparent;
        box-shadow: none;
      }
    }

    &-empty-field {
      padding: 6px 26px;
      border: 1px dashed #d3d5e1;
      background-color: #fff;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: auto;
      cursor: pointer;
      svg {
        height: 12px;
        width: 12px;

        path {
          fill: #d3d5e1;
          transition: all 0.2s ease;
        }
      }
    }
    &-title-with-icons {
      line-height: 1;
      .social-profile-icons {
        margin: 6px 0 0 0;
        opacity: 0;
      }
      a {
        color: transparent;
      }
    }

    &--title-cell {
      width: 100%;
    }
  }

  &__date-picker {
    display: none;
  }

  &__dropdown {
    &-overlay {
      width: 100%;
      height: 100%;
    }
    &-date-picker {
      all: unset !important;
    }
  }

  // Search container
  .gdg-seveqep {
    box-shadow: 0px 8px 24px -4px rgba(16, 24, 40, 0.04),
      0px 20px 24px -4px rgba(16, 24, 40, 0.1);
    border: 1px solid #ededef;
    width: 362px;
    border-radius: 12px;
    padding: 16px;

    .gdg-search-bar-inner {
      align-items: center;
      input[data-testid="search-input"] {
        border: 2px solid #bec9ff;
        border-radius: 8px;
        width: 245px;
        padding: 10px 12px 10px 16px;
        flex-grow: 1;
      }
      button:not(:last-child) {
        width: 20px;
        height: 20px;
        background-color: #ededef;
        color: #74788d;
        &:disabled {
          color: #b4b6be;
        }
      }
      button:nth-child(2) {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        margin-right: 1px;
        margin-left: 8px;
      }
      button:nth-child(3) {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    .gdg-search-progress {
      display: none;
    }
  }

  &__file-loader-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding-top: 5px;
    width: 100%;
    height: 100%;
  }

  &__file-counter {
    display: flex;
    flex-direction: column;
    &-icon {
      margin-bottom: 15px;
    }
  }

  &__loader {
    position: relative;
    width: 80%;
    height: 6px;
    border-radius: 8px;
    background-color: #d3d5e1;

    &-progress {
      position: absolute;
      left: 0;
      width: 84px;
      height: 6px;
      border-radius: 8px;
      background-color: #47c4ab;
    }
  }
  
  &__empty-file-cell {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;
  }

  &__static-files-loading-cell {
    position: absolute;
    color: #74788d;
    font-size: 13px;
    font-weight: 500;
    background-color: #fff;
    pointer-events: none;
  }
}
