.dropdown-portal__menu {
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  gap: 0;
  background: white;

  border: 1px solid #ededef;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 24px -4px rgba(16, 24, 40, 0.04);
  border-radius: 8px;
}
