.single-select-filter-options {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__expander {
    &__toggle {
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: #5d78ff;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
