.select-dropdown {
  .dropdown__menu {
    width: 244px;
  }
}

.action-field-wrapper {
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;
    cursor: pointer;
  }
}

.action-field {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  gap: 8px;

  &-option {
    img,
    svg {
      margin-left: 0 !important;
    }
  }

  img,
  svg {
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }

  &--circle {
    img,
    svg {
      border-radius: 50%;
    }
    .action-field__placeholder {
      border-radius: 50%;
    }
  }

  &__image {
    width: 24px;
    height: 24px;
    background-size: cover;
    flex-shrink: 0;
  }

  &__placeholder {
    width: 24px;
    height: 24px;
    background-size: cover;
    flex-shrink: 0;
    margin-right: 6px;
    border-radius: 4px;
    overflow: hidden;
  }
}

.task__container .task-meta-fields__value .action-field svg {
  width: 12px;
  height: 12px;
}
