.fsc-modal {
  min-width: 612px;
  max-width: 612px;

  .modal__body {
    display: flex;
  }

  .modal__buttons {
    display: none;
    gap: 24px;
  }

  &__info {
    width: 240px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    &-new {
      padding: 4px 10px 4px 10px;
      gap: 4px;
      border-radius: 20px;
      background-color: #fdf1d6;
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      color: #dba53e;
    }

    &-title {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      color: #45485b;
    }

    &-description {
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      color: #45485b;
    }

    .ws-checkbox label {
      font-weight: 400;
      color: #45485b;

      &:hover {
        opacity: 1;
      }
    }

    .ws-button {
      border-color: #ededef;
    }
  }

  &__video video {
    width: 300px;
    height: 300px;
    border-radius: 12px;
    object-fit: cover;
  }
}
