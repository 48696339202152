.how-it-works-modal {
  max-width: 600px;
  &__slide-in {
    top: 50%;
    animation-name: slide-in;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
  }
  &__controls {
    display: flex;
    gap: 10px;
  }
  &__btn {
    padding: 12px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #8f919d;
    margin-bottom: 16px;
  }
  &__step-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    width: 44px;
    height: 24px;
    background: #ededef;
    border-radius: 6px;
    font-size: 12px;
    gap: 4px;
    span:nth-child(2) {
      color: #c6c7cd;
    }
  }

  &__content {
    &-img {
      max-height: 308px;
    }
    &-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #45485b;
      margin-bottom: 8px;
    }
    &-description {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #6a6d7c;
    }
  }

  .settings-tab-content__buttons::after {
    display: none;
  }

  .modal__buttons {
    margin-top: 0;
  }
}

@keyframes slide-in {
  0% {
    top: 100%;
    opacity: 0;
  }
  100% {
    top: 50%;
    opacity: 1;
  }
}
