.how-it-works {
  &__step-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #45485b;
  }

  &__link {
    cursor: pointer;
    font-weight: 600;
  }
}
