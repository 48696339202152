@import "../../../../styles/mixins.scss";

.add-content-proposal {
  &__caption {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &-switch {
      display: flex;
      gap: 8px;
      color: #45485b;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
    &-textarea {
      height: 76px;
      width: 100%;
      padding: 8px;
      resize: none;
      border: 1px solid #bec9ff;
      background-color: #f6f6f7;
      .DraftEditor-root {
        overflow-y: auto;
        @include custom-scrollbar;
      }
    }
  }

  &__buttons {
    display: flex;
    gap: 12px;
  }

  &__add-content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .text-box__attachments-wrapper {
      margin-top: 0;
    }

    .add-attachment-square {
      width: 100px;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      input[type="file"] {
        display: none;
      }
    }
  }
}

.replying-to-header {
  background: #f6f6f7;
  padding: 8px;
  border-radius: 12px;
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__replying-to {
    display: flex;
    gap: 4px;
    color: #6a6d7c;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;

    &--media {
      flex-direction: row;
      align-items: center;
    }

    &-text {
      color: #7d808d;
      font-size: 13px;
      font-style: italic;
      font-weight: 400;
      line-height: 20px;
      word-wrap: break-word;
      max-width: 100%;
    }
  }
}

.content-proposal-media-element-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  .attachment-wrapper {
    width: 40px;
  }
}

.add-links-modal {
  width: 600px;
  .modal__text {
    margin-top: 0;
  }
  &__textarea {
    resize: none;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    color: #45485b;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    padding: 14px 12px 14px 16px;
    outline: none;
    border: 1px solid #b4b6be;
    @include custom-scrollbar;
    &--not-empty {
      height: 120px;
    }
    &:focus {
      border: 1px solid rgba(93, 120, 255, 0.6);
    }
  }
}
