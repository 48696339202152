.idh-date-picker {
  position: relative;

  &__input {
    background: #fff;
    border: 1px solid #d3d5e1;
    border-radius: 8px;
    height: 40px;
    padding: 10px 32px 10px 16px;
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #74788d;
    font-family: "Poppins", sans-serif;
    transition: all 0.2s ease;
    cursor: pointer;
    caret-color: transparent;

    &::placeholder {
      color: #d3d5e1;
    }

    &--transparent {
      border-color: transparent !important;
      width: fit-content;
    }

    &--readOnly {
      cursor: not-allowed;
    }

    &:focus {
      outline: none;
      border-color: #5d78ff;
    }

    &--hidden {
      opacity: 0;
      visibility: hidden;
    }

    &--green {
      color: #47c4ab !important;
    }

    &--red {
      color: #fd397a !important;
    }
  }

  &__icon {
    display: none;
    position: absolute;
    width: 20px;
    height: 20px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent no-repeat center;
  }
}

html body .react-datepicker {
  font-family: "Poppins", sans-serif;
  min-height: 256px;
  width: 300px;
  background: #fff;
  box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: 0;
  padding: 22px 24px;
  filter: drop-shadow(0px 1px 50px rgba(0, 0, 0, 0.1));

  &__navigation {
    border: 0;
    top: 22px;
    width: 16px;
    height: 16px;
    background: transparent no-repeat center;
    opacity: 0.7;
    border-radius: 0.3rem;

    &:hover {
      opacity: 1;
      background-color: #f0f0f0;
    }

    &-icon {
      &::before {
        display: none;
      }
    }
  }

  &__navigation--previous {
    left: 24px;
    background-image: url("../../../images/arrow-left.svg");
  }

  &__navigation--next {
    right: 24px;
    background-image: url("../../../images/arrow-right.svg");
  }

  &__triangle {
    display: none;
  }

  &__month-container {
    float: none;
    width: 100%;
  }

  &__month {
    margin: 0;
  }

  &__current-month {
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #74788d;
    margin-bottom: 6px;
  }

  &__header {
    border-bottom: 1px solid #f2f2f6;
    background-color: transparent;
    padding: 0;
    height: 48px;
    margin-bottom: 1px;
  }

  &__day-names,
  &__week {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 4px;
  }

  &__day,
  &__day-name {
    height: 28px;
    width: 28px;
    font-size: 12px;
    color: #74788d;
    margin: 0;
    transition: all 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: center;

    &--disabled {
      color: #f2f2f6;

      &.react-datepicker__day--today:not(.react-datepicker__day--keyboard-selected):not(.react-datepicker__day--selected):not(.react-datepicker__day--in-range) {
        color: #74788d;
      }
    }

    &--keyboard-selected,
    &--selected,
    &--in-range {
      background-color: #f2f2f6;
      color: #74788d;
    }

    &--outside-month {
      color: #d3d5e1;
    }
  }

  &__day {
    margin: 2px 0;
    font-weight: 500;

    &:hover {
      background: #f2f2f6;
    }
  }

  &__day-name {
    font-weight: 500;
    font-size: 10px;
    line-height: 1.5;
    color: #5d78ff;
    margin-top: 0;
    margin-bottom: 0;
    height: 24px;
  }

  &__close-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;

    &::after {
      background-color: white;
      color: #d3d5e1;
      font-size: 21px;
      top: -4px;
    }
  }
}

.react-datepicker-popper {
  z-index: 1020 !important;
}

.date-picker-wrapper {
  position: relative;
  border: 1px dashed transparent;
  transition: all 0.2s ease;
  border-radius: 6px;

  &--empty {
    width: 66px;
    height: 26px;
    transition: all 0.2s ease;
    border: 1px dashed #d3d5e1;

    &:hover {
      border: 1px dashed #5d78ff;
      background-color: #dfe4ff;

      svg {
        path {
          fill: #5d78ff;
        }
      }
    }
  }

  &__plus {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    z-index: 5;
    pointer-events: none;
    transition: all 0.2s ease;

    path {
      fill: #d3d5e1;
      transition: all 0.2s ease;
    }
  }
}
