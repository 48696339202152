@import "src/styles/variables.scss";

.report-topbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 72px);
  left: 72px;
  height: 76px;
  padding: 16px 20px 16px 40px;
  box-shadow: inset 0px -1px 0px #e5e6ed;
  background-color: #fff;
  z-index: $z-index-navigation;

  &--scrollbar-space {
    width: calc(100% - 78px);
  }

  &__left {
    display: flex;
    align-items: center;

    &-cover {
      object-fit: cover;
      width: 40px;
      height: 40px;
      margin-right: 12px;
      border-radius: 3px;
    }

    &-title {
      color: #74788d;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__right {
    display: flex;

    button {
      margin-left: 20px;
    }

    .ws-button--white {
      color: #74788d;

      svg path {
        fill: #74788d;
      }
    }
  }
}
