@import "../../../styles/media-queries.scss";
@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.add-creators-modal {
  max-width: 600px;

  button {
    width: 160px;
  }
  .modal__title {
    margin: {
      bottom: 8px !important;
    }
  }
  .modal__text {
    line-height: 20px;
  }

  &--global {
    .suggestion--disabled {
      cursor: default;

      &:hover {
        background-color: white !important;
      }

      .suggestion-details__username-tick {
        visibility: visible !important;
      }
    }
  }

  .ws-textarea {
    max-height: 380px;
    overflow-y: auto;
    @include custom-scrollbar;
  }
}
