.publication {
  &__inline-sections {
    display: inline-grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
  }

  .published-dashed-box {
    height: 136px;
    display: flex;
    justify-content: center;
    align-items: center;

    .date-picker-wrapper {
      position: relative;
      border: none;
      margin: 0;

      &--no-hover {
        .dropdown-value-wrapper:hover {
          background: transparent;
        }
      }

      .idh-date-picker__input {
        border: 1px dashed #d3d5e1;
        transition: none;
        height: 26px;
        font-size: 18px;
        text-align: center;

        &--transparent {
          width: inherit;
        }
      }

      &:hover {
        .idh-date-picker__input {
          border: 1px dashed #5d78ff;
        }

        .date-picker-wrapper__plus {
          path {
            fill: #5d78ff;
          }
        }
      }
    }
  }

  .info-box {
    padding: 32px;

    &__text {
      word-break: break-word;
      overflow-y: auto;
      height: 70px;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #74788d;
      padding-right: 4px;

      a {
        color: #5d78ff;
        &:hover {
          text-decoration: underline;
        }
      }

      &::-webkit-scrollbar {
        background-color: #f0f0f0;
        border-radius: 4px;
      }
    }
  }
}
