.access-link-page__block {
  background: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #dadade;

  width: 673px;
  border-radius: 16px;
  padding: 24px;
  max-width: 95vw;
}
