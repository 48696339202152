@import "../../../../../../styles/media-queries.scss";
@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.recruitment-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__content {
    gap: 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__general-wrapper {
    overflow: auto;
    max-height: 150px;
    padding-right: 4px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @include custom-scrollbar;

    @include min-screen($desktopWidth) {
      max-height: 190px;
    }

    @include min-screen($desktopLargeWidth) {
      max-height: 100%;
    }
  }

  &__share-wrapper {
    overflow: auto;
    max-height: 130px;
    padding-right: 4px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    > .modal__column {
      gap: 28px;
    }

    @include custom-scrollbar;
    @include min-screen($desktopLargeWidth) {
      max-height: 530px;
    }
    > .extensions__item {
      border-bottom: 0;
    }
  }

  .tab {
    padding: 13px 20px;
  }

  .tabs {
    margin-bottom: 0;
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #45485b;
    margin-bottom: 8px;
  }

  &__description {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #45485b;
    gap: 12px;
  }

  .ws-input {
    width: 100%;
  }

  &__text-area {
    height: 104px;
    max-width: 592px;
    &:focus,
    &:hover {
      outline: none;
    }

    &:focus-within {
      border: 1px solid rgba(93, 120, 255, 0.6);
    }
  }

  .text-box {
    padding: 12px 12px 12px 16px;
    border-radius: 8px;
    overflow: auto;
    color: #45485b;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
  }

  &__buttons {
    padding-top: 16px;
    border-top: 1px solid #ededef;
    display: flex;
    justify-content: space-between;
  }

  &__droppable-wrapper {
    &--dragging {
      padding-bottom: 167px;
    }
  }

  &__access-fields {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    height: 100%;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;

    .single-access-field__text-area {
      max-width: 100%;
    }

    @include custom-scrollbar;

    @include min-screen($desktopWidth) {
      max-height: 255px;
    }

    @include min-screen($desktopLargeWidth) {
      max-height: 405px;
    }

    .popup__row {
      gap: 32px;
      margin-bottom: 24px;
    }
    .popup__row .popup__col {
      width: auto;
      .ws-react-select-container {
        max-width: 439px;
        width: 100% !important;
      }
      &:first-child {
        flex: 1;
      }
    }

    &-extra-option-btn {
      display: flex;
      gap: 10px;
      align-items: center;
      color: #5d78ff;

      padding: 14px 16px;
      cursor: pointer;
      border-top: solid 1px #ededef;
      border-radius: 0 0 8px 8px;
      transition: all ease-in 0.2s;

      &:hover {
        background-color: #eff1ff;
      }
    }
    &-extra-option {
      opacity: 1 !important;
      cursor: pointer !important;
    }
  }

  &__cover-photo-wrapper {
    display: flex;
    align-items: center;
  }

  &__image {
    position: relative;
    display: grid;
    place-items: center;
    width: 48px;
    height: 48px;
    background-color: #f2f2f6;
    cursor: pointer;
    &:hover .recruitment-form__image-icon {
      opacity: 1;
    }
  }

  &__image-cover {
    position: absolute;
    pointer-events: none;
    width: 48px;
    height: 48px;
    z-index: 0;
    object-fit: cover;
    object-position: center;
  }

  &__image-file {
    cursor: pointer;
    z-index: 1;
  }

  &__image-icon {
    width: 48px;
    height: 48px;
    background-color: rgba(255, 255, 255, 0.7);
    border: dashed 1px #5d78ff;
    display: grid;
    place-items: center;
    opacity: 0;
    transition: all 0.3s ease;
    border-radius: 4px;
  }

  &__cover-photo-controls {
    display: flex;
    gap: 8px;
    margin-left: 16px;
    button {
      all: unset;
      cursor: pointer;
      color: #6a6d7c;
      font-weight: 500;
      font-size: 13px;
      &:hover {
        opacity: 0.7;
      }
    }
    > span {
      color: #dadade;
      font-size: 14px;
    }
  }

  &__regenerate-info {
    display: flex;
    gap: 8px;
    font-size: 12px;
    line-height: 18px;
    color: #8f919d;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .order-number {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    width: 24px;
    height: 24px;
    background: #ededef;
    border-radius: 6px;
  }

  &__item-wrapper {
    display: flex;
    gap: 8px;
  }

  &__switch {
    width: 100px;
    gap: 8px;
  }
}
