.add-elements-to-report-dropdown {
  &__button {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #5d78ff;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }
}
