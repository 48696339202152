.ws-radio {
  &--disabled {
    label,
    input {
      opacity: 0.7;
      pointer-events: none;
    }
  }

  input[type="radio"] {
    position: absolute;
    left: -9999px;

    &:checked + label {
      &::before {
        border: 1px solid #5d78ff;
        background-color: #eff1ff;
      }
      &::after {
        display: block;
      }
    }
  }

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #74788d;
    padding-left: 28px;
    position: relative;
    display: inline-block;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      border: 1px solid #dadade;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      box-sizing: border-box;
    }

    &::after {
      content: "";
      display: none;
      position: absolute;
      top: 6px;
      left: 6px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #5d78ff;
      box-sizing: border-box;
    }
  }
}
