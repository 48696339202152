@import "src/styles/media-queries.scss";
@import "src/styles/variables.scss";

.empty-teams {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 164px;
}
