.glide-data-grid__tooltip {
  position: absolute;
  max-width: 190px;
  background: #45485b;
  color: #dadade;
  box-shadow: 0px 1px 15px rgba(69, 65, 78, 0.2);
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  transition: opacity 0.2s ease;
  padding: 8px;
  word-break: break-word;

  &--header {
    max-width: unset;
  }

  &::before {
    content: url("../../../../../images/rounded-triangle.svg");
    position: absolute;
    width: 12px;
    height: 13px;
    border-color: transparent transparent #fff transparent;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 10px;
  }
}

.glide-data-grid__tooltip[data-popper-placement^="bottom"] {
  &::before {
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
  }
  &::after {
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.glide-data-grid__tooltip[data-popper-placement^="top"] {
  &::before {
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
  }
  &::after {
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
}
