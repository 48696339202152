.add-comment-field {
  position: relative;
  display: flex;
  width: 716px;

  &--focus {
    border: 2px solid #bec9ff;
    border-radius: 16px;
  }

  &__textarea {
    display: flex;
    flex-direction: row !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    border: none;
    min-height: 40px;
    border-radius: 16px;
    border: 1px solid #d3d5e1;
    caret-color: #74788d;
    outline: none;

    &--absolute {
      position: absolute;
      pointer-events: none;
      color: #74788d;
    }

    &:hover,
    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #d3d5e1;
    }

    &--focus {
      flex-direction: column !important;
      border: none;
      padding: 12px;
      min-height: 92px;

      .text-box__buttons {
        margin-top: 12px;
      }
    }
  }

  .expanding-textbox:empty::before {
    padding: {
      left: 8px;
      top: 4px;
    }
  }

  &__toolbar {
    display: flex;
    align-items: flex-end;
    position: absolute;
    right: 12px;
    bottom: 9px;

    &--focus {
      position: static;
      justify-content: space-between;
      margin: 11px;
    }

    svg {
      margin-left: 5px;

      &:hover {
        cursor: pointer;
        path {
          fill: #536ceb;
        }
      }
    }
  }

  &__comment-btn {
    &--disabled {
      opacity: 0.7;
      pointer-events: none;
    }
  }

  .attachment-file__name {
    max-width: 156px;
  }
}