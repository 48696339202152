.dictionary-metadata {
  margin: 24px 0;

  &__row {
    display: flex;
    align-items: center;
    height: 32px;
    margin-bottom: 8px;

    &--high {
      height: unset;
      align-items: flex-start;
      min-height: 32px;

      .task-meta-fields__name {
        margin-top: 8px;
      }
    }
  }

  &__show-more {
    color: #5d78ff;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      color: #7d93ff;
    }
  }
}
