.comment-list {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__item {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 4px;

    &-author {
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      color: #45485b;
    }

    &-text {
      display: block;
      font-size: 13px;
      font-style: italic;
      font-weight: 400;
      line-height: 20px;
      color: #6a6d7c;
      border-radius: 8px;
      padding: 16px;
      background: #f6f6f7;
      white-space: pre-wrap;
    }
  }
}

.empty-comments-list {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #6a6d7c;
  padding-bottom: 48px;

  svg {
    width: 20px;
    height: 20px;
  }
}
