.breadcrumbs {
  display: flex;
  flex-wrap: wrap;

  a:hover {
    color: #536ceb;
  }

  &__item {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #d3d5e1;
    transition: all 0.3s ease;

    &--disabled {
      pointer-events: none;
    }
  }

  & > div {
    &:not(:last-of-type) {
      &::after {
        content: "/";
        margin: 0 16px;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        color: #d3d5e1;
      }
    }
  }
}
