.rc-dropdown {
  font-family: "Poppins", sans-serif !important;
  &-placement {
    &-topRight {
      .dropdown__menu {
        right: 0 !important;
        left: auto !important;
      }
    }
  }
}
