.trial-exceeded-modal {
  &__check {
    color: #5d78ff;
    cursor: pointer;

    &:hover {
      color: #7d93ff;
    }
  }
}
