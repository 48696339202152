.select-tags__selectable-labels {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  max-height: 122px;
  overflow-y: auto;
  padding-right: 8px;

  &--lock-scroll {
    overflow-y: hidden;
    padding-right: 13px;
  }

  &:empty {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #ededef;
  }

  &::-webkit-scrollbar-thumb {
    background: #8f919d;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #6e6f77;
  }

  &:empty {
    display: none;
  }
}
