.report-template {
  position: relative;
  background-color: white;
  min-height: 1500px;
  // overflow: hidden;
  -webkit-print-color-adjust: exact;

  &--loading {
    &::before {
      opacity: 0;
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 580px;
    background-color: #5d78ff;
    left: 0;
    top: 0;
  }

  &__separator {
    display: flex;
    align-items: center;
    width: 100%;
    border: none !important;
    border-bottom: dashed 1px;
    padding: 0 !important;
    z-index: 0 !important;

    &-line {
      position: absolute;
      width: 900px;
      width: calc(100% + 66px);
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      border-bottom: dashed 1px #9eaeff;

      &--hidden {
        opacity: 0;
      }

      &::before {
        content: "";
        position: absolute;
        top: -3px;
        width: 0;
        height: 0;
        left: -6px;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-left: 6px solid #9eaeff;
      }

      &::after {
        content: "";
        position: absolute;
        top: -3px;
        width: 0;
        height: 0;
        right: -6px;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-right: 6px solid #9eaeff;
      }
    }

    &-provider {
      position: absolute;
      display: flex;
      align-items: center;
      top: calc(50% + 12px);
      color: #74788d;
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;

      svg,
      img {
        object-fit: cover;
        width: 20px;
        height: 20px;
        margin-right: 8px;
        border-radius: 2px;
      }
    }

    &-page {
      position: absolute;
      right: 0;
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;
      color: #d3d5e1;
      top: calc(50% - 24px);

      &-number {
        color: #74788d;
      }
    }

    .react-resizable-handle {
      display: none;
    }
  }
}
