.brand-safety {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;

  top: 50px;
  right: 0px;
  z-index: 160;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-width: 402px;
  margin-right: 0 !important;

  &__title {
    color: #45485b;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
  }

  &__scanner {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #74788d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px dashed #d3d5e1;

    &__label {
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }

  &__post-check {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #74788d;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__label {
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }

  &__tooltip-list {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 12px;

    svg {
      flex-shrink: 0;
    }
  }

  &__tooltip-list-title {
    color: white;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  }

  &__tooltip-list-item {
    display: flex;
  }

  &__tooltip {
    text-align: left !important;
    border-radius: 4px !important;
    width: 169px;

    span > :not(.brand-safety-item__tooltip-header) {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
    }
  }
}

.brand-safety-item {
  &__tooltip {
    &-header {
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: white;
      margin-bottom: 4px;

      &--yellow {
        color: #e89437;
      }

      &--red {
        color: #d93025;
      }
    }

    &-button {
      display: block;
      margin-top: 8px;
      color: #5d78ff;
      cursor: pointer;
    }
  }

  &__passed {
    color: #2abb9e;
  }

  &__detected {
    color: #ffb822;
  }

  &__counter-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 8px;
  }

  &__count {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #74788d;
  }
}
