.settings-trial-dropdown {
  display: flex;

  &--trial-visible {
    .dropdown__menu-item:first-of-type {
      background: #fff8e9;
      color: #d29005;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ededef;
    border-radius: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #6a6d7c;
    padding: 7px 8px;
    margin-right: 12px;
    cursor: pointer;

    &--yellow {
      color: #d29005;
      background: #ffeabd;
    }

    &--red {
      color: #d93025;
      background: #f7d6d3;
    }
  }

  .dropdown__menu {
    min-width: 240px;
    z-index: 1005;
  }
}
