.popup {
  max-width: 90vw;
  max-height: 95vh;
  border-radius: 8px;
  padding: 32px;
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background-color: #fff;
  flex-direction: column;
  display: flex;
  transition: all 0.3s ease;
  box-shadow: 0px 20px 24px -4px #1018281a;

  border: solid 1px #ededef;

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #6a6d7c;
    margin-bottom: 16px;
  }

  &__paragraph {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #6a6d7c;
  }

  &__buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .ws-button {
      min-width: 150px;
    }
  }

  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .row {
    display: flex;
    gap: 20px;
  }

  .col {
    width: 100%;
  }

  &__section--title {
    gap: 0 !important;
  }

  &--loading {
    height: 700px;
    justify-content: space-around;
    align-items: center;
  }
}
