.projects-list {
  z-index: 10;

  &__menu-button {
    width: 44px;
    height: 44px;
    padding: 0;

    svg {
      margin: 0;

      path {
        fill: #5d78ff;
      }
    }
  }

  &__customize-button {
    display: flex;
    align-items: center;
  }
}

.project-menu-dropdown {
  svg {
    width: 20px;
    height: 20px;
  }
}

.projects-list-sticky {
  position: sticky;
  left: 0;
}
