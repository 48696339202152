.add-coupons-modal {
  max-width: 600px;
  min-width: 600px;

  &__input-wrapper {
    margin-top: 20px;
  }

  .token-input-container {
    min-height: 46px;
    max-height: 300px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      border-radius: 2px;
      background-color: #e0e2ea;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #a7abc3;
      border-radius: 30px;
    }
  }
}
