.share-project-modal {
  width: 600px;

  .ws-react-select {
    &__control {
      min-height: 48px !important;
    }

    &__height {
      height: 48px;
      top: -48px;
    }

    &__dropdown-indicator.ws-react-select__indicator {
      margin: 14px 14px 0 4px !important;
    }

    &__placeholder {
      top: 15px;
    }

    &__container {
      margin-bottom: 48px;
    }

    &__menu {
      &::before {
        top: -47px !important;
      }
      &-list {
        padding-bottom: 0;
      }
    }
    .image-select-option.create-project svg {
      margin-right: 0;
    }

    .create-project-wrap {
      padding: 14px 16px;
      cursor: pointer;
      border-top: solid 1px #ededef;
      border-radius: 0 0 8px 8px;
      transition: all ease-in .2s;

      &:hover {
        background-color: #eff1ff;
      }
    }
  }

  .modal__title {
    margin-bottom: 16px;
  }

  &__bar {
    display: flex;
    margin-bottom: 16px;

    &-button {
      position: relative;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #8e93ac;
      cursor: pointer;
      height: 20px;
      transition: all 0.2s ease;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        bottom: -1px;
        background-color: #5d78ff;
        transition: all 0.2s ease;
        opacity: 0;
      }

      &--active {
        color: #5d78ff;

        &::after {
          opacity: 1;
        }
      }

      &:not(:first-of-type) {
        margin-left: 24px;
      }
    }
  }

  &__members {
    border-bottom: dotted 2px #d3d5e1;
    overflow-y: auto;
    max-height: 300px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      border-radius: 2px 0;
      width: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #b8d1fe;
      border-radius: 2px;
      width: 5px;
    }

    &-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      margin-bottom: 20px;

      &-left {
        display: flex;
      }

      &-avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 12px;
        object-fit: cover;
      }

      &-right {
        display: flex;
        align-items: center;
      }

      &-trashcan {
        cursor: pointer;
      }

      &-info {
        display: flex;
        flex-direction: column;
      }

      &-name {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #74788d;
      }

      &-email {
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        color: #a7abc3;
      }
    }
  }

  .ws-react-select-container,
  .ws-react-select {
    width: 536px !important;
  }

  &__copy-values-checkbox{
    margin-top: 10px;
  }
}
