.loader-wrapper {
  width: 170px;
  height: 130px;
  margin: 0 auto;

  svg {
    path {
      fill: #5d78ff;
    }
  }

  &--grey {
    svg {
      path {
        fill: #d3d5e1;
      }
    }
  }
}

.loader-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.loader-text {
  margin: 0 auto;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #75767f;
}