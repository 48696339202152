.publications-detection {
  width: 100%;

  &__link {
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    color: #b4b6be;
    cursor: pointer;
  }

  &__row {
    margin-top: 16px;
    min-height: 70px;

    .ws-react-select {
      &-wrapper {
        height: 48px;
      }

      &__control {
        min-height: 48px;
      }

      &__menu::before {
        top: -47px;
      }

      &__placeholder {
        top: 15px;
      }

      &__indicator {
        margin-top: 14px;
      }
    }
  }

  &__keywords-row {
    display: flex;
    width: 100%;
    align-items: start;
    justify-content: space-between;
    gap: 20px;

    .token-input-container {
      width: 100%;
    }

    &-btn {

    }
  }

  &__aggregation {
    &-row {
      display: flex;
      align-items: start;
      justify-content: space-between;
      width: 100%;
      gap: 20px;
    }

    &-icon {
      width: 20px;
      height: 20px;
    }

    &-check-icon {
      width: 20px;
      height: 20px;
    }

    &-text {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 5px;
      width: 100%;

      &-title {
        display: flex;
        align-items: start;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #45485B;
        gap: 10px;

        svg{
          fill: #5D78FF;
        }
      }

      &-description {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: #8F919D;
      }
    }
  }

  .modal__title {
    display: flex;
    gap: 16px;
  }

  .modal__buttons {
    display: none;
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;

    .loader-wrapper {
      display: flex;
      align-items: center;
      height: 46px;
      width: 48px;
      margin: 0 24px;
    }

    &-done {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: #7d808d;
      padding: 12px;
      margin-left: 8px;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 4px;

        path {
          fill: #47c4ab;
        }
      }
    }

    &-back {
      padding: 12px;
      width: 100px;

      svg {
        margin-right: 4px;

        path {
          fill: #7d808d;
        }
      }
    }

    &-wrapper {
      display: flex;
      align-items: center;

      .ws-button {
        padding: 12px;
      }

      &--multiple {
        .ws-button {
          margin-left: 8px;
        }
      }
    }
  }
}
