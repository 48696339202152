.progress-bar {
  height: 6px;
  width: 100%;
  border-radius: 10px;
  position: relative;
  background-color: #e5e6ed;
  overflow: hidden;

  &--with-separator {
    &::after {
      content: "";
      position: absolute;
      top: -2px;
      left: -2px;
      height: 6px;
      width: 66.6666%;
      border-radius: 10px;
      border: 2px solid #fff;
      box-sizing: content-box;
    }
  }

  &__color {
    border-radius: 10px;
    height: 6px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #5ec7eb;
  }
}
