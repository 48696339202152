.access-link-field {
  padding-top: 16px;
  border-top: dashed 1px #cacddb;

  &__row {
    display: flex;
  }

  &__paragraph {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #6a6d7c;
  }

  &__options {
    width: 100%;
  }

  &__option {
    padding: 18px 0;

    &__text {
      display: flex;
      justify-content: space-between;

      &-left {
        display: flex;
        cursor: pointer;
        gap: 8px;
        color: #5d78ff;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        align-items: center;
      }

      &-right {
        display: flex;
        gap: 22px;
        justify-content: flex-end;
        align-items: center;
        font-weight: 500;
        font-size: 13px;
        color: #6a6d7c;
        line-height: 20px;
      }
    }

    &__trash {
      cursor: pointer;
      &:hover {
        path {
          fill: #63646a;
        }
      }
    }

    .tooltip-portal {
      width: 20px;
      height: 20px;
    }
  }
}
