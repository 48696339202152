.filter-button {
  margin-left: auto;
  border-radius: 8px;

  &--active,
  &:hover {
    background-color: #f2f2f6;
    opacity: 1 !important;
  }

  button {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color: #45485b;

    svg {
      margin-right: 4px;
    }
  }
}
