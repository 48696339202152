@import "../../../../../styles/mixins.scss";

.earned-media-value {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  height: 247px;
  padding-right: 16px;
  @include custom-scrollbar;
  &__info {
    border-radius: 8px;
    background-color: #f6f6f7;
    font-size: 13px;
    color: #6a6d7c;
    display: flex;
    gap: 12px;
    padding: 12px;
    line-height: 20px;
    span {
      flex-basis: calc(100% - 20px);
    }
  }
  &__label-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  &__restore-button {
    all: unset;
    cursor: pointer;
  }
  &__monetary-values {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-bottom: 16px;
    border-bottom: 1px solid #ededef;
  }
  &__title {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #45485b;
  }
  &__description {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #8f919d;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ws-input {
        width: 288px;
      }
    }
  }
  &__tooltip {
    width: 135px;
  }
}
