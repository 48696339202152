@import "src/styles/mixins.scss";

.creator-message-pane {
  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .task__container {
      border: none;
    }
  }

  &__topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #ededef;

    &-title {
      font-size: 20px;
      font-weight: 400;
      color: #45485b;
    }

    &-buttons {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &-close-icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__manage-emails {
    padding: 20px;
    height: 100%;
    max-height: calc(100% - 87px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__content-wrapper {
    flex-grow: 1;
  }

  &__info {
    display: flex;
    gap: 8px;
    background-color: #ecf1fa;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  &__info-box {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex-basis: calc(100% - 24px);
  }

  &__info-title {
    color: #3e6ecc;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  &__info-text {
    font-size: 12px;
    color: #45485b;
    line-height: 18px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__header {
    height: 44px;
    display: flex;
    align-items: center;
    span {
      font-size: 10px;
      color: #a1a3ad;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &__emails-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .value-field {
      display: flex;
      align-items: center;
      gap: 6px;

      &:hover .value-field__icon--visible {
        visibility: visible;
      }
    }
    .value-field__icon {
      cursor: pointer;
      visibility: hidden;
    }
  }

  &__cols {
    display: flex;
    > div {
      width: 50%;
    }
    .value-field {
      max-width: 340px;
      word-break: break-all;
    }
  }

  .avatar-and-name {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 500;
    color: #45485b;
    font-size: 14px;
  }

  .avatar-and-name__avatar-wrapper,
  img {
    border-radius: 50%;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__footer {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    border-top: 1px solid #ededef;
    padding: 20px;
    .ws-button {
      min-width: 75px;
    }
  }

  &__email {
    &-header {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 20px;
    }
    &-row {
      display: flex;
      align-items: flex-start;
      color: #45485b;
      font-size: 13px;
      gap: 8px;
      .ws-input-label {
        width: 64px;
        text-transform: capitalize;
      }
      .ws-input {
        width: 100%;
      }
    }
    &-label {
      color: #7d808d;
      font-size: 12px;
    }
    &-body {
      display: flex;
      flex-direction: column;
      height: 100%;
      .text-box {
        padding: 20px;
      }
      position: relative;
    }
    &-body-resize-btn {
      background-color: #ededef;
      border-radius: 4px;
      width: 24px;
      height: 24px;
      position: absolute;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
    }
    &-textarea {
      width: 100%;
      outline: none;
      border: none;
      flex-grow: 1;
      overflow: auto;
      @include custom-scrollbar;
    }
    &-checkbox-wrapper {
      display: flex;
      align-items: center;
      gap: 24px;
    }
    &-checkbox {
      display: flex;
      align-items: center;
      gap: 20px;
      .ws-checkbox label {
        font-size: 12px;
        color: #6a6d7c;
      }
    }
    &-from-box {
      min-height: 40px;
      max-height: 100px;
      border: 1px solid #b4b6be;
      padding: 10px 12px;
      border-radius: 8px;
      width: 100%;
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      @include custom-scrollbar;
      overflow-y: auto;
    }
    &-gray-label {
      display: flex;
      gap: 2px;
      background-color: #ededef;
      color: #45485b;
      font-size: 13px;
      padding: 4px;
      border-radius: 8px;
      button {
        display: flex;
        align-items: center;
      }
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  &__email-address {
    color: #45485b;
    font-size: 13px;
    display: flex;
    align-items: center;
    word-break: break-all;
  }
}

.select__menu.email__dropdown-menu {
  width: auto;
}

.email__dropdown-item .select__label {
  display: flex;
  gap: 4px;
  span span {
    display: flex;
    align-items: center;
  }
}
