@import "../../../styles/media-queries.scss";
@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.customize-fields-dropdown {
  .dropdown__menu {
    width: 318px;
    cursor: unset !important;
    padding: 20px;
  }

  &__tab-name {
    color: #a7abc3;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 4px;
  }

  &__subheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #74788d;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    button {
      height: fit-content !important;
    }
  }

  .dropdown__menu-separator {
    margin-bottom: 8px;
  }
}

.field-switch-list {
  overflow-y: auto;
  max-height: 200px;
  padding-right: 4px;

  @include min-screen-height(900px) {
    max-height: 338px;
  }

  @include custom-scrollbar;
}
