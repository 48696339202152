.dotted-box {
  position: relative;
  z-index: 20;
  border: 1px dashed #d3d5e1;
  background-color: #fff;
  border-radius: 12px;
  padding: 24px;
  transition: box-shadow 0.3s;
  transition-timing-function: cubic-bezier(0, 0.01, 0, 0.87);
}
