.section-header {
  display: flex;
  align-items: center;
  height: 100%;

  &__number {
    width: 36px;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #cacddb;
    margin-left: 6px;
  }

  &__text {
    display: flex;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #74788d;
    border: none;
    outline: none;
    width: 100%;
  }

  &--small .section-header {
    &__number {
      font-size: 16px;
      line-height: 24px;
      margin-left: 0;
    }

    &__text {
      font-size: 16px;
      line-height: 24px;
      margin-left: 0;
    }
  }
}
