.access-link-metafield {
  display: flex;
  align-items: center;
  gap: 8px;

  .switch-button {
    margin-left: 0;
  }

  &__copy {
    height: 26px;
    width: 26px;

    display: flex;
    justify-content: space-around;
    align-items: center;

    border-radius: 100%;

    cursor: pointer;
    transition: all 0.15s ease-in-out;

    path {
      fill: #6a6d7c;
      transition: all 0.15s ease-in-out;
    }

    &--done {
      background: #daf3ee;

      path {
        fill: #47c4ab;
      }
    }
  }

  .dropdown--open {
    svg path {
      fill: #6a6d7c;
    }
  }

  &__dropdown {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg path {
      transition: fill ease 0.15s;
    }

    &:hover {
      svg path {
        fill: #6a6d7c;
      }
    }

    &-menu {
      position: absolute;
      transform: translateX(calc(-50% + 10px));
      margin-top: 6px;
      z-index: 1;

      .dropdown__menu-item {
        color: #45485b;

        &--done {
          background: #daf3ee;
        }
      }
    }
  }

  &__regenerate {
    display: flex;
    align-items: center;
    &-icon {
      margin-right: 8px;
    }
  }
}
