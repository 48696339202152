.copy-value-input {
  position: relative;
  .ws-input {
    width: 481px;
  }

  &__share-btn {
    position: absolute;
    border-radius: 0px 8px 8px 0px;
    top: 0;
    right: 0;
    height: 40px;
    width: 127px;
  }
}
