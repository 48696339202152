.glide-data-grid-header {
  position: absolute;

  &__title {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  &__arrow {
    height: 20px;
    min-width: 18px;
    margin-right: 6px;
  }

  &__arrow-desc {
    transform: rotateX(180deg);
  }
}
