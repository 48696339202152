.check {
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  padding: 1.34px;

  &__background {
    width: 13.33px;
    height: 13.33px;
    background: rgba(#d3d5e1, 0.3);
    border-radius: 50%;
    transition: all 0.3s ease;
  }

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;

    path {
      fill: #a7abc3;
      transition: all 0.3s ease;
    }
  }

  &--active {
    .check__background {
      background: rgba(#47c4ab, 0.3);
    }

    svg {
      opacity: 1;

      path {
        fill: #47c4ab;
      }
    }
  }

  &--big {
    width: 20px;
    height: 20px;

    .check__background {
      width: 16px;
      height: 16px;
    }
  }

  &--large {
    width: 48px;
    height: 48px;

    svg {
      width: 32px;
      height: 32px;
    }

    .check__background {
      width: 48px;
      height: 48px;
    }
  }
}
