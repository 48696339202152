@import "src/styles/mixins.scss";

.access-fields-popup {
  &__select {
    width: 100%;
  }

  .ws-react-select {
    &-container {
      margin-top: 4px;
    }

    &__single-value {
      text-transform: capitalize;
    }
    &__option {
      text-transform: capitalize;
    }
  }

  &__trash {
    align-self: center;
    margin-top: 28px;
    margin-right: 4px;
    cursor: pointer;
    flex-shrink: 0;

    &--disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:not(.access-fields-popup__trash--disabled):hover path {
      fill: #63646a;
    }
  }
}

.access-fields {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - 570px);
  overflow-y: auto;
  overflow-x: hidden;
  @include custom-scrollbar;

  .popup__row {
    margin-bottom: 20px;
  }
}

.dd-active .access-fields {
  pointer-events: none;
}
