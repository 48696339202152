.access-link-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  gap: 16px;

  &__spinner {
    margin: 0 auto;
  }

  &--single {
    min-height: 100vh;
    justify-content: space-around;
  }
}

body.external-page .App {
  background-color: #ededef;

  &__main-container {
    min-height: 100vh;
    height: 100%;
    margin-top: 0;
    margin-left: 0;
  }
}
