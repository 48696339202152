.token-input-container {
  min-height: 48px;
  height: fit-content;
  border-radius: 8px;
  border: 1px solid #b4b6be;
  outline: none;
  padding: 6px;
  padding-bottom: 2px;
  color: #8e93ac;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  transition: all 0.2s ease;

  &--focused {
    border: 1px solid rgba(93, 120, 255, 0.6) !important;
  }
}

.token-input-token {
  background: #ededef !important;
  border-radius: 4px;
  color: #6a6d7c !important;

  &__delete-button {
    border-left: none !important;
  }
}

.token-input-autosized-wrapper input {
  padding-left: 12px !important;

  &::placeholder {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #dadade !important;
  }
}
