.comment {
  &__wrapper {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__avatar {
    width: 32px;
    height: 32px;
    border-radius: 80px;
    object-fit: cover;
  }

  &__block {
    margin-left: 8px;
    width: calc(100% - 38px);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    text-wrap: nowrap;

    &-left {
      display: flex;
      align-items: center;
      overflow: hidden;
      gap: 4px;

      color: #45485b;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
    }

    &-right {
      display: flex;
      align-items: center;
    }
  }

  &__author {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__creation-date {
    color: #a1a3ad;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    position: relative;
    &--tooltip {
      position: absolute;
      top: -3px;
      right: -16px;
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 8px;
    max-width: 100%;

    .attachment-file--in-comment {
      padding: 16px 0;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #74788d;
    white-space: pre-wrap;
    width: 100%;
    max-width: 100%;
    word-wrap: break-word;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  &__like {
    cursor: pointer;
  }

  &__more {
    width: 20px;
    height: 20px;
    margin-left: 12px;
    cursor: pointer;
  }

  &__reply-btn {
    all: unset;
    cursor: pointer;
    color: #45485b;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.more-comments {
  margin-bottom: 20px;
  margin-top: 20px;
  color: #5d78ff;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  cursor: pointer;
}
