.ws-skeleton {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: rgba(#a7abc3, 0.2);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: wave-animation 1s infinite;
  }

  &--circle {
    border-radius: 50%;
  }

  &--text {
    border-radius: 4px;
  }

  &--rect {
    border-radius: 0px;
  }

  @keyframes wave-animation {
    100% {
      transform: translateX(100%);
    }
  }
}
