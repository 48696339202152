.cover-box {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__wrapper {
    // position: relative;
    width: fit-content;
    height: 794px;

    &-image {
      left: 0;
      top: 0;
      width: 100%;
      height: 794px;
      object-fit: cover;
    }
  }

  img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  &__creator {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 54px;
    color: #74788d;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;

    &-avatar {
      width: 32px !important;
      height: 32px !important;
      border-radius: 50%;
      margin-right: 12px;
    }

    .social-profile-icons {
      margin: {
        top: 0;
        left: 12px;
      }
      &__icon {
        width: 24px;
        height: 24px;
        margin: {
          left: 6px;
          right: 0;
        }
      }
    }
  }
}

.cover-box-wrapper {
  padding: 0 !important;
  border-radius: 0 !important;
  border: none !important;
  background-color: transparent !important;
}
