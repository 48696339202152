.profile-data-tab {
  position: relative;
  margin-top: 20px;

  &__section-header {
    color: #8e93ac;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  &__section-wrapper {
    margin-top: 20px;
  }

  &__section-column {
    display: flex;
    flex-direction: column;
  }

  &__section-two-boxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  &__tooltip-header {
    color: white;
    font-weight: 600;
  }

  &__tooltip-first-part {
    margin-bottom: 20px;
  }
}
