.social-profile-icons {
  display: flex;
  margin-top: 4px;
  cursor: pointer;

  &__icon {
    position: relative;

    &--verified {
    }

    &--not-found,
    &--error,
    &--awaiting,
    &--generic-not-found {
      img,
      .pictogram svg {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &--at-least-one-error {
    }
  }

  img,
  .pictogram svg {
    margin-right: 6px;
    width: 18px;
    height: 18px;
  }

  &--small {
    margin-top: 4px;

    img {
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
  }

  &--big {
    margin-top: 0;

    .social-profile-icons__icon {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  .verified-badge {
    position: absolute;
    top: 8px;
    left: 8px;

    img {
      width: 16px;
      height: 16px;
    }
  }
}
