.toolbar-list {
  width: 274px;
  display: flex;
  //   align-items: center;
  justify-content: space-between;
  padding: 24px 0;
  border-bottom: 1px dashed #d3d5e1;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #8e93ac;
  transition: all 0.2s ease;
  // min-height: 72px;

  &--dynamic-provider {
    border-bottom: none;
    padding: 0;
    margin-top: 16px;
  }

  &--closed {
    height: fit-content;
  }

  &--no-bottom-border {
    border-bottom: none;
    padding-bottom: 0;
  }

  &__content {
    width: 100%;
    // height: m;
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-wrapper {
      display: flex;
      align-items: center;

      a {
        color: #8e93ac;
      }
    }

    &-button {
      background-color: #e0e2ea;
      width: 24px;
      height: 24px;
      display: grid;
      place-items: center;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background-color: #e0e2ea;
      }

      svg {
        transition: all 0.2s ease;

        path {
          fill: #8e93ac;
        }
      }

      &--clicked {
        background: transparent;

        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .ws-react-select {
    width: 100px;
  }

  &__checkbox-placeholder {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .ws-checkbox {
    margin-right: 8px;
  }

  &__list {
    transition: height 0.3s ease;
    overflow: hidden;

    &-item {
      color: #a7abc3 !important;
      display: flex;
      align-items: center;
      margin-top: 16px;

      &--single {
        margin-top: 0 !important;
      }

      &-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        a {
          color: #8e93ac;
        }

        &--dark {
          color: #8e93ac;
        }
      }

      .tooltip {
        margin-left: 10px;
      }
    }

    &--visible {
      height: attr(data-height);
    }
  }
}
