.showcase-printing-loader {
  display: flex;
  justify-content: center;
  padding: 250px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
  z-index: 1000;
  opacity: 1;

  &__wrapper {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__bar {
    width: 120px;
    height: 12px;
    background-color: #d3d5e1;
    border-radius: 4px;
    margin-top: 10px;

    &-progress {
      display: flex;
      height: 100%;
      max-width: 100%;
      background-color: #5d78ff;
      border-radius: 4px;
      transition: all 1s ease;
    }
  }

  &__counter {
    margin-top: 16px;
    color: #74788d;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }
}
