.automations {
  min-height: 400px;
  width: 100%;

  &__list {
    &-element {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      padding-bottom: 12px;
      border-bottom: 1px dashed #c8c9ce;
      min-height: 59px;

      &-edit-button {
        color: #5d78ff;
        svg path {
          fill: #5d78ff;
        }
      }

      &-status {
        width: 6px;
        height: 6px;
        background: #ededef;
        border-radius: 50px;
        margin-right: 12px;

        &--active {
          background: #47c4ab;
        }
      }

      &-text {
        position: relative;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #6a6d7c;

        .tooltip {
          margin-left: 8px;
        }
      }
    }
  }

  .ws-skeleton {
    width: 121px;
    height: 46px;
    border-radius: 6px;
  }
}
