.empty-tab-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 480px;

  &__not-found-img {
    margin: {
      top: 36px;
      bottom: 24px;
    }
    max-height: 80px;
  }

  &__header {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #45485b;
    margin-bottom: 6px;
    max-width: 400px;
  }

  &__text {
    margin-bottom: 20px;
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #45485b;
    max-width: 400px;
  }

  .ws-button {
    svg {
      path {
        fill: #fff;
      }

      rect {
        fill: #fff;
        opacity: 1;
      }
    }
  }
}
