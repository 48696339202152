.text-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  color: #74788d;
  border-radius: 8px;
  transition: all 0.2s ease;
  cursor: text;
  position: relative;
  padding: 10px 12px;

  &--readOnly {
    cursor: default;
  }

  &::-webkit-scrollbar {
    width: 4px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f6;
    box-shadow: inset 0 0 5px transparent;
    border-radius: 2px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #a7abc3;
    border-radius: 2px;
    width: 4px;
  }

  .event-trigger {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    width: 0;
    height: 0;
  }

  .public-DraftStyleDefault-block {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    height: max-content;
  }

  .public-DraftEditorPlaceholder-inner {
    position: absolute;
    color: #b4b6be;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
  }

  .DraftEditor {
    &-root {
      width: 100%;
      height: max-content;
    }
  }

  .public-DraftEditorPlaceholder-inner {
    pointer-events: none;
  }

  &__hyperlink {
    color: #5d78ff;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__buttons-left {
    display: flex;
    align-self: flex-end;
    align-items: center;
    svg {
      margin-right: 5px;
      cursor: pointer;

      path {
        transition: all 0.2s ease;
      }

      &:hover {
        path {
          fill: #536ceb;
        }
      }
    }
    &-emoji {
      width: 20px;
      height: 20px;
      cursor: pointer;

      svg {
        position: absolute;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__buttons-right {
    display: flex;
    column-gap: 10px;
  }

  img {
    width: 20px;
    height: 20px;
  }

  &__attachments {
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 16px;
      // Fixes for attachments drag and drop
      padding-bottom: 10px;
      padding-right: 10px;
      overflow: hidden;
    }
  }
}

.mention {
  &--highlight {
    color: #5d78ff !important;
  }
}
