.ws-table {
  border-top: 1px dashed #d3d5e1;
  border-bottom: 1px dashed #d3d5e1;
  margin-top: 30px;
  position: relative;
  // TODO:: just for task lists, do it better in future
  margin-bottom: 32px;

  &::after {
    content: "";
    position: absolute;
    top: 44px;
    left: 0;
    right: 0;
    border-bottom: 1px dashed #d3d5e1;
    z-index: 6;
  }

  &__column {
    // width: 180px;
    // width: 220px;
    background-color: #fff;

    &--dragging {
    }
  }

  &__row {
    // position: relative;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 12px 24px;
    white-space: nowrap;
    height: 110px;
    margin-left: 24px;

    // value field
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #74788d;
    &-flex {
      display: flex;
      position: relative;
      height: 110px;
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        border-bottom: 1px dashed #d3d5e1;
        z-index: 6;
      }
    }
  }

  &__info {
    flex: 1 1;
    flex-shrink: 0;
    background-color: #fff;

    .ws-table__content:not(:last-of-type) {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100vw - 164px);
        border-bottom: 1px dashed #d3d5e1;
      }
    }
  }

  &__column-container {
    flex-shrink: 0;
    border-left: 1px dashed #d3d5e1;
    background-color: #fff;
    padding: 0px 16px;
    width: 170px;

    .ws-table__content:not(:last-of-type) {
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100vw - 164px);
        //border-bottom: 1px dashed #d3d5e1;
      }
    }

    .task-meta-fields__value .value-field__icon {
      position: absolute;
      right: -20px;
      top: 5px;
    }

    .date-picker-wrapper {
      .idh-date-picker__input {
        text-align: center;
        width: 80px;
      }
    }

    .date-picker-wrapper--empty {
      width: 66px !important;

      .value-field__icon {
        display: none;
      }

      .idh-date-picker__input {
        width: 66px !important;
      }
    }
  }

  &__columns {
    display: flex;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      height: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      border-radius: 2px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #b8d1fe;
      border-radius: 2px;
    }
  }

  &__actions {
    padding: 0 24px;
    flex-shrink: 0;
    width: 117.39px;
    border-left: 1px dashed #d3d5e1;

    .ws-table__content {
      position: relative;

      &--first {
        &::after {
          display: none;
        }
      }
    }
  }

  &__header {
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.03em;
    height: 44px;
    color: #a7abc3;
    padding: 14px;
    white-space: nowrap;
    text-transform: uppercase;
    text-align: center;

    &--flex {
      // TODO:: better name
      display: flex;
      align-items: center;
      justify-content: center;
      // border-bottom: 1px dashed #d3d5e1;
    }

    &--left {
      display: flex;
      align-items: center;
      padding-left: 0;
    }

    &-wrapper {
      display: flex;
    }
  }

  &__header-dropdown {
    display: flex;
    justify-content: center;
    text-transform: initial;
  }

  &__header-title {
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-left: 24px;
  }

  &__header-title-icon {
    margin-left: 8px;
  }

  &__content {
    height: 110px;
    display: flex;
    align-items: center;

    &--tasks-table {
      height: 90px;
    }

    &--center {
      justify-content: center;
    }
  }

  &__image {
    position: relative;
    display: grid;
    place-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #f2f2f6;
    cursor: pointer;

    &-cover {
      position: absolute;
      pointer-events: none;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      z-index: 0;
      object-fit: cover;
      object-position: center;

      &--circle {
        border-radius: 50%;
      }

      &--square {
        border-radius: 4px;
      }
    }

    &--square {
      border-radius: 4px;

      input {
        border-radius: 4px;
      }

      .ws-table__image {
        &-icon {
          border-radius: 4px;
        }

        &-cover {
          background-color: #f2f2f6;
          border-radius: 4px;
        }
      }
    }

    &-file {
      cursor: pointer;
      z-index: 1;

      input {
        position: absolute;
        height: 67px;
        width: 48px;
        left: 0;
        top: 0;
        border-radius: 50%;
        opacity: 0;
        z-index: 100;
      }

      input,
      input::-webkit-file-upload-button {
        cursor: pointer;
      }
    }

    &-icon {
      width: 48px;
      height: 48px;
      background-color: transparentize($color: white, $amount: 0.3);
      border: dashed 1px #5d78ff;
      display: grid;
      place-items: center;
      opacity: 0;
      transition: all 0.3s ease;

      &--circle {
        border-radius: 50%;
      }

      &--square {
        border-radius: 4px;
      }
    }

    &:hover {
      .ws-table__image-icon {
        opacity: 1;
      }
    }

    .image-input {
      position: relative;
      cursor: pointer;

      input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }

      input,
      input::-webkit-file-upload-button {
        cursor: pointer;
      }
    }
  }

  &__open-new-card {
    margin-right: 16px;
    height: 16px;

    &:hover {
      cursor: pointer;
    }
  }

  &__name {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #74788d;
    margin: 0 12px;

    .ws-button {
      margin-top: 4px;
    }

    &--with-tick {
      margin-left: 8px;
    }

    svg {
      margin-right: 0;
    }
  }

  &__task-name {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    max-width: 214px;
    cursor: pointer;
    color: #74788d;
  }

  &__task-name-wrapper {
    display: flex;
    align-items: center;
  }

  &__task-shared {
    margin-left: 8px;
    cursor: pointer;

    &--disabled {
      cursor: default;
    }
  }

  &__task-not-shared {
    margin-left: 8px;
    background-image: url("../../../images/client-grey.svg");
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
    cursor: pointer !important;

    &--disabled {
      cursor: default;
    }

    &:hover {
      background-image: url("../../../images/client-light-grey.svg");
    }
  }

  &__task-social-provider {
    margin-top: 4px;
    width: 16px;
    height: 16px;
    display: grid;
    place-items: center;

    span,
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__row-info {
    display: flex;
    align-items: center;
  }

  &__field {
    height: 100%;
    width: 100%;
    min-width: 90px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    input {
      text-align: center;
      width: 100%;
      border: 0;
      background-color: transparent;

      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: #8e93ac;
      outline: 0;
    }
  }

  &__empty-field {
    padding: 6px 26px;
    border: 1px dashed #d3d5e1;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 12px;
      width: 12px;

      path {
        // fill: #d3d5e1;
      }
    }
  }

  &__add-button {
    display: flex;
    align-items: center;
    color: #d3d5e1;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    transition: all 0.2s ease;
    cursor: pointer;

    &--project {
      height: 110px;
      margin: {
        top: 44px;
        left: 0;
        right: 32px;
      }
    }

    &-empty {
      display: grid;
      place-items: center;
      width: 48px;
      height: 48px;
      border: dashed 1px #d3d5e1;
      margin-right: 12px;
      transition: all 0.2s ease;

      &--circle {
        border-radius: 50%;
      }

      &--square {
        border-radius: 4px;
      }

      svg {
        width: 20px;
        height: 20px;

        path {
          fill: #d3d5e1;
          transition: all 0.2s ease;
        }
      }

      &--small {
        width: 30px;
        height: 30px;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    &:hover {
      color: #5d78ff;

      .ws-table__add-button-empty {
        background-color: #eff1ff;
        border: dashed 1px #5d78ff;

        svg path {
          fill: #5d78ff;
        }
      }
    }
  }
}
