.tasks-table-header-options-dropdown {
  text-transform: none;
  vertical-align: middle;

  &__dots {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    cursor: pointer;
    visibility: hidden;
    vertical-align: middle;
    background-image: url("../../../images/three-dots-grey.svg");
  }
}
