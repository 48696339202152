.task-meta-fields {
  margin: {
    top: 24px;
    bottom: 24px;
  }

  .ws-button {
    margin-left: 8px;
  }

  &__row {
    display: flex;
    align-items: center;
    height: 32px;
    margin-bottom: 8px;

    &--high {
      height: unset;
      align-items: flex-start;
      min-height: 32px;

      .task-meta-fields__name {
        margin-top: 8px;
      }
    }
    &--auto {
      height: auto;
    }
  }

  &__header {
    color: #74788d;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }

  &__header-right {
    display: flex;
    align-items: center;
  }

  &__name-wrapper {
    .react-tooltip {
      text-transform: uppercase;
    }
  }

  &__name {
    width: 170px;
    margin-right: 20px;
    color: #a7abc3;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &--required {
      margin-left: 5px;
      font-size: 16px;
    }
  }

  &__value {
    display: flex;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #74788d;

    &-content {
      display: flex;
      align-items: center;
      position: relative;

      &-edit-icon {
        margin-left: 4px;
        display: flex;
        align-items: center;
        background-color: #f2f2f6;
        padding: 0px 8px 0px 6px;
        height: 20px;
        color: #a7abc3;
        border-radius: 94px;
        font-weight: 500;
        font-size: 11px;
        line-height: 20px;

        svg {
          width: 12px;
          height: 12px;
          margin-right: 4px;
        }
      }

      &--disabled {
        &:hover {
          .add-button {
            border: 1px dashed #5d78ff;
            background-color: #dfe4ff;

            svg {
              path {
                fill: #5d78ff;
              }
            }
          }

          .value-field {
            pointer-events: none;
          }
        }

        .add-button {
          pointer-events: none;
        }
        // cursor: pointer !important;
      }
    }

    .value-input,
    .value-textbox {
      padding: 6px 4px;
      width: 550px;
      color: #74788d;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
    }

    .value-textbox {
      height: 100%;
      padding: 6px 4px;
      width: 550px;
      color: #74788d;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      outline: 1px solid transparent;

      &:focus,
      &:hover {
        outline: 1px solid transparent;
      }
    }

    .hidden {
      visibility: hidden;
    }

    .value-input {
      border-radius: 4px;
      transition: outline 0.2s ease;
      // outline: 2px solid transparent;
      outline: none;
      border: none;

      &::placeholder {
        color: #a7abc3;
      }

      // &:hover,
      // &:focus {
      //   outline: 1px solid #d3d5e1;
      // }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .value-placeholder {
      position: absolute;
      height: 100%;
      height: 32px;
      padding: 6px 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      z-index: -1;
      visibility: hidden;
      pointer-events: none;
      min-width: 50px;
      width: max-content;
    }

    .idh-date-picker__input {
      width: 66px;
      height: 26px;
      border-radius: 6px;
      padding: 0;
      border: none;

      &[value="Wednesday"] {
        width: 76px;
      }
    }

    .idh-date-picker--transparent {
      width: 78px;
      height: 26px;
      border-radius: 6px;
      padding: 0;
      border: none;
    }

    .date-picker-wrapper {
      position: relative;
      border: none;
      margin: 0;

      &--no-hover {
        .dropdown-value-wrapper:hover {
          background: transparent;
        }
      }

      .idh-date-picker__input {
        border: 1px dashed #d3d5e1;
        transition: all 0.2s ease;
        height: 26px;
      }

      &:hover {
        .idh-date-picker__input {
          border: 1px dashed #5d78ff;
        }

        .date-picker-wrapper__plus {
          path {
            fill: #5d78ff;
          }
        }
      }

      &--empty:hover {
        .idh-date-picker__input {
          background-color: #dfe4ff;
        }
      }
    }

    .value-field {
      display: flex;
      align-items: center;
      // cursor: pointer;

      .add-button {
        svg {
          margin: 0 !important;
        }
      }

      &__icon {
        margin-left: 6px;
        opacity: 0;
        transition: all 0.2s ease;
        cursor: pointer;
        flex-shrink: 0;
      }

      &:hover {
        .value-field__icon {
          opacity: 1;
        }
      }

      .currency-value,
      .currency-input {
        &--hidden {
          top: 10px;
          left: 0;
          position: absolute;
          visibility: hidden;
          pointer-events: none;
        }
      }
    }
  }

  &__show-more {
    color: #5d78ff;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      color: #7d93ff;
    }
  }
}
