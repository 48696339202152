.influencers-list__cell--photos {
  display: flex;
  justify-content: center;
  cursor: pointer;

  &__item {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: relative;
    background: #f2f2f6 no-repeat center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    color: #1267fb;
    outline: solid white 3px;
    flex-shrink: 0;

    &:not(:first-of-type) {
      margin-left: -4px;
    }

    &--number {
      background-color: #e7f0ff;
    }
  }

  &__empty {
    background: #f2f2f6 url("../../../../../../../../../images/person.svg")
      no-repeat center;
    background-size: 24px 24px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  &:hover {
    opacity: 0.7;
  }
}
