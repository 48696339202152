.dropdown-menu-hover-item {
  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
  }

  &__chevron {
    margin-left: auto;
    transform: rotate(180deg);
  }

  &__menu {
    position: absolute;
    box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 8px 0;
    border-radius: 8px;

    &--left {
      right: calc(100% + 8px);
    }

    &--right {
      left: calc(100% + 8px);
    }
  }
}
