@import "../../../../../styles/media-queries.scss";
@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";


.member-team {
  &-wrapper {
    &__row {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    &__column {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &__switch {
      display: flex;
      flex-direction: column;
      margin-top: 2px;

      &-info {
        &-header {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          color: #45485B;
        }

        &-description {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          color: #585B6C;
        }
      }
    }
  }

  &-list {
    &__table {
      &-head-row,
      &-body-row {
        display: flex;

        &-cell {
          &-checkbox {
            flex-basis: 30px;
          }

          &-name,
          &-members {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            color: #45485b;

            .head {
              font-size: 10px;
              font-style: normal;
              font-weight: 600;
              line-height: 12px;
              letter-spacing: 0;
              text-transform: uppercase;
            }
          }

          &-name {
            flex: 1;
          }

          &-members {
            flex-basis: 100px;
          }
        }
      }

      &-head-row {
        &-cell {
          &-members,
          &-name {
            color: #A1A3AD;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 12px;
            letter-spacing: 0;
            text-transform: uppercase;
          }
        }
      }

      &-head {
        display: flex;
        border-bottom: solid 1px #ededef;
        padding: 12px 0;

        &-el {
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 12px;
          color: #a1a3ad;
          text-transform: uppercase;
        }
      }

      &-body {
        overflow-y: auto;
        max-height: 250px;
        overflow-y: auto;
        @include custom-scrollbar;

        @include min-screen($desktopWidth) {
          max-height: 250px;
        }

        @include min-screen($desktopLargeWidth) {
          max-height: 350px;
        }

        &-row {
          height: 64px;
          display: flex;
          align-items: center;

          &:not(:last-of-type) {
            border-bottom: solid 1px #ededef;
          }

          &-el {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            color: #45485b;
          }
        }
      }

      .stacked-avatars {
        margin: 0;

        .avatar img,
        .avatars-left {
          width: 32px;
          height: 32px;
        }

        .avatars-left {
          color: #6a6d7c;
          background-color: #ededef;
          font-size: 12px;
          font-weight: 500;
          line-height: 12px;
        }
      }

      .team-name {
        cursor: pointer;

        input {
          border: none;
          outline: none;
          padding: 0;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          color: #a1a3ad;
        }
      }
    }
  }
}
