.chevron {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: #ededef;

  padding: 0;
  align-items: center;
  display: flex;
  justify-content: space-around;

  svg {
    transform: rotate(180deg);
  }

  &--expanded {
    svg {
      transform: rotate(0deg);
    }
  }

  &:hover {
    background-color: #e3e3e3;
  }
}
