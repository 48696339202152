.edit-select-options-modal {
  &__content {
    .ws-react-select-container {
      margin-bottom: -24px;
    }
  }

  &__switch-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #45485b;
    border-top: 1px solid #e0e2ea;
    min-height: 46px;
  }

  .select__menu {
    padding: 10px 0;
  }

  .options-list {
    max-height: 350px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f2f2f6;
      border-radius: 30px;
      width: 6px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #a7abc3;
      border-radius: 30px;
      width: 6px;
      height: 64px;
    }
  }
}
