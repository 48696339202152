.share-with-client-modal {
  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .stacked-avatars {
    margin-left: auto;
  }

  .modal__buttons {
    margin-top: 0;
  }
}
