.stacked-avatars {
  display: flex;
  align-items: center;
  padding-left: 12px;
  margin-left: 32px;

  .avatar--client {
    img {
      border: solid #f7d61a 2px !important;
    }
  }

  .avatar img,
  .avatars-left {
    display: grid;
    place-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    background: #ededef;
    color: #6a6d7c;
  }

  .avatars-left,
  .avatar img {
    border: 2px solid #ffffff;
    margin-left: -12px;
  }

  .avatars-left {
    z-index: 1;
  }

  &--users {
    margin-left: 8px;

    .avatar img,
    .avatars-left {
      width: 24px;
      height: 24px;
      font-size: 10px;
      line-height: 10px;
    }
  }

  &--with-on-click {
    cursor: pointer;
  }

  &--small {
    .avatar,
    .avatar img,
    .avatars-left {
      height: 24px;
      width: 24px;
      margin-left: -8px;
    }
  }
}
