.activity {
  padding: 24px 48px;
  padding-top: 0;
  overflow: scroll;
  height: 100%;

  &__no-results {
    height: 70%;
  }

  &__topbar {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    &-heading {
      font-size: 28px;
      line-height: 36px;
      letter-spacing: -0.03em;
      color: #45485b;
      font-weight: 500;
      margin: 26px 0 6px 0;
    }

    &-description {
      font-size: 14px;
      line-height: 22px;
      color: #7d808d;
      font-weight: 400;
      margin: 0;
    }

    &-wrapper {
      position: sticky;
      top: 0px;
      background: white;
      z-index: 1;
      padding-bottom: 20px;
    }
  }

  //   Table
  &__table {
    &-head {
      display: flex;
      align-items: center;
      height: 44px;
      font-weight: 600;
      font-size: 10px;
      line-height: 20px;
      color: #a7abc3;
      text-transform: uppercase;
      border: {
        top: 1px dashed #d3d5e1;
        bottom: 1px dashed #d3d5e1;
      }
      gap: 48px;

      &-time {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-activity {
        flex: 1;
      }

      &-time {
        flex-basis: 160px;
      }

      &-project {
        flex-basis: 240px;
      }

      position: sticky;
      top: 110px;
      background: white;
      z-index: 1;
    }

    &-element {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      border-bottom: 1px dashed #d3d5e1;
      gap: 48px;

      i {
        color: #8f919d;
      }

      &-activity {
        position: relative;
        display: flex;
        align-items: center;
        flex: 1;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #74788d;

        &--text {
          overflow: hidden;
        }

        &-value {
          display: inline;
          white-space: -moz-pre-wrap; /* Mozilla */
          white-space: -hp-pre-wrap; /* HP printers */
          white-space: -o-pre-wrap; /* Opera 7 */
          white-space: -pre-wrap; /* Opera 4-6 */
          white-space: pre-wrap; /* CSS 2.1 */
          white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
          word-wrap: break-word; /* IE */
          word-break: break-all;

          &--text {
            display: inline-flex;
            width: calc(100% - 30px) !important;
          }
        }

        img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          border-radius: 50%;
        }
      }

      &-time {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 160px;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #74788d;
      }

      &-currency {
        color: #a7abc3;
      }

      .tooltip-portal {
        display: inline;
        cursor: pointer;
      }

      &-label {
        padding: 4px 8px;
        border-radius: 8px;
        margin-left: 4px;

        &--multi {
          line-height: 28px;
        }

        &--counter {
          padding: 4px 8px;
          border: 1px solid var(--grey-05, #ededef);
          border-radius: 8px;
        }
      }

      &-text {
        overflow: hidden !important;
        text-overflow: ellipsis;
        white-space: nowrap !important;
        flex: 1;
        flex-grow: 1;
        max-width: fit-content;
        color: #8f919d;
      }

      &-clip {
        transform: translateY(5px);
        margin-left: 6px;
      }

      &-link {
        color: #5d78ff;
        display: inline;
      }

      &-project {
        display: flex;
        align-items: center;
        flex-basis: 240px;

        img {
          width: 24px;
          height: 24px;
          border-radius: 2px;
          object-fit: cover;
          margin-right: 8px;
        }

        &-name {
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #74788d;
          max-width: 208px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
