.content-proposal-summary {
    margin: 20px 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #e0e2ea;
    &__header {
        display: flex;
        justify-content: space-between;
    }
    &__filters {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;

        &.active {
            .ws-button {
                background-color: #f2f2f6;
                border: 1px solid #f2f2f6;
            }
        }

        .selected-filter {
            display: inline-block;
            margin: 0 2px;

            &:not(:last-child)::after {
                content: ", ";
            }

            &::first-letter {
                text-transform: capitalize;
            }
        }
    }

    &__button {
        padding: 11px 30px 11px 11px;
        background: url("../../../images/chevron-select.svg") no-repeat 90%;

        &:hover {
            background: #ededef url("../../../images/chevron-select.svg") no-repeat 90%;
        }
        &--less {
            background-image:  url("../../../images/chevron-up-grey.svg");

            &:hover {
                background: #ededef url("../../../images/chevron-up-grey.svg") no-repeat 90%;
            }
        }
    }

    &__empty {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        color: #45485B;
        font-size: 13px;
        gap: 20px;
        padding: 30px 0;

        svg {
            width: 56px;
            height: 56px;
        }
    }
}

.content-proposal-summary-elements {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    transition: all 300ms;

    .content-proposal-summary-element {
        &--rejected {
             opacity: 0.3;
        }

        .content-proposal-comment__indicator {
            width: 10px;
            height: 10px;
        }

    }
    &--media {
        max-height: 160px;

        .content-proposal-summary-element__media-wrapper {
            flex: 1 0 calc(20% - 10px);
            max-width: calc(20% - 10px);
            margin: 5px;
            position: relative;
            .attachment-wrapper {
                height: 162px;
            }
            .attachment-image, .attachment-video, .attachment-file {
                height: 140px;
                max-height: 140px;
                width: 100%;
                flex-direction: column;
                align-items: center;
                text-align: center;
                justify-content: center;
                padding: 0;
            }
            .attachment-file {
                > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                svg {
                    width: 50px;
                    height: 50px;
                    margin-bottom: 10px;
                    margin-left: auto;
                    margin-right: auto;
                }
                &__details {
                    margin-top: 2px;
                }

                &__name {
                    font-size: 12px;
                    color: #6A6D7C;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 110px;
                }
            }
            .attachment-video__placeholder {
                width: 100%;
                margin-bottom: 20px;
                height: 140px;
                align-items: center;
                justify-content: center;
                display: flex;
                background-color: #f6f6f7;
                cursor: pointer;
                transition: all 300ms;

                &:hover {
                    opacity: 0.9;
                }
            }
        }
    }

    &--caption {
        gap: 20px;
        max-height: 160px;
        display: flex;
        .content-proposal-summary-element--caption {
            flex: 1 0 calc(50% - 20px);
            max-width: calc(50% - 20px);
            margin: 5px;
            position: relative;
            .content-proposal-comment__caption {
                width: 100%;
                transition: all 300ms;
                cursor: pointer;

                > div {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                &:hover {
                    background-color: rgb(246 246 247 / 50%);
                }
            }
        }
    }

    &.expanded {
        max-height: 5000px;
        padding-bottom: 15px;
    }
}