@import "src/styles/variables.scss";

.tooltip {
  position: relative;
  display: block;
  word-break: break-word;

  &--no-break {
    white-space: nowrap;
    word-break: keep-all;
  }

  &--up {
    .tooltip__content {
      top: auto;
      bottom: calc(100% + 12px);

      &::before {
        top: auto;
        bottom: -8px;
        border-width: 8px 6px 0 6px;
        border-color: #fff transparent transparent transparent;
      }
    }
  }

  &--small {
    .tooltip__content {
      width: auto;
      min-width: 96px;
      font-weight: 600;
    }
  }

  &--big {
    .tooltip__content {
      width: 400px;
    }
  }

  &--auto-size {
    .tooltip__content {
      min-width: auto;
      width: fit-content;
    }
  }

  &--align-right {
    .tooltip__content {
      left: auto;
      right: -8px;
      transform: translateX(0);

      &::before {
        left: auto;
        right: 14px;
        transform: translateX(0);
      }
    }
  }

  &--align-left {
    .tooltip__content {
      left: -8px;
      right: auto;
      transform: translateX(0);

      &::before {
        right: auto;
        left: 14px;
        transform: translateX(0);
      }
    }
  }

  &:not(.tooltip--clickable):hover {
    .tooltip__content {
      display: block;
    }
  }

  &__content {
    position: absolute;
    top: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    width: 227px;
    background: #45485b;
    box-shadow: 0px 1px 15px rgba(69, 65, 78, 0.2);
    border-radius: 8px;
    text-transform: initial;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #dadade;
    z-index: $z-index-max;
    cursor: auto;
    opacity: 0;
    transition: all 0.2s ease;

    &--open {
      opacity: 1;
    }

    &--center {
      text-align: center;
    }

    &::before {
      content: "";
      position: absolute;
      top: -8px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 1px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 8px 6px;
      border-color: transparent transparent #45485b transparent;
    }
  }

  &__text {
    padding: 8px;
    max-height: 250px;
    overflow: auto;
    display: block;
    transition: all 0.3s ease;
  }

  .tooltip-sign {
    width: 16px;
    height: 16px;
    display: block;
    cursor: pointer;

    svg path {
      fill: #a7abc3;
    }
  }

  .tooltip-blue-text {
    color: #1267fb;
  }
}

body .react-tooltip__dark-mode {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  background: #45485b;
  color: #dadade;
  border-radius: 8px;
  padding: 8px 12px;
  box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
  opacity: 1;
  z-index: $z-index-max;
}
