// .c.mention {
//   color: #4a85bb;
//   text-decoration: none;
// }

.mention {
  color: #5d78ff;
}

.mentionSuggestions {
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  padding: 8px 0;
  flex-direction: column;
  box-sizing: border-box;
  transform-origin: 50% 0%;
  transform: scaleY(0);
  margin-bottom: 10px;
  width: 360px;
  box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.mentionSuggestionsEntryContainer {
  display: table;
  width: 100%;
}

.mentionSuggestionsEntryContainerLeft,
.mentionSuggestionsEntryContainerRight {
  display: table-cell;
  vertical-align: middle;
}

.mentionSuggestionsEntryContainerRight {
  width: 100%;
  padding-left: 8px;
}

.mentionSuggestionsEntry {
  display: flex;
  align-items: center;
  color: #74788d;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  white-space: nowrap;
  padding: 4px 12px;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    object-fit: cover;
    margin-right: 12px;
  }
}

.mentionSuggestionsEntry:active {
  background-color: #f6f7f9;
}

.mentionSuggestionsEntryFocused {
  composes: mentionSuggestionsEntry;
  background-color: #f6f7f9;
  cursor: pointer;
}

.mentionSuggestionsEntryText,
.mentionSuggestionsEntryTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mentionSuggestionsEntryText {
}

.mentionSuggestionsEntryTitle {
  font-size: 80%;
  color: #a7a7a7;
}

.mentionSuggestionsEntryAvatar {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
