$minWidth: 320px;
$tabletWidth: 768px;
$desktopWidth: 1024px;
$desktopMediumWidth: 1280px;
$desktopLargeWidth: 1400px;
$desktopHugeWidth: 1720px;

$desktopSmallHeight: 1000px;

// z-index values are also stored in zIndex.ts
$z-index-under: -1;
$z-index-base: 0;
$z-index-above: 10;
$z-index-navigation: 1010;
$z-index-popover: 1020;
$z-index-dropdown: 1030;
$z-index-tooltip: 1040;
$z-index-max: 2147483647;
