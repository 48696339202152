.no-results {
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: #a7abc3;
  margin-bottom: 42px;
  display: block;

  &--with-line {
    border-top: 1px dashed #d3d5e1;
    padding-top: 20px;
  }

  &--with-underline {
    border-bottom: 1px dashed #d3d5e1;
    padding-bottom: 20px;
    width: 100%;
  }
}
