.report-summary-learnings {
  display: flex;
  flex-direction: column;

  &__heading {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #a7abc3;
  }

  &__content {
    margin-top: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #74788d;
    width: 100%;
    height: 100%;
  }
}
