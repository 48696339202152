.ws-button {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: 500;
  padding: 0;
  font-size: 12px;
  line-height: 18px;
  border: 0;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  transition: all 0.15s ease;

  svg {
    width: 20px;
    height: 20px;

    path {
      transition: all 0.15s ease;
    }
  }

  // TODO: Thin and medium classes should be removed.

  &--thin {
    padding: 8px 16px 8px 10px;
    height: 36px;
  }

  &--medium {
    padding: 12px 16px 12px 12px;
  }

  &--font-medium {
    font-size: 13px;
    line-height: 20px;
  }

  &--font-big {
    font-size: 14px;
    line-height: 22px;
  }

  &--small {
    padding: 12px;
    height: 36px;
  }

  &--large {
    padding: 12px;
    height: 46px;
  }

  &--text-with-icon {
    padding: 12px 10px 12px 8px;
  }

  &--square {
    width: 36px;
    height: 36px;
    padding: 8px;
  }

  &--square-small {
    padding: 6px;
    width: 32px;
    height: 32px;
  }

  &--blue {
    color: #fff;
    background: #5d78ff;
    border: 1px solid #5d78ff;

    svg {
      path,
      rect {
        fill: #fff;
      }
    }

    &:hover {
      background: #6d85ff;
      border: 1px solid #6d85ff;
    }
  }

  &--green {
    color: #47c4ab;
    background-color: #edfaf7;
    border: 1px solid #47c4ab;

    svg {
      path,
      rect {
        fill: #47c4ab;
      }
    }

    &:hover {
      background-color: #d0f5ec;
    }
  }

  &--red {
    background-color: #ffecf2;
    border: 1px solid #ffecf2;
    color: #d93025;

    &:hover {
      background-color: #f7d6d3;
      border: 1px solid #f7d6d3;
    }

    svg {
      path,
      rect {
        fill: #fd397a;
      }
    }
  }

  &--light-blue {
    background-color: #eff1ff;
    border: 1px solid #eff1ff;
    color: #5d78ff;

    &:hover {
      background-color: #dfe4ff;
      border: 1px solid #dfe4ff;
    }
  }

  &--light {
    background-color: #eff1ff;
    border: 1px solid #eff1ff;
    color: #5d78ff;

    &:hover {
      background-color: #dfe4ff;
      border: 1px solid #dfe4ff;
    }
  }

  &--grey {
    background-color: #f2f2f6;
    color: #74788d;

    &:hover {
      background-color: #e0e2ea;
    }
  }

  &--light-grey {
    background-color: #ededef;
    border: 1px solid #ededef;
    color: #6a6d7c;

    &:hover {
      background-color: #dadade;
      border: 1px solid #dadade;
    }
  }

  &--white {
    background-color: #fff;
    border: 1px solid white;

    color: #45485b;

    &:hover {
      background-color: #f2f2f6;
      border: 1px solid #f2f2f6;
    }
  }

  &--transparent {
    background-color: transparent;
    color: #5d78ff;

    &:hover {
      color: #7d93ff;
      svg path {
        fill: #7d93ff;
      }
    }

    &.ws-button--disabled {
      background-color: transparent;
      cursor: not-allowed;
    }
  }

  &--white-active {
    background-color: #f2f2f6;
  }

  &--white-to-light {
    background-color: #fff;
    color: #5d78ff !important;

    &:hover {
      background-color: #eff1ff;
    }

    &.ws-button--disabled {
      background-color: #fff;
    }
  }

  &--white-to-red {
    background-color: #fff;
    color: #fd397a;

    svg path {
      fill: #fd397a;
    }

    &:hover {
      background-color: #ffecf2;
    }

    &.ws-button--disabled {
      background-color: #fff;
    }
  }

  &--white-with-border {
    background-color: white;
    color: #5d78ff;
    border: 1px solid #aebbff;

    svg path {
      fill: #5d78ff;
    }

    &:hover {
      background: #eff1ff;
    }
  }

  &--white-with-black-border {
    background-color: white;
    color: #7d808d;
    border: 1px solid #7d808d;

    &:hover {
      background: #ededef;
    }
  }

  &--white-with-grey-border {
    background: transparent;
    border: solid #7d808d 1px;

    &:hover {
      background-color: #ececee;
    }
  }

  &--white-with-light-grey-border {
    background-color: transparent;
    border: 1px solid #ededef;

    color: #45485b;
    &:hover {
      background-color: #ededef;
    }
  }

  &--bare {
    background: transparent;
    border: 1px solid transparent;
    color: #5d78ff;

    &:hover {
      color: #536ceb;
      background-color: #dfe4ff;
      border: 1px solid #dfe4ff;

      svg path {
        fill: #536ceb;
      }
    }
  }

  &--orange {
    background-color: #fff1d3;
    color: #d29005;
    border: 1px solid #fff1d3;
    &:hover {
      background-color: #ffeabd;
      border: 1px solid #ffeabd;
    }
  }

  &--disabled {
    background: #f2f2f6;
    color: #d3d5e1 !important;
    pointer-events: none;
    border-color: #f2f2f6;

    svg {
      path,
      rect {
        fill: #d3d5e1;
      }
    }
  }

  &--no-background {
    background: transparent;
    border: none;
  }
}
