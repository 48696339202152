.choose-person-dropdown {
  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    object-fit: cover;
  }

  &__skeleton{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;

    &-row {
      display: flex;
      font-size: 13px;
      line-height: 20px;
      padding: 4px 16px;
      color: #45485b;
      align-items: center;
      cursor: pointer;
      word-break: break-word;
      min-height: 32px;
    }
  }

  .dropdown__menu {
    width: 360px;
    padding: 0;
    margin: 0;

    &-item {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #74788d;
      text-transform: initial;

      img {
        margin: {
          left: 0;
          right: 12px;
        }
      }
    }
  }

  &__dot {
    margin: 0 4px;
    color: #a7abc3;
  }

  &__email {
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    color: #a7abc3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.member-option {
  display: flex;
  align-items: center;
  text-transform: initial;

  &__photo {
    width: 24px;
    height: 24px;
    border-radius: 50%;

    &-loader {
      margin-right: 12px;
      margin-left: 0 !important;
    }
  }

  &__name {
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #74788d;
  }

  &__dot {
    margin: 0 4px;
    color: #a7abc3;
  }

  &__email {
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    color: #a7abc3;
  }
}

.assignee-field {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.2s ease;

  &__username {
    width: 100%;
  }

  &--disabled {
    cursor: not-allowed;
  }
}

.choose-person-dropdown-menu {
  font-family: Poppins;
  width: 360px;
  padding: 0 !important;

  .ws-react-select-container {
    margin-bottom: 0;
  }

  .ws-react-select__height {
    display: none;
  }

  .ws-react-select__menu {
    position: static;
    &::before {
      transition: none;
      top: 0 !important;
    }

    &-list {
      max-height: 160px;
    }
  }
}
