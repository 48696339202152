.cover-image-uploader {
  &__cover-photo-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__image {
    width: 48px;
    height: 48px;
    cursor: pointer;
    border-radius: 4px;
  }

  &__placeholder {
    border: 1px dashed #c8c9ce;
    width: 48px;
    height: 48px;
    display: grid;
    place-items: center;
    border-radius: 4px;
    svg {
      width: 16px;
      height: 16px;
    }
    path {
      fill: #d3d5e1;
    }
  }

  &__image-cover {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 4px;
    cursor: pointer;
    img {
      border: 1px solid #dadade;
    }
  }

  &__cover-photo-controls {
    display: flex;
    gap: 8px;
    button {
      all: unset;
      cursor: pointer;
      color: #6a6d7c;
      font-weight: 500;
      font-size: 13px;
      &:hover {
        opacity: 0.7;
      }
    }
    > span {
      color: #dadade;
      font-size: 14px;
    }
  }
}
