.sidebar__item {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  text-decoration: none;
  padding: 8px;
  border-radius: 6px;
  transition: background 0.3s ease;

  &-text {
    left: 24px;
    padding-left: 20px;
    position: absolute;
    width: 133px;
    opacity: 0;
    white-space: nowrap;
    transition: all 0.3s ease;
  }

  svg path {
    transition: all 0.3s ease;
    fill: #45485b;
  }

  span {
    display: flex;
    align-items: center;
  }

  a {
    display: flex;
  }

  a,
  span {
    color: #45485b;
    cursor: pointer;
  }

  &:hover {
    background: #f6f6f7;
  }

  &--active {
    background: #ededef;
  }

  &--active-light {
    background: #f6f6f7;
  }

  &--active,
  &:hover {
    // svg path {
    //   fill: #5d78ff;
    // }

    a,
    span {
      color: #45485b;
    }
  }

  svg {
    flex-shrink: 0;
  }
}
