.coupon-data-import-modal {
  max-width: 600px;
  min-width: 600px;

  &__input-wrapper {
    margin-top: 20px;

    .ws-input {
      width: 100%;
      height: 46px;
    }
  }

  &__header-label {
    font-size: 12px;
    color: #45485b;
  }

  &__upload-file-wrapper {
    padding: 10px 10px;
    border: 1px dashed #ddd;
    border-radius: 10px;
    margin: 20px 0px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .input-file{
      display: none;
    }

    .file-icon {
      margin-right: 10px;
    }

    .file-label {
      margin-right: 10px;
    }

    .file-tooltip-label {
      margin-right: 10px;
      font-size: 10px;
      color: #aaa;
    }
  }

  &__textarea {
    height: 150px;

    &-wrapper {
      margin-bottom: 20px;
    }

    &--warning {
      border-color: #f25a49;
    }
  }

  .invalid-data-label {
    svg {
      margin-right: 5px;
    }

    color: #f25a49;
    font-size: 12px;
    display: flex;
    margin-top: 10px;
  }

  .success-data-label {
    svg {
      margin-right: 5px;
      height: 15px;
      width: 15px;
    }

    font-size: 12px;
    display: flex;
    margin-top: 10px;
    align-items: center;
  }

  .import-data-loader {
    .loader-wrapper {
      width: 30px;
      height: 25px;
      margin: 0;
      margin-right: 5px;
    }

    font-size: 12px;
    display: flex;
    margin-top: 10px;
    color: #45485b;
    align-items: center;
  }

  &__templates-label{
    margin-bottom: 5px;
  }

  .token-input-container {
    min-height: 46px;
    max-height: 300px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      border-radius: 2px;
      background-color: #e0e2ea;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #a7abc3;
      border-radius: 30px;
    }
  }
}
