.page-header {
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.03em;
  color: #45485b;
  font-weight: 500;
  margin: 0;
  margin-bottom: 8px;
}

.page-description {
  font-size: 14px;
  line-height: 22px;
  color: #7d808d;
  font-weight: 400;
  margin: 0 0 20px 0;
}
