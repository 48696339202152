@import "../../../../styles/variables";

.basic-info-wrapper {
  padding: 0 !important;
}

.basic-info {
  padding: 5px 8px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  height: #{$grid-height};

  &__left {
    display: flex;
    align-items: center;

    &-cover {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 12px;
    }

    &-title {
      color: #74788d;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      margin-right: 12px;
    }

    .social-profile-icons {
      margin-bottom: 0;
    }
  }

  &__right {
    &-campaign-logo {
      width: 36px;
      height: 36px;
    }
  }
}
