.velocity-value-wrapper {
}

.velocity-value {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8e93ac;
  display: flex;
  align-items: center;
  justify-content: center;

  &__warning {
    margin-right: 4px;

    path,
    rect {
      fill: #ffb822;
    }
  }

  &:not(.velocity-value--no-click) {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .velocity-icon {
    margin-right: 4px;
  }

  &--high {
    color: #5ec7eb;
  }

  &--low {
    color: #fd397a;
  }
}

.velocity-value-tooltip {
  justify-content: center;

  &__content {
    max-width: 816px !important;
    padding: 16px !important;

    &::before {
      left: unset;
      right: 13%;
    }

    &-wrap {
      max-height: 323px;
      overflow-y: auto;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 20px;
    text-align: left;
  }

  &__filters-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    row-gap: 12px;
  }

  &__filter {
    display: flex;
    align-items: center;

    .velocity-value-tooltip__filter-value {
      margin-left: 4px;
    }

    .velocity-value-tooltip__result-value {
      margin-left: 4px;
      white-space: pre-wrap;
      word-break: break-all;
    }

    &--matched {
      .velocity-value-tooltip__filter-label {
        color: #45485b;
        background: #c8ede6;
      }

      .velocity-value-tooltip__result-value {
        color: #47c4ab;
      }
    }

    &--mismatched {
      .velocity-value-tooltip__filter-label {
        color: #727692;
        background: #f2f2f6;
      }

      .velocity-value-tooltip__result-value {
        color: #a7abc3;
      }
    }
  }

  &__value {
    color: #5ec7eb;
    margin-left: 4px;
  }

  &__filter-value {
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
  }

  &__filter-label {
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    padding: 4px 8px;
    border-radius: 4px;
    white-space: pre-wrap;
    word-break: break-all;
  }

  &__filter-fit-icon {
    margin-left: 4px;
    width: 16px;
    height: 16px;
  }
}
