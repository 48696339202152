@import "src/styles/variables.scss";

.grid-box {
  &--small {
    padding: 7px 16px 6px 16px !important;
  }
}

.react-grid-item:not(.react-grid-placeholder) {
  border: dashed 1px #d3d5e1;
  border-radius: 6px;
  background-color: #fff;
  outline: none;
  padding: 14px;

  ~ .react-grid-item {
    &--small {
      padding: 8px !important;
    }
    &--no-padding {
      padding: 0px !important;
    }
  }

  &:hover {
    border: dashed 1px #5d78ff;

    .react-resizable-handle,
    .react-drag-handle,
    .react-remove-handle {
      opacity: 1;
    }
  }
}

.react-draggable-dragging {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  z-index: $z-index-max;
}

.react-grid-item {
  .profile-box {
    width: 100%;
  }

  &__content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
    outline: none;
    // padding: 8px;
  }

  // &:hover {
  //   .react-grid-item__content {
  //     background-color: #eff1ff;
  //   }
  // }

  .profile-box {
    padding: 0;
    border: none;
  }
}

.react-grid-placeholder {
  background: #eff1ff;
  transition: all 0.2s ease;
}

.react-resizable-handle {
  position: absolute;
  width: 34px;
  height: 34px;
  bottom: -17px;
  right: -17px;
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-image: url("../../../../images/resize.svg");
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  cursor: se-resize;
  background-color: #5d78ff;
  border-radius: 4px;
  padding: 4px;
  opacity: 0;
}

.react-remove-handle {
  position: absolute;
  width: 34px;
  height: 34px;
  top: -17px;
  right: -17px;
  background-color: #5d78ff;
  border-radius: 4px;
  display: grid;
  place-items: center;
  opacity: 0;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: white;
    }
  }
}

.react-drag-handle {
  display: grid;
  place-items: center;
  background-color: #5d78ff;
  position: absolute;
  width: 34px;
  height: 34px;
  bottom: -17px;
  left: calc(50% - 17px);
  opacity: 0;
  border-radius: 4px;
  cursor: move;

  &:active {
    cursor: move;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.static {
  .react-resizable-handle,
  .react-drag-handle,
  .react-remove-handle {
    display: none;
  }
  background-color: transparent !important;
}
