.coupon-groups {
  height: calc(100vh - 64px);
  overflow-y: auto;
  width: 100%;
  padding: 44px 48px;

  &__topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    margin-bottom: 20px;

    &-wrapper {
      display: flex;
      align-items: center;
    }
  }
}
