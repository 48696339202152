.brand-safety-email-notification-tooltip {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__header {
    color: white;
    font-weight: 500;
  }
  &__text {
  }
}
