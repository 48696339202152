.showcase-template {
  @media print {
    break-after: page;
  }

  position: relative;
  background-color: white;
  min-height: 10px;
  // overflow: hidden;
  -webkit-print-color-adjust: exact;

  .react-grid-layout {
    transition: all 0.2s ease;
  }

  &--hidden .showcase-template-content {
    opacity: 0;
  }

  &__separator {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    border: none !important;
    border-bottom: dashed 1px;
    padding: 0 !important;
    z-index: 0 !important;

    &__info {
      position: absolute;
      bottom: 12px;
      font-size: 10px;
      font-weight: 400;
      line-height: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: #b4b6be;
    }

    &-colored-line {
      position: absolute;
      bottom: 0px;
      width: calc(100% + 48px);
      height: 4px;
      left: 50%;
      transform: translateX(-50%);
    }

    &-line {
      position: absolute;
      width: 900px;
      width: calc(100% + 66px);
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      border-bottom: dashed 1px #9eaeff;

      &--hidden {
        display: none;
      }

      &::before {
        content: "";
        position: absolute;
        top: -3px;
        width: 0;
        height: 0;
        left: -6px;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-left: 6px solid #9eaeff;
      }

      &::after {
        content: "";
        position: absolute;
        top: -3px;
        width: 0;
        height: 0;
        right: -6px;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-right: 6px solid #9eaeff;
      }
    }

    &-provider {
      position: absolute;
      display: flex;
      align-items: center;
      top: calc(50% + 12px);
      color: #74788d;
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;
      height: 20px;

      svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }

    &-campaign-logo {
      position: absolute;
      top: calc(50% + 8px);
      right: 12px;
      height: 24px;

      img,
      svg {
        height: 24px;
      }

      img {
        object-fit: contain;
      }
    }

    .react-resizable-handle {
      display: none;
    }
  }
}
