.project-details {
  height: 100%;
  display: flex;
  flex-direction: column;

  .project-summary-boxes {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3 - 13px));
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    grid-auto-rows: 190px;
  }

  .project-summary-boxes-empty {
    color: #8e93ac;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  // Kacper TODO: wydzielić
  .dashed-box-header {
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    color: #a7abc3;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 12px;
  }

  .creators-insights-tab {
    .tab-filter {
      &__header {
        font-size: 12px;
        padding-top: 5px;
        padding-bottom: 22px;
        color: #74788d;
        line-height: 20px;
      }

      &__select {
        width: 230px;
        margin-bottom: 22px;
        cursor: pointer;
      }
    }

    .tab-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 20px;
      grid-column-gap: 20px;
    }
  }

  .audience-data-tab {
    .tab-filter {
      &__header {
        font-size: 12px;
        padding-top: 5px;
        padding-bottom: 12px;
        color: #74788d;
        line-height: 20px;
      }

      &__info-bar {
        background: #ededef;
        border-radius: 4px;
        padding: 6px;
        display: flex;
        margin-bottom: 20px;

        &-text {
          color: #6a6d7c;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          max-width: 700px;
        }

        svg {
          margin-right: 8px;
          width: 16px !important;
          height: 16px !important;
        }
      }

      &__select {
        width: 230px;
        margin-bottom: 22px;
        cursor: pointer;
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 20px;
      grid-column-gap: 20px;
    }

    &__second-grid {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 20px;
      grid-column-gap: 20px;
    }
  }

  .project-details-tabs-container {
    .loader-wrapper {
      margin: 0 auto;
      margin-top: 50px;
      width: 100px;
      height: auto;
    }
  }

  .project-details-empty-tab {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--publishers,
    &--all-creators {
      text-align: center;
    }

    &__icon {
      width: 32px;
      height: 32px;
      margin-bottom: 20px;
    }

    &__text {
      color: #74788d;
    }
  }

  .pane__container {
    border-top: none;
    &-wrap {
      border-top: 1px solid #f2f2f6;
      overflow-y: auto;
    }
  }

  .select__value-wrapper {
    max-width: unset;
  }
}
