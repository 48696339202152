.influencers-list__cell--author {
    display: flex;
    gap: 8px;
    align-items: center;

    &__avatar {
        width: 24px;
        height: 24px;
        border-radius: 100%;
    }

    &__name {

    }
}