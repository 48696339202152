.extensions {
  position: relative;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__col {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__row {
    display: flex;
    gap: 12px;
  }

  &__list {
    .extensions__item {
      margin-bottom: 16px;
    }
    &-header {
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 14px;
      color: #8f919d;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 16px;
    border-bottom: 1px dashed #dadade;
  }

  &__item-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__name {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #45485b;
    margin-bottom: 4px;
  }

  &__header {
    &-text {
      margin-top: 5px;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #8f919d;
    max-width: 476px;
    display: flex;
    justify-content: space-between;
  }

  &__action-btn,
  &__action-blue-btn {
    all: unset;
    cursor: pointer;
    display: flex;
    color: #45485b;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    gap: 4px;
    transition: color 0.2s;

    span {
      color: #45485b;
    }

    svg path {
      fill: #45485b;
    }

    &--disabled {
      color: #d3d5e1;
      cursor: not-allowed;
      pointer-events: none;

      svg path {
        fill: #d3d5e1;
      }
    }
  }

  &__action-blue-btn {
    color: #5d78ff;

    span {
      color: #5d78ff;
    }

    &:hover {
      color: #7d93ff;
    }
  }

  &__back-btn {
    all: unset;
    cursor: pointer;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    color: #b4b6be;
  }

  &__back-label {
    all: unset;
    margin-left: 5px;
    padding-right: 5px;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    color: #b4b6be;
  }

  .loader-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__tabs {
    display: flex;
    flex: 1;
    .tab {
      padding: 13px 20px;
    }

    .tabs {
      margin-bottom: 0;
    }

    &--small {
      .tab {
        padding: 11px 12px;
      }
      .tab__name {
        font-size: 12px;
      }
    }
  }

  &__how-it-works-btn {
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #6a6d7c;
    transition: color 0.2s;
    &:hover {
      color: #b4b6be;
    }
  }
}

.recruitment-form__share-wrapper .extensions__item {
  border-bottom: 0;
}
