.content-proposal-comment {
  display: flex;
  flex-direction: column;
  gap: 32px;
  &__caption {
    position: relative;
    border-radius: 8px;
    background: #f6f6f7;
    padding: 16px;
    color: #6a6d7c;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    font-style: italic;
    width: fit-content;
    max-width: 100%;
    word-wrap: break-word;
    .content-proposal-comment__indicator {
      bottom: -5px;
    }
  }
  &__media-wrapper {
    position: relative;
    width: fit-content;
    .attachment-wrapper--content-proposal:has(.attachment-file)
      + .content-proposal-comment__indicator {
      bottom: 0;
    }

    .comment-video__image {
      width: 330px;
      height: 330px;
    }

    .attachment-file {
      width: 330px;
      height: 330px;
      max-height: 330px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 0;
      margin-bottom: 5px;

      .attachment-file__url-icon svg {
        width: 80px;
        height: 80px;
      }

      svg {
        width: 150px;
        height: 150px;
      }

      &__details {
        max-width: 330px;
        padding-top: 5px;
      }

      &__type {
        color: #b4b6be;
        font-size: 13px;
        font-weight: 400;
      }

      &__download {
        color: #555763;
        font-size: 13px;
        font-weight: 400;
        transition: all 300ms;
        text-decoration: underline;
      }
    }

    .attachment-video__placeholder {
      width: 330px;
      height: 310px;
      background: #f6f6f7;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
  }
  &__content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 12px;
    &--caption {
      flex-basis: 100%;
      position: relative;
      max-width: 100%;
    }

    &--rejected {
      .content-proposal-comment__caption,
      .content-proposal-comment__media-wrapper {
        opacity: 0.3;
      }
    }
  }
  &__accept-reject-buttons {
    display: flex;
  }
  &__btn {
    width: 165px;
    height: 46px;
    background-color: #fff;
    border: 1px solid #ededef;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 12px;
    gap: 8px;
    color: #45485b;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    transition: all 0.2s;

    &:hover,
    &--selected {
      background: #f6f6f7;
    }

    &:disabled {
      pointer-events: none;
      color: #b4b6be;
      img {
        filter: grayscale(1);
      }
    }

    img {
      width: 20px;
      height: 20px;
    }

    &--accepted {
      color: #389652;
      background-color: #eff8f1;
      pointer-events: none;
    }

    &--rejected {
      background: #f6f6f7;
      pointer-events: none;
    }

    &:nth-child(1) {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-right-width: 0;
    }

    &:nth-child(2) {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  &__indicator {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    outline: 5px solid #fff;
    position: absolute;
    bottom: 19px;
    right: -4px;
    &--accepted {
      background-color: #57b471;
    }
    &--fresh {
      background-color: #5d78ff;
    }
    &--rejected {
      background-color: #74788d;
    }
  }

  &__element-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__element-title {
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    color: #8f919d;
    text-transform: uppercase;
  }
}
