.attachment-wrapper {
  position: relative;

  &__preview-img,
  &__preview-video,
  &__preview-url {
    height: 64px;
    width: 64px;
  }

  &__preview-url {
    border-radius: 4px;
    background-color: #f6f6f7;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s;
    &:hover {
      background-color: #ededef;
    }
  }

  &--dropdown {
    border-radius: 4px;
    background-color: #ededef;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &--in-comment {
    width: 100%;
  }

  &__inline {
    display: flex;
    gap: 10px;
    padding: 16px 32px 16px 0px;

    .attachment-image,
    .attachment-video {
      width: 64px !important;
      height: 64px !important;
    }

    .attachment-file__details {
      justify-content: center;
    }

    .extension-icon {
      margin-left: 20px;
      margin-right: 12px;
    }
  }

  .remove-icon {
    position: absolute;
    right: -7px;
    bottom: -7px;
    cursor: pointer;
    z-index: 1;
  }

  .dnd-icon {
    display: none;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &--white {
      fill: #fff;
    }
    &--gray {
      fill: #6a6d7c;
    }
    &--visible {
      display: block;
    }
  }

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    border-radius: 4px;
    opacity: 0;
    transition: all 0.2s;
    &--visible {
      opacity: 1;
    }
  }

  &--in-comment {
    margin-top: 0;
    margin-right: 0;
  }

  &:hover {
    cursor: pointer;
  }

  .attachment-file {
    margin: 0;
    width: fit-content;

    &__dnd-icon {
      margin-left: 8px;
      opacity: 0;
      &--visible {
        opacity: 1;
      }
    }

    &__remove-icon {
      position: absolute;
      right: -7px;
      bottom: -7px;
      cursor: pointer;
      z-index: 1;
    }
  }

  &.attachment-loader {
    .attachment-loader__icon {
      margin-left: 20px;
      margin-right: 22px;
    }
  }

  &--comments {
    cursor: default !important;
  }
}

.attachment-image {
  border: 1px solid #f6f6f7;
  border-radius: 8px;
  width: 110px;
  height: 64px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  flex-shrink: 0;
  transition: all 0.1s ease;

  &__small {
    border-radius: 8px;
    width: 40px;
    height: 40px;
  }

  &--hovered {
    filter: brightness(70%);
  }
}

.attachment-preview-video,
.attachment-preview-image {
  border: 1px solid #dadade;
  border-radius: 4px;
  object-fit: cover;
}

.attachment-preview-image {
  width: 100% !important;
  height: 100% !important;
}

.attachment-video {
  border: 1px solid #f6f6f7;
  border-radius: 8px;
  width: 110px;
  height: 64px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  transition: all 0.1s ease;

  &__small {
    border-radius: 8px;
    width: 40px;
    height: 40px;
  }

  &__inline {
    border-radius: 8px;
    width: 64px;
    height: 64px;
    margin-right: 10px;
  }

  &__placeholder {
    border-radius: 8px;
    width: 110px;
    height: 64px;
    position: relative;
    text-align: center;

    &-border {
      border: 1px solid #f6f6f7;
    }
  }

  &__div {
    position: relative;
    display: inline-block;
  }

  &__play-icon-btn {
    all: unset;
    cursor: pointer;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    backdrop-filter: blur(6px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.2);
  }

  &__play-icon {
    width: 35px;
    height: 35px;
    margin: 0px !important;

    &__small {
      width: 25px;
      height: 25px;
      position: absolute;
      bottom: 5px;
      left: 0px;
      margin: 0px !important;
    }
  }

  &--hovered {
    filter: brightness(70%);
  }
}

.comment-image {
  width: 330px;
  height: 330px;
  border: 1px solid #dadade;
  border-radius: 8px;
  object-fit: cover;

  &:hover {
    cursor: pointer;
  }
}

.comment-video {
  margin-top: 8px;

  &:hover {
    cursor: pointer;
  }

  &__image {
    width: 330px;
    height: 330px;
    border: 2px solid #dadade;
    border-radius: 8px;
    object-fit: cover;
    cursor: pointer;
  }
}

.attachment-file {
  background-color: #f6f6f7;
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 16px 32px 16px 0px;
  max-height: 64px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #ededef;
  }

  &__download {
    color: #555763;
    text-decoration: underline;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &--in-comment {
    width: 400px;
    margin-top: 8px;
    margin-right: 0;
    margin-top: 0;
  }

  &__details {
    display: flex;
    flex-direction: column;
  }

  &__icon {
    margin-left: 20px;
    margin-right: 12px;
  }

  &__name {
    color: #74788d;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 190px;

    &--in-comment {
      width: 326px;
    }
  }

  &__download-wrapper {
    display: flex;
  }

  &__type {
    color: #a7abc3;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
    white-space: nowrap;
    max-width: 75%;
    text-overflow: ellipsis;
    overflow: hidden;
    &::after {
      content: "・";
      margin: 0 4px;
      vertical-align: middle;
      color: #b4b6be;
    }
  }

  &__download {
    color: #a7abc3;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    transition: all 0.1s ease-out;

    &:hover {
      cursor: pointer;
      color: #6a6d7c;
    }
  }
}

.attachment-settings-dropdown {
  position: absolute;
  top: 50%;
  right: 8px;
  z-index: 1;
  transform: translateY(-50%);

  &--dark {
    > svg path {
      fill: #8e93ac;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.attachment-details {
  display: flex;
  gap: 4px;
  color: #b4b6be;
  font-size: 13px;
  line-height: 16px;
  padding-top: 5px;
  &__name {
    max-width: 230px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__download-link {
    color: #555763;
    transition: all 300ms;
    span {
      text-decoration: underline;
    }
  
    &::before {
      content: "・";
      margin: 0 4px;
      vertical-align: middle;
      color: #b4b6be;
    }
  }
}