.search-section {
  padding: 0 12px;

  &__header {
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    color: #A1A3AD;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    margin: {
      top: 12px;
      left: 8px;
      bottom: 9px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 60px;
    border-radius: 6px;
    padding-left: 8px;
    transition: all 0.2s ease;
    margin-bottom: 3px;

    &:hover {
      background-color: rgba(242, 242, 246, 0.48);
    }

    &-cover {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-right: 12px;
      background-color: #f2f2f6;

      &--square {
        border-radius: 3px;
      }
    }

    &-tick {
      width: 48px;
      height: 48px;
      margin-right: 8px;
    }

    &-name {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      width: 365px;
      color: #45485B;

      .name-section {
        &__main {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &__description {
          font-size: 11px;
          color: #7D808D;
          margin-top: 2px;
          font-weight: 300;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &__item-icon {
    &--dictionary-element {
      background-color: #EDEDEF;
      border-radius: 4px;
      padding: 7px 7px 3px 7px;
      margin-right: 10px;
      width: 48px;
      height: 48px;
      display: flex;
      align-content: center;
      align-items: center;

      span {
        width: 100%;
        display: block;
        text-align: center;
      }

      svg {
        width: 20px;

        path {
          fill: #C8C9CE;
        }
      }

    }
  }
}
