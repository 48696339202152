.cover-summary {
  position: relative;
  height: 490px;
  padding: 0px 24px;

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__box {
    width: 100%;
    height: 410px;
    border-radius: 6px;
    border: dashed 1px #dadade;
    padding: 16px;

    &-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: #45485b;
      margin-bottom: 12px;
    }

    textarea {
      height: 340px;
      width: 100%;
      resize: none;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: #45485b;
      padding: 0;
      border: 0;
      outline: 0;
      overflow: hidden;
    }
  }
}
