@import "../../../styles/mixins.scss";

.ws-react-select {
  width: 100%;

  &--is-disabled {
    .ws-react-select__control {
      background: rgba(#d3d5e1, 0.3) !important;
    }
  }

  &-container {
    margin-bottom: -40px;
  }

  &-label {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #a7abc3;
    margin-bottom: 4px;
  }

  &-wrapper {
    position: relative;
    display: flex;

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
      .ws-react-select .ws-react-select__control {
        background: #f2f2f6;
      }
    }

    &--hide-placeholder {
      .ws-react-select__placeholder {
        display: none;
      }
    }
  }

  &__placeholder {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #b4b6be;
    position: absolute;
    top: 11px;
    left: 16px;
    cursor: default;
  }

  &__height {
    height: 40px !important;
    width: 1px;
    display: inline-block;
    position: relative;
    left: -5px;
    top: -40px;
  }

  &__input {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }

  .image-select-option {
    display: flex;
    align-items: center;

    &__text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    svg {
      margin-right: 8px;
    }
  }

  // class is doubled to write styles without !important
  .ws-react-select {
    &__control {
      border: 1px solid #b4b6be;
      border-radius: 8px;
      min-height: 40px;
      background: #fff;

      &:hover {
        border-color: #b4b6be;
      }

      .ws-react-select__dropdown-indicator {
        transition: all 0.15s ease;
        margin: 10px 10px 0 4px !important;
      }

      &--menu-is-open {
        border-color: #fff !important;
        box-shadow: none;

        .ws-react-select__multi-value,
        .ws-react-select__single-value {
          display: none;
        }
        .ws-react-select__clear-indicator {
          display: none;
        }
        .ws-react-select__dropdown-indicator {
          transform: rotate(180deg);
        }
      }

      &:not(.ws-react-select__control--menu-is-open) {
        .ws-react-select__input {
          position: absolute;
          left: -9999px;
        }
      }

      &--is-focused {
        border-color: #fff;
      }
    }

    &__clear-indicator,
    &__dropdown-indicator {
      cursor: pointer;
      background-size: contain;
      width: 20px;
      height: 20px;
      padding: 0;

      &:hover {
        opacity: 0.7;
      }

      svg {
        display: none;
      }
    }

    &__clear-indicator {
      // background: url("../../../images/circle-cross.svg") no-repeat center;
      margin: 10px 4px 0;
    }

    &__dropdown-indicator {
      background: url("../../../images/chevron-select.svg") no-repeat center;
      margin: 10px 10px 0 4px;
    }

    &__indicators {
      align-items: flex-start;
      z-index: 11;
      position: relative;
    }

    &__indicator-separator {
      display: none;
    }

    &__value-container {
      padding-left: 15px;
      min-height: 32px;

      &--is-multi {
        padding: 4px 16px;
        margin: 0;

        div:last-of-type {
          // input wrapper without class when isSearchable is true
          margin: 0;
          &::after {
            display: none;
          }
        }

        input:not(:focus) {
          position: absolute;
        }
      }
    }

    &__single-value {
      font-weight: 500;
      font-size: 12px;
      line-height: 1.5;
      color: #6a6d7c;
    }

    &__multi-value {
      display: flex;
      align-items: center;
      width: 100%;
      height: 30px;
      margin: 0;
      padding: 0;
      width: 160px;

      &__label {
        padding: 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 1.5;
      }

      &__remove {
        display: none;
      }
    }

    &__menu {
      margin: -1px 0 0;
      background: #fff;
      border-radius: 8px;
      border-top-color: #fff;
      box-shadow: none;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      z-index: 12;
      padding: 6px 0;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: -39px;
        box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
        z-index: -1;
        border-radius: 8px;
        pointer-events: none;
      }
    }

    &__option {
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      padding: 4px 16px;
      color: #45485b;
      display: flex;
      align-items: center;
      cursor: pointer;
      word-break: break-word;
      min-height: 32px;

      &-description {
        margin-top: 4px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #6a6d7c;
      }

      &:active {
        background: transparent;
      }

      &--is-focused {
        background: #f6f7f9;
      }

      &--is-selected {
        background: transparent;

        &::after {
          content: "";
          background: url("../../../images/tick.svg") no-repeat center;
          width: 20px;
          height: 20px;
          margin-left: 10px;
          display: inline-block;
          flex-shrink: 0;
        }

        &:hover,
        &.ws-react-select__option--is-focused {
          background: #f6f7f9;
        }

        svg {
          margin-right: 8px;
        }
      }

      &--is-disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &--sticky-bottom {
        position: sticky;
        bottom: 0px;
        background: white;
        padding-bottom: 4px;
        border-radius: 0 0 0 8px;
        border-top: solid #e5e6ed 1px;
        overflow: hidden;
      }

      &--hidden {
        display: none;
      }
    }
  }

  &--with-tiles {
    .ws-react-select {
      &__value-container {
        &--is-multi {
          display: flex;
          flex-wrap: wrap;
          gap: 6px;
          padding: 6px;
        }
      }

      &__multi-value {
        width: fit-content;
        margin: 0;
        gap: 6px;
        display: flex;
        align-items: center;
        color: #8e93ac;
        padding: 8px;
        border-radius: 4px;
        height: 36px;

        &__remove {
          display: flex;
          margin-left: 4px;
          cursor: pointer;

          svg path {
            transition: fill 0.1s ease;
          }
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }

  &__menu-list-wrap {
    max-height: 226.109px;
    display: flex;
    flex-direction: column;
  }

  &__menu-list {
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 8px;

    @include custom-scrollbar;
  }
}

.ws-react-select__menu-portal {
  .ws-react-select__menu {
    margin: -1px 0 0;
    background: #fff;
    border-radius: 8px;
    border-top-color: #fff;
    box-shadow: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: -39px;
      box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
      z-index: -1;
      border-radius: 8px;
    }
  }

  .ws-react-select__option {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    padding: 4px 16px;
    color: #8e93ac;
    display: flex;
    align-items: center;
    cursor: pointer;
    word-break: break-word;
    min-height: 32px;

    &:active {
      background: transparent;
    }

    &--is-focused {
      background: #f6f7f9;
    }

    &--is-selected {
      background: transparent;
      color: #74788d;

      &::after {
        content: "";
        background: url("../../../images/tick.svg") no-repeat center;
        width: 20px;
        height: 20px;
        margin-left: 10px;
        display: inline-block;
        flex-shrink: 0;
      }

      &:hover,
      &.ws-react-select__option--is-focused {
        background: #f6f7f9;
      }

      svg {
        margin-right: 8px;
      }
    }

    &--is-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.menu--open {
  animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.menu--close {
  animation: fadeOut 0.2s ease-in-out;
}

.ws-react-select__input {
  font-family: "Poppins", sans-serif;
}
