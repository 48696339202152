.audiences-manager-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
  padding: 47px 0;

  &__text {
    max-width: 350px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #6a6d7c;

    &-header {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #45485b;
      margin: 0;
    }
  }

  &__image {
    width: 633px;
    height: 422px;
  }

  &__button {
    .emoji {
      height: 18px;
    }
  }
}
