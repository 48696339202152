.field-icons {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px dashed #cacddb;
  border-bottom: 1px dashed #cacddb;
  margin-top: 16px;

  &__switch {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #74788d;
    gap: 15px;
  }

  &__selectors {
    display: flex;
    align-items: center;

    .icon-color-selector {
      margin-right: 20px;
    }
  }
}
