.cover-top {
  position: relative;
  height: 490px;
  padding: 20px;

  &__background-color {
    height: 310px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0px;
  }

  &__images {
    position: relative;
    width: 100%;
    height: 310px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-image {
      width: 265px;
      height: 310px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
        flex-grow: 1;
        object-fit: cover;
      }
    }
  }

  &__info {
    border: dashed 1px #d3d5e1;
    border-radius: 6px;
    padding: 16px 20px;
    margin-top: 20px;
    display: flex;
    gap: 20px;
    align-items: center;
    color: #45485b;

    .idh-aa-logo img {
      max-width: 80px;
      height: 80px;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
    }

    &-title {
      input {
        padding: 0;
        border: 0;
        outline: 0;
        overflow: hidden;
        width: 500px;
        color: #45485b;
        height: 36px;
        font-size: 28px;
        font-weight: 500;
        line-height: 36px;
      }
    }
  }
}
