.progress-bar-data {
  width: 100%;

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    justify-content: space-between;
  }

  &__icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  &__label {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #a7abc3;
    display: flex;
    align-items: center;
    word-wrap: break-word;

    svg {
      margin-right: 8px;
    }
  }

  &__bar {
    flex: 1;
    padding: 0 24px;
  }

  &__value {
    width: 34px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #74788d;
    white-space: nowrap;
  }

  &__country {
    display: flex;
    align-items: center;

    .flag {
      margin-right: 8px;
    }
  }
}
