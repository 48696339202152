.status {
  height: 28px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  gap: 6px;

  img {
    width: 16px;
    height: 16px;
  }

  &--green-01 {
    color: #228c77;
    background-color: #daf3ee;
  }
  &--green-02 {
    color: #389652;
    background-color: #ddf0e3;
  }
  &--grey-01 {
    color: #5a607c;
    background-color: #f2f2f6;
  }
  &--grey-02 {
    color: #6a6d7c;
    background-color: #ededef;
  }
}
