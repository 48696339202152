.campaign-info {
  display: flex;
  gap: 8px;
  align-items: center;

  &__col {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &--big {
    .campaign-info__name {
      font-size: 20px;
    }
  }

  a.campaign-info__name {
    &:hover {
      opacity: 0.7;
    }
  }

  &__name-wrapper {
    display: flex;
    align-items: center;
  }

  &__name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #45485b;
    word-break: break-word;
    margin-right: 8px;
  }

  &__folder {
    display: flex;
  }

  &__folder-name {
    display: flex;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #d3d5e1;

    &:hover {
      opacity: 0.7;
    }

    &::before {
      content: "";
      background: url("../../../../../../../../../images/folder-grey.svg")
        no-repeat center;
      background-size: contain;
      display: inline-block;
      margin-right: 4px;
      width: 16px;
      height: 16px;
    }
  }

  &__edit {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #8e93ac;
    margin-left: 16px;

    &:hover {
      cursor: pointer;
    }

    &::before {
      content: "";
      background: url("../../../../../../../../../images/edit.svg") no-repeat
        center;
      background-size: contain;
      display: inline-block;
      margin-right: 4px;
      vertical-align: middle;
      width: 16px;
      height: 16px;
    }
  }

  &__additional {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.5;
    color: #a7abc3;
    display: flex;
    align-items: center;
  }

  &__billing-type {
    text-transform: uppercase;
    margin-left: 16px;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: block;
      background: url("../../../../../../../../../images/billing-type.svg")
        no-repeat center;
      background-size: contain;
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }

  &__dates {
    margin-left: 35px;
  }

  &__end-date {
    margin-left: 10px;

    &::before {
      content: "";
      background: url("../../../../../../../../../images/arrow-tail-right.svg")
        no-repeat center;
      background-size: contain;
      width: 16px;
      height: 16px;
      margin-right: 10px;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -1px;
    }
  }

  .social-provider-icon2--instagram {
    border-radius: 8px;
  }

  .campaing-info-tooltip-icon {
    cursor: pointer;

    &:hover path {
      fill: #6a6d7c;
    }
  }
}
