.influencers-list {
  position: relative;

  &__heading {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.03em;
    color: #45485b;
    font-weight: 500;
    margin: 0 0 6px 0;
  }

  &__description {
    font-size: 14px;
    line-height: 22px;
    color: #7D808D;
    font-weight: 400;
    margin: 0;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .ws-button {
      padding: 12px;
      padding-right: 20px;
      gap: 4px;
      font-weight: 500;
    }

    &-btns {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }

  &__actions-button {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 6px;
    padding: 0;
    width: 44px;
    height: 44px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    svg path {
      fill: #8f919d;
    }

    &--open {
      background: #dceafb;

      svg path {
        fill: #1267fb;
      }
    }
  }

  &__actions-tooltip {
    flex-direction: column;
  }

  &__actions-separator {
    color: #74788d;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: left;
    border-bottom: 1px dashed #cacddb;
    margin: 0 16px 6px 16px;
    padding: 6px 0 16px 0;
  }

  &__action {
    display: flex;
    align-items: center;
    min-width: 110px;

    &-icon {
      svg path {
        width: 20px;
        height: 20px;
        fill: #a7abc3;
      }
    }
  }

  .influencers-list-table {
    .author-column {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .cdt-table {
    &__header-column {
      justify-content: start;
    }
    &__header--column {
      &-front_brand_safety_email_notification {
        text-align: left;
      }
    }

    thead tr {
      border-top: 1px dashed #d3d5e1;

      th {
        font-size: 10px;
        font-weight: 600;
        color: #a1a3ad;
        letter-spacing: 0;

        padding: 10px 12px;
      }

      th:nth-child(2) {
        border-right: 1px dashed #d3d5e1;
      }

      th:nth-child(3) {
        padding-left: 39px;
      }

      th:last-child {
        border-left: 1px dashed #d3d5e1;
      }
    }

    tbody tr {
      height: 100px;

      td {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #45485b;
      }

      td:nth-child(2) {
        border-right: 1px dashed #d3d5e1;
      }

      td:nth-child(3) {
        padding-left: 39px;
      }

      td:last-child {
        border-left: 1px dashed #d3d5e1;
        text-align: center;
      }
    }
    td {
      text-align: start;
    }
  }
}

.photos-column {
  width: 100px;
}

.dropdown__menu-separator {
  padding: 5px 20px;
  margin-top: 0;
}
