@import "../../../styles/mixins.scss";

.top-search {
  display: flex;
  align-items: center;
  height: 64px;

  &--focused {
    .top-search__input {
      transform: none;
    }

    .top-search__magnifier-icon {
      opacity: 0;
      // visibility: hidden;
    }
  }

  &:hover {
    .top-search__magnifier-icon {
      path {
        fill: #5d78ff;
      }
    }
  }

  &__magnifier-icon {
    position: absolute;
    margin-right: 8px;
    transition: all 0.2s ease;
    pointer-events: none;

    path {
      transition: all 0.2s ease;
    }
  }

  &__input-wrapper {
    position: relative;
    width: 420px;
  }

  &__input {
    outline: none;
    border: none;
    width: 420px;
    transform: translateX(32px);
    transition: all 0.2s ease;
    color: #74788d;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    &::placeholder {
      color: #a7abc3;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__clear-button {
    position: absolute;
    cursor: pointer;
    transition: all 0.2s ease;
    right: 0px;

    &--hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__menu {
    position: absolute;
    width: 480px;
    max-height: 90vh;
    left: 52px;
    top: 64px;
    background-color: #fff;
    box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 16px 16px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 7px 0;
    z-index: 100;
    @include custom-scrollbar;

    &-loader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .loader-wrapper {
        height: 36px;
        width: 49px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    &-placeholder {
      display: flex;
      align-items: center;
      padding: 0 20px;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      color: #a7abc3;

      svg {
        margin-right: 12px;
      }
    }
  }
}
