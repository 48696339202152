@import "src/styles/variables.scss";

.tooltip-popper__content {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  background: #45485b;
  color: #dadade;
  border-radius: 8px;
  padding: 8px 12px;
  box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
  opacity: 1;
  z-index: $z-index-max;
  max-width: 250px;
  word-break: break-word;

  &::before {
    content: "";
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 1px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 8px 6px;
    border-color: transparent transparent #45485b transparent;
  }

  &--center {
    text-align: center;
  }
}
