.creators {
  position: relative;

  .ws-react-select-container {
    width: 180px;
    margin-bottom: -50px;
  }

  .loader-wrapper {
    margin: 0 auto;
    margin-top: 200px;
    width: 100px;
    height: auto;
  }
}
