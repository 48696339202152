.dictionary-element-selector {
  width: 244px;

  &__value {
    display: flex;
    align-items: center;

    &:hover {
      .dictionary-element-selector__value-link {
        opacity: 1;
      }
    }

    &-link {
      margin-left: 12px;
      cursor: pointer;
      opacity: 0;
      transition: all 0.2s ease;
    }
  }
}

.dictionary-element-option {
  cursor: pointer;
  display: flex;
  width: 100%;
  gap: 10px;

  &__row {
    display: flex;
    gap: 5px;
    width: 100%;
    align-items: start;
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;

    &-sub-field {
      width: 100%;
      max-width: 190px;

      &-text {
        border-radius: 8px;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #5a607c;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        //background: #F2F2F6;
        //padding: 4px 8px;
      }
    }
  }

  span {
    width: 20px;
    height: 20px;
  }
}

.dictionary-element-selector-menu {
  font-family: Poppins;
  width: 274px;
  max-height: 288px;
  padding: 0 !important;

  .ws-react-select-container {
    margin-bottom: 0;
  }

  .ws-react-select__menu-list {
    max-height: 240px !important;
  }

  .ws-react-select__height {
    display: none;
  }

  .ws-react-select__option {
    width: 100%;

    .image-select-option {
      flex-basis: 20px;
    }
  }

  .ws-react-select__menu {
    position: static;
    &::before {
      transition: none;
      top: 0;
    }

    &-list {
      max-height: 160px;
    }
  }
}
