.social-data {
  padding-top: 10px;

  &__tab {
    display: flex;
    align-items: center;
    text-transform: capitalize;

    img {
      margin-right: 8px;
    }
  }
}
