@import "../../styles/media-queries.scss";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.credibility-tool-page {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
}

.credibility-tool-page-left {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 510px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  padding: 64px 64px 42px 64px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  video {
    height: 100%;
    width: 510px;
    float: left;
    top: 0;
    left: 0px;
    position: fixed;
    z-index: -2000;
    object-fit: none ;
  }

  &__video-wrapper::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    content: '';
    z-index: -1000;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 40.82%, rgba(0, 0, 0, 0.70) 71.3%);
  }

  @include max-screen($desktopWidth) {
    display: none;
    min-height: unset;
  }

  &__container {
    margin-top: 24px;
  }

  &__header {
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
  }

  &__description {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
  }

  &__box {
    color: #DADADE;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    border-radius: 16px;
    padding: 16px;
    display: flex;

    &-column {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &--blue {
      background: rgba(93, 120, 255, 0.2);
      border: 1px solid rgba(93, 120, 255, 0.3);
      backdrop-filter: blur(5px);
    }
  }

  &__profile {
    display: flex;

    &-avatar{
      img {
        height: 48px;
        width: 48px;
      }
    }

    &-data {
      display: flex;
      flex-direction: column;

      &-header {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
      }

      &-description {
        display: flex;
        gap: 15px;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }

  &__credibility-score {
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
    }

    .progress-bar {
      width: 130px;
    }

    .credibility-score {
      &-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;

      }

      &-value {
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }

  &__box-icon {
    flex-shrink: 0;
    margin-right: 16px;
    width: 24px;
    height: 24px;
  }

  &__social-platform {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #8f919d;
    display: flex;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 4px;
  }

  &__social-platform-icon {
    margin-right: 4px;
    width: 16px;
    height: 16px;
  }

  &__copyrights {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #8f919d;
  }
}

.credibility-tool-page-right {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: calc(100% - 510px);
  margin-left: auto;

  .ws-react-select__placeholder {
    font-size: 13px;
    line-height: 22px;
  }

  &__wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__navigation {
    padding: 40px 40px 0 40px;
    @include max-screen($desktopWidth) {
      padding: 0;
    }
  }

  &__text-area-wrapper {
    position: relative;
  }

  @include max-screen($desktopWidth) {
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    display: block;
    min-height: unset;
    width: 100%;
    margin-bottom: 0;

    .ws-button {
      font-size: 16px;
      line-height: 28px;
    }

    .ws-input-label {
      font-size: 14px;
      line-height: 22px;
    }

    .ws-react-select__placeholder {
      font-size: 14px;
      line-height: 22px;
    }

    .ws-react-select__input {
      height: 48px;
    }

    .ws-react-select__single-value {
      font-size: 14px;
      line-height: 22px;
    }

    .ws-react-select__value-container {
      min-height: 34px;
    }

    .ws-react-select__dropdown-indicator {
      margin-top: 14px;
    }

    .ws-react-select__height {
      height: 48px !important;
    }

    .ws-react-select__control {
      min-height: 48px;
    }

    .ws-react-select__option {
      font-size: 14px;
      line-height: 22px;
      min-height: 38px;
    }

    .ws-input {
      font-size: 16px;
      line-height: 28px;
      height: 48px;
      width: 100%;
    }

    .ws-checkbox {
      label {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  .ws-input {
    margin-bottom: 11px;
    &--validation-error {
      margin-bottom: 0;
    }
  }

  .ws-react-select .ws-react-select__multi-value {
    width: auto;

    &::after {
      content: ",";
      padding-right: 3px;
      color: #74788d;
    }
  }

  &__row {
    display: flex;
    gap: 19px;
  }

  &__column {
    width: 50%;
  }
  .ws-react-select__single-value {
    .credibility-tool-page-right__country-option {
      &-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 120px;
      }
    }
  }

  &__country-option {
    display: flex;
    gap: 8px;
    .flag {
      border-radius: 50%;
      width: 18px;
      height: 18px;
      object-fit: cover;
    }
  }

  .ws-react-select-wrapper {
    margin-bottom: 11px;
  }

  &__back-to-homepage {
    display: flex;
    align-items: center;

    a {
      color: #74788d;
    }
  }

  &__back-to-homepage-icon {
    margin-right: 4px;
  }



  &__header {
    font-weight: 500;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    color: #45485b;
    margin-bottom: 16px;

    &--confirm {
      margin-bottom: 48px;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #45485b;
    width: 440px;
    margin-bottom: 32px;

    @include max-screen($desktopWidth) {
      width: 100%;
    }
  }

  &__disclaimer {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #585b6c;
    width: 440px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin-top: 32px;

    @include max-screen($desktopWidth) {
      font-size: 14px;
      line-height: 22px;
      width: 100%;
    }

    a {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #5d78ff;
    }
  }

  &__data-processing-info {
    &__header {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #45485B;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 12px;

      svg {
        cursor: pointer;

        path {
          fill: #6A6D7C;
        }
      }
    }

    &__content {
      border-radius: 8px;
      background: #F6F6F7;
      padding: 8px 12px 12px 12px;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #45485B;

      .ws-checkbox{
        margin-top: 0px;
      }

      a {
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        color: #45485B;
      }
    }
  }

  &__user-message {
    color: #45485b;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-top: 8px;
    margin-bottom: 11px;
  }

  &__characters-left {
    &--under-limit {
      color: #57b471;
    }
    &--over-limit {
      color: #d93025;
    }
  }

  &__invalid-email-error {
    display: flex;
    align-items: center;
    color: #d93025;
    margin-bottom: 11px;
    font-size: 12px;
  }

  &__invalid-email-error-icon {
    margin-right: 4px;
  }

  &__sign-in {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #585b6c;
    margin-top: 8px;

    a {
      color: #5d78ff;
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #6a6d7c;
    margin-bottom: 16px;
  }

  &__bold {
    font-weight: 500;
    color: #45485b;
  }

  &__email-address {
    color: #45485b;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  &__try-again {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #45485b;
    margin-bottom: 32px;
  }

  &__try-again-link {
    color: #5d78ff;
    font-weight: 500;

    &:hover {
      cursor: pointer;
    }
  }

  &__container {
    max-width: 500px;
    display: flex;
    flex-direction: column;

    &-logo {
      margin-top: 20px;
      margin-bottom: 32px;
    }

    @include max-screen($desktopWidth) {
      width: 100%;
      margin-top: 40px;

      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &--confirm {
      align-items: center;
    }
  }

  &__confirm-wrapper {
    width: 400px;

    @include max-screen($desktopWidth) {
      width: 100%;
    }
  }

  &__vertical-line {
    border: 1px solid #ededef;
    width: 1px;
    margin: 0 auto;
    height: 176px;
  }

  &__box {
    display: flex;
    margin-bottom: 8px;
  }

  &__box-left {
    margin-right: 16px;
  }

  &__box-header-icon {
    margin-bottom: 8px;
  }

  &__box-header-text {
    color: #45485b;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  &__submit-wrapper {
    height: 80px;
    display: flex;
    align-items: flex-start;
    margin-top: 32px;
    .loader-wrapper {
      margin: 0;
      svg {
        width: 100px !important;
      }
    }
  }

  &__btn {
    padding: 12px 60px;
  }
}
