.export-wrapper {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;

  .export-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #45485b;
  }

  .export-types {
    display: flex;
    gap: 24px;
    align-items: center;

    &--disabled {
      .export-type {
        cursor: not-allowed;
        pointer-events: none;
        color: #d3d5e1;

        svg {
          path,
          rect {
            fill: #d3d5e1;
          }
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .export-type {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 32px;
    font-size: 12px;
    line-height: 22px;
    font-weight: 500;
    color: #45485b;
    text-transform: uppercase;

    svg {
      width: 100%;
      height: 100%;
      margin-right: 4px;

      path,
      rect {
        fill: #45485b;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }
}
