@import "../../../../../../styles/media-queries.scss";
@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.meta-field-members-list {
  overflow: auto;
}

.global-meta-field-table {
  &__head {
    display: flex;
    align-items: center;
    height: 44px;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #a7abc3;
    text-transform: uppercase;
    border: {
      top: 1px dashed #d3d5e1;
      bottom: 1px dashed #d3d5e1;
    }
    gap: 48px;

    &-meta-field {
      flex-basis: 330px;
    }

    &-members {
      flex-basis: 300px;
    }

    &-campaign,
    &-campaigns {
      flex-basis: 300px;
    }

    &-connected-with,
    &-coupons {
      flex: 1;
    }

    &-action {
      flex-basis: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px dashed #d3d5e1;
      height: 100%;
      padding-right: 16px;
    }
  }
}

.meta-field-members-list {
  padding-bottom: 16px;
  padding-right: 16px;
}

.meta-field-members-list::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}
.meta-field-members-list::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #DADADA;
}
