@import "../../../../styles/media-queries.scss";
@import "../../../../styles/variables.scss";

.store-configuration {
  max-height: 454px;

  &__instructions {
    width: 100%;
    border-top: dashed 1px #ededef;
    padding: {
      top: 12px;
      right: 12px;
    }
    overflow-y: scroll;
    height: calc(100% - 180px);

    @include min-screen($desktopLargeWidth) {
      height: calc(100% - 40px);
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f2f2f6;
      border-radius: 30px;
      width: 6px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #a7abc3;
      border-radius: 30px;
      width: 6px;
      height: 64px;
    }

    code {
      font-weight: bold;
      color: #45485b;
    }

    ul {
      list-style: inside;
      list-style-type: disc;
    }

    &-webhook-url {
      display: flex;
      align-items: center;
      margin: {
        top: 8px;
        bottom: 24px;
      }

      input {
        height: 40px;
        border-radius: 8px 0 0 8px;
        padding: 14px 12px 14px 16px;
        width: calc(100% - 120px);
        border: 1px solid #b4b6be;
        outline: none;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: #45485b;

        &:focus {
          border: 1px solid #9eaeff;
        }
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 8px 8px 0;
        height: 40px;
        width: 120px;
        border: 1px solid #5d78ff;
        background: #5d78ff;
        color: white;
        outline: none;
        border: none;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        cursor: pointer;
        transition: all 0.2s ease;

        svg {
          margin-right: 8px;
          path {
            fill: white;
          }
        }

        &:hover {
          background: #7d93ff;
        }
      }
    }

    &-section {
    }
  }
}
