.template-selector {
  position: relative;
  display: flex;
  align-items: center;
  width: 274px;
  z-index: 100;

  &--disabled {
    .template-selector {
      &__value {
        pointer-events: none;
        background: rgba(#d3d5e1, 0.3) !important;
        opacity: 0.5;
      }

      &__menu {
        display: none;
      }
    }
  }

  &__value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #8e93ac;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    padding: 8px 12px 8px 16px;
    border: 1px solid #d3d5e1;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 1;

    &-placeholder {
      position: absolute;
      color: #d3d5e1;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      opacity: 0;
    }

    &--menu-open {
      //   box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
      border: 1px solid transparent;

      .template-selector__value {
        &-wrapper {
          opacity: 0;
        }

        &-placeholder {
          opacity: 1;
        }

        &-chevron {
          transform: rotate(180deg);
        }
      }
    }

    &-wrapper {
      display: flex;
      align-items: center;
      transition: all 0.3s ease;
    }

    .custom-image-wrapper,
    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 8px;
    }

    &-chevron {
      width: 20px;
      height: 20px;
      transition: all 0.3s ease;

      path {
        fill: #8e93ac;
      }
    }
  }

  &__menu {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
    background-color: white;
    padding: {
      top: 40px;
      bottom: 8px;
    }
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;

    &-separator {
      width: 100%;
      height: 1px;
      background: #f2f2f6;
      margin: 8px 0;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &-list {
      max-height: 280px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px transparent;
        border-radius: 2px;
        background-color: #e0e2ea;
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: #a7abc3;
        border-radius: 30px;
      }
    }

    &--visible {
      opacity: 1;
      visibility: visible;
      display: block;
    }

    &-item {
      height: 32px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 24px 8px 20px;
      cursor: pointer;

      &:hover {
        background-color: #f3f3f7;
      }

      &-cover {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 12px;
      }

      &-title {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #74788d;

        svg {
          height: 16px;
          width: 16px;
          margin-left: 4px;
        }
      }

      &-left {
        display: flex;
        align-items: center;
      }

      &-trashcan {
        display: grid;
        place-items: center;
        width: 32px;
        height: 32px;

        svg path {
          transition: all 0.3s ease;
        }

        &:hover {
          svg path {
            fill: #fd397a;
          }
        }
      }
    }
  }
}
