.audience-results {
  &__summary {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #a7abc3;

    &-column {
      display: flex;
      flex-direction: column;
      margin-right: 10px;

      > div {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        img {
          margin-right: 6px;
        }
      }
    }
  }

  &__legend {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #92bdf3;
  }

  .link-to-profile {
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: #a7abc3;
    display: flex;
    align-items: center;
    margin-top: 8px;

    &:hover {
      opacity: 0.7;
    }
  }

  .empty-influencer-list {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 30px 0px;

    &__left-side {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      text-align: center;
      color: #45485b;
      width: 100%;
    }

    &__right-side {
      display: flex;
      flex-direction: column;
      width: 75%;
      align-items: start;
      justify-content: center;
    }
  }

  .cdt-table {
    tbody tr:last-of-type {
      border-bottom: 0;
    }

    &__image {
      border-radius: 50%;
      border: none;

      &--error {
        background-image: url("../../../../../../../images/person.svg") !important;
        background-color: #f2f2f6 !important;
        background-size: 40px 40px !important;
        border: 0 !important;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    &__header--column {
      &-front_select_page {
        label span {
          font-size: 11px;
          line-height: 19px;
        }
      }
    }

    &__brand-safety {
      .material-tooltip__toggle {
        justify-content: center;
      }
    }
  }

  .cdt-table__number--light {
    font-weight: 500;
  }

  .gender {
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      width: 20px;
      height: 20px;
      margin-right: 4px;
      background: transparent no-repeat center;
      background-size: contain;
    }

    &--female::before {
      background-image: url("../../../../../../../images/gender-female.svg");
    }

    &--male::before {
      background-image: url("../../../../../../../images/gender-male.svg");
    }
  }

  .table-no-result {
    display: flex;
  }

  .competitors-post-check-column {
    width: 130px;
  }

  .velocity-index-tooltip {
    .tooltip__content {
      white-space: normal;
    }
  }

  .no-result-link {
    color: #1267fb;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  &__recent-posts {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 272px;
    gap: 16px;
  }

  .cdt-table__image-content {
    width: 80px;
    height: 80px;
  }

  &__go-to-social {
    color: #45485b;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    justify-content: center;

    &-info {
      margin-right: 4px;
      vertical-align: middle;

      path {
        fill: #8f919d;
      }
    }

    &-icon {
      margin-left: 12px;
      vertical-align: middle;

      path {
        fill: #45485b;
      }
    }

    .tooltip {
      cursor: default;
    }

    .tooltip__content {
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
    }

    a {
      color: inherit;
      text-decoration: inherit;
    }
  }
}
