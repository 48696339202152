.task-statistic {
  // Kacper TODOchange class name
  display: flex;
  align-items: center;

  &__header {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    padding: 9px 0;
    text-transform: uppercase;
    color: #a7abc3;
    letter-spacing: 1px;
    text-align: center;
    white-space: pre-wrap;
  }

  &__icon {
    width: 40px;
    height: 40px;

    svg {
      width: 40px;
      height: 40px;
    }
    
    margin-right: 16px;
  }

  &__edit {
    margin-left: 16px;
    width: 20px;
    height: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  &__value {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #74788d;
  }

  &__input {
    background-color: #eff1ff;
    color: #74788d;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    width: 150px;
    border: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }

    &--hidden {
      display: none;
    }

    &:focus {
      outline: none;
    }
  }
}

.statistic-dashed-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px 2px 44px 2px;
}
