.dropdown-set-as-access-level-dropdown {
  &__chevron-down {
    margin-left: 4px;
    width: 16px;
    height: 16px;
  }

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f2f2f6;
    border-radius: 8px;
    width: 90px;
    height: 28px;
    padding: 4px 8px;
    color: #8e93ac;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    text-transform: capitalize;
    cursor: pointer;

    &--disabled {
      cursor: not-allowed;
      background: #ededef;
      color: #b4b6be;

      &:hover {
        background: #ededef !important;
      }
    }

    &:hover {
      background: #e0e2ea;
    }
  }

  &__tick-blue {
    width: 20px;
    height: 20px;
    margin-left: 4px;
  }

  .dropdown__menu {
    min-width: 96px;
  }

  .dropdown__menu-item {
    padding: 6px 16px;
  }

  .dropdown__menu-item svg {
    margin-right: 0;
  }
}
