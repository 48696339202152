@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #a7abc3;
    border-radius: 30px;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #e0e2ea;
    border-radius: 30px;
    width: 6px;
  }
}
