@import "src/styles/variables.scss";

.navbar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 72px;
  width: calc(100% - 72px);
  height: 64px;
  background: white;
  border-bottom: 1px solid #d3d5e1;
  transition: margin 0.5s ease-in-out;
  z-index: $z-index-navigation;

  &__left {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 48px;
  }

  &__right {
    display: flex;
    align-items: center;
    padding-right: 48px;

    &-request-demo {
      background: #eff1ff;
      border-radius: 8px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #6a6d7c;
      padding: 7px 8px;
      margin-right: 24px;
      outline: none;
      border: none;
      cursor: pointer;
    }

    .dropdown__menu {
      &-list-wrapper {
        max-height: 50vh;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px transparent;
          border-radius: 2px;
          background-color: #e0e2ea;
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background: #a7abc3;
          border-radius: 30px;
        }
      }

      &-item {
        svg path {
          fill: #45485b;
        }

        &-tick path {
          fill: #5d78ff !important;
        }
      }
    }

    .yellow-plus {
      path {
        fill: white;
        &:first-of-type {
          fill: #ffb822;
        }
      }
    }

    &-user-data {
      display: flex;
      flex-direction: column;
      margin-right: 12px;
    }

    &-name {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #74788d;
    }

    &-workspace-name {
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      color: #a7abc3;
    }

    &-avatar {
      width: 32px;
      height: 32px;
      background-color: transparentize($color: #a7abc3, $amount: 0.7);
      border-radius: 50%;
      background-image: url("../../images/avatar.svg");
      background-size: 25px 25px;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 12px;

      .avatar-upload-input {
        display: none;
      }

      img {
        width: 100%;
        height: 32px;
        object-fit: cover;
        object-position: center;
        border-radius: inherit;
        border-radius: 50%;
        margin: 0;
        transition: all 0.15s ease;
        cursor: pointer;
      }
    }
  }

  &__right-separator {
    height: 24px;
    width: 1px;
    background-color: #d3d5e1;
    margin: 0 24px;
  }

  &__demo-mode-btn {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    color: #45485b;
    margin-right: 32px;
  }
}
