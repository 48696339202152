.showcase-loader {
  background: transparentize($color: #fff, $amount: 0);
  left: 0;
  top: 0;
  width: 100%;
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
}
