.sentiment-analysis {
  &__tab-content {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    line-height: 26px;
    font-size: 18px;

    span,
    svg {
      width: 40px;
      height: 40px;
    }
  }
}
