.coupon-groups-table {
  &__topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    margin-bottom: 24px;
  }
  &__buttons {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
