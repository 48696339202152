.field-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 158px;
  padding: 8px 16px;
  transition: all 0.2s ease;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #f2f2f6;
  }

  &__data {
    display: flex;
    align-items: center;
  }

  &__data-name {
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    color: #8e93ac;
    margin-right: 8px;
  }
}
