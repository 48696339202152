@import "../../../../../styles/mixins.scss";

.global-fields {
  .global-fields__input-wrapper {
    .ws-button {
      flex-basis: 130px;
    }
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    input {
      flex-grow: 1;
    }

    .ws-input {
      height: 46px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 16px;
    padding-right: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #8f919d;

    &-column-name {
      display: flex;
      flex-grow: 1;
    }

    span {
      text-align: center;
    }

    &-reserved-for {
      flex-basis: 100px;
    }

    &-type {
      flex-basis: 40px;
    }

    &-action {
      flex-basis: 56px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 250px;
    @include custom-scrollbar;

    &-item {
      display: flex;
      align-items: center;
      padding: 16px 8px;
      cursor: pointer;
      transition: background-color 0.2s;
      left: auto !important;
      background-color: #fff;

      &--disabled {
        cursor: not-allowed;
      }

      &:hover {
        background-color: #ededef;
      }

      &-drag {
        margin-right: 6px;
      }
    }
  }

  &__text {
    flex-grow: 1;
  }

  &__name {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #45485b;
    display: flex;
    align-items: center;
    gap: 10px;

    &-text {
      max-width: 334px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #8f919d;
    max-width: 348px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__reserved-for {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #8f919d;
    text-align: center;
    flex-basis: 100px;
    text-transform: capitalize;
  }

  &__icon {
    flex-basis: 40px;
    display: flex;
    justify-content: center;
    svg {
      width: 24px;
      height: 24px;
    }
    path {
      fill: #6a6d7c;
    }
  }

  &__action {
    display: flex;
    justify-content: center;
    flex-basis: 56px;
  }

  &__tooltip-wrapper {
    max-width: 169px;
  }

  &__tooltip-title {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
  }
  &__tooltip-description {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    margin: 0;
    margin-top: 4px;
  }

  &__loader {
    .loader-wrapper {
      width: 80px;
      height: 50px;
      margin: 0 auto;
    }
  }
}
