@import "src/styles/mixins.scss";

.projects-data-table__cell {
  min-width: 72px;
  min-height: 36px;
  flex-shrink: 0;
  // display: flex;
  align-items: center;
  height: 86px;
  width: auto;
  border-bottom: dashed #d3d5e1 1px;

  &__content {
    padding: 0 24px;
    max-width: 240px;
  }

  &[data-sticky-last-left-td="true"] {
    position: sticky;
    left: 2px !important;
    padding-left: 50px;
    width: 483px;

    border-right: 1px dashed #d3d5e1;
    background: white;
    z-index: 5;

    &::before {
      content: "";
      position: absolute;
      left: -2px;
      top: -4px;
      height: calc(100% + 5px);
      width: 52px;
      background: white;
    }
  }

  &[data-sticky-first-right-td="true"] {
    position: sticky;
    right: 52px !important;
    width: 86px;

    border-left: 1px dashed #d3d5e1;
    background: white;
    z-index: 5;

    &::after {
      content: "";
      position: absolute;
      right: -52px;
      top: -2px;
      height: calc(100% + 5px);
      width: 52px;
      background: white;
    }
  }

  .text-box.value-textbox {
    max-height: 78px;
    overflow-y: auto;
    padding: 0;
    padding-right: 4px;
    margin: 8px 0;
    @include custom-scrollbar;
  }

  .select__value-wrapper {
    max-height: 66px;
    overflow-y: auto;
    padding-right: 4px;
    max-width: 160px;
    @include custom-scrollbar;
  }

  .task-meta-fields__value-content {
    z-index: 2;
  }

  .task-meta-fields__value .value-textbox,
  .task-meta-fields__value .value-input {
    width: unset;
    max-width: 160px;
    min-width: 25px;
  }

  .add-button {
    width: 66px;

    &--circle {
      width: 26px;
    }
  }
}
