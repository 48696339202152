.publication-summary-tab {
}

.task-section-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &--with-tooltip {
    justify-content: flex-start;
  }
}
