.projects-table-filters {
  &__fields-wrap {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  .ws-checkbox label span {
    word-break: break-all;
  }

  .pane__container.filters {
    height: calc(100vh - 102px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      border-radius: 4px;
      width: 5px;
      background: #ededef;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      width: 5px;
      background: #8f919d;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #6e6f77;
    }
  }
}
.project-filters-button {
  border-radius: 0 0 4px 4px;
}
