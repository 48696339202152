.rename-task-modal {
  .modal__title {
    margin: 0;
    gap: 0;
  }

  .modal__text {
    margin-top: 16px;
  }

  .modal__column {
    width: 100%;

    input {
      width: 100%;
    }
  }

  button[type="submit"] {
    gap: 0;
  }
}
